import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["callback", "error"],
  _excluded2 = ["callback"],
  _excluded3 = ["callback"],
  _excluded4 = ["callback"],
  _excluded5 = ["callback"],
  _excluded6 = ["callback", "events"],
  _excluded7 = ["callback"],
  _excluded8 = ["callback"],
  _excluded9 = ["user"],
  _excluded10 = ["callback"],
  _excluded11 = ["callback"],
  _excluded12 = ["callback"],
  _excluded13 = ["callback"],
  _excluded14 = ["callback"],
  _excluded15 = ["callback"];
import afterAuthNavigationActions from './afterAuthNavigation.actions';
import handleVerifyMFAResponseActions from './handleVerifyMFAResponse.actions';
import mfaRequiredStateActions from './mfaRequiredState.actions';
import mfaWithAuthenticatorActions from './mfaWithAuthenticator.actions';
import mfaWithEmailCodeActions from './mfaWithEmailCode.actions';
import mfaWithSMSActions from './mfaWithSMS.actions';
import mfaWithWebAuthnActions from './mfaWithWebAuthn.actions';
import { LoginFlow, LoginStep } from '../interfaces';
import { base64urlDecode, deepResetState, errorHandler, errorTraceId, GTMEventAction, publicKeyCredentialToJSON, reportGTMEvent, retryIfNeeded, withRetryConfig } from '../../../helpers';
import { initialState } from '../state';
import { getSearchParam, isEmailPayload, TENANT_ID_PARAM_KEY } from '../helpers';
import { AuthStrategyEnum, ContextHolder, removeTabTenantFromSessionStorage, WebAuthnDeviceType } from '@frontegg/rest-api';
import hostedLoginAuthorizeActions from './hostedLoginAuthorize.actions';
import { FronteggNativeModule, isEntitlementsDeeplyEqual } from '../../../toolkit';
import { REQUEST_NAME, UserVerifiedOriginTypes } from '../../interfaces';
import { authStrategyLoginStepMap } from '../consts';
import { isMfaRequired } from '../../helpers';
import { MFAStep } from '../../MfaState/interfaces';
import { SamlVendors } from '../../SSOState/interfaces';
import { DEFAULT_RETRY_CONFIG } from '../../../constants';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const contextHolder = ContextHolder.for(store.root.appName);
  /** @private */
  const __refreshTokenHosted = async () => {
    const user = store.auth.user;
    if (!(user != null && user.refreshToken)) {
      contextHolder.setAccessToken(null);
      contextHolder.setUser(null);
      actions.setAuthState({
        user: null,
        isAuthenticated: false
      });
      return;
    }
    try {
      const body = {
        grant_type: 'refresh_token',
        refresh_token: user == null ? void 0 : user.refreshToken
      };
      const response = await api.auth.exchangeOAuthTokensV2(body);
      const updatedUser = await __handleUnnecessaryEntitlementsUpdate(response.user);
      actions.afterAuthenticationStateUpdate(_extends({}, response, {
        user: updatedUser
      }), {
        isAuthenticated: true
      });
    } catch (e) {
      contextHolder.setAccessToken(null);
      contextHolder.setUser(null);
      actions.setAuthState({
        user: null,
        isAuthenticated: false
      });
    }
  };
  const __shouldNavigateToRegisterQuickLogin = user => {
    var _ref;
    const {
      routes,
      loginState
    } = store.auth;
    const quickLoginToRegister = (_ref = localStorage.getItem('register-quick-login')) != null ? _ref : loginState.quickLoginToRegister;
    return quickLoginToRegister && localStorage.getItem(`${user.id}-${quickLoginToRegister}`) !== 'true' && !window.location.pathname.endsWith(routes.logoutUrl);
  };

  /** @private */
  const __refreshTokenEmbedded = async () => {
    try {
      const {
        routes,
        loginState,
        onRedirectTo
      } = store.auth;
      const {
        user,
        tenants = [],
        activeTenant
      } = await api.auth.refreshTokenV3();
      if (isMfaRequired(user, store.root.appName)) {
        const mfaRequiredState = await actions.getMfaRequiredState(user);
        actions.setAuthState(mfaRequiredState);
        onRedirectTo(routes.loginUrl, {
          preserveQueryParams: true
        });
      } else {
        var _ref2;
        if (user.id) {
          localStorage.setItem('userId', user.id);
        }
        const quickLoginToRegister = (_ref2 = localStorage.getItem('register-quick-login')) != null ? _ref2 : loginState.quickLoginToRegister;
        const shouldNavigateToRegisterQuickLogin = __shouldNavigateToRegisterQuickLogin(user);
        const updatedUser = await __handleUnnecessaryEntitlementsUpdate(user);
        actions.afterAuthenticationStateUpdate({
          user: updatedUser,
          tenants,
          activeTenant
        }, {
          loginState: _extends({}, loginState, {
            quickLoginToRegister,
            flow: shouldNavigateToRegisterQuickLogin ? LoginFlow.RegisterQuickLogin : LoginFlow.Login
          }),
          isAuthenticated: true
        });
        await __handleRedirectRefreshToken(shouldNavigateToRegisterQuickLogin);
      }
    } catch (e) {
      contextHolder.setAccessToken(null);
      contextHolder.setUser(null);
      actions.setAuthState({
        user: undefined,
        isAuthenticated: false
      });
    }
  };

  /** @protected */
  const __refreshToken = async () => {
    const hostedLoginBox = store.auth.hostedLoginBox;
    if (hostedLoginBox) {
      await __refreshTokenHosted();
    } else {
      await __refreshTokenEmbedded();
    }
  };

  /**
   * Prevent unnecessary entitlements reference update inside the user object by
   * deep equality with the stored user.entitlements
   * This function should not be used for first login because no stored entitlements.
   * @param updatedUser new user response from the BE
   * @returns final user object with the correct entitlements reference
   *
   * @private
   */
  const __handleUnnecessaryEntitlementsUpdate = async updatedUser => {
    var _store$auth$user;
    // @ts-ignore TODO: fix entitlements state type
    const oldEntitlements = (_store$auth$user = store.auth.user) == null ? void 0 : _store$auth$user.entitlements;
    if (isEntitlementsDeeplyEqual(oldEntitlements, updatedUser == null ? void 0 : updatedUser.entitlements)) {
      // set the previous entitlements object
      return _extends({}, updatedUser, {
        entitlements: oldEntitlements
      });
    }
    return updatedUser;
  };

  /** @private */
  const __handleRedirectRefreshToken = async shouldNavigateToRegisterQuickLogin => {
    var _window;
    const url = new URL((_window = window) == null ? void 0 : _window.location.href);
    const {
      routes,
      loginState,
      onRedirectTo
    } = store.auth;
    const invitationToken = url.searchParams.get('invitationToken');
    const redirectRoutes = [routes.socialLoginCallbackUrl, routes.oidcRedirectUrl, routes.samlCallbackUrl];
    if (!invitationToken) {
      redirectRoutes.push(routes.loginUrl, routes.signUpUrl);
    }
    if (shouldNavigateToRegisterQuickLogin) {
      onRedirectTo(routes.loginUrl);
    } else if (redirectRoutes.some(url => url && window.location.pathname.endsWith(url))) {
      if (loginState.isNewUser && routes.signUpSuccessUrl && routes.socialLoginCallbackUrl === window.location.pathname) {
        onRedirectTo(routes.signUpSuccessUrl, {
          refresh: routes.signUpSuccessUrl.startsWith('http')
        });
      } else {
        await actions.afterAuthNavigation();
      }
    }
  };

  /** @private */
  const __loadSSOPublicConfigurationFunction = async payload => {
    try {
      var _payload$callback;
      const {
        isActive
      } = await retryIfNeeded(api.auth.getSSOPublicConfiguration, payload == null ? void 0 : payload.retryConfig);
      actions.setAuthState({
        isSSOAuth: isActive
      });
      payload == null ? void 0 : (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, {
        isSSOAuth: isActive
      });
    } catch (e) {
      var _payload$callback2;
      actions.setAuthState({
        isSSOAuth: false
      });
      payload == null ? void 0 : (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, null, e);
    }
  };
  const __refreshMetadata = async payload => {
    let ssoACS;
    try {
      var _metadata$configurati;
      const metadata = await retryIfNeeded(api.metadata.getSamlMetadata, payload == null ? void 0 : payload.retryConfig);
      ssoACS = metadata == null ? void 0 : (_metadata$configurati = metadata.configuration) == null ? void 0 : _metadata$configurati.acsUrl;
    } catch (e) {
      console.error(e);
    }
    actions.setAuthState({
      ssoACS
    });
  };

  /** @private */
  const __isMFARequiredSSR = async ({
    accessToken,
    user
  }) => {
    if (!accessToken) {
      actions.setAuthState({
        user: undefined,
        isAuthenticated: false
      });
      return;
    }
    const {
      onRedirectTo,
      routes
    } = store.auth;
    if (isMfaRequired(user, store.root.appName)) {
      const mfaRequiredState = await actions.getMfaRequiredState(user, DEFAULT_RETRY_CONFIG, true);
      actions.setAuthState(mfaRequiredState);
      onRedirectTo(routes.loginUrl, {
        preserveQueryParams: true
      });
    }
  };
  const setLoginState = state => {
    Object.assign(store.auth.loginState, state);
  };
  const resetLoginState = () => {
    deepResetState(store, ['auth', 'loginState'], initialState);
  };
  const getRetryWithBlockingCallbackPayload = requestName => {
    const callback = (_, error) => {
      if (!error || error.statusCode < 500) return;
      actions.setErrorByRequestName({
        requestName,
        traceId: errorTraceId(error)
      });
    };
    return withRetryConfig({
      callback
    });
  };
  const requestAuthorize = async firstTime => {
    const calls = [];
    const callsAfterRefresh = [];
    calls.push(__refreshToken());
    if (firstTime) {
      actions.setAuthState({
        isLoading: true
      });
      calls.push(actions.loadSocialLoginsConfigurationV2(withRetryConfig()));
      calls.push(actions.loadAllowSignUps(getRetryWithBlockingCallbackPayload(REQUEST_NAME.LOAD_ALLOW_SIGNUPS)));
      calls.push(actions.loadPublicAuthStrategiesPolicy(getRetryWithBlockingCallbackPayload(REQUEST_NAME.LOAD_PUBLIC_AUTH_STRATEGIES_POLICY)));
      calls.push(__loadSSOPublicConfigurationFunction(getRetryWithBlockingCallbackPayload(REQUEST_NAME.LOAD_SSO_PUBLIC_CONFIGURATION)));
      calls.push(actions.loadVendorPublicInfo(withRetryConfig()));
      calls.push(__refreshMetadata(withRetryConfig()));
      /*
        We will load custom login routes only if custom login is enabled
         to check if custom login is enabled without the tenant alias (search-param/subdomain)
        we have to wait for the user state (refreshToken request)
      */
      callsAfterRefresh.push(actions.loadCustomLoginRoutes(getRetryWithBlockingCallbackPayload(REQUEST_NAME.LOAD_CUSTOM_LOGIN_ROUTES)));
    }
    await Promise.all(calls);
    if (callsAfterRefresh.length > 0) {
      await Promise.all(callsAfterRefresh);
    }
    actions.setAuthState({
      isLoading: false
    });
  };
  const requestAuthorizeSSR = async payload => {
    const calls = [];
    calls.push(actions.loadSocialLoginsConfigurationV2(withRetryConfig()));
    calls.push(actions.loadAllowSignUps(getRetryWithBlockingCallbackPayload(REQUEST_NAME.LOAD_ALLOW_SIGNUPS)));
    calls.push(__loadSSOPublicConfigurationFunction(getRetryWithBlockingCallbackPayload(REQUEST_NAME.LOAD_SSO_PUBLIC_CONFIGURATION)));
    calls.push(actions.loadVendorPublicInfo(withRetryConfig()));
    calls.push(__refreshMetadata());
    calls.push(__isMFARequiredSSR(payload));
    calls.push(actions.loadCustomLoginRoutes(getRetryWithBlockingCallbackPayload(REQUEST_NAME.LOAD_CUSTOM_LOGIN_ROUTES)));
    await Promise.all(calls);
  };
  const ssoPreloginFailed = async _ref3 => {
    let {
        callback,
        error
      } = _ref3,
      body = _objectWithoutPropertiesLoose(_ref3, _excluded);
    const publicPolicy = store.auth.securityPolicyState.publicPolicy.policy;
    if (!(publicPolicy != null && publicPolicy.authStrategy)) {
      actions.setLoginState({
        step: LoginStep.loginWithPassword,
        loading: false
      });
      callback == null ? void 0 : callback();
      return;
    }
    if ((publicPolicy == null ? void 0 : publicPolicy.authStrategy) === AuthStrategyEnum.EmailAndPassword) {
      actions.setLoginState({
        step: LoginStep.loginWithPassword,
        loading: false
      });
      callback == null ? void 0 : callback();
    } else if ([AuthStrategyEnum.MagicLink, AuthStrategyEnum.Code, AuthStrategyEnum.SmsCode].includes(publicPolicy == null ? void 0 : publicPolicy.authStrategy)) {
      await actions.passwordlessPreLogin(_extends({}, body, {
        type: publicPolicy == null ? void 0 : publicPolicy.authStrategy,
        callback
      }));
    } else {
      actions.setLoginState({
        step: LoginStep.loginWithPassword,
        loading: false,
        error: errorHandler(error)
      });
      callback == null ? void 0 : callback();
    }
  };
  const preLogin = async payload => {
    const {
      email,
      recaptchaToken,
      invitationToken,
      callback
    } = payload;
    setLoginState({
      loading: true
    });
    try {
      const onRedirectTo = store.auth.onRedirectTo;
      const tenantId = getSearchParam(TENANT_ID_PARAM_KEY);
      const preLoginResult = await api.auth.preLoginV2({
        email,
        tenantId
      });
      const {
        address,
        idpType
      } = preLoginResult != null ? preLoginResult : {};
      if (address) {
        let ssoRedirectUrl = address;
        if (idpType === SamlVendors.Oidc && !ssoRedirectUrl.includes('redirect_uri')) {
          const {
            oidcRedirectUrl
          } = store.auth.routes;
          ssoRedirectUrl += `&redirect_uri=${window.location.origin}${oidcRedirectUrl}`;
        }
        if (FronteggNativeModule.isLoginWithSSOAvailable()) {
          FronteggNativeModule.loginWithSSO(email);
          setLoginState({
            loading: false
          });
          callback == null ? void 0 : callback();
        } else {
          setLoginState({
            step: LoginStep.redirectToSSO,
            loading: false,
            ssoRedirectUrl
          });
          setTimeout(() => {
            onRedirectTo(ssoRedirectUrl, {
              refresh: true
            });
          }, 2000);
        }
      } else {
        await ssoPreloginFailed({
          email,
          recaptchaToken,
          callback,
          invitationToken
        });
      }
    } catch (e) {
      await ssoPreloginFailed({
        email,
        recaptchaToken,
        callback,
        invitationToken,
        error: e
      });
    }
  };
  const postLogin = async payload => {
    const {
      onRedirectTo,
      routes
    } = store.auth;
    setLoginState({
      loading: true
    });
    try {
      const user = await api.auth.postLogin(payload);
      actions.setAuthState({
        user: user.accessToken ? user : undefined,
        isAuthenticated: !!user.accessToken
      });
      await actions.afterAuthNavigation();
    } catch (e) {
      setTimeout(() => {
        onRedirectTo(routes.authenticatedUrl);
      }, 1000);
      setLoginState({
        step: LoginStep.loginWithSSOFailed,
        loading: false
      });
    }
  };
  const login = async payload => {
    const {
      email,
      password,
      recaptchaToken,
      invitationToken,
      callback
    } = payload;
    setLoginState({
      loading: true
    });
    try {
      const {
        user,
        tenants = [],
        activeTenant
      } = await api.auth.loginv2({
        email,
        password,
        recaptchaToken,
        invitationToken
      });
      const {
        onRedirectTo,
        routes
      } = store.auth;
      if (isMfaRequired(user, store.root.appName)) {
        const mfaRequiredState = await actions.getMfaRequiredState(user, DEFAULT_RETRY_CONFIG, true);
        actions.setAuthState(mfaRequiredState);
        onRedirectTo(routes.loginUrl, {
          preserveQueryParams: true
        });
      } else {
        const loginState = store.auth.loginState;
        const isAuthenticated = !!user.accessToken;
        if (user.id) {
          localStorage.setItem('userId', user.id);
        }
        actions.afterAuthenticationStateUpdate({
          user,
          tenants,
          activeTenant
        }, {
          loginState: {
            flow: loginState.flow,
            quickLoginToRegister: loginState.quickLoginToRegister,
            email,
            loading: false,
            error: undefined,
            mfaToken: user.mfaToken,
            step: loginState.flow === LoginFlow.Login ? LoginStep.success : loginState.step,
            tenants,
            tenantsLoading: true,
            isBreachedPassword: user.isBreachedPassword
          },
          isAuthenticated
        });
        const [securityCenterLoginFlows] = await actions.getFeatureFlags(['security-center-show-login-flows']);
        if (loginState.flow === LoginFlow.Login) {
          if (securityCenterLoginFlows && user.isBreachedPassword && !isAuthenticated) {
            setLoginState({
              step: LoginStep.breachedPassword,
              loading: false
            });
          } else {
            if (isAuthenticated) {
              const shouldShowPrompt = await actions.__shouldShowPromptPasskeys();
              if (shouldShowPrompt) {
                setLoginState({
                  step: LoginStep.promptPasskeys,
                  loading: false
                });
                onRedirectTo(routes.loginUrl, {
                  preserveQueryParams: true
                });
              } else {
                await actions.afterAuthNavigation();
              }
            }
          }
        }
      }
      if (FronteggNativeModule.isSuggestSavePasswordAvailable()) {
        FronteggNativeModule.suggestSavePassword(email, password);
      }

      // TODO: extract item name to constants
      localStorage.removeItem('register-quick-login');
      callback == null ? void 0 : callback(true);
    } catch (e) {
      contextHolder.setAccessToken(null);
      contextHolder.setUser(null);
      callback == null ? void 0 : callback(false, e);
      setLoginState({
        email,
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const logout = async payload => {
    const hostedLoginBox = store.auth.hostedLoginBox;
    actions.setAuthState({
      isLoading: true
    });
    try {
      if (hostedLoginBox) {
        await api.auth.OAuthLogout();
      } else {
        await api.auth.logout();
      }
    } catch {
      /* empty */
    }
    if (contextHolder.isSessionPerTenantEnabled()) {
      removeTabTenantFromSessionStorage();
    }
    actions.resetAuthState();
    await actions.requestAuthorize(true);
    payload == null ? void 0 : payload();
  };
  const silentLogout = async payload => {
    var _payload$callbackTime;
    try {
      await api.auth.logout();
    } catch {
      /* empty */
    }
    if (contextHolder.isSessionPerTenantEnabled()) {
      removeTabTenantFromSessionStorage();
    }
    setTimeout(() => {
      var _payload$callback3;
      return payload == null ? void 0 : (_payload$callback3 = payload.callback) == null ? void 0 : _payload$callback3.call(payload, true);
    }, (_payload$callbackTime = payload == null ? void 0 : payload.callbackTimeout) != null ? _payload$callbackTime : 500);
  };
  const recoverMfa = async payload => {
    setLoginState({
      loading: true
    });
    try {
      var _payload$callback4;
      await api.auth.recoverMfaToken(payload);
      setLoginState({
        loading: false,
        error: undefined,
        step: LoginStep.preLogin
      });
      actions.setAuthState({
        user: undefined,
        isAuthenticated: false
      });
      (_payload$callback4 = payload.callback) == null ? void 0 : _payload$callback4.call(payload, true);
    } catch (e) {
      var _payload$callback5;
      setLoginState({
        error: errorHandler(e),
        loading: false
      });
      (_payload$callback5 = payload.callback) == null ? void 0 : _payload$callback5.call(payload, false, e);
    }
  };
  const quickSmsPasswordlessPreLogin = async _payload => {
    const {
        callback
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded2);
    try {
      setLoginState({
        loading: true
      });

      // TODO: fix @frontegg/rest-api return value
      // @ts-ignore
      const preloginRes = await api.auth.passwordlessPreLogin(_extends({}, payload, {
        type: AuthStrategyEnum.SmsCode
      }));
      setLoginState({
        step: LoginStep.loginWithQuickSmsOtc,
        loading: false,
        phoneNumber: preloginRes == null ? void 0 : preloginRes.phoneNumber,
        error: undefined
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setLoginState({
        error: errorHandler(e),
        loading: false
      });
      callback == null ? void 0 : callback(false, e);
    }
  };
  const changePhoneNumberWithVerification = async _payload => {
    const {
        callback
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded3);
    try {
      setLoginState({
        loading: true
      });
      const changePhoneRes = await api.auth.changePhoneNumberWithVerification(payload);
      setLoginState({
        phoneNumber: payload.phoneNumber,
        loading: false,
        changePhoneId: changePhoneRes.changePhoneId,
        step: LoginStep.loginWithQuickSmsOtc,
        error: undefined
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setLoginState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const verifyChangePhoneNumber = async _payload => {
    const {
        callback
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded4);
    try {
      setLoginState({
        loading: true
      });
      await api.auth.verifyChangePhoneNumber(payload);
      setLoginState({
        loading: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setLoginState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const passwordlessPreLogin = async _payload => {
    const {
        callback
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded5);
    try {
      setLoginState({
        loading: true
      });
      let email;
      if (isEmailPayload(payload)) {
        email = payload.email;
      }
      // TODO: [Typescript 4.8] fix @frontegg/rest-api return value
      // @ts-ignore
      const preloginRes = await api.auth.passwordlessPreLogin(payload);
      // @ts-ignore
      const step = authStrategyLoginStepMap[payload.type];
      setLoginState({
        step,
        loading: false,
        phoneNumber: preloginRes == null ? void 0 : preloginRes.phoneNumber,
        email,
        error: undefined
      });
      callback == null ? void 0 : callback();
    } catch (e) {
      setLoginState({
        error: errorHandler(e),
        loading: false
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const passwordlessPostLogin = async _payload => {
    const {
        callback,
        events
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded6);
    try {
      setLoginState({
        loading: true
      });
      const {
        user,
        tenants = [],
        activeTenant
      } = await api.auth.passwordlessPostLoginV2(payload);
      const {
        routes,
        onRedirectTo
      } = store.auth;
      if (isMfaRequired(user, store.root.appName)) {
        const mfaRequiredState = await actions.getMfaRequiredState(user);
        actions.setAuthState(mfaRequiredState);
        onRedirectTo(routes.loginUrl, {
          preserveQueryParams: true
        });
      } else {
        const loginState = store.auth.loginState;
        if (user.emailVerified) {
          var _events$userVerified;
          const userVerifiedPayload = {
            email: user.email,
            origin: UserVerifiedOriginTypes.PASSWORDLESS,
            id: user.id,
            tenantId: user.tenantId,
            createdAt: new Date(),
            name: user.name
          };
          events == null ? void 0 : (_events$userVerified = events.userVerified) == null ? void 0 : _events$userVerified.call(events, userVerifiedPayload);
          reportGTMEvent(GTMEventAction.USER_VERIFIED, userVerifiedPayload);
        }
        if (user.id) {
          localStorage.setItem('userId', user.id);
        }
        actions.afterAuthenticationStateUpdate({
          user,
          tenants,
          activeTenant
        });
        setLoginState({
          error: undefined
        });
        actions.setAuthState({
          isLoading: false
        });
        if (loginState.flow === LoginFlow.Login) {
          const shouldShowPrompt = await actions.__shouldShowPromptPasskeys();
          if (shouldShowPrompt) {
            actions.setLoginState({
              step: LoginStep.promptPasskeys,
              loading: false
            });
            onRedirectTo(routes.loginUrl, {
              preserveQueryParams: true
            });
          } else {
            actions.setAuthState({
              isAuthenticated: true
            });
            await actions.afterAuthNavigation();
          }
        } else {
          onRedirectTo(routes.loginUrl, {
            preserveQueryParams: true
          });
        }
        localStorage.removeItem('register-quick-login');
        callback == null ? void 0 : callback(true);
      }
    } catch (e) {
      setLoginState({
        error: errorHandler(e, 'Failed to authenticate')
      });
      callback == null ? void 0 : callback(null, e);
    } finally {
      setLoginState({
        loading: false
      });
    }
  };
  const verifyInviteToken = async payload => {
    try {
      setLoginState({
        loading: true
      });

      // @ts-ignore TODO: fix @frontegg/rest-api return value
      const {
        name: inviteTokenTenantName
      } = await api.auth.verifyInviteToken(payload);
      setLoginState({
        inviteTokenTenantName
      });
    } catch (e) {
      setLoginState({
        inviteTokenError: errorHandler(e, `We couldn't verify your invitation`)
      });
    } finally {
      setLoginState({
        loading: false
      });
    }
  };
  const webAuthnPrelogin = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded7);
    try {
      var _options$allowCredent;
      actions.setPasskeysState({
        loading: true
      });
      setLoginState({
        loading: true
      });

      // @ts-ignore TODO: fix @frontegg/rest-api return value
      const {
        options
      } = await api.auth.webAuthnPreLogin(body);
      options.challenge = base64urlDecode(options.challenge);
      options.allowCredentials = (_options$allowCredent = options.allowCredentials) == null ? void 0 : _options$allowCredent.map(credentials => _extends({}, credentials, {
        id: base64urlDecode(credentials.id)
      }));
      setLoginState({
        error: undefined
      });
      callback == null ? void 0 : callback(options);
    } catch (e) {
      setLoginState({
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null);
    } finally {
      actions.setPasskeysState({
        loading: false
      });
      setLoginState({
        loading: false
      });
    }
  };
  const webAuthnPostLogin = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded8);
    try {
      var _publicKey$response$u;
      actions.setPasskeysState({
        loading: true
      });
      setLoginState({
        loading: true
      });
      const publicKey = publicKeyCredentialToJSON(body.publicKey);
      const _await$api$auth$webAu = await api.auth.webAuthnPostLoginV2(_extends({}, publicKey, {
          response: _extends({}, publicKey.response, {
            userHandle: (_publicKey$response$u = publicKey.response.userHandle) != null ? _publicKey$response$u : undefined
          }),
          recaptchaToken: body.recaptchaToken,
          invitationToken: body.invitationToken
        })),
        {
          user
        } = _await$api$auth$webAu,
        rest = _objectWithoutPropertiesLoose(_await$api$auth$webAu, _excluded9);
      if (isMfaRequired(user, store.root.appName)) {
        const {
          routes,
          onRedirectTo
        } = store.auth;
        const mfaRequiredState = await actions.getMfaRequiredState(user);
        actions.setAuthState(mfaRequiredState);
        onRedirectTo(routes.loginUrl, {
          preserveQueryParams: true
        });
      } else {
        if (user.id) {
          localStorage.setItem('userId', user.id);
        }
        actions.afterAuthenticationStateUpdate(_extends({
          user
        }, rest), {
          isAuthenticated: true
        });
        setLoginState({
          error: undefined
        });
        await actions.afterAuthNavigation();
        // TODO: Itamar why moving callback to inside the else block
        callback == null ? void 0 : callback(true);
      }
      // callback?.(true);
    } catch (e) {
      setLoginState({
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null);
    } finally {
      actions.setPasskeysState({
        loading: false
      });
      setLoginState({
        loading: false
      });
    }
  };
  const webAuthnCreateNewDeviceSession = async payload => {
    const {
      callback
    } = payload;
    try {
      setLoginState({
        loading: true
      });

      // @ts-ignore TODO: fix @frontegg/rest-api return value
      const {
        options
      } = await api.auth.webAuthnCreateNewDeviceSession();
      options.user.id = base64urlDecode(options.user.id);
      options.challenge = base64urlDecode(options.challenge);
      options.excludeCredentials = [];
      callback == null ? void 0 : callback(options);
    } catch (e) {
      setLoginState({
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null);
    } finally {
      setLoginState({
        loading: false
      });
    }
  };
  const webAuthnVerifyNewDeviceSession = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded10);
    try {
      setLoginState({
        loading: true
      });
      const publicKey = publicKeyCredentialToJSON(body.publicKey);
      const deviceType = (publicKey == null ? void 0 : publicKey.authenticatorAttachment) === 'platform' ? WebAuthnDeviceType.Platform : WebAuthnDeviceType.CrossPlatform;
      await api.auth.verifyNewDeviceSession({
        id: publicKey.id,
        response: publicKey.response,
        deviceType: deviceType
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setLoginState({
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null);
    } finally {
      setLoginState({
        loading: false
      });
    }
  };
  const getUserIP = async payload => {
    const {
      callback
    } = payload;
    try {
      actions.setAuthState({
        userIpData: {
          loading: true
        }
      });
      const {
        ip,
        country_code
      } = await api.metadata.getCurrentUserIpMetadata();
      actions.setAuthState({
        userIpData: {
          ip,
          countryCode: country_code,
          loading: false
        }
      });
      actions.setAuthState({
        userIp: ip
      });
      callback == null ? void 0 : callback({
        ip,
        countryCode: country_code
      });
    } catch (e) {
      actions.setAuthState({
        userIpData: {
          loading: false
        }
      });
      callback == null ? void 0 : callback(false);
    }
  };
  const preEnrollMFAWebAuthnForLogin = async _payload => {
    const {
        callback
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded11);
    setLoginState({
      loading: true
    });
    try {
      var _data$options$exclude;
      const data = await api.auth.preEnrollMFAWebAuthnForLogin(payload);
      const options = _extends({}, data.options, {
        challenge: base64urlDecode(data.options.challenge),
        user: _extends({}, data.options.user, {
          id: base64urlDecode(data.options.user.id)
        }),
        excludeCredentials: (_data$options$exclude = data.options.excludeCredentials) == null ? void 0 : _data$options$exclude.map(credentials => _extends({}, credentials, {
          id: base64urlDecode(credentials.id)
        }))
      });
      setLoginState({
        loading: false
      });
      callback == null ? void 0 : callback({
        options,
        webauthnToken: data.webauthnToken
      });
    } catch (e) {
      setLoginState({
        loading: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null);
    }
  };
  const enrollMFAWebAuthnForLogin = async _payload => {
    const {
        callback
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded12);
    setLoginState({
      loading: true
    });
    try {
      const publicKey = publicKeyCredentialToJSON(payload.publicKey);
      const data = await api.auth.enrollMFAWebAuthnForLoginV2(_extends({}, payload, {
        options: publicKey
      }));
      await handleEnrollMFAResponse(data);
      setLoginState({
        loading: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setLoginState({
        loading: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null);
    }
  };
  const enrollMFAAuthenticatorAppForLogin = async _payload => {
    const {
        callback
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded13);
    setLoginState({
      loading: true
    });
    try {
      const data = await api.auth.enrollMFAAuthenticatorAppForLoginV2(payload);
      await handleEnrollMFAResponse(data);
      setLoginState({
        loading: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setLoginState({
        loading: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null);
    }
  };
  const preEnrollMFASMSForLogin = async _payload => {
    const {
        callback
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded14);
    setLoginState({
      loading: true
    });
    try {
      const data = await api.auth.preEnrollMFASMSForLogin(payload);
      actions.setMfaState({
        otcToken: data.otcToken,
        step: MFAStep.smsVerifyCode,
        phoneNumber: data.phoneNumber
      });
      setLoginState({
        loading: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setLoginState({
        loading: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null);
    }
  };
  const enrollMFASMSForLogin = async _payload => {
    const {
        callback
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded15);
    setLoginState({
      loading: true
    });
    try {
      const data = await api.auth.enrollMFASMSForLoginV2(payload);
      await handleEnrollMFAResponse(data);
      setLoginState({
        loading: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setLoginState({
        loading: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null);
    }
  };
  const handleEnrollMFAResponse = async payload => {
    const {
      user,
      tenants = [],
      activeTenant
    } = payload;
    const mfaState = {
      step: MFAStep.recoveryCode,
      loading: false,
      error: undefined,
      saving: false
    };
    if (user != null && user.recoveryCode) {
      mfaState.recoveryCode = user.recoveryCode;
    }
    actions.setMfaState(mfaState);
    actions.setUser(user);
    actions.setTenantsState({
      tenants,
      activeTenant,
      loading: false
    });
    if (user.id) {
      localStorage.setItem('userId', user.id);
    }
  };
  const resetBreachedPassword = async payload => {
    setLoginState({
      loading: true
    });
    try {
      await api.auth.forgotPassword(payload);
      setLoginState({
        loading: false,
        error: undefined,
        step: LoginStep.breachedPasswordSuccess
      });
      actions.setAuthState({
        isAuthenticated: false
      });
    } catch (e) {
      setLoginState({
        loading: false,
        error: errorHandler(e)
      });
    }
  };

  /** @private */
  const __refreshTokenHostedSocialLogins = async () => {
    const {
      user: currentUser
    } = store.auth;
    if (!(currentUser != null && currentUser.refreshToken)) {
      contextHolder.setAccessToken(null);
      contextHolder.setUser(null);
      actions.setAuthState({
        user: null,
        isAuthenticated: false
      });
      return;
    }
    try {
      const body = {
        grant_type: 'refresh_token',
        refresh_token: currentUser == null ? void 0 : currentUser.refreshToken
      };
      const response = await api.auth.exchangeOAuthTokensV2(body);
      const updatedUser = await __handleUnnecessaryEntitlementsUpdate(response.user);
      actions.afterAuthenticationStateUpdate(_extends({}, response, {
        user: updatedUser
      }), {
        isAuthenticated: true
      });
    } catch (e) {
      contextHolder.setAccessToken(null);
      contextHolder.setUser(null);
      actions.setAuthState({
        user: null,
        isAuthenticated: false
      });
    }
  };
  /** @private */
  const __refreshTokenEmbeddedSocialLogins = async () => {
    try {
      const {
        routes,
        loginState,
        onRedirectTo
      } = store.auth;
      const {
        user,
        tenants = [],
        activeTenant
      } = await api.auth.refreshTokenV3();
      if (isMfaRequired(user, store.root.appName)) {
        const mfaRequiredState = await actions.getMfaRequiredState(user);
        actions.setAuthState(mfaRequiredState);
        onRedirectTo(routes.loginUrl, {
          preserveQueryParams: true
        });
      } else {
        const updatedUser = await __handleUnnecessaryEntitlementsUpdate(user);
        const shouldShowPrompt = await actions.__shouldShowPromptPasskeys();
        if (shouldShowPrompt) {
          actions.setLoginState({
            step: LoginStep.promptPasskeys,
            loading: false
          });
          actions.afterAuthenticationStateUpdate({
            user: updatedUser,
            tenants,
            activeTenant
          }, {
            isLoading: false
          });
          onRedirectTo(routes.loginUrl, {
            preserveQueryParams: true
          });
        } else {
          var _ref4;
          if (user.id) {
            localStorage.setItem('userId', user.id);
          }
          const quickLoginToRegister = (_ref4 = localStorage.getItem('register-quick-login')) != null ? _ref4 : loginState.quickLoginToRegister;
          const shouldNavigateToRegisterQuickLogin = __shouldNavigateToRegisterQuickLogin(user);
          actions.afterAuthenticationStateUpdate({
            user: updatedUser,
            tenants,
            activeTenant
          }, {
            loginState: _extends({}, loginState, {
              quickLoginToRegister,
              flow: shouldNavigateToRegisterQuickLogin ? LoginFlow.RegisterQuickLogin : LoginFlow.Login
            }),
            isAuthenticated: true
          });
          await __handleRedirectRefreshToken(shouldNavigateToRegisterQuickLogin);
        }
      }
    } catch (e) {
      contextHolder.setAccessToken(null);
      contextHolder.setUser(null);
      actions.setAuthState({
        user: undefined,
        isAuthenticated: false
      });
    }
  };
  const refreshTokenForSocialLogins = async () => {
    if (store.auth.hostedLoginBox) {
      await __refreshTokenHostedSocialLogins();
    } else {
      await __refreshTokenEmbeddedSocialLogins();
    }
  };
  return _extends({
    setLoginState,
    resetLoginState,
    requestAuthorize,
    requestAuthorizeSSR,
    preLogin,
    postLogin,
    login,
    logout,
    silentLogout,
    recoverMfa,
    quickSmsPasswordlessPreLogin,
    changePhoneNumberWithVerification,
    verifyChangePhoneNumber,
    passwordlessPreLogin,
    passwordlessPostLogin,
    verifyInviteToken,
    webAuthnPrelogin,
    webAuthnPostLogin,
    webAuthnCreateNewDeviceSession,
    webAuthnVerifyNewDeviceSession,
    getUserIP,
    preEnrollMFAWebAuthnForLogin,
    enrollMFAWebAuthnForLogin,
    enrollMFAAuthenticatorAppForLogin,
    preEnrollMFASMSForLogin,
    enrollMFASMSForLogin,
    handleEnrollMFAResponse,
    resetBreachedPassword,
    refreshTokenForSocialLogins,
    // protected
    __refreshToken
  }, hostedLoginAuthorizeActions(store, api, sharedActions), afterAuthNavigationActions(store, api, sharedActions), handleVerifyMFAResponseActions(store, api, sharedActions), mfaRequiredStateActions(store, api, sharedActions), mfaWithAuthenticatorActions(store, api, sharedActions), mfaWithEmailCodeActions(store, api, sharedActions), mfaWithSMSActions(store, api, sharedActions), mfaWithWebAuthnActions(store, api, sharedActions));
});