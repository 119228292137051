<div class="batch-creation-container">
  <div class="batch-creation-header">
    <span class="header-title">{{ 'createNewBatch' | translate }}</span>
  </div>
  <div class="batch-content-columns">
    <!-- Batch Settings Column -->
    <div class="batch-settings-column column">
      <app-batch-settings
        [batchFormGroup]="batchFormGroup"
        [tenantId]="tenantId"
      >
      </app-batch-settings>
    </div>

    <!-- Upload Documents Column -->
    <div class="upload-documents-column column">
      <span class="column-header">{{ 'uploadDocuments' | translate }}</span>
      <app-file-uploader
        [tenantId]="tenantId"
        (uploadedDocumentIdsEvent)="setFileIdsToAssociate($event)"
        (uploadStatusEvent)="onUploadStatusUpdate($event)"
      >
      </app-file-uploader>
    </div>

    <!-- Upload Status Column -->
    <div class="upload-status-column column">
      <span class="column-header">{{ 'uploadStatus' | translate }}</span>
      <div class="upload-status-section">
        <div
          class="upload-status-summary"
          (click)="toggleUploadStatus()"
        >
          <div class="status-summary-content">
            <div class="summary-text">
              <span class="summary-title"
                >{{ 'uploading' | translate }} {{ uploadStatuses.length }}
                {{ 'files' | translate }}</span
              >
              <span class="summary-description"
                >{{ uploadedCount() }}/{{ uploadStatuses.length }}
                {{ 'filesUploaded' | translate }}</span
              >
            </div>
            <fa-icon
              class="arrow-icon"
              [icon]="isExpanded ? faCaretUp : faCaretDown"
            ></fa-icon>
          </div>
        </div>
        <div
          *ngIf="isExpanded"
          class="upload-status-details"
        >
          <div
            *ngFor="let file of uploadStatuses"
            class="file-upload-detail"
          >
            <span class="file-name">{{ file.file.name }}</span>
            <span class="spinner-span">
              <ngx-spinner
                color="#006CFA"
                [name]="'uploadSpinner-' + file.file.name"
                bdColor="rgba(255,255,255,0.3)"
                size="small"
                type="ball-spin-clockwise"
                [fullScreen]="false"
              ></ngx-spinner>
            </span>
            <span *ngIf="file.status === 'completed'">
              <fa-icon
                [icon]="faCircleCheck"
                class="success-icon"
              ></fa-icon>
            </span>
            <span *ngIf="file.status === 'failed'">
              <fa-icon
                [icon]="faExclamationCircle"
                class="error-icon"
              ></fa-icon>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Bottom Section: Batch Creation Buttons -->
  <div class="batch-creation-buttons">
    <button
      class="cancel-button"
      (click)="cancelBatchCreation()"
      [attr.aria-label]="'cancel' | translate"
    >
      {{ 'cancel' | translate }}
    </button>
    <button
      [disabled]="!canCreateBatch()"
      class="create-button"
      (click)="createBatch()"
      [attr.aria-label]="'create' | translate"
    >
      {{ 'create' | translate }}
    </button>
  </div>
</div>
