import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
const VeloDebugHeaders = {
  'x-frontegg-api-key': 'my-frontegg-api-key',
  'frontegg-vendor-id': '04ae2174-d8d9-4a90-8bab-2548e210a508',
  'frontegg-tenant-id': '02e6ab6f-8b25-48e4-b06d-2c53745ce213',
  'frontegg-user-id': 'e5c397a1-7120-4753-9216-e5d7f35d1f2d'
};
export class VeloApi extends BaseApiClient {
  constructor(appName) {
    super(appName);
    this.getAgentsConfig = async () => {
      return this.get(`${urls.velo.v1}/config`, undefined, {
        headers: VeloDebugHeaders
      });
    };
    this.getConversations = async () => {
      return this.get(`${urls.velo.v1}/conversations`, undefined, {
        headers: VeloDebugHeaders
      });
    };
    this.getConversationById = async payload => {
      return this.get(`${urls.velo.v1}/conversation/${payload.id}`, undefined, {
        headers: VeloDebugHeaders
      });
    };
    this.createConversation = async payload => {
      return this.post(`${urls.velo.v1}/conversations`, {
        message: payload.message,
        stream: payload.stream
      }, {
        responseType: payload.stream === false ? 'json' : 'stream',
        headers: VeloDebugHeaders
      });
    };
    this.sendMessage = async payload => {
      return this.post(`${urls.velo.v1}/conversation/${payload.conversationId}/messages`, {
        message: payload.message,
        stream: payload.stream
      }, {
        responseType: payload.stream === false ? 'json' : 'stream',
        headers: VeloDebugHeaders
      });
    };
    this.sendApiResponse = async payload => {
      return this.post(`${urls.velo.v1}/conversation/${payload.conversationId}/response/${payload.callId}`, {
        data: payload.data,
        status: payload.status,
        stream: payload.stream
      }, {
        responseType: payload.stream === false ? 'json' : 'stream',
        headers: VeloDebugHeaders
      });
    };
    this.getConversationResource = async payload => {
      return this.get(`${urls.velo.v1}/conversation/${payload.conversationId}/resource/${payload.resourceId}`, undefined, {
        headers: VeloDebugHeaders
      });
    };
  }

  /**
   * Get Velo agents config
   * @returns {Promise<IVeloAgentConfigResponse[]>}
   */
}

export default new VeloApi('default');