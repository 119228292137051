import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["groupId"];
import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class GroupsApi extends BaseApiClient {
  constructor(appName) {
    super(appName);
    this.getGroupById = async ({
      groupId
    }, query) => {
      return this.get(`${urls.identity.groups.v1}/${groupId}`, _extends({}, query));
    };
    this.getGroups = async query => {
      return this.get(`${urls.identity.groups.v1}`, _extends({}, query));
    };
    this.getGroupsByIds = async (body, params) => {
      return this.post(`${urls.identity.groups.getByIds.v1}`, body, {
        params
      });
    };
    this.createGroup = async body => {
      return this.post(`${urls.identity.groups.v1}`, body);
    };
    this.updateGroup = async _ref => {
      let {
          groupId
        } = _ref,
        body = _objectWithoutPropertiesLoose(_ref, _excluded);
      return this.patch(`${urls.identity.groups.v1}/${groupId}`, body);
    };
    this.deleteGroup = async groupId => {
      return this.delete(`${urls.identity.groups.v1}/${groupId}`);
    };
    this.addRolesToGroup = async (groupId, body) => {
      return this.post(`${urls.identity.groups.v1}/${groupId}/roles`, body);
    };
    this.deleteRolesFromGroup = async (groupId, body) => {
      return this.delete(`${urls.identity.groups.v1}/${groupId}/roles`, body);
    };
    this.addUsersToGroup = async (groupId, body) => {
      return this.post(`${urls.identity.groups.v1}/${groupId}/users`, body);
    };
    this.deleteUsersFromGroup = async (groupId, body) => {
      return this.delete(`${urls.identity.groups.v1}/${groupId}/users`, body);
    };
    this.getGroupConfiguration = async () => {
      return this.get(`${urls.identity.groups.configurations.v1}`);
    };
    this.updateGroupConfiguration = async body => {
      return this.post(`${urls.identity.groups.configurations.v1}`, body);
    };
  }

  /**
   * Get group by given id
   * @param groupId - The ID of the group to retrieve
   * @param query - Optional query parameters
   */
}

export default new GroupsApi('default');