import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["activeTenant", "tenants"];
import { initialState } from './state';
import { errorHandler, deepResetState, retryIfNeeded } from '../../helpers';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setAccountSettingsState = state => {
    Object.assign(store.auth.accountSettingsState, state);
  };
  const resetAccountSettingsState = () => {
    deepResetState(store, ['auth', 'accountSettingsState'], initialState);
  };
  const updateTenantState = ({
    newSettingValues
  }) => {
    const _store$auth$tenantsSt = store.auth.tenantsState,
      {
        activeTenant,
        tenants
      } = _store$auth$tenantsSt,
      rest = _objectWithoutPropertiesLoose(_store$auth$tenantsSt, _excluded);
    const updatedTenants = tenants.map(tenant => tenant.id !== (activeTenant == null ? void 0 : activeTenant.id) ? tenant : _extends({}, tenant, newSettingValues));
    const updatedActiveTenant = _extends({}, activeTenant, newSettingValues);
    sharedActions.setTenantsState(_extends({}, rest, {
      tenants: updatedTenants,
      activeTenant: updatedActiveTenant
    }));
  };
  const saveAccountSettings = async payload => {
    try {
      var _payload$callback;
      setAccountSettingsState({
        loading: true
      });
      const {
        address,
        timezone,
        dateFormat,
        timeFormat,
        currency,
        logo
      } = store.auth.accountSettingsState;
      const body = await api.accountSettings.updateSettings(_extends({
        address,
        timezone,
        dateFormat,
        timeFormat,
        currency,
        logo
      }, payload));
      updateTenantState({
        newSettingValues: body
      });
      setAccountSettingsState(_extends({}, body, {
        loading: false
      }));
      (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, body);
    } catch (e) {
      var _payload$callback2;
      setAccountSettingsState({
        loading: false,
        error: errorHandler(e)
      });
      (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, null, e);
    }
  };
  const loadAccountSettings = async payload => {
    setAccountSettingsState({
      loading: !(payload != null && payload.silentLoading),
      error: null
    });
    try {
      const body = await retryIfNeeded(() => api.accountSettings.getSettings(), payload == null ? void 0 : payload.retryConfig);
      setAccountSettingsState(_extends({}, body, {
        loading: false
      }));
    } catch (e) {
      setAccountSettingsState({
        loading: false,
        error: errorHandler(e)
      });
    }
  };
  return {
    setAccountSettingsState,
    resetAccountSettingsState,
    saveAccountSettings,
    loadAccountSettings
  };
});