import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, map } from 'rxjs';
import { BatchService } from '../../services/batch/batch.service';
import { UserPrivilegesResponse, Privilege, Department } from '../../models/sdk-interfaces';

export interface userPivileges {
  $id: string;
  Id: number;
  Code: string;
  Name: string;
  IsGranted: number;
  IsInherited: number;
  IsPerUser: number;
  ScopeType: number;
  Source: [];
}

@Injectable({
  providedIn: 'root'
})
export class UserPrivilegesService {
  private privilegesSubject = new BehaviorSubject<UserPrivilegesResponse | undefined>(undefined);

  constructor(private batchService: BatchService) {}

  /**
   * Call to get user privileges
   * @returns Observable with mock privileges
   */
  getUserPrivileges(
    tenantId: string,
    userId: string,
    applicationCode: string,
    accessToken: string
  ): Promise<UserPrivilegesResponse> {
    return this.batchService.getBatchPrivileges(tenantId, userId, applicationCode, accessToken);
  }

  /**
   * Sets the privileges and updates
   * @param privileges - Array of privileges
   */
  setPrivileges(privileges: UserPrivilegesResponse): void {
    this.privilegesSubject.next(privileges);
  }

  /**
   * Checks if the user can perform an action based on a privilege code.
   * @param privilegeCode - The code of the privilege to check.
   */
  canPerformAction(privilegeCode: string): Observable<boolean> {
    return this.privilegesSubject.asObservable().pipe(
      map(privileges => {
        if (!privileges) {
          return false; // If privileges are not yet set, deny by default.
        }

        const privilege = privileges.privileges.find(p => p.operationName === privilegeCode);
        return privilege ? true : false;
      })
    );
  }

  getDepartments(): Observable<Department[]> {
    return this.privilegesSubject.asObservable().pipe(
      map(privileges => {
        if (!privileges) {
          return [];
        }

        const departmentArray: Department[] = [];

        privileges.privileges.forEach(privilege => {
          privilege.context.departments.forEach(department => {
            if (!departmentArray.find(d => d.code === department.code)) {
              departmentArray.push(department);
            }
          });
        });

        return departmentArray;
      })
    );
  }
}
