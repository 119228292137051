import _extends from "@babel/runtime/helpers/esm/extends";
import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class UsersApi extends BaseApiClient {
  constructor(appName) {
    super(appName);
    this.GetUserJwt = async body => {
      return this.post(`${urls.identity.users.v3}/me/token`, body);
    };
    this.getUsersV2 = async (queryParams, options) => {
      return this.get(urls.identity.users.v2, queryParams, {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.getUsersV3 = async (queryParams, options) => {
      return this.get(urls.identity.users.v3, queryParams, {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.getUsersRoles = async (queryParams, options) => {
      return this.get(urls.identity.users.roles.v3, _extends({}, queryParams, {
        ids: queryParams.ids.join(',')
      }), {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.getUsersGroups = async (queryParams, options) => {
      return this.get(urls.identity.users.groups.v3, _extends({}, queryParams, {
        ids: queryParams.ids.join(',')
      }), {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.getCurrentUserTenantsV3 = async options => {
      return this.get(urls.identity.users.tenants.me.v3, undefined, {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.getCurrentUserTenantsV1 = async options => {
      return this.get(urls.identity.users.tenants.me.v1, undefined, {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.sendResetBreachedPasswordEmails = async () => {
      return this.post(urls.identity.users.passwords.resetBreachedPasswords.v1);
    };
    this.updateUserProfileV2 = async body => {
      return this.put(`${urls.identity.users.v2}/me`, body);
    };
    this.unlockMe = async body => {
      return this.post(`${urls.identity.users.v3}/me/unlock`, body);
    };
  }
}
export default new UsersApi('default');