class FronteggNativeModule {
  constructor() {
    this.loginWithSSO = email => {
      if (this.isIOSNativeBridgeAvailable()) {
        var _window$webkit, _window$webkit$messag, _window$webkit$messag2;
        (_window$webkit = window.webkit) == null ? void 0 : (_window$webkit$messag = _window$webkit.messageHandlers) == null ? void 0 : (_window$webkit$messag2 = _window$webkit$messag.FronteggNativeBridge) == null ? void 0 : _window$webkit$messag2.postMessage(JSON.stringify({
          action: 'loginWithSSO',
          payload: email
        }));
      } else if (this.isAndroidNativeBridgeAvailable()) {
        var _window$FronteggNativ;
        (_window$FronteggNativ = window.FronteggNativeBridge) == null ? void 0 : _window$FronteggNativ.loginWithSSO(email);
      } else {
        throw new Error('FronteggNativeBridge is not available');
      }
    };
    this.loginWithSocialLogin = url => {
      if (this.isIOSNativeBridgeAvailable()) {
        var _window$webkit2, _window$webkit2$messa, _window$webkit2$messa2;
        (_window$webkit2 = window.webkit) == null ? void 0 : (_window$webkit2$messa = _window$webkit2.messageHandlers) == null ? void 0 : (_window$webkit2$messa2 = _window$webkit2$messa.FronteggNativeBridge) == null ? void 0 : _window$webkit2$messa2.postMessage(JSON.stringify({
          action: 'loginWithSocialLogin',
          payload: url
        }));
      } else if (this.isAndroidNativeBridgeAvailable()) {
        var _window$FronteggNativ2;
        (_window$FronteggNativ2 = window.FronteggNativeBridge) == null ? void 0 : _window$FronteggNativ2.loginWithSocialLogin(url);
      } else {
        throw new Error('FronteggNativeBridge is not available');
      }
    };
    this.loginWithSocialLoginProvider = provider => {
      if (this.isIOSNativeBridgeAvailable()) {
        var _window$webkit3, _window$webkit3$messa, _window$webkit3$messa2;
        (_window$webkit3 = window.webkit) == null ? void 0 : (_window$webkit3$messa = _window$webkit3.messageHandlers) == null ? void 0 : (_window$webkit3$messa2 = _window$webkit3$messa.FronteggNativeBridge) == null ? void 0 : _window$webkit3$messa2.postMessage(JSON.stringify({
          action: 'loginWithSocialLoginProvider',
          payload: provider
        }));
      } else if (this.isAndroidNativeBridgeAvailable()) {
        var _window$FronteggNativ3;
        (_window$FronteggNativ3 = window.FronteggNativeBridge) == null ? void 0 : _window$FronteggNativ3.loginWithSocialLoginProvider(provider);
      } else {
        throw new Error('FronteggNativeBridge is not available');
      }
    };
    this.loginWithCustomSocialLoginProvider = providerId => {
      if (this.isIOSNativeBridgeAvailable()) {
        var _window$webkit4, _window$webkit4$messa, _window$webkit4$messa2;
        (_window$webkit4 = window.webkit) == null ? void 0 : (_window$webkit4$messa = _window$webkit4.messageHandlers) == null ? void 0 : (_window$webkit4$messa2 = _window$webkit4$messa.FronteggNativeBridge) == null ? void 0 : _window$webkit4$messa2.postMessage(JSON.stringify({
          action: 'loginWithCustomSocialLoginProvider',
          payload: providerId
        }));
      } else if (this.isAndroidNativeBridgeAvailable()) {
        var _window$FronteggNativ4;
        (_window$FronteggNativ4 = window.FronteggNativeBridge) == null ? void 0 : _window$FronteggNativ4.loginWithCustomSocialLoginProvider(providerId);
      } else {
        throw new Error('FronteggNativeBridge is not available');
      }
    };
    this.suggestSavePassword = (email, password) => {
      if (this.isIOSNativeBridgeAvailable()) {
        var _window$webkit5, _window$webkit5$messa, _window$webkit5$messa2;
        (_window$webkit5 = window.webkit) == null ? void 0 : (_window$webkit5$messa = _window$webkit5.messageHandlers) == null ? void 0 : (_window$webkit5$messa2 = _window$webkit5$messa.FronteggNativeBridge) == null ? void 0 : _window$webkit5$messa2.postMessage(JSON.stringify({
          action: 'suggestSavePassword',
          payload: JSON.stringify({
            email,
            password
          })
        }));
      } else if (this.isAndroidNativeBridgeAvailable()) {
        var _window$FronteggNativ5;
        (_window$FronteggNativ5 = window.FronteggNativeBridge) == null ? void 0 : _window$FronteggNativ5.suggestSavePassword(email, password);
      } else {
        throw new Error('FronteggNativeBridge is not available');
      }
    };
    this.promptSocialLoginConsent = () => {
      var _window$FronteggNativ6, _window$FronteggNativ7;
      return (_window$FronteggNativ6 = (_window$FronteggNativ7 = window.FronteggNativeBridgeFunctions) == null ? void 0 : _window$FronteggNativ7['shouldPromptSocialLoginConsent']) != null ? _window$FronteggNativ6 : false;
    };
  }
  isIOSNativeBridgeAvailable() {
    var _window$webkit6, _window$webkit6$messa;
    return ((_window$webkit6 = window.webkit) == null ? void 0 : (_window$webkit6$messa = _window$webkit6.messageHandlers) == null ? void 0 : _window$webkit6$messa.FronteggNativeBridge) != null;
  }
  isAndroidNativeBridgeAvailable() {
    return window.FronteggNativeBridge != null;
  }

  /**
   * @deprecated use isSocialLoginProviderAvailable instead for pkce flow in mobile
   */
  isSocialLoginAvailable() {
    return this.isAvailable('loginWithSocialLogin');
  }
  isSocialLoginProviderAvailable() {
    return this.isAvailable('loginWithSocialLoginProvider');
  }
  isCustomSocialLoginProviderAvailable() {
    return this.isAvailable('loginWithCustomSocialLoginProvider');
  }
  isLoginWithSSOAvailable() {
    return this.isAvailable('loginWithSSO');
  }
  isSuggestSavePasswordAvailable() {
    return this.isAvailable('suggestSavePassword');
  }
  isAvailable(method) {
    if (this.isIOSNativeBridgeAvailable() || this.isAndroidNativeBridgeAvailable()) {
      var _window$FronteggNativ8, _window$FronteggNativ9;
      return (_window$FronteggNativ8 = (_window$FronteggNativ9 = window.FronteggNativeBridgeFunctions) == null ? void 0 : _window$FronteggNativ9[method]) != null ? _window$FronteggNativ8 : false;
    }
    return false;
  }
}
export default new FronteggNativeModule();