import { urls } from '../../constants';
import { BaseApiClient } from '../../BaseApiClient';
export class SecurityPolicyApi extends BaseApiClient {
  constructor(appName) {
    super(appName);
    this.getGlobalSecurityPolicy = async () => {
      return this.get(urls.identity.configurations.v1);
    };
    this.getMfaPolicy = async () => {
      return this.get(`${urls.identity.configurations.v1}/mfa-policy`);
    };
    this.getVendorMfaPolicy = async () => {
      return this.get(`${urls.identity.configurations.v1}/mfa-policy/vendor`);
    };
    this.saveMfaPolicy = async body => {
      if (body.id) {
        return this.patch(`${urls.identity.configurations.v1}/mfa-policy`, body);
      } else {
        return this.post(`${urls.identity.configurations.v1}/mfa-policy`, body);
      }
    };
    this.getLockoutPolicy = async () => {
      return this.get(`${urls.identity.configurations.v1}/lockout-policy`);
    };
    this.getVendorLockoutPolicy = async () => {
      return this.get(`${urls.identity.configurations.v1}/lockout-policy/vendor`);
    };
    this.saveLockoutPolicy = async body => {
      if (body.id) {
        return this.patch(`${urls.identity.configurations.v1}/lockout-policy`, body);
      } else {
        return this.post(`${urls.identity.configurations.v1}/lockout-policy`, body);
      }
    };
    this.getCaptchaPolicy = async () => {
      try {
        return await this.get(`${urls.identity.configurations.v1}/captcha-policy/public`);
      } catch {
        return null;
      }
    };
    this.getPasswordHistoryPolicy = async () => {
      return this.get(`${urls.identity.configurations.v1}/password-history-policy`);
    };
    this.getVendorPasswordHistoryPolicy = async () => {
      return this.get(`${urls.identity.configurations.v1}/password-history-policy/vendor`);
    };
    this.savePasswordHistoryPolicy = async body => {
      if (body.id) {
        return this.patch(`${urls.identity.configurations.v1}/password-history-policy`, body);
      } else {
        return this.post(`${urls.identity.configurations.v1}/password-history-policy`, body);
      }
    };
    this.getPasswordRotationPolicy = async () => {
      return this.get(`${urls.identity.configurations.v1}/password-rotation`);
    };
    this.getVendorPasswordRotationPolicy = async () => {
      return this.get(`${urls.identity.configurations.v1}/password-rotation/vendor`);
    };
    this.savePasswordRotationPolicy = async body => {
      return this.post(`${urls.identity.configurations.v1}/password-rotation`, body);
    };
    this.getPasswordConfigPolicy = async () => {
      return this.get(`${urls.identity.configurations.v1}/password`);
    };
    this.getDomainRestrictions = async () => {
      return this.get(`${urls.identity.restrictions.emailDomain.v1}`);
    };
    this.getDomainRestrictionsConfig = async () => {
      return this.get(`${urls.identity.restrictions.emailDomain.v1}/config`);
    };
    this.createDomainRestriction = async body => {
      return this.post(`${urls.identity.restrictions.emailDomain.v1}`, body);
    };
    this.updateDomainRestrictionConfig = async body => {
      return this.post(`${urls.identity.restrictions.emailDomain.v1}/config`, body);
    };
    this.deleteDomainRestriction = async id => {
      return this.delete(`${urls.identity.restrictions.emailDomain.v1}/${id}`);
    };
    this.getIPRestrictions = async params => {
      return this.get(`${urls.identity.restrictions.ip.v1}`, params);
    };
    this.getIPRestrictionsConfig = async () => {
      return this.get(`${urls.identity.restrictions.ip.v1}/config`);
    };
    this.createIPRestriction = async body => {
      return this.post(`${urls.identity.restrictions.ip.v1}`, body);
    };
    this.bulkCreateIPRestriction = async body => {
      return this.post(`${urls.identity.restrictions.ip.v1}/bulk`, body);
    };
    this.updateIPRestrictionConfig = async body => {
      return this.post(`${urls.identity.restrictions.ip.v1}/config`, body);
    };
    this.deleteIPRestriction = async id => {
      return this.delete(`${urls.identity.restrictions.ip.v1}/${id}`);
    };
    this.testCurrentIp = async () => {
      return this.post(`${urls.identity.restrictions.ip.v1}/verify`);
    };
    this.testCurrentIpInAllowList = async () => {
      return this.post(`${urls.identity.restrictions.ip.v1}/verify/allow`);
    };
  }

  /** Get global secure access configuration */
}

export default new SecurityPolicyApi('default');