import { initialState } from './state';
import { deepResetState } from '../../helpers';
import { getCurrentUserTenantsFunction } from '@frontegg/rest-api';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setTenantsState = state => {
    Object.assign(store.auth.tenantsState, state);
  };
  const resetTenantsState = () => {
    deepResetState(store, ['auth', 'tenantsState'], initialState);
  };
  const switchTenant = async payload => {
    const {
      tenantId,
      callback
    } = payload;
    actions.setAuthState({
      isLoading: true
    });
    try {
      await api.tenants.switchTenant({
        tenantId
      });
      await actions.__refreshToken();
      const callbackConsumed = callback == null ? void 0 : callback(true);
      if (!callbackConsumed) {
        actions.setAuthState({
          isLoading: false
        });
      }
    } catch (e) {
      actions.setAuthState({
        isLoading: false
      });
      callback == null ? void 0 : callback(false, e);
    }
  };
  const loadTenants = async payload => {
    setTenantsState({
      loading: true
    });
    try {
      var _payload$callback;
      const userTenantsResponseFunc = getCurrentUserTenantsFunction(store.root.appName);
      const userTenantsResponse = await userTenantsResponseFunc();
      setTenantsState({
        tenants: userTenantsResponse.tenants,
        activeTenant: userTenantsResponse.activeTenant,
        loading: false
      });
      payload == null ? void 0 : (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, []);
    } catch (e) {
      var _payload$callback2;
      payload == null ? void 0 : (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, [], e);
      setTenantsState({
        loading: false
      });
    }
  };
  const loadSubTenants = async payload => {
    setTenantsState({
      loading: true
    });
    try {
      var _payload$callback3;
      // @ts-ignore // TODO: fix type in rest-api
      const subTenants = await retryIfNeeded(() => api.tenants.getSubTenants(), payload == null ? void 0 : payload.retryConfig);
      setTenantsState({
        subTenants,
        loading: false
      });
      payload == null ? void 0 : (_payload$callback3 = payload.callback) == null ? void 0 : _payload$callback3.call(payload, true);
    } catch (e) {
      var _payload$callback4;
      setTenantsState({
        loading: false
      });
      payload == null ? void 0 : (_payload$callback4 = payload.callback) == null ? void 0 : _payload$callback4.call(payload, null, e);
    }
  };
  const loadSubTenantsTree = async payload => {
    setTenantsState({
      loading: true
    });
    try {
      var _payload$callback5;
      const tenantTree = await api.tenants.getSubTenantsAsTree();
      setTenantsState({
        tenantTree,
        loading: false
      });
      payload == null ? void 0 : (_payload$callback5 = payload.callback) == null ? void 0 : _payload$callback5.call(payload, true);
    } catch (e) {
      var _payload$callback6;
      setTenantsState({
        loading: false
      });
      payload == null ? void 0 : (_payload$callback6 = payload.callback) == null ? void 0 : _payload$callback6.call(payload, null, e);
    }
  };
  return {
    setTenantsState,
    resetTenantsState,
    switchTenant,
    loadTenants,
    loadSubTenants,
    loadSubTenantsTree
  };
});