<div class="header">
  <img
    class="header-logo"
    src="../../../assets/header-logo.svg"
    routerLink="/printer"
    alt="header-logo"
  />
  <span>{{ (isPrinter() ? 'printerHeader' : 'sendToEtrieve') | translate }}</span>
  <div
    class="nav-buttons"
    *ngIf="includeSettings"
  >
    <fa-icon
      class="header-icon"
      [icon]="faGear"
      [ngClass]="{ active: showSettings }"
      (click)="toggleShowSettings()"
      tabindex="0"
      (keyup.enter)="toggleShowSettings()"
      [attr.aria-label]="'settings' | translate"
    />
    <div
      class="settings-menu"
      *ngIf="showSettings"
    >
      <span
        class="pointer"
        (click)="changeContentUrl()"
        tabindex="0"
        (keyup.enter)="changeContentUrl()"
        [attr.aria-label]="'signOut' | translate"
      >
        <img
          src="../../../assets/browser-solid.svg"
          class="img-icon"
          alt="Content URL Icon"
        />
        {{ 'contentUrl' | translate }}
      </span>
    </div>
  </div>
</div>
