import _extends from "@babel/runtime/helpers/esm/extends";
import { getMfaStepForEnrolledUsers, getMfaStepForNotEnrolledUsers } from '../helpers';
import { LoginStep, LoginFlow } from '../interfaces';
import { MFAStep } from '../../MfaState/interfaces';
import { retryIfNeeded } from '../../../helpers';
export default ((store, api, _sharedActions) => {
  const getMfaRequiredState = async (user, retryConfig, shouldContinueWhenFailing = false, emailFromSignup) => {
    var _user$mfaDevices, _user$mfaDevices$emai, _user$userEmail;
    let step = LoginStep.loginWithTwoFactor;
    const mfaState = {
      step: MFAStep.verify,
      loading: false,
      saving: false
    };
    const loginState = store.auth.loginState;
    let isAllowedToRemember;
    let mfaDeviceExpiration;
    try {
      const response = await retryIfNeeded(() => api.auth.checkIfAllowToRememberMfaDevice(user.mfaToken), retryConfig);
      isAllowedToRemember = response.isAllowedToRemember;
      mfaDeviceExpiration = response.mfaDeviceExpiration;
    } catch (e) {
      if (!shouldContinueWhenFailing) {
        throw e;
      }
    }
    const isEnrolled = !('mfaEnrolled' in user && !user.mfaEnrolled) || ((_user$mfaDevices = user.mfaDevices) == null ? void 0 : (_user$mfaDevices$emai = _user$mfaDevices.emails) == null ? void 0 : _user$mfaDevices$emai.length);
    if (isEnrolled) {
      Object.assign(mfaState, {
        mfaDevices: user.mfaDevices,
        mfaToken: user.mfaToken,
        step: getMfaStepForEnrolledUsers(user.mfaDevices)
      });
    } else {
      Object.assign(mfaState, {
        step: getMfaStepForNotEnrolledUsers(user.mfaStrategies),
        qrCode: user.qrCode,
        recoveryCode: user.recoveryCode,
        loading: false,
        mfaToken: user.mfaToken,
        mfaStrategies: user.mfaStrategies
      });
      step = LoginStep.forceTwoFactor;
    }
    let quickLoginState = {};
    const quickLoginToRegister = localStorage.getItem('register-quick-login');
    if (quickLoginToRegister) {
      quickLoginState = {
        quickLoginToRegister,
        flow: LoginFlow.RegisterQuickLogin
      };
    }
    return {
      user: undefined,
      isAuthenticated: false,
      mfaState,
      loginState: _extends({}, loginState, quickLoginState, {
        mfaToken: user.mfaToken,
        mfaRequired: user.mfaRequired,
        loading: false,
        error: undefined,
        step,
        tenantsLoading: true,
        email: (_user$userEmail = user.userEmail) != null ? _user$userEmail : emailFromSignup,
        tenants: [],
        allowRememberMfaDevice: isAllowedToRemember,
        mfaDeviceExpiration,
        isBreachedPassword: user.isBreachedPassword
      })
    };
  };
  return {
    getMfaRequiredState
  };
});