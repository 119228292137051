import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class PlansApi extends BaseApiClient {
  constructor(...args) {
    super(...args);
    this.getSubscriptionPlans = async () => {
      return this.get(urls.subscriptions.billing.plans.v1);
    };
    this.getSubscriptionPlan = async planId => {
      return this.get(`${urls.subscriptions.billing.plans.v1}/${planId}`);
    };
  }
}