import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["endpoint", "headerProps"];
import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class AuditsApi extends BaseApiClient {
  constructor(appName) {
    super(appName);
    this.getAudits = async params => {
      return this.get(urls.audits.v1, params);
    };
    this.getAuditsStats = async params => {
      return this.get(`${urls.audits.v1}/stats`, params);
    };
    this.exportAudits = async params => {
      const {
          endpoint,
          headerProps
        } = params,
        restParams = _objectWithoutPropertiesLoose(params, _excluded);
      return this.postDownload(`${urls.audits.v1}/export/${endpoint}`, {
        properties: headerProps
      }, restParams);
    };
  }

  /**
   * Fetches audit logs based on the provided parameters.
   *
   * @param params - Parameters to filter and paginate audits.
   * @returns A promise that resolves to the list of audits.
   */
}

export default new AuditsApi('default');