import * as i0 from '@angular/core';
import { Component, Injectable, Inject, NgZone, Directive, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as i1 from '@angular/router';
import { DefaultUrlSerializer, RouterModule } from '@angular/router';
import { initialize } from '@frontegg/js';
import { isAuthRoute } from '@frontegg/redux-store';
import { BehaviorSubject, Observable } from 'rxjs';
import { FronteggFrameworks, ContextHolder, NotEntitledJustification } from '@frontegg/rest-api';
export { ContextHolder } from '@frontegg/rest-api';
import angularCoreVersion from '@angular/core/package.json';
import { __awaiter } from 'tslib';
import FastDeepEqual from 'fast-deep-equal';
class FronteggComponent {}
FronteggComponent.ɵfac = function FronteggComponent_Factory(t) {
  return new (t || FronteggComponent)();
};
FronteggComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FronteggComponent,
  selectors: [["frontegg"]],
  decls: 0,
  vars: 0,
  template: function FronteggComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FronteggComponent, [{
    type: Component,
    args: [{
      // tslint:disable-next-line:component-selector
      selector: 'frontegg',
      template: ``
    }]
  }], null, null);
})();
var sdkVersion = {
  version: '7.11.5'
};
class FronteggAppOptionsClass {
  constructor() {
    this.contextOptions = {
      baseUrl: 'https://sub-domain.frontegg.com'
    };
  }
}
class FronteggAppService {
  constructor(config, router, ngZone) {
    var _a, _b;
    this.config = config;
    this.router = router;
    this.ngZone = ngZone;
    this.isLoadingSubject = new BehaviorSubject(true);
    this.isAuthenticatedSubject = new BehaviorSubject(false);
    this.stateSubject = new BehaviorSubject({});
    this.authStateSubject = new BehaviorSubject({
      isLoading: true,
      isAuthenticated: false
    });
    this.auditsStateSubject = new BehaviorSubject({});
    this.connectivityStateSubject = new BehaviorSubject({});
    this.subscriptionsStateSubject = new BehaviorSubject({});
    this.vendorStateSubject = new BehaviorSubject({});
    if (!this.config) {
      throw Error('Need to pass config: FronteggConfigOptions in FronteggAppModule.forRoot(config)');
    }
    if (window.CYPRESS_CONFIG) {
      this.config = window.CYPRESS_CONFIG;
    }
    const onRedirectTo = (to, opts) => {
      var _a;
      const baseName = window.location.origin;
      let path = to;
      if (path.startsWith(baseName) && baseName !== '/') {
        path = path.substring(baseName.length - 1);
      }
      if ((opts === null || opts === void 0 ? void 0 : opts.preserveQueryParams) || isAuthRoute(path, (_a = config.authOptions) === null || _a === void 0 ? void 0 : _a.routes)) {
        path = `${path}${window.location.search}`;
      }
      if (opts === null || opts === void 0 ? void 0 : opts.refresh) {
        window.location.href = path;
      } else {
        this.ngZone.run(() => {
          if (opts === null || opts === void 0 ? void 0 : opts.replace) {
            this.router.navigateByUrl(path, {
              replaceUrl: true
            });
          } else {
            this.router.navigateByUrl(path);
          }
        });
      }
    };
    const metadataHeaders = {
      fronteggSdkVersion: `@frontegg/angular@${sdkVersion.version}`,
      // TODO: remove this any type after updating rest-api context options type to accept string.
      // @ts-ignore
      framework: `${FronteggFrameworks.Angular}@${angularCoreVersion.version}`
    };
    const appName = (_a = this.config.appName) !== null && _a !== void 0 ? _a : 'default';
    // prepare config default values
    this.config = Object.assign(Object.assign({}, this.config), {
      appName,
      contextOptions: Object.assign(Object.assign({}, this.config.contextOptions), {
        metadataHeaders
      }),
      authOptions: Object.assign(Object.assign({}, this.config.authOptions), {
        hostedLoginOptions: Object.assign({
          loadUserOnFirstLoad: true
        }, (_b = this.config.authOptions) === null || _b === void 0 ? void 0 : _b.hostedLoginOptions),
        onRedirectTo
      })
    });
    ContextHolder.for(appName).setOnRedirectTo(onRedirectTo);
    this.fronteggApp = initialize(Object.assign({
      onRedirectTo
    }, this.config));
    // Add all login box routes to Angular router config
    this.router.resetConfig([...this.mapAuthComponents, {
      path: '',
      canActivate: [FronteggLoadGuard],
      children: [...this.router.config]
    }]);
    const initialFronteggState = this.fronteggApp.store.getState();
    this.updateState(initialFronteggState);
    // Subscribe on fronteggApp store to change state subjects
    this.fronteggApp.store.subscribe(() => {
      this.updateState(this.fronteggApp.store.getState());
    });
  }
  get state$() {
    return this.stateSubject.asObservable();
  }
  get authState$() {
    return this.authStateSubject.asObservable();
  }
  get auditsState$() {
    return this.auditsStateSubject.asObservable();
  }
  get connectivityState$() {
    return this.connectivityStateSubject.asObservable();
  }
  get subscriptionsState$() {
    return this.subscriptionsStateSubject.asObservable();
  }
  get vendorState$() {
    return this.vendorStateSubject.asObservable();
  }
  get isLoading$() {
    return this.isLoadingSubject.asObservable();
  }
  get isAuthenticated$() {
    return this.isAuthenticatedSubject.asObservable();
  }
  updateState(fronteggStore) {
    if (this.isLoadingSubject.getValue() !== fronteggStore.auth.isLoading) {
      this.isLoadingSubject.next(fronteggStore.auth.isLoading);
    }
    if (this.isAuthenticatedSubject.getValue() !== fronteggStore.auth.isAuthenticated) {
      this.isAuthenticatedSubject.next(fronteggStore.auth.isAuthenticated);
    }
    this.stateSubject.next(fronteggStore);
    this.authStateSubject.next(fronteggStore.auth);
    this.auditsStateSubject.next(fronteggStore.audits);
    this.connectivityStateSubject.next(fronteggStore.connectivity);
    this.subscriptionsStateSubject.next(fronteggStore.subscriptions);
    this.vendorStateSubject.next(fronteggStore.vendor);
  }
  get mapAuthComponents() {
    const authRoutes = this.authRoutes;
    return Object.keys(authRoutes).filter(key => key !== 'authenticatedUrl' && key !== 'signUpSuccessUrl' && key != null).map(key => {
      var _a;
      return {
        path: (_a = authRoutes[key]) === null || _a === void 0 ? void 0 : _a.substring(1),
        component: FronteggComponent
      };
    });
  }
  get authRoutes() {
    return this.fronteggApp.store.getState().auth.routes;
  }
  // Open admin portal
  showAdminPortal(options) {
    this.fronteggApp.showAdminPortal(options);
  }
  /**
   *  Open admin portal hosted mode
   * @param newTab - open in new tab
   * */
  openHostedAdminPortal(newTab) {
    this.fronteggApp.openHostedAdminPortal(newTab);
  }
  // Open admin portal
  hideAdminPortal() {
    var _a;
    (_a = this.fronteggApp) === null || _a === void 0 ? void 0 : _a.hideAdminPortal();
  }
  // Open checkout dialog
  showCheckoutDialog(opts) {
    this.fronteggApp.showCheckoutDialog(opts);
  }
  // Open checkout dialog
  hideCheckoutDialog() {
    var _a;
    (_a = this.fronteggApp) === null || _a === void 0 ? void 0 : _a.hideCheckoutDialog();
  }
}
FronteggAppService.ɵfac = function FronteggAppService_Factory(t) {
  return new (t || FronteggAppService)(i0.ɵɵinject(FronteggAppOptionsClass), i0.ɵɵinject(i1.Router), i0.ɵɵinject(i0.NgZone));
};
FronteggAppService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: FronteggAppService,
  factory: FronteggAppService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FronteggAppService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [FronteggAppOptionsClass]
      }]
    }, {
      type: i1.Router
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
class FronteggLoadGuard {
  constructor(fronteggAppService) {
    this.fronteggAppService = fronteggAppService;
  }
  canActivate(route, state) {
    return new Observable(obs => {
      this.fronteggAppService.isLoading$.subscribe(loading => {
        if (!loading) {
          obs.next(true);
        }
      });
    });
  }
}
FronteggLoadGuard.ɵfac = function FronteggLoadGuard_Factory(t) {
  return new (t || FronteggLoadGuard)(i0.ɵɵinject(FronteggAppService));
};
FronteggLoadGuard.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: FronteggLoadGuard,
  factory: FronteggLoadGuard.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FronteggLoadGuard, [{
    type: Injectable
  }], function () {
    return [{
      type: FronteggAppService
    }];
  }, null);
})();
class FronteggBaseGuard {
  parse(url) {
    const dus = new DefaultUrlSerializer();
    return dus.parse(url);
  }
  serialize(tree) {
    const dus = new DefaultUrlSerializer();
    return dus.serialize(tree).replace(/%40/gi, '@').replace(/%3A/gi, ':').replace(/%24/gi, '$').replace(/%2C/gi, ',').replace(/%3B/gi, ';').replace(/%2B/gi, '+').replace(/%3D/gi, '=').replace(/%3F/gi, '?').replace(/%2F/gi, '/').replace(/(https?):\/([\w\d])/gi, '$1://$2');
  }
}
const runNgZoneIfNeeded = (ngZone, fn) => {
  if (NgZone.isInAngularZone()) {
    fn();
  } else {
    ngZone.run(fn);
  }
};

/**
 * A service for managing user state subscription
 * The service gives the ability to subscribe to user state change and get a manipulated data when the user state changes
 */
class FronteggUserSubscriptionService {
  constructor(fronteggAppService) {
    this.fronteggAppService = fronteggAppService;
    this.userStateSubject = new BehaviorSubject(undefined);
    const state = this.fronteggAppService.fronteggApp.store.getState();
    this.updateUserStateIfNeeded(state.auth);
    // Memoized user State
    this.fronteggAppService.fronteggApp.store.subscribe(() => {
      const newState = this.fronteggAppService.fronteggApp.store.getState();
      this.updateUserStateIfNeeded(newState.auth);
    });
  }
  /**
   * Trigger user subject change event if the user reference changes
   * No need for deep equal because we already check it internally
   * @param authState AuthState object
   */
  updateUserStateIfNeeded(authState) {
    const userState = authState.user;
    if (this.userStateSubject.value === userState) {
      return;
    }
    this.userStateSubject.next(userState);
  }
  /**
   * The function gives the ability to return a manipulated data of the user state as a subscription.
   *
   * @param dataManipulator Manipulator function that receives user state and returns a manipulated data
   * @param observer For receiving manipulated data result
   * @returns a subscription to be able to unsubscribe
   */
  getUserManipulatorSubscription(dataManipulator, observer) {
    // used for computing the end user result because we don't return the state itself, but a calculated one
    const userSubject = new BehaviorSubject(undefined);
    const stateSubscription = this.userStateSubject.subscribe(user => {
      userSubject.next(dataManipulator(user));
    });
    // subscribing the consumer observer
    const userResultSubscription = userSubject.asObservable().subscribe(observer);
    // monkey patched to manage both un-subscriptions: state and user manipulated result
    const originalUnsubscribe = userResultSubscription.unsubscribe.bind(userResultSubscription);
    userResultSubscription.unsubscribe = () => {
      originalUnsubscribe();
      stateSubscription.unsubscribe();
    };
    return userResultSubscription;
  }
}
FronteggUserSubscriptionService.ɵfac = function FronteggUserSubscriptionService_Factory(t) {
  return new (t || FronteggUserSubscriptionService)(i0.ɵɵinject(FronteggAppService));
};
FronteggUserSubscriptionService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: FronteggUserSubscriptionService,
  factory: FronteggUserSubscriptionService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FronteggUserSubscriptionService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: FronteggAppService
    }];
  }, null);
})();
class FronteggAuthService {
  constructor(fronteggAppService, router, fronteggUserSubscriptionService) {
    this.fronteggAppService = fronteggAppService;
    this.router = router;
    this.fronteggUserSubscriptionService = fronteggUserSubscriptionService;
    this.authStateSubject = new BehaviorSubject({
      isAuthenticated: false,
      isLoading: true
    });
    this.acceptInvitationStateSubject = new BehaviorSubject({});
    this.accountSettingsStateSubject = new BehaviorSubject({});
    this.activateAccountStateSubject = new BehaviorSubject({});
    this.apiTokensStateSubject = new BehaviorSubject({});
    this.forgotPasswordStateSubject = new BehaviorSubject({});
    this.loginStateSubject = new BehaviorSubject({});
    this.mfaStateSubject = new BehaviorSubject({});
    this.profileStateSubject = new BehaviorSubject({});
    this.rolesStateSubject = new BehaviorSubject({});
    this.routesSubject = new BehaviorSubject({});
    this.securityPolicyStateSubject = new BehaviorSubject({});
    this.signUpStateSubject = new BehaviorSubject({});
    this.socialLoginStateSubject = new BehaviorSubject({});
    this.ssoStateSubject = new BehaviorSubject({});
    this.teamStateSubject = new BehaviorSubject({});
    this.tenantsStateSubject = new BehaviorSubject({});
    this.userSubject = new BehaviorSubject({});
    this.isAuthenticatedSubject = new BehaviorSubject(false);
    this.isLoadingSubject = new BehaviorSubject(true);
    this.isSSOAuthSubject = new BehaviorSubject(false);
    this.ssoACSSubject = new BehaviorSubject('');
    this.customLoginStateSubject = new BehaviorSubject({});
    this.groupsStateSubject = new BehaviorSubject({});
    this.groupsDialogsStateSubject = new BehaviorSubject({});
    this.impersonateStateSubject = new BehaviorSubject({});
    this.allAccountsStateSubject = new BehaviorSubject({});
    this.allAccountsDialogsStateSubject = new BehaviorSubject({});
    this.passkeysStateSubject = new BehaviorSubject({});
    this.provisioningStateSubject = new BehaviorSubject({});
    this.resetPhoneNumberStateSubject = new BehaviorSubject({});
    this.restrictionsStateSubject = new BehaviorSubject({});
    this.securityCenterStateSubject = new BehaviorSubject({});
    this.sessionsPolicyStateSubject = new BehaviorSubject({});
    this.sessionsStateSubject = new BehaviorSubject({});
    this.smsStateSubject = new BehaviorSubject({});
    this.stepUpStateSubject = new BehaviorSubject({});
    this.applicationsStateSubject = new BehaviorSubject({});
    this.userIpSubject = new BehaviorSubject(undefined);
    this.keepSessionAliveSubject = new BehaviorSubject(undefined);
    this.socialLoginOptionsSubject = new BehaviorSubject(undefined);
    this.includeQueryParamSubject = new BehaviorSubject(undefined);
    this.hostedLoginBoxSubject = new BehaviorSubject(undefined);
    this.disableSilentRefreshSubject = new BehaviorSubject(undefined);
    this.enforceRedirectToSameSiteSubject = new BehaviorSubject(undefined);
    this.allowedRedirectOriginsSubject = new BehaviorSubject(undefined);
    /**
     * Triggers step up flow
     * @param options.maxAge optional max age
     */
    this.stepUp = options => this.fronteggAppService.fronteggApp.stepUp(options);
    // Root Actions
    this.setState = state => this.dispatchAction('setState', state);
    this.resetState = () => this.dispatchAction('resetState');
    this.setUser = user => this.dispatchAction('setUser', user);
    // Login Actions
    this.setLoginState = state => this.dispatchAction('setLoginState', state);
    this.resetLoginState = () => this.dispatchAction('resetLoginState');
    this.requestAuthorize = firstTime => this.dispatchAction('requestAuthorize', firstTime);
    this.loginWithRedirect = (params, shouldRedirectToLogin, firstTime, loginDirectAction) => {
      if (this.isHostedLoginCallbackRoute()) {
        return;
      }
      this.dispatchAction('requestHostedLoginAuthorizeV2', {
        additionalParams: params,
        shouldRedirectToLogin: shouldRedirectToLogin !== null && shouldRedirectToLogin !== void 0 ? shouldRedirectToLogin : true,
        firstTime: firstTime !== null && firstTime !== void 0 ? firstTime : false,
        loginDirectAction
      });
      this.setState({
        isLoading: true
      });
    };
    this.preLogin = payload => this.dispatchAction('preLogin', payload);
    this.postLogin = payload => this.dispatchAction('postLogin', payload);
    this.login = payload => this.dispatchAction('login', payload);
    this.loginWithMfa = payload => this.dispatchAction('loginWithMfa', payload);
    this.recoverMfa = payload => this.dispatchAction('recoverMfa', payload);
    this.logout = callback => this.dispatchAction('logout', callback);
    this.silentLogout = callback => this.dispatchAction('silentLogout', callback);
    this.checkIfAllowToRememberMfaDevice = payload => this.dispatchAction('checkIfAllowToRememberMfaDevice', payload);
    // Social Logins Actions
    this.setSocialLoginsState = state => this.dispatchAction('setSocialLoginsState', state);
    this.resetSocialLoginsState = () => this.dispatchAction('resetSocialLoginsState');
    this.loadSocialLoginsConfiguration = () => this.dispatchAction('loadSocialLoginsConfiguration');
    this.loginViaSocialLogin = payload => this.dispatchAction('loginViaSocialLogin', payload);
    this.setSocialLoginError = payload => this.dispatchAction('setSocialLoginError', payload);
    // Activate Account Actions
    this.setActivateState = state => this.dispatchAction('setActivateState', state);
    this.resetActivateState = () => this.dispatchAction('resetActivateState');
    this.setActivateStrategyState = state => this.dispatchAction('setActivateStrategyState', state);
    this.activateAccount = payload => this.dispatchAction('activateAccount', payload);
    this.resendActivationEmail = payload => this.dispatchAction('resendActivationEmail', payload);
    this.getActivateAccountStrategy = payload => this.dispatchAction('getActivateAccountStrategy', payload);
    // Accept Invitation Actions
    this.setAcceptInvitationState = state => this.dispatchAction('setAcceptInvitationState', state);
    this.resetAcceptInvitationState = () => this.dispatchAction('resetAcceptInvitationState');
    this.acceptInvitation = payload => this.dispatchAction('acceptInvitation', payload);
    // Forgot Password Actions
    this.setForgotPasswordState = state => this.dispatchAction('setForgotPasswordState', state);
    this.resetForgotPasswordState = () => this.dispatchAction('resetForgotPasswordState');
    this.forgotPassword = payload => this.dispatchAction('forgotPassword', payload);
    this.resetPassword = payload => this.dispatchAction('resetPassword', payload);
    this.loadPasswordConfig = payload => this.dispatchAction('loadPasswordConfig', payload);
    // Sign Up Actions
    this.setSignUpState = state => this.dispatchAction('setSignUpState', state);
    this.resetSignUpState = () => this.dispatchAction('resetSignUpState');
    this.signUpUser = payload => this.dispatchAction('signUpUser', payload);
    this.resetSignUpStateSoft = () => this.dispatchAction('resetSignUpStateSoft');
    // Profile Actions
    this.setProfileState = state => this.dispatchAction('setProfileState', state);
    this.resetProfileState = () => this.dispatchAction('resetProfileState');
    this.loadProfile = () => this.dispatchAction('loadProfile');
    this.saveProfile = payload => this.dispatchAction('saveProfile', payload);
    this.changePassword = payload => this.dispatchAction('changePassword', payload);
    // SSO actions
    this.setSSOState = state => this.dispatchAction('setSSOState', state);
    this.resetSSOState = () => this.dispatchAction('resetSSOState');
    this.loadSSOConfigurations = () => this.dispatchAction('loadSSOConfigurations');
    this.loadSSOAuthorizationRoles = () => this.dispatchAction('loadSSOAuthorizationRoles');
    this.saveSSOConfigurations = payload => this.dispatchAction('saveSSOConfigurations', payload);
    this.saveSSOConfigurationsFile = payload => this.dispatchAction('saveSSOConfigurationsFile', payload);
    this.saveSSOConfigurationsFileWithCallback = payload => this.dispatchAction('saveSSOConfigurationsFileWithCallback', payload);
    this.validateSSODomain = payload => this.dispatchAction('validateSSODomain', payload);
    this.updateSSOAuthorizationRoles = payload => this.dispatchAction('updateSSOAuthorizationRoles', payload);
    this.deleteSamlGroup = payload => this.dispatchAction('deleteSamlGroup', payload);
    this.createSamlGroup = payload => this.dispatchAction('createSamlGroup', payload);
    this.oidcPostlogin = payload => this.dispatchAction('oidcPostlogin', payload);
    // Mfa actions
    this.setMfaState = state => this.dispatchAction('setMfaState', state);
    this.resetMfaState = () => this.dispatchAction('resetMfaState');
    this.enrollMfa = () => this.dispatchAction('enrollMfa');
    this.verifyMfa = payload => this.dispatchAction('verifyMfa', payload);
    this.verifyMfaAfterForce = payload => this.dispatchAction('verifyMfaAfterForce', payload);
    this.disableMfa = payload => this.dispatchAction('disableMfa', payload);
    // Team actions
    this.setTeamLoader = payload => this.dispatchAction('setTeamLoader', payload);
    this.setTeamError = payload => this.dispatchAction('setTeamError', payload);
    this.setTeamState = payload => this.dispatchAction('setTeamState', payload);
    this.resetTeamState = () => this.dispatchAction('resetTeamState');
    this.loadUsers = payload => this.dispatchAction('loadUsers', payload);
    this.loadRoles = payload => this.dispatchAction('loadRoles', payload);
    this.addUser = payload => this.dispatchAction('addUser', payload);
    this.updateUser = payload => this.dispatchAction('updateUser', payload);
    this.deleteUser = payload => this.dispatchAction('deleteUser', payload);
    this.resendActivationLink = payload => this.dispatchAction('resendActivationLink', payload);
    this.resendInvitationLink = payload => this.dispatchAction('resendInvitationLink', payload);
    this.openAddUserDialog = payload => this.dispatchAction('openAddUserDialog', payload);
    this.closeAddUserDialog = payload => this.dispatchAction('closeAddUserDialog', payload);
    this.openDeleteUserDialog = payload => this.dispatchAction('openDeleteUserDialog', payload);
    this.closeDeleteUserDialog = payload => this.dispatchAction('closeDeleteUserDialog', payload);
    // API Token Actions
    this.setApiTokensLoader = payload => this.dispatchAction('setApiTokensLoader', payload);
    this.setApiTokensError = payload => this.dispatchAction('setApiTokensError', payload);
    this.setApiTokensState = state => this.dispatchAction('setApiTokensState', state);
    this.resetApiTokensState = () => this.dispatchAction('resetApiTokensState');
    this.loadApiTokens = payload => this.dispatchAction('loadApiTokens', payload);
    this.initApiTokensData = payload => this.dispatchAction('initApiTokensData', payload);
    this.addTenantApiToken = payload => this.dispatchAction('addTenantApiToken', payload);
    this.addUserApiToken = payload => this.dispatchAction('addUserApiToken', payload);
    this.deleteUserApiToken = payload => this.dispatchAction('deleteUserApiToken', payload);
    this.deleteTenantApiToken = payload => this.dispatchAction('deleteTenantApiToken', payload);
    // Security Policy Actions
    this.setSecurityPolicyState = state => this.dispatchAction('setSecurityPolicyState', state);
    this.setSecurityPolicyGlobalState = state => this.dispatchAction('setSecurityPolicyGlobalState', state);
    this.setSecurityPolicyPublicState = state => this.dispatchAction('setSecurityPolicyPublicState', state);
    this.setSecurityPolicyMfaState = state => this.dispatchAction('setSecurityPolicyMfaState', state);
    this.setSecurityPolicyLockoutState = state => this.dispatchAction('setSecurityPolicyLockoutState', state);
    this.setSecurityPolicyCaptchaState = state => this.dispatchAction('setSecurityPolicyCaptchaState', state);
    this.setSecurityPolicyPasswordHistoryState = state => this.dispatchAction('setSecurityPolicyPasswordHistoryState', state);
    this.resetSecurityPolicyState = () => this.dispatchAction('resetSecurityPolicyState');
    this.setSecurityPolicyPasswordState = state => this.dispatchAction('setSecurityPolicyPasswordState', state);
    this.loadSecurityPolicy = () => this.dispatchAction('loadSecurityPolicy');
    this.loadPublicSecurityPolicy = () => this.dispatchAction('loadPublicSecurityPolicy');
    this.loadVendorPasswordConfig = () => this.dispatchAction('loadVendorPasswordConfig');
    this.loadSecurityPolicyMfa = () => this.dispatchAction('loadSecurityPolicyMfa');
    this.saveSecurityPolicyMfa = payload => this.dispatchAction('saveSecurityPolicyMfa', payload);
    this.loadSecurityPolicyLockout = () => this.dispatchAction('loadSecurityPolicyLockout');
    this.saveSecurityPolicyLockout = payload => this.dispatchAction('saveSecurityPolicyLockout', payload);
    this.loadSecurityPolicyCaptcha = () => this.dispatchAction('loadSecurityPolicyCaptcha');
    this.loadSecurityPolicyPasswordHistory = () => this.dispatchAction('loadSecurityPolicyPasswordHistory');
    this.saveSecurityPolicyPasswordHistory = payload => this.dispatchAction('saveSecurityPolicyPasswordHistory', payload);
    // Account Settings Actions
    this.setAccountSettingsState = state => this.dispatchAction('setAccountSettingsState', state);
    this.resetAccountSettingsState = () => this.dispatchAction('resetAccountSettingsState');
    this.loadAccountSettings = payload => this.dispatchAction('loadAccountSettings', payload);
    this.saveAccountSettings = payload => this.dispatchAction('saveAccountSettings', payload);
    // Tenants Actions
    this.setTenantsState = state => this.dispatchAction('setTenantsState', state);
    this.resetTenantsState = () => this.dispatchAction('resetTenantsState');
    this.switchTenant = payload => this.dispatchAction('switchTenant', payload);
    this.loadTenants = payload => this.dispatchAction('loadTenants', payload);
    // Roles Actions
    this.setRolesState = state => this.dispatchAction('setRolesState', state);
    this.resetRolesState = () => this.dispatchAction('resetRolesState');
    this.loadRolesAndPermissions = payload => this.dispatchAction('loadRolesAndPermissions', payload);
    this.addRole = payload => this.dispatchAction('addRole', payload);
    this.updateRole = payload => this.dispatchAction('updateRole', payload);
    this.deleteRole = payload => this.dispatchAction('deleteRole', payload);
    this.attachPermissionsToRole = payload => this.dispatchAction('attachPermissionsToRole', payload);
    this.attachPermissionToRoles = payload => this.dispatchAction('attachPermissionToRoles', payload);
    const authSubStates = [{
      field: 'acceptInvitationState',
      subject: this.acceptInvitationStateSubject
    }, {
      field: 'accountSettingsState',
      subject: this.accountSettingsStateSubject
    }, {
      field: 'activateAccountState',
      subject: this.activateAccountStateSubject
    }, {
      field: 'apiTokensState',
      subject: this.apiTokensStateSubject
    }, {
      field: 'forgotPasswordState',
      subject: this.forgotPasswordStateSubject
    }, {
      field: 'tenantsState',
      subject: this.tenantsStateSubject
    }, {
      field: 'loginState',
      subject: this.loginStateSubject
    }, {
      field: 'mfaState',
      subject: this.mfaStateSubject
    }, {
      field: 'profileState',
      subject: this.profileStateSubject
    }, {
      field: 'rolesState',
      subject: this.rolesStateSubject
    }, {
      field: 'routes',
      subject: this.routesSubject
    }, {
      field: 'securityPolicyState',
      subject: this.securityPolicyStateSubject
    }, {
      field: 'signUpState',
      subject: this.signUpStateSubject
    }, {
      field: 'socialLoginState',
      subject: this.socialLoginStateSubject
    }, {
      field: 'ssoState',
      subject: this.ssoStateSubject
    }, {
      field: 'teamState',
      subject: this.teamStateSubject
    }, {
      field: 'user',
      subject: this.userSubject
    }, {
      field: 'isSSOAuth',
      subject: this.isSSOAuthSubject
    }, {
      field: 'ssoACS',
      subject: this.ssoACSSubject
    }, {
      field: 'customLoginState',
      subject: this.customLoginStateSubject
    }, {
      field: 'groupsState',
      subject: this.groupsStateSubject
    }, {
      field: 'groupsDialogsState',
      subject: this.groupsDialogsStateSubject
    }, {
      field: 'impersonateState',
      subject: this.impersonateStateSubject
    }, {
      field: 'allAccountsState',
      subject: this.allAccountsStateSubject
    }, {
      field: 'allAccountsDialogsState',
      subject: this.allAccountsDialogsStateSubject
    }, {
      field: 'passkeysState',
      subject: this.passkeysStateSubject
    }, {
      field: 'provisioningState',
      subject: this.provisioningStateSubject
    }, {
      field: 'resetPhoneNumberState',
      subject: this.resetPhoneNumberStateSubject
    }, {
      field: 'restrictionsState',
      subject: this.restrictionsStateSubject
    }, {
      field: 'securityCenterState',
      subject: this.securityCenterStateSubject
    }, {
      field: 'sessionsPolicyState',
      subject: this.sessionsPolicyStateSubject
    }, {
      field: 'sessionsState',
      subject: this.sessionsStateSubject
    }, {
      field: 'smsState',
      subject: this.smsStateSubject
    }, {
      field: 'stepUpState',
      subject: this.stepUpStateSubject
    }, {
      field: 'applicationsState',
      subject: this.applicationsStateSubject
    }, {
      field: 'userIp',
      subject: this.userIpSubject
    }, {
      field: 'keepSessionAlive',
      subject: this.keepSessionAliveSubject
    }, {
      field: 'socialLoginOptions',
      subject: this.socialLoginOptionsSubject
    }, {
      field: 'includeQueryParam',
      subject: this.includeQueryParamSubject
    }, {
      field: 'hostedLoginBox',
      subject: this.hostedLoginBoxSubject
    }, {
      field: 'disableSilentRefresh',
      subject: this.disableSilentRefreshSubject
    }, {
      field: 'enforceRedirectToSameSite',
      subject: this.enforceRedirectToSameSiteSubject
    }, {
      field: 'allowedRedirectOrigins',
      subject: this.allowedRedirectOriginsSubject
    }];
    const state = this.fronteggAppService.fronteggApp.store.getState();
    this.updateState(state.auth, authSubStates);
    const callback = op => {
      const newState = this.fronteggAppService.fronteggApp.store.getState();
      this.updateState(newState.auth, authSubStates);
      // this.updateDeepState(op);
    };
    this.fronteggAppService.fronteggApp.store.subscribe(callback);
  }
  getAuthState() {
    return this.fronteggAppService.fronteggApp.store.getState().auth;
  }
  get authState$() {
    return this.authStateSubject.asObservable();
  }
  get acceptInvitationState$() {
    return this.acceptInvitationStateSubject.asObservable();
  }
  get accountSettingsState$() {
    return this.accountSettingsStateSubject.asObservable();
  }
  get activateState$() {
    return this.activateAccountStateSubject.asObservable();
  }
  get apiTokensState$() {
    return this.apiTokensStateSubject.asObservable();
  }
  get forgotPasswordState$() {
    return this.forgotPasswordStateSubject.asObservable();
  }
  get loginState$() {
    return this.loginStateSubject.asObservable();
  }
  get mfaState$() {
    return this.mfaStateSubject.asObservable();
  }
  get profileState$() {
    return this.profileStateSubject.asObservable();
  }
  get rolesState$() {
    return this.rolesStateSubject.asObservable();
  }
  get routesState$() {
    return this.routesSubject.asObservable();
  }
  get securityPolicyState$() {
    return this.securityPolicyStateSubject.asObservable();
  }
  get signUpState$() {
    return this.signUpStateSubject.asObservable();
  }
  get socialLoginState$() {
    return this.socialLoginStateSubject.asObservable();
  }
  get ssoState$() {
    return this.ssoStateSubject.asObservable();
  }
  get teamState$() {
    return this.teamStateSubject.asObservable();
  }
  get tenantsState$() {
    return this.tenantsStateSubject.asObservable();
  }
  get user$() {
    return this.userSubject.asObservable();
  }
  get user() {
    return this.fronteggAppService.fronteggApp.store.getState().auth.user;
  }
  get isAuthenticated$() {
    return this.isAuthenticatedSubject.asObservable();
  }
  get isAuthenticated() {
    return this.fronteggAppService.fronteggApp.store.getState().auth.isAuthenticated;
  }
  get isLoading$() {
    return this.isLoadingSubject.asObservable();
  }
  get isSSOAuth$() {
    return this.isSSOAuthSubject.asObservable();
  }
  get ssoACS$() {
    return this.ssoACSSubject.asObservable();
  }
  get customLoginStateSubject$() {
    return this.customLoginStateSubject.asObservable();
  }
  get groupsStateSubject$() {
    return this.groupsStateSubject.asObservable();
  }
  get groupsDialogsStateSubject$() {
    return this.groupsDialogsStateSubject.asObservable();
  }
  get impersonateStateSubject$() {
    return this.impersonateStateSubject.asObservable();
  }
  get allAccountsStateSubject$() {
    return this.allAccountsStateSubject.asObservable();
  }
  get allAccountsDialogsStateSubject$() {
    return this.allAccountsDialogsStateSubject.asObservable();
  }
  get passkeysStateSubject$() {
    return this.passkeysStateSubject.asObservable();
  }
  get provisioningStateSubject$() {
    return this.provisioningStateSubject.asObservable();
  }
  get resetPhoneNumberStateSubject$() {
    return this.resetPhoneNumberStateSubject.asObservable();
  }
  get restrictionsStateSubject$() {
    return this.restrictionsStateSubject.asObservable();
  }
  get securityCenterStateSubject$() {
    return this.securityCenterStateSubject.asObservable();
  }
  get sessionsPolicyStateSubject$() {
    return this.sessionsPolicyStateSubject.asObservable();
  }
  get sessionsStateSubject$() {
    return this.sessionsStateSubject.asObservable();
  }
  get smsStateSubject$() {
    return this.smsStateSubject.asObservable();
  }
  get stepUpStateSubject$() {
    return this.stepUpStateSubject.asObservable();
  }
  get applicationsStateSubject$() {
    return this.applicationsStateSubject.asObservable();
  }
  get userIpSubject$() {
    return this.userIpSubject.asObservable();
  }
  get keepSessionAliveSubject$() {
    return this.keepSessionAliveSubject.asObservable();
  }
  get socialLoginOptionsSubject$() {
    return this.socialLoginOptionsSubject.asObservable();
  }
  get includeQueryParamSubject$() {
    return this.includeQueryParamSubject.asObservable();
  }
  get hostedLoginBoxSubject$() {
    return this.hostedLoginBoxSubject.asObservable();
  }
  get disableSilentRefreshSubject$() {
    return this.disableSilentRefreshSubject.asObservable();
  }
  get enforceRedirectToSameSiteSubject$() {
    return this.enforceRedirectToSameSiteSubject.asObservable();
  }
  get allowedRedirectOriginsSubject$() {
    return this.allowedRedirectOriginsSubject.asObservable();
  }
  // // tslint:disable-next-line:typedef
  // private updateDeepState(ops: Op[]) {
  //   ops.filter(op => op[0] === 'set').forEach((op) => {
  //     const [ action, path, value ] = op;
  //     const state = path[0];
  //     const subState = path[1];
  //     const deepPath = path.slice(2);
  //     console.log(state, subState, deepPath, value);
  //     switch (state) {
  //       case 'auth':
  //         if(this.authStateSubject.value )
  //         break;
  //     }
  //   });
  // }
  updateState(authState, authSubStates) {
    if (this.authStateSubject.value !== authState) {
      this.authStateSubject.next(authState);
    }
    for (const authSubState of authSubStates) {
      if (!FastDeepEqual(authSubState.subject.value, authState[authSubState.field])) {
        authSubState.subject.next(authState[authSubState.field]);
      }
    }
    if (this.isAuthenticatedSubject.value !== authState.isAuthenticated) {
      this.isAuthenticatedSubject.next(authState.isAuthenticated);
    }
    if (this.isLoadingSubject.value !== authState.isLoading) {
      this.isLoadingSubject.next(authState.isLoading);
    }
  }
  dispatchAction(type, payload) {
    const store = this.fronteggAppService.fronteggApp.store;
    store.dispatch({
      type: `auth/${type}`,
      payload
    });
  }
  isHostedLoginCallbackRoute() {
    const path = this.fronteggAppService.router.url;
    const hostedLoginRedirectUrl = this.fronteggAppService.authRoutes.hostedLoginRedirectUrl;
    return path.startsWith(hostedLoginRedirectUrl !== null && hostedLoginRedirectUrl !== void 0 ? hostedLoginRedirectUrl : '/oauth/callback');
  }
  /**
   * @param options.maxAge optional max age
   * @returns A subscription for step up state - true when user is stepped up, false otherwise
   */
  isSteppedUp$(observer, options) {
    return this.fronteggUserSubscriptionService.getUserManipulatorSubscription(() => this.fronteggAppService.fronteggApp.isSteppedUp(options), observer);
  }
}
FronteggAuthService.ɵfac = function FronteggAuthService_Factory(t) {
  return new (t || FronteggAuthService)(i0.ɵɵinject(FronteggAppService), i0.ɵɵinject(i1.Router), i0.ɵɵinject(FronteggUserSubscriptionService));
};
FronteggAuthService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: FronteggAuthService,
  factory: FronteggAuthService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FronteggAuthService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: FronteggAppService
    }, {
      type: i1.Router
    }, {
      type: FronteggUserSubscriptionService
    }];
  }, null);
})();
class FronteggAuthGuard extends FronteggBaseGuard {
  constructor(fronteggAppService, fronteggAuthService, router, ngZone) {
    super();
    this.fronteggAppService = fronteggAppService;
    this.fronteggAuthService = fronteggAuthService;
    this.router = router;
    this.ngZone = ngZone;
  }
  /**
   * Wait for loader to finish
   * @private
   */
  waitForLoader() {
    return new Promise(resolve => {
      const subscription = this.fronteggAppService.isLoading$.subscribe(isLoading => {
        if (!isLoading) {
          resolve(true);
          subscription.unsubscribe();
        }
      });
    });
  }
  /**
   * Navigate to login page if user is not authenticated
   * @private
   */
  navigateToLoginIfNeeded(redirectUrl) {
    return __awaiter(this, void 0, void 0, function* () {
      const {
        isAuthenticated
      } = this.fronteggAuthService.getAuthState();
      if (!isAuthenticated) {
        if (this.fronteggAppService.fronteggApp.options.hostedLoginBox) {
          localStorage.setItem('FRONTEGG_AFTER_AUTH_REDIRECT_URL', redirectUrl);
          this.fronteggAuthService.loginWithRedirect({
            prompt: 'login'
          });
          return false;
        } else {
          return this.router.navigateByUrl(this.fronteggAppService.authRoutes.loginUrl + '?redirectUrl=' + encodeURIComponent(redirectUrl));
        }
      }
      return true; // activate navigation
    });
  }
  canActivate(route, state) {
    const redirectUrl = state.url;
    const {
      isLoading
    } = this.fronteggAuthService.getAuthState();
    if (!isLoading) {
      // if showLoader false
      // check if user is authenticated
      return this.navigateToLoginIfNeeded(redirectUrl);
    }
    // wait for loader to finish and then check if user is authenticated
    return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
      yield this.waitForLoader();
      runNgZoneIfNeeded(this.ngZone, () => {
        this.navigateToLoginIfNeeded(redirectUrl).then(resolve).catch(reject);
      });
    }));
  }
}
FronteggAuthGuard.ɵfac = function FronteggAuthGuard_Factory(t) {
  return new (t || FronteggAuthGuard)(i0.ɵɵinject(FronteggAppService), i0.ɵɵinject(FronteggAuthService), i0.ɵɵinject(i1.Router), i0.ɵɵinject(i0.NgZone));
};
FronteggAuthGuard.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: FronteggAuthGuard,
  factory: FronteggAuthGuard.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FronteggAuthGuard, [{
    type: Injectable
  }], function () {
    return [{
      type: FronteggAppService
    }, {
      type: FronteggAuthService
    }, {
      type: i1.Router
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
class AuthorizedContentDirective {
  constructor(templateRef, viewContainer, fronteggAuthService) {
    this.templateRef = templateRef;
    this.viewContainer = viewContainer;
    this.fronteggAuthService = fronteggAuthService;
    this.roles = [];
  }
  set authorizedContent(roles) {
    this.roles = roles;
  }
  ngAfterContentInit() {
    this.fronteggAuthService.user$.subscribe(user => {
      var _a;
      if ((_a = user === null || user === void 0 ? void 0 : user.roles) === null || _a === void 0 ? void 0 : _a.some(role => this.roles.includes(role.name))) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
}
AuthorizedContentDirective.ɵfac = function AuthorizedContentDirective_Factory(t) {
  return new (t || AuthorizedContentDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(FronteggAuthService));
};
AuthorizedContentDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AuthorizedContentDirective,
  selectors: [["", "authorizedContent", ""]],
  inputs: {
    authorizedContent: "authorizedContent"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthorizedContentDirective, [{
    type: Directive,
    args: [{
      selector: '[authorizedContent]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }, {
      type: i0.ViewContainerRef
    }, {
      type: FronteggAuthService
    }];
  }, {
    authorizedContent: [{
      type: Input
    }]
  });
})();
class FronteggSubscriptionService {
  constructor(fronteggAppService) {
    this.fronteggAppService = fronteggAppService;
    this.subscriptionStateSubject = new BehaviorSubject({});
    this.checkoutStateSubject = new BehaviorSubject({
      loading: false,
      open: false,
      success: false,
      error: null
    });
    this.loadSubscription = () => this.dispatchAction('billing/subscription/loadSubscription');
    this.openCheckout = plan => {
      this.checkoutStateSubject.next({
        loading: false,
        open: true,
        success: false,
        error: null
      });
      this.fronteggAppService.showCheckoutDialog({
        plan,
        onSuccess: () => {
          this.checkoutStateSubject.next({
            loading: false,
            open: false,
            success: true,
            error: null
          });
        },
        onError: error => {
          const checkoutState = this.checkoutStateSubject.value;
          this.checkoutStateSubject.next(Object.assign(Object.assign({}, checkoutState), {
            error
          }));
        },
        onClose: () => {
          const checkoutState = this.checkoutStateSubject.value;
          this.checkoutStateSubject.next(Object.assign(Object.assign({}, checkoutState), {
            open: false
          }));
        }
      });
    };
    this.hideCheckout = () => {
      this.fronteggAppService.hideCheckoutDialog();
    };
    // Memoized Subscription State
    this.fronteggAppService.subscriptionsState$.subscribe(subscriptionState => {
      if (subscriptionState != null) {
        const {
          billing: {
            subscription
          },
          checkout: checkoutState
        } = subscriptionState;
        if (!FastDeepEqual(this.subscriptionStateSubject.getValue(), subscription)) {
          this.subscriptionStateSubject.next(subscription);
        }
        const checkoutSubjectState = this.checkoutStateSubject.getValue();
        if (checkoutSubjectState.loading !== checkoutState.loading) {
          this.checkoutStateSubject.next(Object.assign(Object.assign({}, checkoutSubjectState), {
            loading: checkoutState.loading
          }));
        }
      }
    });
  }
  get subscriptionState$() {
    return this.subscriptionStateSubject.asObservable();
  }
  get checkoutState$() {
    return this.checkoutStateSubject.asObservable();
  }
  dispatchAction(type, payload) {
    const store = this.fronteggAppService.fronteggApp.store;
    // @ts-ignore
    store.dispatch({
      type: `${subscriptionsStoreName}/${type}`,
      payload
    });
  }
}
FronteggSubscriptionService.ɵfac = function FronteggSubscriptionService_Factory(t) {
  return new (t || FronteggSubscriptionService)(i0.ɵɵinject(FronteggAppService));
};
FronteggSubscriptionService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: FronteggSubscriptionService,
  factory: FronteggSubscriptionService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FronteggSubscriptionService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: FronteggAppService
    }];
  }, null);
})();

/**
 * An entitlements service for:
 *   1. Managing state subscription.
 *   2. Querying about feature and permission entitlements state.
 *   3. Load entitlements on demand.
 */
class FronteggEntitlementsService {
  constructor(fronteggAppService, fronteggUserSubscriptionService) {
    this.fronteggAppService = fronteggAppService;
    this.fronteggUserSubscriptionService = fronteggUserSubscriptionService;
  }
  /**
   * @param feature featureKey
   * @param observer For receiving the feature entitlements result including if the user is entitled to the given feature.
   *                 Attaching the justification if not entitled
   * @param customAttributes consumer attributes
   * @returns a subscription to be able to unsubscribe
   * @throws when entitlement is not enabled via frontegg options
   */
  featureEntitlements$(feature, observer, customAttributes) {
    return this.fronteggUserSubscriptionService.getUserManipulatorSubscription(user => {
      // the entitlemenets-common npm doesn't know to overcome the case of signed out user, then we get console errors
      if (user) {
        return this.fronteggAppService.fronteggApp.getFeatureEntitlements(feature, customAttributes);
      }
      return {
        isEntitled: false,
        justification: NotEntitledJustification.MISSING_FEATURE
      };
    }, observer);
  }
  /**
   * @param permission permission key
   * @param observer For receiving the permission entitlements result including if the user is entitled to the given permission.
   *                 Attaching the justification if not entitled
   * @param customAttributes consumer attributes
   * @returns a subscription to be able to unsubscribe
   */
  permissionEntitlements$(permission, observer, customAttributes) {
    return this.fronteggUserSubscriptionService.getUserManipulatorSubscription(user => {
      if (user) {
        return this.fronteggAppService.fronteggApp.getPermissionEntitlements(permission, customAttributes);
      }
      return {
        isEntitled: false,
        justification: NotEntitledJustification.MISSING_PERMISSION
      };
    }, observer);
  }
  /**
   * @param options permissionKey or featureKey in an options object
   * @param observer For receiving the permission entitlements result including if the user is entitled to the given permission.
   *                 Attaching the justification if not entitled
   * @param customAttributes consumer attributes
   * @returns a subscription to be able to unsubscribe
   */
  entitlements$(options, observer, customAttributes) {
    return this.fronteggUserSubscriptionService.getUserManipulatorSubscription(user => {
      if (user) {
        return this.fronteggAppService.fronteggApp.getEntitlements(options, customAttributes);
      }
      const justification = 'featureKey' in options ? NotEntitledJustification.MISSING_FEATURE : NotEntitledJustification.MISSING_PERMISSION;
      return {
        isEntitled: false,
        justification
      };
    }, observer);
  }
  /**
   * Load entitlements data on demand
   * @param callback called on request completed with true if succeeded, false if failed
   */
  loadEntitlements(callback) {
    this.fronteggAppService.fronteggApp.loadEntitlements(callback);
  }
}
FronteggEntitlementsService.ɵfac = function FronteggEntitlementsService_Factory(t) {
  return new (t || FronteggEntitlementsService)(i0.ɵɵinject(FronteggAppService), i0.ɵɵinject(FronteggUserSubscriptionService));
};
FronteggEntitlementsService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: FronteggEntitlementsService,
  factory: FronteggEntitlementsService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FronteggEntitlementsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: FronteggAppService
    }, {
      type: FronteggUserSubscriptionService
    }];
  }, null);
})();
class FronteggAppModule {
  static forRoot(config) {
    return {
      ngModule: FronteggAppModule,
      providers: [FronteggAppService, FronteggAuthGuard, FronteggLoadGuard, FronteggAuthService, FronteggEntitlementsService, FronteggSubscriptionService, FronteggUserSubscriptionService, {
        provide: FronteggAppOptionsClass,
        useValue: config
      }]
    };
  }
}
FronteggAppModule.ɵfac = function FronteggAppModule_Factory(t) {
  return new (t || FronteggAppModule)();
};
FronteggAppModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FronteggAppModule
});
FronteggAppModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, RouterModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FronteggAppModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RouterModule],
      declarations: [FronteggComponent, AuthorizedContentDirective],
      exports: [FronteggComponent, AuthorizedContentDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of frontegg-app
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AuthorizedContentDirective, FronteggAppModule, FronteggAppService, FronteggAuthGuard, FronteggAuthService, FronteggComponent, FronteggEntitlementsService, FronteggSubscriptionService };
