import { urls } from './constants';
export const fronteggAuthApiRoutesRegex = [
// Logout urls:
// - `${urls.identity.auth.v1}/logout`,
// - `${urls.oauth.v1}/logout`,
/^(.*)\/logout$/g, '/logout',
// Saml urls:
// - `${urls.identity.auth.v1}/user/saml/postlogin`,
// - `${urls.identity.auth.v2}/user/oidc/postlogin`,
/^\/identity\/resources\/auth\/v[0-9]*\/user\/[^\/]*\/postlogin$/g,
// impersonation url:
/^\/identity\/resources\/impersonation\/v[0-9]$/g,
// Passwordless urls:
// - `${urls.identity.auth.v1}/passwordless/magiclink/postlogin`,
// - `${urls.identity.auth.v1}/passwordless/code/postlogin`,
// - `${urls.identity.auth.v1}/passwordless/smscode/postlogin`,
// - `${urls.identity.auth.v1}/passwordless/${type.toLocaleLowerCase()}/postlogin`,
/^\/identity\/resources\/auth\/v[0-9]*\/passwordless\/[^\/]*\/postlogin$/g,
// Webauthn postlogin urls:
/^\/identity\/resources\/auth\/v[0-9]*\/webauthn\/postlogin$/g,
// General login urls:
// - `${urls.identity.auth.v1}/user`,
// - `${urls.identity.auth.v1}/user/token/refresh`,
// - `${urls.identity.users.v1}`,
// - `${urls.identity.users.v1}/signUp`,
// - `${urls.identity.users.v1}/activate`,
// - `${urls.identity.users.v1}/invitation/accept`,
/^\/identity\/resources\/auth\/v[0-9]*\/user$/g, /^\/identity\/resources\/auth\/v[0-9]*\/user\/token\/refresh$/g, /^\/identity\/resources\/users\/v[0-9]*\/signUp$/g, /^\/identity\/resources\/users\/v[0-9]*\/activate$/g, /^\/identity\/resources\/users\/v[0-9]*\/invitation\/accept$/g,
// - `${urls.identity.auth.v1}/user/mfa/verify`,
// - `${urls.identity.auth.v1}/user/mfa/authenticator/enroll/verify`,
// - `${urls.identity.auth.v1}/user/mfa/authenticator/${deviceId}/verify`,
// - `${urls.identity.auth.v1}/user/mfa/sms/enroll/verify`,
// - `${urls.identity.auth.v1}/user/mfa/sms/${deviceId}/verify`,
// - `${urls.identity.auth.v1}/user/mfa/webauthn/enroll/verify`,
// - `${urls.identity.auth.v1}/user/mfa/webauthn/${deviceId}/verify`,
/^\/identity\/resources\/auth\/v[0-9]*\/user\/mfa\/verify$/g, /^\/identity\/resources\/auth\/v[0-9]*\/user\/mfa\/[^\/]*\/[^\/]*\/verify$/g,
// Oauth urls:
// - `${urls.oauth.v1}/token`
// - `${urls.oauth.v1}/authorize/silent`
/^\/oauth\/token$/g, /^\/oauth\/authorize\/silent$/g];
export const fronteggRefreshTokenUrl = `${urls.identity.auth.v1}/user/token/refresh`;
export const fronteggSilentRefreshTokenUrl = `${urls.oauth.v1}/authorize/silent`;
export const fronteggUsersUrl = `${urls.identity.users.v2}/me`;
export const fronteggTenantsUrl = `${urls.identity.users.v2}/me/tenants`;
export const fronteggTenantsV3Url = `${urls.identity.users.v3}/me/tenants`;
export const fronteggEntitlementsV2Url = urls.entitlements.v2;