import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  lastValueFrom,
  of,
  Subscription,
  switchMap
} from 'rxjs';
import { TenantApiService } from '../../services/tenant-api/tenant-api.service';
import {
  Area,
  ContentApiService,
  DocumentType,
  KeyField as DocumentTypeKeyField
} from '../../services/content-api/content-api.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Attachment } from '../../models/attachment';
import { MappingKeyField, MapResponse } from '../../models/docTypeMapping';
import { KeyField, StatusResponse } from '../../models/statusResponse';
import { ErrorResponse } from '../../models/errorResponse';
import { ActivatedRoute } from '@angular/router';
import { base64ToFile, buildKeyFields } from '../../utils/documentUtils';
import { ExtractionResponse } from '../../models/extractionResponse';
import { Location } from '@angular/common';
import { DocumentManagementService } from '../../services/document-management/document-management.service';
import { KeyFieldsComponent } from '../../components/key-fields/key-fields.component';
import { faCircleCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { environment } from '../../environments/environment';
import { FileSelectorAttachment } from '../../components/file-selector/file-selector.component';
import { FeatureFlagService } from '../../services/feature-flag/feature-flag.service';
import { DocumentResponse } from '../../models/documentResponse';
import { NgxSpinnerService } from 'ngx-spinner';

interface PrinterDocument {
  contentUri: string;
  fileName: string;
  fileId: string;
  fileContent: string;
  contentType: string;
  requestId?: string;
  status?: StatusResponse;
  completed: boolean;
  canceled?: boolean;
  classificationStarted: boolean;
  mappedItems?: MapResponse['mapItems'];
}

declare const Softdocs: any;
declare const pendo: any;

@Component({
  selector: 'app-printer',
  templateUrl: './printer.component.html',
  styleUrls: ['./printer.component.scss']
})
export class PrinterComponent implements OnInit, OnDestroy {
  @ViewChild(KeyFieldsComponent) keyFieldsComponent!: KeyFieldsComponent;

  private activeKeys: Set<string> = new Set();
  private subscription = new Subscription();

  autoclassifierSdk = new Softdocs.DocumentAnalysisSdk(environment.urls.autoclassifierApi);
  documentManagementSdk = new Softdocs.DocumentManagementSdk(environment.urls.autoclassifierApi);
  docTypeMappingSdk = new Softdocs.DocTypeMappingSdk(environment.urls.autoclassifierApi);

  documentsReceived$ = new BehaviorSubject<boolean>(false);
  autoclassificationFlag$ = new BehaviorSubject<boolean>(false);

  isAutoClassificationEnabled: boolean = false;
  isAutoclassificationFlagLoading: boolean = true;

  tenantId: string = '';
  documents: PrinterDocument[] = [];
  documentIds: string[] = [];
  documentTypes: DocumentType[] = [];
  areas: Area[] = [];
  documentForm!: FormGroup;
  filteredDocumentTypes: DocumentType[] = [];
  currentDocumentType: DocumentType | undefined;
  formStates: Map<number, any> = new Map();
  currentAttachmentIndex: number = 0;
  faCircleCheck = faCircleCheck;
  faExclamationCircle = faExclamationCircle;
  authToken: string = '';

  currentMostConfidentDocument: any;
  showClassificationResults: boolean = false;
  showUnsupportedFileMessage: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private _location: Location,
    private authService: AuthService,
    private featureFlagService: FeatureFlagService,
    private tenantService: TenantApiService,
    private contentService: ContentApiService,
    private docMngmtService: DocumentManagementService,
    private messageService: MessageService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService
  ) {
    this.subscription.add(
      this.route.queryParams.subscribe(params => {
        this.documentIds = params['id'].split(',');
        this.authToken = params['accessToken'];
      })
    );
  }

  /**
   * Initializes the component, setting up necessary data and feature flags
   */
  ngOnInit() {
    const { key, name, tenantId } = this.authService.extractUserInfo(this.authToken);
    this.tenantId = tenantId;

    pendo.initialize({
      account: {
        id: tenantId
      }
    });

    this.featureFlagService.initialize({ key, name, tenantId });

    this.subscription.add(
      this.featureFlagService
        .getFlag('autoclassification-for-virtual-printer', true)
        .subscribe(flagValue => {
          this.isAutoclassificationFlagLoading = false;
          this.isAutoClassificationEnabled = flagValue as boolean;
          this.autoclassificationFlag$.next(flagValue);
        })
    );

    this.loadData();

    this.subscription.add(
      combineLatest([this.documentsReceived$, this.autoclassificationFlag$]).subscribe(
        ([documentsReceived, autoclassificationEnabled]) => {
          if (documentsReceived && autoclassificationEnabled) {
            this.analyzeDocuments();
          }
        }
      )
    );

    this.initializeDocumentForm();
  }

  /**
   * Initializes the document form with default values
   */
  initializeDocumentForm() {
    this.documentForm = this.fb.group({
      area: [''],
      documentTypeCode: ['']
    });
  }

  analyzeDocuments(): void {
    if (this.documents.length === 0 || !this.isAutoClassificationEnabled) {
      return;
    }

    Promise.all(this.documents.map(doc => this.analyzeDocument(doc)))
      .then(() => {})
      .catch(error => {
        console.error('Error analyzing documents', error);
      });
  }

  /**
   * Retrieves the document from the Document Management SDK
   * @param {string} documentId - The ID of the document to retrieve
   */
  getDocumentFromDocMgmtSdk(documentId: string): Promise<void> {
    return this.documentManagementSdk
      .getDocument(this.tenantId, documentId)
      .then((response: DocumentResponse) => {
        const newDocument: PrinterDocument = {
          contentUri: response.contentUri,
          fileName: response.fileName,
          fileId: response.id,
          contentType: response.type,
          fileContent: '',
          completed: false,
          classificationStarted: true
        };

        return lastValueFrom(this.docMngmtService.getDocument(newDocument.contentUri)).then(
          arrayBuffer => {
            const binaryString = Array.from(new Uint8Array(arrayBuffer))
              .map(byte => String.fromCharCode(byte))
              .join('');
            const base64 = btoa(binaryString);
            newDocument.fileContent = base64;
            this.documents.push(newDocument);
            this.cdr.detectChanges();
            this.spinner.show('fileClassificationSpinner-' + newDocument.fileName);
          }
        );
      })
      .catch((error: any) => {
        console.error('Document get Error:', error);
        this.showErrorToast('getDocumentError');
      });
  }

  /**
   * Initializes data to be used by the component
   */
  loadData(): void {
    this.authService.setAuthToken(this.authToken);
    this.tenantId = this.authService.extractTenantId(this.authToken);

    this.subscription.add(
      this.tenantService
        .getTenantUrls(this.tenantId)
        .pipe(
          switchMap(tenantUrls => {
            this.contentService.setBaseUrl(tenantUrls.contentApiUrl);
            return this.contentService.getDocumentTypes();
          }),
          switchMap(docTypes => {
            this.documentTypes = docTypes;
            return this.contentService.getAreas();
          }),
          catchError(error => {
            console.error('Error in chained requests', error);
            this.showErrorToast('dataError');
            return of([]); // Return an observable to keep the stream alive
          })
        )
        .subscribe(areas => {
          this.areas = areas;
          Promise.all(this.documentIds.map(docId => this.getDocumentFromDocMgmtSdk(docId))).then(
            () => {
              this.documentsReceived$.next(true);
            }
          );
        })
    );
  }

  /**
   * Event listener to handle changes to a document's classification request
   * @param {CustomEvent} event - The documentStatusUpdate event
   */
  @HostListener('window:documentStatusUpdate', ['$event'])
  handleDocumentStatusUpdate(event: CustomEvent) {
    const index = this.documents.findIndex(
      document => document.requestId === event.detail.requestId
    );
    if (index !== -1 && !this.documents[index].status) {
      this.documents[index].status = event.detail.status;
      if (
        index === this.currentAttachmentIndex &&
        this.documents[this.currentAttachmentIndex].classificationStarted
      ) {
        this.buildFormFields();
      }
    }
  }

  /**
   * Event listener to handle errors in a document's classification request
   * @param {CustomEvent} event - The documentStatusError event
   */
  @HostListener('window:documentStatusError', ['$event'])
  handleDocumentStatusError(event: CustomEvent) {
    const { requestId, errorCode } = event.detail;
    const index = this.documents.findIndex(doc => doc.requestId === requestId);

    if (index !== -1) {
      const doc = this.documents[index];

      let errorMessage;
      switch (errorCode) {
        case 'documentInvalid':
          errorMessage = 'documentInvalidError';
          break;
        default:
          errorMessage = 'analyzeError';
          break;
      }

      this.showErrorToast(errorMessage, doc.fileName);
      this.cancelFileAutoClassification(index);

      if (
        index === this.currentAttachmentIndex &&
        this.documents[this.currentAttachmentIndex].classificationStarted
      ) {
        this.buildFormFields();
      }
    }
  }

  /**
   * Handles the keydown event for both macOS and Windows platforms
   * @param {KeyboardEvent} event - The keydown event
   */
  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.activeKeys.add(event.key.toLowerCase());

    const isMac = this.isMacPlatform();

    // CMD + SHIFT + A + I
    const isMacShortcutPressed =
      isMac &&
      event.metaKey &&
      event.shiftKey &&
      this.activeKeys.has('a') &&
      this.activeKeys.has('i');

    // CTRL + SHIFT + A + I
    const isWindowsShortcutPressed =
      !isMac &&
      event.ctrlKey &&
      event.shiftKey &&
      this.activeKeys.has('a') &&
      this.activeKeys.has('i');

    if (isMacShortcutPressed || isWindowsShortcutPressed) {
      event.preventDefault();
      this.openDialog();
      this.activeKeys.clear();
    }
  }

  /**
   * Handles the keyup event by removing the key from the activeKeys set
   * @param {KeyboardEvent} event - The keyup event
   */
  @HostListener('window:keyup', ['$event'])
  onKeyUp(e: KeyboardEvent) {
    this.activeKeys.delete(e.key.toLowerCase());
  }

  /**
   * Checks if the platform is macOS
   * @returns {boolean} - Returns true if the platform is macOS, otherwise false
   */
  isMacPlatform(): boolean {
    // Use navigator.userAgentData if available (future-proof)
    if ((navigator as any).userAgentData) {
      return (navigator as any).userAgentData.platform === 'macOS';
    }

    // Fallback to user agent string check if userAgentData is not available
    return /Mac|iPhone|iPod|iPad/.test(navigator.userAgent);
  }

  /**
   * Opens the classification results dialog.
   */
  openDialog() {
    if (!this.showClassificationResults) {
      this.currentMostConfidentDocument = this.getMostConfidentDocument(
        this.documents[this.currentAttachmentIndex]
      );
    } else {
      this.currentMostConfidentDocument = null;
    }
    this.showClassificationResults = !this.showClassificationResults;
  }

  /**
   * Closes the classification results dialog.
   */
  closeClassificationResults(): void {
    this.showClassificationResults = false;
  }

  /**
   * Handles changes in the form, such as area or document type selection
   * @param {Event} event - The change event from the form
   */
  formOnChange(event: any): void {
    const target = event.target;

    if (target && target.id === 'areaSelector') {
      const selectedArea = target.value;
      this.documentForm.controls['area'].setValue(selectedArea);

      this.filterDocumentTypes(selectedArea);

      const foundType = this.filteredDocumentTypes.find(
        type => type.code === this.currentDocumentType?.code
      );

      if (!foundType) {
        this.initializeFormControls(selectedArea, '');
        this.currentDocumentType = undefined;
      }
    }

    if (target && target.id === 'documentTypeSelector') {
      this.formStates.set(this.currentAttachmentIndex, this.documentForm.value);

      const selectedTypeCode = target.value;

      if (!selectedTypeCode) {
        this.initializeFormControls(this.documentForm.controls['area'].value, '');
        this.currentDocumentType = undefined;
      } else {
        this.currentDocumentType = this.filteredDocumentTypes.find(
          type => type.code === selectedTypeCode
        );

        if (this.currentDocumentType) {
          const currentDocument = this.documents[this.currentAttachmentIndex];
          const savedMapItems = currentDocument.mappedItems;

          if (savedMapItems) {
            const mapItem = savedMapItems.find(item => item.docTypeCode === selectedTypeCode);

            if (mapItem) {
              const controlsAsFormControl = this.documentForm.controls as {
                [key: string]: FormControl;
              };

              this.populateFormControls(controlsAsFormControl, selectedTypeCode, mapItem.keyFields);

              this.handleLookupFields(mapItem.keyFields);
            } else {
              this.buildFormFields();
            }
          } else {
            this.buildFormFields();
          }
        } else {
          this.currentDocumentType = undefined;
          this.documentForm.controls['documentTypeCode'].reset();
        }
      }
    }
  }

  /**
   * Filters the document types list by the currently selected area
   * @param {string} areaCode - The code of the selected area
   */
  filterDocumentTypes(areaCode: string): void {
    this.filteredDocumentTypes = this.documentTypes.filter(type => type.areaCode === areaCode);
  }

  /**
   * Builds and prepopulates form fields for the current document
   */
  buildFormFields(): void {
    this.currentMostConfidentDocument = this.getMostConfidentDocument(
      this.documents[this.currentAttachmentIndex]
    );

    const savedState = this.formStates.get(this.currentAttachmentIndex);

    if (savedState && savedState.area) {
      this.initializeFormControls(savedState.area, savedState.documentTypeCode);
      this.documentForm.patchValue(savedState);
    } else {
      this.buildFormFieldsForNewDocument();
    }
  }

  /**
   * Initializes form controls with area and document type
   * @param {string} area - The selected area
   * @param {string} documentTypeCode - The selected document type code
   */
  private initializeFormControls(area: string, documentTypeCode: string): void {
    const formControls: { [key: string]: FormControl } = {
      area: new FormControl(area),
      documentTypeCode: new FormControl(documentTypeCode)
    };
    this.filterDocumentTypes(area);
    this.currentDocumentType = this.filteredDocumentTypes.find(
      type => type.code === documentTypeCode
    );

    if (this.currentDocumentType) {
      this.addKeyFieldsToFormControls(formControls);
    }

    this.documentForm = this.fb.group(formControls);
  }

  /**
   * Builds form fields for a new document when there is no saved state
   */
  private buildFormFieldsForNewDocument(): void {
    const formControls: { [key: string]: FormControl } = {
      area: new FormControl(''),
      documentTypeCode: new FormControl('')
    };

    this.currentDocumentType = undefined;
    const attachment = this.documents[this.currentAttachmentIndex];

    if (
      attachment.status &&
      Array.isArray(attachment.status.documents) &&
      attachment.status.documents.length > 0
    ) {
      const document = this.getMostConfidentDocument(attachment);

      if (document) {
        const keyFields = this.extractKeyFields(document);
        this.mapKeyFields(document.classifyInfo?.documentType as string, keyFields).then(
          response => {
            if (response && response.mapItems.length > 0) {
              const mapItem = response.mapItems[0];

              attachment.mappedItems = response.mapItems;

              const docTypeCode = mapItem.docTypeCode;
              this.currentDocumentType = this.getDocumentTypeByCode(docTypeCode);
              this.populateFormControls(formControls, docTypeCode, mapItem.keyFields);

              this.documentForm = this.fb.group(formControls);

              this.handleLookupFields(mapItem.keyFields);
            }
          }
        );
      }
    } else {
      // Create empty form controls if no status is available
      this.initializeFormControls('', '');
    }
  }

  /**
   * Extracts key fields from a document
   * @param {any} document - The document to extract key fields from
   * @returns {KeyField[]} - An array of extracted key fields
   */
  private extractKeyFields(document: any): KeyField[] {
    return document.extractInfo?.keyFields.map((field: KeyField) => ({
      ...field,
      value: field.value === null ? '' : field.value
    })) as KeyField[];
  }

  /**
   * Gets the document with the highest confidence from the list of documents in an attachment
   * @param {PrinterDocument} attachment - The attachment containing the documents
   * @returns {any} - The document with the highest confidence
   */
  private getMostConfidentDocument(attachment: PrinterDocument): any {
    if ((attachment.status?.documents.length ?? 0) < 1) {
      return null;
    }
    return attachment.status?.documents.reduce((prev, current) => {
      if (prev.classifyInfo && current.classifyInfo) {
        return prev.classifyInfo.confidence > current.classifyInfo.confidence ? prev : current;
      }
      return prev;
    });
  }

  /**
   * Retrieves a document type by its code
   * @param {string | undefined} docTypeCode - The document type code
   * @returns {DocumentType | undefined} - The matching document type, if found
   */
  private getDocumentTypeByCode(docTypeCode: string | undefined): DocumentType | undefined {
    return this.documentTypes.find(type => type.code === docTypeCode);
  }

  /**
   * Populates form controls with values based on the selected document type and key fields
   * @param {Object} formControls - The form controls to populate
   * @param {string | undefined} docTypeCode - The document type code
   * @param {MappingKeyField[]} mappedKeyFields - The key fields to populate the form controls with
   */
  private populateFormControls(
    formControls: { [key: string]: FormControl },
    docTypeCode: string | undefined,
    mappedKeyFields: MappingKeyField[]
  ): void {
    if (this.currentDocumentType) {
      const areaCode = this.currentDocumentType.areaCode;
      this.filterDocumentTypes(areaCode);
      (formControls['area'] as FormControl).setValue(areaCode);
      (formControls['documentTypeCode'] as FormControl).setValue(docTypeCode);

      this.addKeyFieldsToFormControls(formControls);

      mappedKeyFields.forEach(mappedField => {
        const formControl = formControls[mappedField.key];
        if (formControl) {
          if (
            this.currentDocumentType?.keyfields.find(kf => kf.code === mappedField.key)
              ?.dataType === 'Date'
          ) {
            const date = new Date(mappedField.value);
            if (!isNaN(date.getDate())) {
              formControl.setValue(new Date(mappedField.value));
            }
          } else {
            formControl.setValue(mappedField.value);
          }
        }
      });

      this.documentForm.patchValue(this.documentForm.getRawValue(), { emitEvent: true });
    }
  }

  /**
   * Adds key fields to the form controls
   * @param {Object} formControls - The form controls to add key fields to
   */
  private addKeyFieldsToFormControls(formControls: { [key: string]: FormControl }): void {
    this.currentDocumentType?.keyfields.forEach(field => {
      formControls[field.code] = new FormControl('');
      this.applyFieldRules(field, formControls);
    });
  }

  /**
   * Handles lookup fields in the form, performing lookups as necessary
   * @param {MappingKeyField[]} mappedKeyFields - The key fields for lookup
   */
  private handleLookupFields(mappedKeyFields: MappingKeyField[]): void {
    if (this.currentDocumentType?.keyfields.some(keyfield => keyfield.dataType === 'Lookup')) {
      this.setLookup(mappedKeyFields);
    }
  }

  /**
   * Applies validation and default rules to a form control
   * @param {DocumentTypeKeyField} field - The field metadata
   * @param {Object} formControls - The form controls to which rules should be applied
   */
  applyFieldRules(field: DocumentTypeKeyField, formControls: { [key: string]: FormControl }) {
    if (field.default) {
      if (field.dataType === 'Date' && field.default === 'CurrentDate') {
        formControls[field.code].setValue(new Date());
      } else {
        formControls[field.code].setValue(field.default);
      }
    }

    if (field.required) {
      formControls[field.code].setValidators([Validators.required]);
    }

    if (field.maxLength) {
      formControls[field.code].setValidators([Validators.maxLength(field.maxLength)]);
    }

    if (field.textPattern) {
      formControls[field.code].setValidators([Validators.pattern(field.textPattern)]);
    }

    if (field.minValue) {
      formControls[field.code].setValidators([Validators.min(field.minValue)]);
    }
  }

  /**
   * Maps key fields based on the classified document type and provided key fields
   * @param {string} classifiedDocType - The classified document type
   * @param {KeyField[]} keyFields - The key fields to map
   * @param {string} [documentType] - Optional document type to map to
   * @returns {Promise<MapResponse>} - A promise of the map response
   */
  mapKeyFields(
    classifiedDocType: string,
    keyFields: KeyField[],
    documentType?: string
  ): Promise<MapResponse> {
    const mappingKeyFields: MappingKeyField[] = keyFields
      .filter(kf => kf.value !== undefined && kf.value !== null && kf.value !== '')
      .map(kf => ({ key: kf.key, value: kf.value }));

    return this.docTypeMappingSdk
      .mapKeyFields(this.tenantId, {
        modelDocType: classifiedDocType,
        keyFields: mappingKeyFields,
        docTypeCode: documentType
      })
      .then((response: MapResponse) => {
        return response;
      })
      .catch((error: ErrorResponse) => {
        console.error('Error mapping key fields: ', error);
        return undefined;
      });
  }

  /**
   * Analyzes a document using the autoclassifier SDK
   * @param {PrinterDocument} document - The document to analyze
   * @returns {Promise<void>} - A promise that resolves when the analysis is complete
   */
  async analyzeDocument(document: PrinterDocument): Promise<void> {
    const file = base64ToFile(document.fileContent, document.fileName, 'application/pdf');
    this.autoclassifierSdk
      .extractDocumentFromUpload(file, true)
      .then((response: ExtractionResponse) => {
        document.requestId = response.requestId;
      })
      .catch((error: ErrorResponse) => {
        console.error('Error analyzing document:', error);
        this.showErrorToast('analyzeError', document.fileName);
      });
  }

  /**
   * Selects an attachment and builds form fields for that attachment
   * @param {number} index - The index of the selected attachment
   */
  selectAttachment(index: number): void {
    this.showUnsupportedFileMessage = false;
    if (index >= 0 && index < this.documents.length) {
      this.formStates.set(this.currentAttachmentIndex, this.documentForm.value);
      this.currentAttachmentIndex = index;

      if (
        this.documents[this.currentAttachmentIndex].classificationStarted ||
        !this.isAutoClassificationEnabled
      ) {
        this.buildFormFields();
      } else {
        this.initializeDocumentForm(); // Initialize the form if no classification has started
      }
    }
  }

  /**
   * Performs a lookup on key fields that are of type 'Lookup'
   * @param {MappingKeyField[]} mappedKeyFields - The mapped key field data to use for the lookup
   */
  setLookup(mappedKeyFields: MappingKeyField[]): void {
    this.currentDocumentType?.keyfields
      .filter(keyfield => keyfield.dataType === 'Lookup')
      .forEach(keyfield => {
        if (keyfield.lookupType?.namingFields.some(namingField => namingField.required)) {
          let lookupSearchTerm = '';
          keyfield.lookupType.namingFields
            .filter(namingField => namingField.required)
            .forEach(namingField => {
              const matchingField = mappedKeyFields?.find(
                mappedField => mappedField.key === `${keyfield.code}.${namingField.fieldCode}`
              );
              if (matchingField) {
                lookupSearchTerm += `${matchingField.value} `;
              }
            });

          lookupSearchTerm = lookupSearchTerm.trim();
          if (
            lookupSearchTerm &&
            this.documents[this.currentAttachmentIndex].classificationStarted
          ) {
            this.keyFieldsComponent.lookupUsers(
              keyfield.code,
              keyfield.lookupType.code,
              lookupSearchTerm,
              true
            );
          }
        }
      });
  }

  /**
   * Starts the classification process for the selected attachment
   */
  startAttachmentClassification(): void {
    this.documents[this.currentAttachmentIndex].classificationStarted = true;
    if (this.documents[this.currentAttachmentIndex].status) {
      this.buildFormFields();
    }
  }

  /**
   * Uploads an attachment with type, area, and key field information to Softdocs Etrieve Content.
   */
  uploadDocument(): void {
    if (this.documentForm?.valid) {
      const formData = buildKeyFields(
        this.documentForm.value,
        this.currentDocumentType as DocumentType
      );

      const multiValueFields = this.currentDocumentType?.keyfields.filter(kf => kf.multivalue);

      if (multiValueFields) {
        multiValueFields.forEach(multiValueField => {
          const multValueFieldCode = multiValueField.code + '-' + this.currentDocumentType?.code;
          if (this.keyFieldsComponent.multiValueFields[multValueFieldCode]) {
            this.keyFieldsComponent.multiValueFields[multValueFieldCode].forEach(value => {
              formData.push({
                fieldCode: multiValueField.code,
                value: value
              });
            });
          }
        });
      }

      const attachment: Attachment = {
        content: {
          content: this.documents[this.currentAttachmentIndex].fileContent,
          format: 'base64'
        },
        attachmentType: this.documents[this.currentAttachmentIndex].contentType,
        requestId: this.documents[this.currentAttachmentIndex].requestId,
        name: this.documents[this.currentAttachmentIndex].fileName,
        contentType: this.documents[this.currentAttachmentIndex].contentType,
        id: '',
        isInline: false,
        size: 0
      };

      this.contentService
        .uploadDocument(
          attachment,
          this.documentForm.controls['area'].value as string,
          this.documentForm.controls['documentTypeCode'].value as string,
          formData
        )
        .subscribe({
          error: error => {
            console.error('Failed to upload document', error);
            this.showErrorToast('uploadError');
            this.keyFieldsComponent.onUploadComplete();
          },
          complete: () => {
            this.documentManagementSdk
              .deleteDocument(this.tenantId, this.documents[this.currentAttachmentIndex].fileId)
              .then(() => {
                console.log('Document deleted;', this.documents[this.currentAttachmentIndex].fileId);
              });
            this.showUploadDoneToast();
            this.keyFieldsComponent.onUploadComplete();
            this.documents[this.currentAttachmentIndex].completed = true;

            if (this.documents.findIndex(doc => !doc.completed) !== -1) {
              this.selectAttachment(this.documents.findIndex(doc => !doc.completed));
            } else {
              this.redirectBack();
            }
          }
        });
    } else {
      console.error('Form is not valid, cannot upload document.');
      this.keyFieldsComponent.onUploadComplete();
      this.showErrorToast('validationError');
    }
  }

  /**
   * Cancels the file filing process for the current attachment
   */
  cancelFileFiling(): void {
    this.documentManagementSdk
      .deleteDocument(this.tenantId, this.documents[this.currentAttachmentIndex].fileId)
      .then(() => {
        console.log('Document deleted;', this.documents[this.currentAttachmentIndex].fileId);
        this.documents[this.currentAttachmentIndex].canceled = true;
        if (this.documents?.findIndex(doc => !doc.completed && !doc.canceled) !== -1) {
          this.selectAttachment(this.documents.findIndex(doc => !doc.completed && !doc.canceled));
        } else {
          this.redirectBack();
        }
      });
  }

  /**
   * Redirects the user back to the printer application
   */
  redirectBack(): void {
    setTimeout(() => {
      this._location.back();
    }, 2000); // Wait 2 seconds before navigating back
  }

  /**
   * Retrieves the attachments for the file selector
   * @returns {FileSelectorAttachment[]} - An array of file selector attachments
   */
  getFileSelectorAttachments(): FileSelectorAttachment[] {
    const attachments = this.documents.map(
      doc =>
        ({
          name: doc.fileName,
          completed: doc.completed,
          status: doc.status as StatusResponse,
          classificationStarted: doc.classificationStarted,
          canceled: doc.canceled
        }) as FileSelectorAttachment
    );

    return attachments;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Shows a toast message indicating that the file upload is complete.
   */
  showUploadDoneToast(): void {
    this.messageService.add({
      severity: 'success',
      summary: 'uploadToastTitlePrinter',
      detail: 'uploadToastMessagePrinter',
      key: 'uploadDoneToast',
      life: 10000
    });
  }

  /**
   * Shows an error toast message.
   * @param {string} toastMessage - The error message to display.
   * @param {string} [toastData] - Additional data to display in the toast.
   */
  showErrorToast(toastMessage: string, toastData?: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: toastMessage,
      key: 'errorToast',
      data: toastData ? toastData : '',
      life: 10000
    });
  }

  /**
   * Sets the current document status to an empty object, cancelling the auto-classification process
   */
  cancelFileAutoClassification(index: number): void {
    this.documents[index].status = {
      documents: []
    };
  }
}
