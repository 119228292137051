import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {
  BatchIdResponse,
  BatchResponse,
  CreateBatchRequest,
  ListBatchesParams,
  BatchListResponse,
  UpdateBatchRequest,
  BulkCreateBatchDocumentRequest,
  BatchDocumentListResponse,
  BatchDocumentResponse,
  UpdateBatchDocumentRequest,
  UserPrivilegesResponse
} from '../../models/sdk-interfaces';

declare const Softdocs: any;

@Injectable({
  providedIn: 'root'
})
export class BatchService {
  private batchSdk = new Softdocs.BatchSdk(environment.urls.autoclassifierApi);

  constructor() {}

  /**
   * Create a Batch ID
   * @param tenantId Tenant identifier
   * @returns A promise resolving to BatchIdResponse
   */
  createBatchId(tenantId: string): Promise<BatchIdResponse> {
    return this.batchSdk.createBatchId(tenantId);
  }

  /**
   * Create a new Batch
   * @param tenantId Tenant identifier
   * @param userId User identifier
   * @param data Request body for creating a batch
   * @returns A promise resolving to BatchResponse
   */
  createBatch(tenantId: string, userId: string, data: CreateBatchRequest): Promise<BatchResponse> {
    return this.batchSdk.createBatch(tenantId, userId, data);
  }

  /**
   * Update an existing Batch
   * @param tenantId Tenant identifier
   * @param userId User identifier
   * @param batchId Batch identifier
   * @param data Request body for updating a batch
   * @returns A promise resolving to BatchResponse
   */
  updateBatch(
    tenantId: string,
    userId: string,
    batchId: string,
    data: UpdateBatchRequest
  ): Promise<BatchResponse> {
    return this.batchSdk.updateBatch(tenantId, userId, batchId, data);
  }

  /**
   * Get Batch details
   * @param tenantId Tenant identifier
   * @param batchId Batch identifier
   * @returns A promise resolving to BatchResponse
   */
  getBatch(tenantId: string, batchId: string): Promise<BatchResponse> {
    return this.batchSdk.getBatch(tenantId, batchId);
  }

  /**
   * List Batches with optional filters
   * @param tenantId Tenant identifier
   * @param params Optional parameters for listing batches
   * @returns A promise resolving to BatchListResponse
   */
  listBatches(tenantId: string, params?: ListBatchesParams): Promise<BatchListResponse> {
    return this.batchSdk.listBatches(tenantId, params);
  }

  /**
   * Delete a Batch
   * @param tenantId Tenant identifier
   * @param userId User identifier
   * @param batchId Batch identifier
   * @returns A promise resolving to void
   */
  deleteBatch(tenantId: string, userId: string, batchId: string): Promise<void> {
    return this.batchSdk.deleteBatch(tenantId, userId, batchId);
  }

  /**
   * Bulk create documents in a batch
   * @param tenantId Tenant identifier
   * @param userId User identifier
   * @param batchId Batch identifier
   * @param data List of documents to add to the batch
   * @returns A promise resolving to BatchDocumentListResponse
   */
  bulkCreateBatchDocuments(
    tenantId: string,
    userId: string,
    batchId: string,
    data: BulkCreateBatchDocumentRequest
  ): Promise<BatchDocumentListResponse> {
    return this.batchSdk.bulkCreateBatchDocuments(tenantId, userId, batchId, data);
  }

  /**
   * Get documents in a batch
   * @param tenantId Tenant identifier
   * @param batchId Batch identifier
   * @returns A promise resolving to BatchDocumentListResponse
   */
  getBatchDocuments(tenantId: string, batchId: string): Promise<BatchDocumentListResponse> {
    return this.batchSdk.getBatchDocuments(tenantId, batchId);
  }

  /**
   * Update a specific document in a batch
   * @param tenantId Tenant identifier
   * @param userId User identifier
   * @param batchId Batch identifier
   * @param documentId Document identifier
   * @param data Request body for updating a batch document
   * @returns A promise resolving to BatchDocumentResponse
   */
  updateBatchDocument(
    tenantId: string,
    userId: string,
    batchId: string,
    documentId: string,
    data: UpdateBatchDocumentRequest
  ): Promise<BatchDocumentResponse> {
    return this.batchSdk.updateBatchDocument(tenantId, userId, batchId, documentId, data);
  }

  /**
   * Remove a document from a batch
   * @param tenantId Tenant identifier
   * @param userId User identifier
   * @param batchId Batch identifier
   * @param documentId Document identifier
   * @returns A promise resolving to void
   */
  removeBatchDocument(
    tenantId: string,
    userId: string,
    batchId: string,
    documentId: string
  ): Promise<void> {
    return this.batchSdk.removeBatchDocument(tenantId, userId, batchId, documentId);
  }

  /**
   * Checkout a Batch
   * @param tenantId Tenant identifier
   * @param userId User identifier
   * @param batchId Batch identifier
   * @returns Updated batch details
   */
  checkoutBatch(tenantId: string, userId: string, batchId: string): Promise<BatchResponse> {
    return this.batchSdk.checkoutBatch(tenantId, userId, batchId);
  }

  /**
   * Checkin a Batch
   * @param tenantId Tenant identifier
   * @param userId User identifier
   * @param batchId Batch identifier
   * @returns Updated batch details
   */
  checkinBatch(tenantId: string, userId: string, batchId: string): Promise<BatchResponse> {
    return this.batchSdk.checkinBatch(tenantId, userId, batchId);
  }

  /**
   * Get Batch Privileges
   * @param tenantId Tenant identifier
   * @param userId User identifier
   * @param applicationCode Application code
   * @param accessToken bearer token
   * @returns User privileges
   */
  getBatchPrivileges(
    tenantId: string,
    userId: string,
    applicationCode: string,
    accessToken: string
  ): Promise<UserPrivilegesResponse> {
    return this.batchSdk.getBatchPrivileges(tenantId, userId, applicationCode, accessToken);
  }
}
