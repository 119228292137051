import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["callback"],
  _excluded2 = ["callback"],
  _excluded3 = ["callback"],
  _excluded4 = ["callback"];
import { deepResetState, errorHandler, retryIfNeeded } from '../../../helpers';
import { initialState } from './state';
import { daysToMinutes, minutesToDays } from './helpers/timeConvertors';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;

  /**
   * @protected
   */
  const __getSecurityPolicyPublicStateWithCustomLogin = async (securityPolicyPublicState, retryConfig) => {
    var _authStrategies$mainA, _authStrategies$mainA2;
    const isCustomLoginEnabled = await actions.customLoginEnabled();
    if (!isCustomLoginEnabled) {
      return securityPolicyPublicState;
    }
    const authStrategies = await __getAuthStrategy(retryConfig);
    if (!(authStrategies != null && (_authStrategies$mainA = authStrategies.mainAuthStrategies) != null && (_authStrategies$mainA2 = _authStrategies$mainA[0]) != null && _authStrategies$mainA2.strategy)) {
      return securityPolicyPublicState;
    }
    return _extends({}, securityPolicyPublicState, {
      authStrategy: authStrategies.mainAuthStrategies[0].strategy
    });
  };

  /**
   * @private
   */
  const __getAuthStrategy = async retryConfig => {
    const isAuthenticated = store.auth.isAuthenticated;
    let policy;
    if (isAuthenticated) {
      policy = await retryIfNeeded(api.auth.getPublicAuthStrategiesConfigForAuthenticatedUser, retryConfig);
    } else {
      policy = await retryIfNeeded(api.auth.getVendorPublicAuthStrategiesConfig, retryConfig);
    }
    return policy;
  };

  /**
   * @private
   */
  const __setSecurityPolicyPublicStateForCustomLogin = async authStrategy => {
    var _store$auth$securityP, _store$auth$securityP2;
    const isCustomLoginEnabled = await actions.customLoginEnabled();
    if (!authStrategy || !isCustomLoginEnabled) {
      return;
    }
    const {
      policy
    } = (_store$auth$securityP = (_store$auth$securityP2 = store.auth.securityPolicyState) == null ? void 0 : _store$auth$securityP2.publicPolicy) != null ? _store$auth$securityP : {
      policy: null
    };
    setSecurityPolicyPublicState({
      policy: _extends({}, policy, {
        authStrategy
      }),
      loading: false
    });
  };
  const setSecurityPolicyState = state => {
    Object.assign(store.auth.securityPolicyState, state);
  };
  const setSecurityPolicyGlobalState = state => {
    Object.assign(store.auth.securityPolicyState.globalPolicy, state);
  };
  const setSecurityPolicyPublicState = state => {
    Object.assign(store.auth.securityPolicyState.publicPolicy, state);
  };
  const setSecurityPolicyMfaState = state => {
    Object.assign(store.auth.securityPolicyState.mfaPolicy, state);
  };
  const setSecurityPolicyVendorMfaState = state => {
    Object.assign(store.auth.securityPolicyState.vendorMfaPolicy, state);
  };
  const setSecurityPolicyLockoutState = state => {
    Object.assign(store.auth.securityPolicyState.lockoutPolicy, state);
  };
  const setSecurityPolicyVendorLockoutState = state => {
    Object.assign(store.auth.securityPolicyState.vendorLockoutPolicy, state);
  };
  const setSecurityPolicyCaptchaState = state => {
    Object.assign(store.auth.securityPolicyState.captchaPolicy, state);
  };
  const setSecurityPolicyPasswordHistoryState = state => {
    Object.assign(store.auth.securityPolicyState.passwordHistoryPolicy, state);
  };
  const setSecurityPolicyVendorPasswordHistoryState = state => {
    Object.assign(store.auth.securityPolicyState.vendorPasswordHistoryPolicy, state);
  };
  const setSecurityPolicyPasswordRotationState = state => {
    Object.assign(store.auth.securityPolicyState.passwordRotationPolicy, state);
  };
  const setSecurityPolicyPasswordState = state => {
    Object.assign(store.auth.securityPolicyState.passwordPolicy, state);
  };
  const setSecurityPolicyAuthStrategyPublicState = state => {
    Object.assign(store.auth.securityPolicyState.publicAuthStrategyPolicy, state);
  };
  const resetSecurityPolicyState = () => {
    deepResetState(store, ['auth', 'securityPolicyState'], initialState);
  };
  const loadSecurityPolicy = async payload => {
    const actionPayload = {
      retryConfig: payload == null ? void 0 : payload.retryConfig
    };
    await Promise.all([
    // this request tend to fail with 403 so no retry
    loadGlobalSecurityPolicy(),
    // this request tend to fail with 404 when mfa policy is missing
    loadSecurityPolicyMfa(), loadSecurityPolicyVendorMfa(actionPayload), loadSecurityPolicyLockout(actionPayload),
    // this request tend to fail with 403 so no retry
    loadSecurityPolicyVendorLockout(actionPayload), loadSecurityPolicyVendorPasswordHistory(), loadSecurityPolicyCaptcha(actionPayload), loadPublicAuthStrategiesPolicy(actionPayload)]);
  };
  const loadGlobalSecurityPolicy = async payload => {
    setSecurityPolicyGlobalState({
      loading: true,
      error: null
    });
    try {
      const policy = await retryIfNeeded(() => api.auth.getGlobalSecurityPolicy(), payload == null ? void 0 : payload.retryConfig);
      setSecurityPolicyGlobalState({
        policy,
        loading: false
      });
    } catch (e) {
      setSecurityPolicyGlobalState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const loadPublicSecurityPolicy = async payload => {
    setSecurityPolicyPublicState({
      loading: true,
      error: null
    });
    try {
      const policy = await api.auth.getVendorConfig();
      const policyWithCustomLoginAuthStrategy = await __getSecurityPolicyPublicStateWithCustomLogin(policy, payload == null ? void 0 : payload.retryConfig);
      setSecurityPolicyPublicState({
        policy: policyWithCustomLoginAuthStrategy,
        loading: false
      });
    } catch (e) {
      setSecurityPolicyPublicState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const loadVendorPasswordConfig = async payload => {
    setSecurityPolicyPasswordState({
      loading: true,
      error: null
    });
    try {
      var _payload$callback;
      const policy = await retryIfNeeded(() => api.auth.getPasswordConfigPolicy(), payload == null ? void 0 : payload.retryConfig);
      setSecurityPolicyPasswordState({
        policy,
        loading: false
      });
      payload == null ? void 0 : (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, policy);
    } catch (e) {
      var _payload$callback2;
      setSecurityPolicyPasswordState({
        error: errorHandler(e),
        loading: false
      });
      payload == null ? void 0 : (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, null, e);
    }
  };
  const loadSecurityPolicyMfa = async payload => {
    setSecurityPolicyMfaState({
      loading: true,
      error: null
    });
    try {
      const policy = await retryIfNeeded(() => api.auth.getMfaPolicy(), payload == null ? void 0 : payload.retryConfig);
      setSecurityPolicyMfaState({
        policy,
        loading: false
      });
    } catch (e) {
      setSecurityPolicyMfaState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const loadSecurityPolicyVendorMfa = async payload => {
    setSecurityPolicyMfaState({
      loading: true,
      error: null
    });
    try {
      const policy = await retryIfNeeded(() => api.auth.getVendorMfaPolicy(), payload == null ? void 0 : payload.retryConfig);
      setSecurityPolicyVendorMfaState({
        policy,
        loading: false
      });
    } catch (e) {
      setSecurityPolicyVendorMfaState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const loadSecurityPolicyLockout = async payload => {
    setSecurityPolicyLockoutState({
      loading: true,
      error: null
    });
    try {
      const policy = await retryIfNeeded(() => api.auth.getLockoutPolicy(), payload == null ? void 0 : payload.retryConfig);
      setSecurityPolicyLockoutState({
        policy,
        loading: false
      });
    } catch (e) {
      setSecurityPolicyLockoutState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const loadSecurityPolicyVendorLockout = async payload => {
    setSecurityPolicyVendorLockoutState({
      loading: true,
      error: null
    });
    try {
      const policy = await retryIfNeeded(() => api.auth.getVendorLockoutPolicy(), payload == null ? void 0 : payload.retryConfig);
      setSecurityPolicyVendorLockoutState({
        policy,
        loading: false
      });
    } catch (e) {
      setSecurityPolicyVendorLockoutState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const loadSecurityPolicyCaptcha = async payload => {
    setSecurityPolicyLockoutState({
      loading: true,
      error: null
    });
    try {
      const policy = await retryIfNeeded(() => api.auth.getCaptchaPolicy(), payload == null ? void 0 : payload.retryConfig);
      setSecurityPolicyCaptchaState({
        policy,
        loading: false
      });
    } catch (e) {
      setSecurityPolicyCaptchaState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const loadSecurityPolicyPasswordHistory = async () => {
    setSecurityPolicyPasswordHistoryState({
      loading: true,
      error: null
    });
    try {
      const policy = await api.auth.getPasswordHistoryPolicy();
      setSecurityPolicyPasswordHistoryState({
        policy,
        loading: false
      });
    } catch (e) {
      setSecurityPolicyPasswordHistoryState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const loadSecurityPolicyVendorPasswordHistory = async payload => {
    setSecurityPolicyVendorPasswordHistoryState({
      loading: true,
      error: null
    });
    try {
      const policy = await retryIfNeeded(() => api.auth.getVendorPasswordHistoryPolicy(), payload == null ? void 0 : payload.retryConfig);
      setSecurityPolicyVendorPasswordHistoryState({
        policy,
        loading: false
      });
    } catch (e) {
      setSecurityPolicyVendorPasswordHistoryState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const loadSecurityPolicyPasswordRotation = async () => {
    setSecurityPolicyPasswordRotationState({
      loading: true,
      error: null
    });
    try {
      const policy = await api.securityPolicy.getPasswordRotationPolicy();
      const vendorPolicy = await api.securityPolicy.getVendorPasswordRotationPolicy();
      policy.notificationPeriod = minutesToDays(policy.notificationPeriod);
      const vendorPolicyInDays = minutesToDays(vendorPolicy.rotationPeriod);
      const policyInDays = minutesToDays(policy.rotationPeriod);
      policy.rotationPeriod = Math.min(vendorPolicyInDays, policyInDays);
      policy.vendorRotationPeriod = vendorPolicyInDays;
      setSecurityPolicyPasswordRotationState({
        policy,
        loading: false
      });
    } catch (e) {
      setSecurityPolicyPasswordRotationState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const loadPublicAuthStrategiesPolicy = async payload => {
    setSecurityPolicyAuthStrategyPublicState({
      loading: true,
      error: null
    });
    try {
      var _policy$mainAuthStrat, _policy$mainAuthStrat2, _payload$callback3;
      const policy = await __getAuthStrategy(payload == null ? void 0 : payload.retryConfig);
      await __setSecurityPolicyPublicStateForCustomLogin(policy == null ? void 0 : (_policy$mainAuthStrat = policy.mainAuthStrategies) == null ? void 0 : (_policy$mainAuthStrat2 = _policy$mainAuthStrat[0]) == null ? void 0 : _policy$mainAuthStrat2.strategy);
      setSecurityPolicyAuthStrategyPublicState({
        policy,
        loading: false
      });
      payload == null ? void 0 : (_payload$callback3 = payload.callback) == null ? void 0 : _payload$callback3.call(payload, policy);
    } catch (e) {
      var _payload$callback4;
      setSecurityPolicyAuthStrategyPublicState({
        error: errorHandler(e),
        loading: false
      });
      payload == null ? void 0 : (_payload$callback4 = payload.callback) == null ? void 0 : _payload$callback4.call(payload, null, e);
    }
  };
  const saveSecurityPolicyMfa = async payload => {
    const {
        callback
      } = payload,
      newSecurityPolicy = _objectWithoutPropertiesLoose(payload, _excluded);
    setSecurityPolicyMfaState({
      saving: true,
      error: null
    });
    try {
      const policy = await api.auth.securityPolicy.saveMfaPolicy(newSecurityPolicy);
      setSecurityPolicyMfaState({
        policy,
        saving: false
      });
      callback == null ? void 0 : callback(policy);
    } catch (e) {
      setSecurityPolicyMfaState({
        saving: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const saveSecurityPolicyLockout = async payload => {
    const {
        callback
      } = payload,
      newSecurityPolicy = _objectWithoutPropertiesLoose(payload, _excluded2);
    setSecurityPolicyLockoutState({
      saving: true,
      error: null
    });
    try {
      const policy = await api.auth.saveLockoutPolicy(newSecurityPolicy);
      setSecurityPolicyLockoutState({
        policy,
        saving: false
      });
      callback == null ? void 0 : callback(policy);
    } catch (e) {
      setSecurityPolicyLockoutState({
        saving: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const saveSecurityPolicyPasswordHistory = async payload => {
    const {
        callback
      } = payload,
      newSecurityPolicy = _objectWithoutPropertiesLoose(payload, _excluded3);
    setSecurityPolicyPasswordHistoryState({
      saving: true,
      error: null
    });
    try {
      const policy = await api.auth.savePasswordHistoryPolicy(newSecurityPolicy);
      setSecurityPolicyPasswordHistoryState({
        policy,
        saving: false
      });
      callback == null ? void 0 : callback(policy);
    } catch (e) {
      setSecurityPolicyPasswordHistoryState({
        saving: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const saveSecurityPolicyPasswordRotation = async payload => {
    const {
        callback
      } = payload,
      newSecurityPolicy = _objectWithoutPropertiesLoose(payload, _excluded4);
    setSecurityPolicyPasswordRotationState({
      saving: true,
      error: null
    });
    try {
      const policy = await api.securityPolicy.savePasswordRotationPolicy(_extends({}, newSecurityPolicy, {
        notificationPeriod: daysToMinutes(newSecurityPolicy.notificationPeriod),
        rotationPeriod: daysToMinutes(newSecurityPolicy.rotationPeriod)
      }));
      setSecurityPolicyPasswordRotationState({
        policy: newSecurityPolicy,
        saving: false
      });
      callback == null ? void 0 : callback(policy);
    } catch (e) {
      setSecurityPolicyPasswordRotationState({
        saving: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  return {
    // reducers
    setSecurityPolicyState,
    setSecurityPolicyGlobalState,
    setSecurityPolicyPublicState,
    setSecurityPolicyMfaState,
    setSecurityPolicyVendorMfaState,
    setSecurityPolicyLockoutState,
    setSecurityPolicyVendorLockoutState,
    setSecurityPolicyCaptchaState,
    setSecurityPolicyPasswordHistoryState,
    setSecurityPolicyVendorPasswordHistoryState,
    setSecurityPolicyPasswordRotationState,
    resetSecurityPolicyState,
    setSecurityPolicyPasswordState,
    setSecurityPolicyAuthStrategyPublicState,
    // actions
    loadSecurityPolicy,
    loadGlobalSecurityPolicy,
    loadPublicSecurityPolicy,
    loadVendorPasswordConfig,
    loadSecurityPolicyMfa,
    loadSecurityPolicyVendorMfa,
    loadSecurityPolicyLockout,
    loadSecurityPolicyVendorLockout,
    loadSecurityPolicyCaptcha,
    loadSecurityPolicyPasswordHistory,
    loadSecurityPolicyVendorPasswordHistory,
    loadSecurityPolicyPasswordRotation,
    loadPublicAuthStrategiesPolicy,
    saveSecurityPolicyMfa,
    saveSecurityPolicyLockout,
    saveSecurityPolicyPasswordHistory,
    saveSecurityPolicyPasswordRotation,
    // protected
    __getSecurityPolicyPublicStateWithCustomLogin
  };
});