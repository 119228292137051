// External Libraries
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, of, switchMap } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

// FontAwesome
import {
  faFile,
  faFileCirclePlus,
  faQuestionCircle,
  faGear,
  faInfoCircle,
  faSignOut
} from '@fortawesome/free-solid-svg-icons';

// Frontegg Services
import { FronteggAppService, FronteggAuthService } from '@frontegg/angular';

// Project Services
import { AuthService } from '../../services/auth/auth.service';
import { FeatureFlagService } from '../../services/feature-flag/feature-flag.service';
import { SignOutService } from '../../services/sign-out-state/sign-out-state.service';
import { UserPrivilegesService } from '../../services/auth-privileges/auth-privileges.service';

declare const pendo: any;

@Component({
  selector: 'app-web-capture',
  templateUrl: './web-capture.component.html',
  styleUrls: ['./web-capture.component.scss']
})
export class WebCaptureComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  private signOutSubscription = new Subscription();

  isAccessEnabled: boolean | null = null;
  isAccessFlagLoading: boolean = true;
  featureFlagErrorText: string = '';

  isLoading = true;
  loadingSubscription: Subscription;
  user?: any;
  tenantId: string = '';
  isUrlVerified: boolean = false;
  currentTab: string = '';
  showSettings: boolean = false;
  isSettingsOpening: boolean = false;
  showAboutModal: boolean = false;

  faFile = faFile;
  faFileCirclePlus = faFileCirclePlus;
  faQuestionCircle = faQuestionCircle;
  faGear = faGear;
  faInfoCircle = faInfoCircle;
  faSignOut = faSignOut;

  constructor(
    private fronteggAuthService: FronteggAuthService,
    private fronteggAppService: FronteggAppService,
    private authService: AuthService,
    private featureFlagService: FeatureFlagService,
    private router: Router,
    private signOutService: SignOutService,
    private userPrivilegesService: UserPrivilegesService
  ) {
    this.loadingSubscription = fronteggAppService.isLoading$.subscribe(
      isLoading => (this.isLoading = isLoading)
    );
  }

  ngOnInit(): void {
    this.currentTab = this.router.url.includes('batch') ? 'batches' : '';
    this.checkFeatureFlag();

    this.subscription.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.currentTab = this.router.url.includes('batch') ? 'batches' : '';
        }
      })
    );

    this.signOutSubscription = this.signOutService.signOut$.subscribe(signOut => {
      if (signOut && !this.signOutService.isFilingContent) {
        this.signOutService.signOut();
      }
    });
  }

  /**
   * Run the feature flag check for the provided tenantId
   * @param tenantId the provided tenantId
   */
  runFeatureFlagCheck(tenantId: string): void {
    this.isAccessFlagLoading = true;
    this.featureFlagService
      .initialize({ tenantId })
      .then(() =>
        this.subscription.add(
          this.featureFlagService
            .getFlag('web-capture-access', false)
            .pipe(
              switchMap(flagValue => {
                this.isAccessFlagLoading = false;
                if (flagValue) {
                  this.isAccessEnabled = true;
                  pendo.initialize({ account: { id: tenantId } });
                  this.startFronteggAuth();
                } else {
                  this.isUrlVerified = false;
                  this.isAccessEnabled = false;
                  this.featureFlagErrorText = 'webCaptureFeatureFlagError';
                }
                return of(null);
              })
            )
            .subscribe()
        )
      )
      .catch(() => {
        this.isAccessFlagLoading = false;
        this.isUrlVerified = false;
        this.isAccessEnabled = false;
      });
  }

  /**
   * Re-verifies the tenant URL and triggers feature flag check based on the tenant ID
   */
  onSuccessfulVerification(): void {
    const tenantId = localStorage.getItem('tenantId');
    if (tenantId) {
      this.isAccessFlagLoading = true;
      this.runFeatureFlagCheck(tenantId);
    }
  }

  /**
   * Check the feature flag based on tenantId from localStorage.
   */
  checkFeatureFlag(): void {
    const tenantId = localStorage.getItem('tenantId');

    if (tenantId) {
      this.runFeatureFlagCheck(tenantId);
    } else {
      this.isAccessFlagLoading = false;
      this.isUrlVerified = false;
      this.isAccessEnabled = null;
    }
  }

  /**
   * Starts Frontegg Authentication flow
   */
  startFronteggAuth() {
    this.isUrlVerified = true;

    const organization = localStorage.getItem('vanityName');
    this.tenantId = localStorage.getItem('tenantId') || '';

    if (this.tenantId && organization) {
      this.subscription.add(
        this.fronteggAuthService.authState$.subscribe(authState => {
          if (!authState.isAuthenticated && !this.isLoading) {
            this.loginWithRedirect(this.tenantId, organization);
          } else {
            if (authState.user?.accessToken) {
              this.user = authState.user;
              this.authService.setAuthToken(this.user?.accessToken);
              this.userPrivilegesService
                .getUserPrivileges(
                  this.tenantId,
                  this.user.id,
                  'WEB_CAPTURE',
                  this.user.accessToken
                )
                .then(privileges => {
                  this.userPrivilegesService.setPrivileges(privileges); // Set privileges initially
                });
            }
          }
        })
      );
    } else {
      this.isUrlVerified = false;
    }
  }

  /**
   * Redirects the user to the login page with the specified tenant id and organization
   * @param tenantId - the id of the tenant to log in to
   * @param organization - the organization name associated with the tenant
   */
  loginWithRedirect(tenantId: string, organization: string): void {
    this.fronteggAuthService.loginWithRedirect({
      tenantId: tenantId,
      organization: organization
    });
  }

  /**
   * Signs out the user of the application and redirects them back to the log in page
   */
  signOut(): void {
    this.signOutService.setSignOutState(true);
  }

  goToTab(tab: string) {
    this.navigateToTab(tab);
  }

  navigateToTab(tab: string) {
    if (tab === '') {
      this.router.navigate(['/webcapture']);
    } else {
      this.router.navigate(['/webcapture', tab]);
    }
  }

  @HostListener('document:click')
  public onClick(): void {
    if (!this.isSettingsOpening) {
      this.showSettings = false;
    } else {
      this.isSettingsOpening = false;
    }
  }

  /**
   * Checks if the settings menu should be hidden after a focus event.
   */
  checkNavButtonsFocus(event: FocusEvent): void {
    if (!(event.relatedTarget as HTMLInputElement)?.classList.contains('settings-option')) {
      this.showSettings = false;
    }
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.signOutSubscription.unsubscribe();
  }

  toggleShowSettings(): void {
    this.isSettingsOpening = !this.showSettings ? true : false;
    this.showSettings = !this.showSettings;
  }

  /**
   * Removes the tenant ID and vanity name from local storage and reloads the page to reset the Content URL.
   */
  changeContentUrl(): void {
    localStorage.removeItem('tenantId');
    localStorage.removeItem('vanityName');
    location.reload();
  }

  canPerformAction(actionCode: string): Observable<boolean> {
    return this.userPrivilegesService.canPerformAction(actionCode);
  }
}
