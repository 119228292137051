<div class="doc-filing-container">
  <app-file-selector
    [attachments]="getFileSelectorAttachments()"
    [currentAttachmentIndex]="currentAttachmentIndex"
    (selectAttachmentEvent)="selectAttachment($event)"
    [enableBatchOptions]="(canPerformAction('EDIT_BATCH') | async) ?? false"
    [canCloseBatch]="true"
    (openEditBatchEvent)="openEditBatchModal()"
    (openAddDocumentsEvent)="openAddDocumentsModal()"
    (deleteBatchEvent)="confirmDeleteBatch()"
    (closeBatchEvent)="confirmCloseBatch()"
    [isLoadingFiles]="loadingFilingFiles"
    [isBatchCompleted]="isBatchCompleted()"
  ></app-file-selector>
  <div
    class="document-viewer-container"
    [hidden]="showUnsupportedFileMessage"
  >
    <document-viewer-widget
      [base64Content]="documents[currentAttachmentIndex]?.fileContent"
      [showClassificationButton]="false"
      (unsupportedFileType)="showUnsupportedFileMessage = true"
    ></document-viewer-widget>
  </div>
  <div
    *ngIf="showUnsupportedFileMessage"
    class="unsupported-msg-container"
  >
    <span class="unsupported-msg-text">{{ 'unsupportedFileType' | translate }}</span>
  </div>
  <div
    *ngIf="showClassificationResults"
    class="classification-results-container"
  >
    <app-classification-results
      [currentMostConfidentDocument]="currentMostConfidentDocument"
      (closeResultsEvent)="closeClassificationResults()"
    ></app-classification-results>
  </div>
  <div class="key-fields-container">
    <app-key-fields
      [docAreas]="areas"
      [docFormGroup]="documentForm"
      (changeEvent)="formOnChange($event)"
      [attachmentStatus]="documents[this.currentAttachmentIndex]?.status"
      [filteredDocumentTypes]="filteredDocumentTypes"
      [currentDocumentType]="currentDocumentType"
      (uploadDocumentEvent)="uploadDocument()"
      [showDeleteDocumentButton]="(canPerformAction('MANAGE_BATCH_DOCUMENTS') | async) ?? false"
      (deleteDocumentEvent)="deleteDocument()"
      [isAutoClassificationEnabled]="true"
      (cancelFileAutoClassificationEvent)="
        cancelFileAutoClassification(this.currentAttachmentIndex)
      "
    ></app-key-fields>
  </div>
</div>
<p-dialog
  [header]="'addDocuments' | translate"
  [modal]="true"
  [(visible)]="showAddDocumentsModal"
  [style]="{ width: '647px' }"
  class="add-documents-dialog"
  [draggable]="false"
  [resizable]="false"
  (onShow)="onDialogShow()"
  [closeAriaLabel]="'close' | translate"
>
  <div class="file-uploader-container">
    <app-file-uploader
      [tenantId]="tenantId"
      (uploadedDocumentIdsEvent)="addDocumentsToBatch($event)"
    >
    </app-file-uploader>
  </div>
</p-dialog>
<p-dialog
  [header]="'editBatch' | translate"
  [modal]="true"
  [(visible)]="showEditBatchModal"
  [style]="{ width: '550px' }"
  class="edit-batch-dialog"
  [draggable]="false"
  [resizable]="false"
  [closeAriaLabel]="'close' | translate"
>
  <div class="batch-info">
    <div class="batch-info-item">
      <span class="batch-info-item-label">{{ 'batchId' | translate }}</span>
      <span class="batch-info-item-text">{{ batch?.batchId }}</span>
    </div>
    <div class="batch-info-item">
      <span class="batch-info-item-label">{{ 'creationDate' | translate }}</span>
      <span class="batch-info-item-text">{{ batch?.createdAt | date: 'MM/dd/yyyy HH:mm a' }}</span>
    </div>
    <div class="batch-info-item">
      <span class="batch-info-item-label">{{ 'documentCount' | translate }}</span>
      <span class="batch-info-item-text">{{ batch?.statistics?.documentCount }}</span>
    </div>
    <div class="batch-info-item">
      <span class="batch-info-item-label">{{ 'createdBy' | translate }}</span>
      <span class="batch-info-item-text">{{ batch?.createdBy }}</span>
    </div>
  </div>
  <div class="batch-settings">
    <span class="batch-setting-title">{{ 'batchSettings' | translate }}</span>
    <form
      *ngIf="showEditBatchModal"
      class="edit-batch-form"
      [formGroup]="editBatchFormGroup"
    >
      <label>
        <span>{{ 'batchName' | translate }}</span>
        <input
          type="text"
          formControlName="name"
        />
      </label>
      <label *ngIf="canPerformAction('CHANGE_BATCH_ACCESS') | async">
        {{ 'department' | translate }}
        <div class="select-container">
          <select formControlName="department">
            <option
              *ngFor="let department of settingsDepartments"
              [value]="department.code"
            >
              {{ department.name }}
            </option>
          </select>
        </div>
      </label>
      <label htmlFor="defaultArea">
        {{ 'defaultArea' | translate }}
        <div class="select-container">
          <select formControlName="defaultArea">
            <option
              *ngFor="let area of settingsAreas"
              [value]="area.code"
            >
              {{ area.name }}
            </option>
          </select>
        </div>
      </label>
      <label>
        <span>{{ 'defaultDocumentType' | translate }}</span>
        <select formControlName="defaultDocumentType">
          <option
            *ngFor="let documentType of batchEditFilteredDocumentTypes"
            [value]="documentType.code"
          >
            {{ documentType.name }}
          </option>
        </select>
      </label>
    </form>
  </div>
  <div class="buttons-container">
    <button
      class="cancel-button"
      (click)="closeEditBatchModal()"
      [attr.aria-label]="'cancel' | translate"
    >
      {{ 'cancel' | translate }}
    </button>
    <button
      class="save-button"
      (click)="saveBatchSettings()"
      [attr.aria-label]="'save' | translate"
      [disabled]="isBatchCompleted()"
    >
      {{ 'save' | translate }}
    </button>
  </div>
</p-dialog>
<p-toast
  position="bottom-right"
  key="uploadDoneToast"
  styleClass="upload-done-toast"
>
  <ng-template
    let-message
    pTemplate="message"
  >
    <div class="toast-message-container">
      <div class="icon-container">
        <fa-icon [icon]="faCircleCheck"></fa-icon>
      </div>
      <div class="message-container">
        <span class="message-summary">{{ message.summary | translate }}</span>
        <span>{{ message.detail | translate }}</span>
      </div>
    </div>
  </ng-template>
</p-toast>
<p-toast
  position="bottom-right"
  key="errorToast"
  styleClass="error-toast"
>
  <ng-template
    let-message
    pTemplate="message"
  >
    <div class="toast-message-container">
      <div class="icon-container">
        <fa-icon [icon]="faExclamationCircle"></fa-icon>
      </div>
      <div class="message-container">
        <span class="message-summary">{{ message.summary | translate }}</span>
        <span>{{ message.detail | translate }}</span>
        <span *ngIf="message.data">{{ message.data }}</span>
      </div>
    </div>
  </ng-template>
</p-toast>
