<!-- Batch Creation Component -->
<app-batch-creation
  [tenantId]="tenantId"
  [userId]="user.customClaims.principal_id"
  [areas]="areas"
  (cancelBatchCreationEvent)="cancelBatchCreation()"
  (hasUploadedFilesEvent)="hasUploadedFilesAtCreation = true"
  (finishBatchCreationEvent)="finishBatchCreation()"
  [deleteDocumentsEvent]="deleteDocumentsEventSubject.asObservable()"
></app-batch-creation>
