import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"],
  _excluded2 = ["callback"],
  _excluded3 = ["callback"],
  _excluded4 = ["callback"];
import { errorHandler, withRetryConfig } from '../../../helpers';
import { RestrictionType } from '@frontegg/rest-api';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;

  /**
   * @private
   */
  const __getPagination = statePagination => {
    var _statePagination$_fil, _statePagination$_lim, _statePagination$_off;
    return {
      _filter: (_statePagination$_fil = statePagination == null ? void 0 : statePagination._filter) != null ? _statePagination$_fil : RestrictionType.ALLOW,
      _limit: (_statePagination$_lim = statePagination == null ? void 0 : statePagination._limit) != null ? _statePagination$_lim : 10,
      _offset: (_statePagination$_off = statePagination == null ? void 0 : statePagination._offset) != null ? _statePagination$_off : 0
    };
  };

  /**
   * This function is used to wrap sagas of the security page.
   * This function returns function,
   * that execute the saga it gets as a parameter,
   * and after it execute loadRecommendations and loadInsights sagas.
   * We need to call loadRecommendations and loadInsights after all change in the security page,
   * in order to keep the recommendations and insights updated.
   * @param action - saga to execute
   * @protected
   */
  const __securityCenterSagaWrapper = action => {
    return async (...args) => {
      const [securityCenterFeatureFlag] = await actions.getFeatureFlags(['security-center-admin-portal-major-version']);
      await action(...args);
      if (securityCenterFeatureFlag) {
        await actions.loadRecommendations(withRetryConfig());
        await actions.loadInsights(withRetryConfig());
      }
    };
  };
  const setRestrictionsState = state => {
    Object.assign(store.auth.restrictionsState, state);
  };
  const setIpRestrictionsState = state => {
    Object.assign(store.auth.restrictionsState.ipRestrictions, state);
  };
  const setEmailDomainRestrictionsState = state => {
    Object.assign(store.auth.restrictionsState.emailDomainRestrictions, state);
  };
  const loadIpRestrictions = async () => {
    setIpRestrictionsState({
      loading: true,
      error: null
    });
    try {
      const ipRestrictionState = store.auth.restrictionsState.ipRestrictions;
      const restrictionsConfig = await api.auth.getIPRestrictionsConfig();
      const pagination = __getPagination({
        _filter: restrictionsConfig.strategy,
        _limit: ipRestrictionState.pagination._limit,
        _offset: ipRestrictionState.pagination._offset
      });
      const {
        items,
        _metadata: {
          totalPages
        }
      } = await api.auth.getIPRestrictions(pagination);

      // @ts-ignore // TODO: fix type in rest-api
      const restrictions = items;
      setIpRestrictionsState({
        data: {
          restrictions,
          config: restrictionsConfig
        },
        totalPages,
        pagination,
        loading: false
      });
    } catch (e) {
      setIpRestrictionsState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const saveIpRestriction = async payload => {
    const {
        callback
      } = payload,
      newRestriction = _objectWithoutPropertiesLoose(payload, _excluded);
    setIpRestrictionsState({
      saving: true,
      error: null
    });
    try {
      var _ipRestrictionsState$, _ipRestrictionsState$2;
      await api.auth.createIPRestriction(newRestriction);
      const ipRestrictionsState = store.auth.restrictionsState.ipRestrictions;
      const pagination = __getPagination(ipRestrictionsState.pagination);
      const {
        items,
        _metadata: {
          totalPages
        }
      } = await api.auth.getIPRestrictions(pagination);

      // @ts-ignore // TODO: fix type in rest-api
      const restrictions = items;
      await checkIfUserIpValid();
      setIpRestrictionsState({
        data: {
          config: (_ipRestrictionsState$ = (_ipRestrictionsState$2 = ipRestrictionsState.data) == null ? void 0 : _ipRestrictionsState$2.config) != null ? _ipRestrictionsState$ : {
            isActive: false,
            strategy: RestrictionType.ALLOW
          },
          restrictions
        },
        pagination,
        totalPages,
        saving: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setIpRestrictionsState({
        saving: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const saveIpRestrictionsConfig = async payload => {
    const {
        callback
      } = payload,
      config = _objectWithoutPropertiesLoose(payload, _excluded2);
    setIpRestrictionsState({
      saving: true,
      error: null
    });
    try {
      await api.auth.updateIPRestrictionConfig(config);
      const ipRestrictionsState = store.auth.restrictionsState.ipRestrictions;
      const restrictionsConfig = await api.auth.getIPRestrictionsConfig();
      const pagination = __getPagination(_extends({}, ipRestrictionsState.pagination, {
        _filter: restrictionsConfig.strategy
      }));
      const {
        items,
        _metadata: {
          totalPages
        }
      } = await api.auth.getIPRestrictions(pagination);

      // @ts-ignore // TODO: fix type in rest-api
      const restrictions = items;
      await checkIfUserIpValid();
      setIpRestrictionsState({
        data: {
          config: restrictionsConfig,
          restrictions
        },
        pagination,
        totalPages,
        saving: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setIpRestrictionsState({
        saving: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const deleteIpRestriction = async payload => {
    const {
      id,
      callback
    } = payload;
    setIpRestrictionsState({
      saving: true,
      error: null
    });
    try {
      var _ipRestrictionsState$3;
      await api.auth.deleteIPRestriction(id);
      const ipRestrictionsState = store.auth.restrictionsState.ipRestrictions;
      const pagination = __getPagination(ipRestrictionsState.pagination);
      const {
        items,
        _metadata: {
          totalPages
        }
      } = await api.auth.getIPRestrictions(pagination);

      // @ts-ignore // TODO: fix type in rest-api
      const restrictions = items;
      await checkIfUserIpValid();
      setIpRestrictionsState({
        data: {
          config: ((_ipRestrictionsState$3 = ipRestrictionsState.data) == null ? void 0 : _ipRestrictionsState$3.config) || {
            isActive: false,
            strategy: RestrictionType.ALLOW
          },
          restrictions
        },
        pagination,
        totalPages,
        saving: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setIpRestrictionsState({
        saving: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const checkIfUserIpValid = async () => {
    try {
      const {
        valid: userIpValid
      } = await api.auth.testCurrentIpInAllowList();
      setIpRestrictionsState({
        userIpValid
      });
    } catch (e) {
      setIpRestrictionsState({
        error: errorHandler(e)
      });
    }
  };
  const addCurrentUserIpAndActivate = async payload => {
    const {
      callback
    } = payload;
    setIpRestrictionsState({
      saving: true,
      error: null
    });
    try {
      var _ref, _store$auth$userIpDat;
      const userIp = (_ref = ((_store$auth$userIpDat = store.auth.userIpData) == null ? void 0 : _store$auth$userIpDat.ip) || store.auth.userIp) != null ? _ref : '';
      await Promise.all([api.auth.createIPRestriction({
        ip: userIp,
        isActive: true,
        description: 'My IP',
        strategy: RestrictionType.ALLOW
      }), api.auth.updateIPRestrictionConfig({
        isActive: true
      })]);
      const ipRestrictionState = store.auth.restrictionsState.ipRestrictions;
      const pagination = __getPagination(ipRestrictionState.pagination);
      const {
        items,
        _metadata: {
          totalPages
        }
      } = await api.auth.getIPRestrictions(pagination);

      // @ts-ignore // TODO: fix type in rest-api
      const restrictions = items;
      setIpRestrictionsState({
        data: {
          config: {
            isActive: true,
            strategy: RestrictionType.ALLOW
          },
          restrictions
        },
        userIpValid: true,
        pagination,
        totalPages,
        saving: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setIpRestrictionsState({
        saving: false,
        error: e
      });
      callback == null ? void 0 : callback(false, e);
    }
  };
  const loadEmailDomainRestrictions = async () => {
    setEmailDomainRestrictionsState({
      loading: true,
      error: null
    });
    try {
      const restrictionsConfig = await api.auth.getDomainRestrictionsConfig();
      const restrictions = await api.auth.getDomainRestrictions();
      const filteredRestrictions = restrictions.items.filter(restriction => restriction.type === restrictionsConfig.listType);
      setEmailDomainRestrictionsState({
        data: {
          restrictions: filteredRestrictions,
          config: restrictionsConfig
        },
        loading: false
      });
    } catch (e) {
      setEmailDomainRestrictionsState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const saveEmailDomainRestriction = async payload => {
    const {
        callback
      } = payload,
      newRestriction = _objectWithoutPropertiesLoose(payload, _excluded3);
    setEmailDomainRestrictionsState({
      saving: true,
      error: null
    });
    const emailDomainRestrictionsState = store.auth.restrictionsState.emailDomainRestrictions;
    try {
      var _emailDomainRestricti2;
      await api.auth.createDomainRestriction(newRestriction);
      const restrictions = await api.auth.getDomainRestrictions();
      const filteredRestrictions = restrictions.items.filter(restriction => {
        var _emailDomainRestricti;
        return restriction.type === ((_emailDomainRestricti = emailDomainRestrictionsState.data) == null ? void 0 : _emailDomainRestricti.config.listType);
      });
      setEmailDomainRestrictionsState({
        data: {
          config: ((_emailDomainRestricti2 = emailDomainRestrictionsState.data) == null ? void 0 : _emailDomainRestricti2.config) || {
            active: false,
            blockPublicDomains: false,
            listType: newRestriction.type
          },
          restrictions: filteredRestrictions
        },
        saving: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setEmailDomainRestrictionsState({
        saving: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const saveEmailDomainRestrictionsConfig = async payload => {
    const {
        callback
      } = payload,
      config = _objectWithoutPropertiesLoose(payload, _excluded4);
    setEmailDomainRestrictionsState({
      saving: true,
      error: null
    });
    try {
      const updatedConfig = await api.auth.updateDomainRestrictionConfig(config);
      const restrictions = await api.auth.getDomainRestrictions();
      const filteredRestrictions = restrictions.items.filter(restriction => restriction.type === updatedConfig.listType);
      setEmailDomainRestrictionsState({
        data: {
          config: updatedConfig,
          restrictions: filteredRestrictions
        },
        saving: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setEmailDomainRestrictionsState({
        saving: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const deleteEmailDomainRestriction = async payload => {
    const {
      id
    } = payload;
    setEmailDomainRestrictionsState({
      saving: true,
      error: null
    });
    const emailDomainRestrictionsState = store.auth.restrictionsState.emailDomainRestrictions;
    try {
      var _emailDomainRestricti4;
      await api.auth.deleteDomainRestriction(id);
      const restrictions = await api.auth.getDomainRestrictions();
      const filteredRestrictions = restrictions.items.filter(restriction => {
        var _emailDomainRestricti3;
        return restriction.type === ((_emailDomainRestricti3 = emailDomainRestrictionsState.data) == null ? void 0 : _emailDomainRestricti3.config.listType);
      });
      setEmailDomainRestrictionsState({
        data: {
          config: ((_emailDomainRestricti4 = emailDomainRestrictionsState.data) == null ? void 0 : _emailDomainRestricti4.config) || {
            active: false,
            blockPublicDomains: false,
            listType: RestrictionType.ALLOW
          },
          restrictions: filteredRestrictions
        },
        saving: false
      });
    } catch (e) {
      setEmailDomainRestrictionsState({
        saving: false,
        error: errorHandler(e)
      });
    }
  };
  const wrappedLoadIpRestrictions = __securityCenterSagaWrapper(loadIpRestrictions);
  const wrappedSaveIpRestriction = __securityCenterSagaWrapper(saveIpRestriction);
  const wrappedSaveIpRestrictionsConfig = __securityCenterSagaWrapper(saveIpRestrictionsConfig);
  const wrappedDeleteIpRestriction = __securityCenterSagaWrapper(deleteIpRestriction);
  const wrappedCheckIfUserIpValid = __securityCenterSagaWrapper(checkIfUserIpValid);
  const wrappedAddCurrentUserIpAndActivate = __securityCenterSagaWrapper(addCurrentUserIpAndActivate);
  const wrappedLoadEmailDomainRestrictions = __securityCenterSagaWrapper(loadEmailDomainRestrictions);
  const wrappedSaveEmailDomainRestriction = __securityCenterSagaWrapper(saveEmailDomainRestriction);
  const wrappedSaveEmailDomainRestrictionsConfig = __securityCenterSagaWrapper(saveEmailDomainRestrictionsConfig);
  const wrappedDeleteEmailDomainRestriction = __securityCenterSagaWrapper(deleteEmailDomainRestriction);
  return {
    // reducers
    setRestrictionsState,
    setIpRestrictionsState,
    setEmailDomainRestrictionsState,
    __securityCenterSagaWrapper,
    // actions
    loadIpRestrictions: wrappedLoadIpRestrictions,
    saveIpRestriction: wrappedSaveIpRestriction,
    saveIpRestrictionsConfig: wrappedSaveIpRestrictionsConfig,
    deleteIpRestriction: wrappedDeleteIpRestriction,
    checkIfUserIpValid: wrappedCheckIfUserIpValid,
    addCurrentUserIpAndActivate: wrappedAddCurrentUserIpAndActivate,
    loadEmailDomainRestrictions: wrappedLoadEmailDomainRestrictions,
    saveEmailDomainRestriction: wrappedSaveEmailDomainRestriction,
    saveEmailDomainRestrictionsConfig: wrappedSaveEmailDomainRestrictionsConfig,
    deleteEmailDomainRestriction: wrappedDeleteEmailDomainRestriction
  };
});