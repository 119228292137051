import _extends from "@babel/runtime/helpers/esm/extends";
import { ApplicationAccessType } from '@frontegg/rest-api';
import { deepResetState, errorHandler, retryIfNeeded } from '../../helpers';
import { initialState } from './state';
import { getFlattenAppIds } from './helpers';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setApplicationsState = payload => {
    Object.assign(store.auth.applicationsState, payload);
  };
  const resetApplicationsState = () => {
    deepResetState(store, ['auth', 'applicationsState'], initialState);
  };
  const loadUserApplications = async payload => {
    setApplicationsState({
      loading: true,
      fetching: true
    });
    try {
      var _store$auth$user$id, _store$auth$user;
      const userId = (_store$auth$user$id = (_store$auth$user = store.auth.user) == null ? void 0 : _store$auth$user.id) != null ? _store$auth$user$id : '';
      const retryConfig = payload == null ? void 0 : payload.retryConfig;
      const appIds = await retryIfNeeded(() => api.applications.getUserApplicationsId({
        userId
      }), retryConfig);
      const userApplications = await retryIfNeeded(() => api.applications.getApplicationsData({
        appIds
      }), retryConfig);
      setApplicationsState({
        userApplications,
        fetching: false,
        loading: false
      });
    } catch (e) {
      setApplicationsState({
        loading: false,
        fetching: false,
        error: errorHandler(e)
      });
    }
  };
  const loadAccountApplications = async payload => {
    const retryConfig = payload == null ? void 0 : payload.retryConfig;
    setApplicationsState({
      loading: true,
      fetching: true
    });
    try {
      const tenantAppIds = await retryIfNeeded(() => api.applications.getTenantsApplications(), retryConfig);
      const appIds = getFlattenAppIds(tenantAppIds);
      const accountApplications = await retryIfNeeded(() => api.applications.getApplicationsData({
        appIds
      }), retryConfig);
      const usersOfApplications = await retryIfNeeded(() => api.applications.getUsersOfApplications({
        appIds
      }), retryConfig);
      const accountApplicationsWithUsers = accountApplications.map(app => {
        var _usersOfApplications$;
        return _extends({}, app, {
          userIds: (_usersOfApplications$ = usersOfApplications.find(u => u.appId === app.id)) == null ? void 0 : _usersOfApplications$.userIds
        });
      });
      setApplicationsState({
        accountApplications: accountApplicationsWithUsers,
        fetching: false,
        loading: false
      });
    } catch (e) {
      setApplicationsState({
        loading: false,
        fetching: false,
        error: errorHandler(e)
      });
    }
  };
  const loadMultipleUsersApplications = async ({
    userIds,
    retryConfig
  }) => {
    try {
      const usersAppIds = await retryIfNeeded(() => api.applications.getUsersApplicationsId({
        userIds
      }), retryConfig);
      const appIds = getFlattenAppIds(usersAppIds);
      const accountApplications = await retryIfNeeded(() => api.applications.getApplicationsData({
        appIds
      }), retryConfig);
      return userIds.reduce((acc, userId) => {
        var _usersAppIds$find, _userAppIds$map$filte, _userAppIds$map;
        const userAppIds = (_usersAppIds$find = usersAppIds.find(app => app.userId == userId)) == null ? void 0 : _usersAppIds$find.appIds;
        acc[userId] = [...accountApplications.filter(app => app.accessType === ApplicationAccessType.FREE_ACCESS), ...((_userAppIds$map$filte = userAppIds == null ? void 0 : (_userAppIds$map = userAppIds.map(appId => accountApplications.find(app => appId === app.id))) == null ? void 0 : _userAppIds$map.filter(app => !!app)) != null ? _userAppIds$map$filte : [])];
        return acc;
      }, {});
    } catch (e) {
      setApplicationsState({
        error: errorHandler(e)
      });
    }
  };
  const ASSIGNMENT_DEFAULT_ERROR_MESSAGE = 'Assignment action failed. Try again or contact support.';
  const assignUserToApps = async payload => {
    const {
      userId,
      appIds,
      callback
    } = payload;
    try {
      var _store$auth$user$tena, _store$auth, _store$auth$user2, _store$auth2, _store$auth2$applicat, _store$auth$teamState, _store$auth3, _store$auth3$teamStat, _accountApps$filter;
      const tenantId = (_store$auth$user$tena = (_store$auth = store.auth) == null ? void 0 : (_store$auth$user2 = _store$auth.user) == null ? void 0 : _store$auth$user2.tenantId) != null ? _store$auth$user$tena : '';
      await api.applications.assignUserToApplications({
        userId,
        appIds,
        tenantId
      });
      const accountApps = (_store$auth2 = store.auth) == null ? void 0 : (_store$auth2$applicat = _store$auth2.applicationsState) == null ? void 0 : _store$auth2$applicat.accountApplications;
      const users = (_store$auth$teamState = (_store$auth3 = store.auth) == null ? void 0 : (_store$auth3$teamStat = _store$auth3.teamState) == null ? void 0 : _store$auth3$teamStat.users) != null ? _store$auth$teamState : [];
      const newApps = (_accountApps$filter = accountApps == null ? void 0 : accountApps.filter(app => appIds.includes(app.id))) != null ? _accountApps$filter : [];
      if (newApps != null && newApps.length) {
        sharedActions.setTeamState({
          users: users.map(user => {
            var _user$applications;
            return user.id === userId ? _extends({}, user, {
              applications: [...((_user$applications = user.applications) != null ? _user$applications : []), ...newApps]
            }) : user;
          })
        });
      }
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setApplicationsState({
        error: errorHandler(e, ASSIGNMENT_DEFAULT_ERROR_MESSAGE)
      });
      callback == null ? void 0 : callback(false);
    }
  };
  const unassignUserFromApps = async payload => {
    const {
      userId,
      appIds,
      callback
    } = payload;
    try {
      var _store$auth$user$tena2, _store$auth4, _store$auth4$user, _store$auth$teamState2, _store$auth5, _store$auth5$teamStat, _users$find;
      const tenantId = (_store$auth$user$tena2 = (_store$auth4 = store.auth) == null ? void 0 : (_store$auth4$user = _store$auth4.user) == null ? void 0 : _store$auth4$user.tenantId) != null ? _store$auth$user$tena2 : '';
      await api.applications.unassignUserFromApplications({
        userId,
        appIds,
        tenantId
      });
      const users = (_store$auth$teamState2 = (_store$auth5 = store.auth) == null ? void 0 : (_store$auth5$teamStat = _store$auth5.teamState) == null ? void 0 : _store$auth5$teamStat.users) != null ? _store$auth$teamState2 : [];
      const userApps = (_users$find = users.find(user => user.id === userId)) == null ? void 0 : _users$find.applications;
      if (userApps != null && userApps.length && appIds.length) {
        sharedActions.setTeamState({
          users: users.map(user => user.id === userId ? _extends({}, user, {
            applications: userApps.filter(app => !appIds.includes(app.id))
          }) : user)
        });
      }
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setApplicationsState({
        error: errorHandler(e, ASSIGNMENT_DEFAULT_ERROR_MESSAGE)
      });
      callback == null ? void 0 : callback(false);
    }
  };
  const assignNewUserToApps = async ({
    appIds,
    user
  }) => {
    var _store$auth6, _store$auth6$applicat, _apps$filter;
    const [multiAppsFlag] = await actions.getFeatureFlags(['multi-apps-admin-portal']);
    if (!multiAppsFlag) {
      return;
    }
    const apps = (_store$auth6 = store.auth) == null ? void 0 : (_store$auth6$applicat = _store$auth6.applicationsState) == null ? void 0 : _store$auth6$applicat.accountApplications;
    const defaultApps = (_apps$filter = apps == null ? void 0 : apps.filter(app => app.accessType === ApplicationAccessType.FREE_ACCESS)) != null ? _apps$filter : [];
    if (appIds != null && appIds.length) {
      var _store$auth$user$tena3, _store$auth7, _store$auth7$user;
      const tenantId = (_store$auth$user$tena3 = (_store$auth7 = store.auth) == null ? void 0 : (_store$auth7$user = _store$auth7.user) == null ? void 0 : _store$auth7$user.tenantId) != null ? _store$auth$user$tena3 : '';
      await api.applications.assignUserToApplications({
        userId: user.id,
        appIds,
        tenantId
      });
      return appIds.map(appId => {
        var _apps$find;
        return (_apps$find = apps == null ? void 0 : apps.find(app => app.id === appId)) != null ? _apps$find : [];
      }).concat(defaultApps);
    } else {
      return defaultApps;
    }
  };
  const mapUsersWithApplicationData = async ({
    shouldLoadApps,
    users,
    retryConfig
  }) => {
    const [multiAppsFlag] = await sharedActions.getFeatureFlags(['multi-apps-admin-portal']);
    if (!multiAppsFlag || !shouldLoadApps) {
      return users;
    }
    const userIds = users.map(user => user.id);
    const userApps = await loadMultipleUsersApplications({
      userIds,
      retryConfig
    });
    if (!userApps) {
      return users;
    }
    return users.map(user => userApps != null && userApps[user.id] ? _extends({}, user, {
      applications: userApps[user.id]
    }) : user);
  };
  return {
    setApplicationsState,
    resetApplicationsState,
    loadUserApplications,
    loadAccountApplications,
    loadMultipleUsersApplications,
    assignUserToApps,
    unassignUserFromApps,
    assignNewUserToApps,
    mapUsersWithApplicationData
  };
});