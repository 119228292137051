<div class="batch-upload-container">
  <!-- Batch Creation Section -->
  <div class="create-batch-container">
    <div class="create-batch-header">
      <div class="header-text">
        <span class="create-batch-title">{{ 'createBatchTitle' | translate }}</span>
        <span class="create-batch-description">{{ 'createBatchDescription' | translate }}</span>
      </div>
      <button
        *ngIf="canPerformAction('CREATE_BATCH') | async"
        class="create-batch-button"
        (click)="startBatchCreation()"
        [attr.aria-label]="'createBatch' | translate"
      >
        <img
          src="../../../assets/rectangle-history-circle-plus-solid.svg"
          alt="Create Batch Icon"
        />
        <span>{{ 'createBatch' | translate }}</span>
      </button>
    </div>

    <!-- Batch List Component -->
    <div class="batch-list-container">
      <app-batch-list
        [tenantId]="tenantId"
        (openBatchEvent)="openBatch($event)"
      ></app-batch-list>
    </div>
  </div>
</div>
