import { BaseApiClient } from '../BaseApiClient';
import { InvoicesApi } from './invoices';
import { ManagedSubscriptionApi } from './managedSubscriptions';
import { PaymentMethodsApi } from './paymentMethods';
import { PaymentProvidersApi } from './paymentProviders';
import { PlansApi } from './plans';
import { SubscriptionApi } from './subscriptions';
import { SummariesApi } from './summaries';
import { SubscriptionTenantConfigApi } from './tenantConfiguration';
import { VendorPublicConfigApi } from './vendorPublicConfigurations';
import { StripeProviderApi } from './providers/stripe';
export class SubscriptionsApi extends BaseApiClient {
  constructor(appName) {
    super(appName);
    this.invoicesApi = new InvoicesApi(this.appName);
    this.managedSubscriptionApi = new ManagedSubscriptionApi(this.appName);
    this.paymentMethodsApi = new PaymentMethodsApi(this.appName);
    this.paymentProvidersApi = new PaymentProvidersApi(this.appName);
    this.plansApi = new PlansApi(this.appName);
    this.subscriptionApi = new SubscriptionApi(this.appName);
    this.summariesApi = new SummariesApi(this.appName);
    this.subscriptionTenantConfigApi = new SubscriptionTenantConfigApi(this.appName);
    this.vendorPublicConfigApi = new VendorPublicConfigApi(this.appName);
    this.stripeProviderApi = new StripeProviderApi(this.appName);
    this.getSubscriptionInvoices = this.invoicesApi.getSubscriptionInvoices.bind(this.invoicesApi);
    this.getSubscriptionInvoice = this.invoicesApi.getSubscriptionInvoice.bind(this.invoicesApi);
    this.getSubscriptionInvoicePdf = this.invoicesApi.getSubscriptionInvoicePdf.bind(this.invoicesApi);
    this.getManagedSubscription = this.managedSubscriptionApi.getManagedSubscription.bind(this.managedSubscriptionApi);
    this.getManagedSubscriptions = this.managedSubscriptionApi.getManagedSubscriptions.bind(this.managedSubscriptionApi);
    this.cancelManagedSubscription = this.managedSubscriptionApi.cancelManagedSubscription.bind(this.managedSubscriptionApi);
    this.renewManagedSubscription = this.managedSubscriptionApi.renewManagedSubscription.bind(this.managedSubscriptionApi);
    this.updateManagedSubscription = this.managedSubscriptionApi.updateManagedSubscription.bind(this.managedSubscriptionApi);
    this.getPaymentMethods = this.paymentMethodsApi.getPaymentMethods.bind(this.paymentMethodsApi);
    this.getPaymentMethod = this.paymentMethodsApi.getPaymentMethod.bind(this.paymentMethodsApi);
    this.updatePaymentMethodBillingDetails = this.paymentMethodsApi.updatePaymentMethodBillingDetails.bind(this.paymentMethodsApi);
    this.getPaymentProviders = this.paymentProvidersApi.getPaymentProviders.bind(this.paymentProvidersApi);
    this.getSubscriptionPlans = this.plansApi.getSubscriptionPlans.bind(this.plansApi);
    this.getSubscriptionPlan = this.plansApi.getSubscriptionPlan.bind(this.plansApi);
    this.getSubscriptions = this.subscriptionApi.getSubscriptions.bind(this.subscriptionApi);
    this.getSubscription = this.subscriptionApi.getSubscription.bind(this.subscriptionApi);
    this.cancelSubscription = this.subscriptionApi.cancelSubscription.bind(this.subscriptionApi);
    this.renewSubscription = this.subscriptionApi.renewSubscription.bind(this.subscriptionApi);
    this.updateSubscription = this.subscriptionApi.updateSubscription.bind(this.subscriptionApi);
    this.getSubscriptionSummaries = this.summariesApi.getSubscriptionSummaries.bind(this.summariesApi);
    this.getTenantConfiguration = this.subscriptionTenantConfigApi.getTenantConfiguration.bind(this.subscriptionTenantConfigApi);
    this.createTenantConfiguration = this.subscriptionTenantConfigApi.createTenantConfiguration.bind(this.subscriptionTenantConfigApi);
    this.getVendorPublicConfigurations = this.vendorPublicConfigApi.getVendorPublicConfigurations.bind(this.vendorPublicConfigApi);
    this.createSubscription = this.stripeProviderApi.createSubscription.bind(this.stripeProviderApi);
    this.getStripeCustomer = this.stripeProviderApi.getStripeCustomer.bind(this.stripeProviderApi);
    this.createStripeCustomer = this.stripeProviderApi.createStripeCustomer.bind(this.stripeProviderApi);
    this.getStripePaymentProviderConfiguration = this.stripeProviderApi.getStripePaymentProviderConfiguration.bind(this.stripeProviderApi);
    this.createStripePaymentMethodSetupIntentSecret = this.stripeProviderApi.createStripePaymentMethodSetupIntentSecret.bind(this.stripeProviderApi);
  }
}
export default new SubscriptionsApi('default');
export * from './interfaces';
export * from './enums';