import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class EntitlementsApi extends BaseApiClient {
  constructor(appName) {
    super(appName);
    this.loadEntitlementsV2 = async () => {
      return this.get(urls.entitlements.v2);
    };
  }

  /**
   * Load user entitlements data v2.
   * Including all user permissions and features data.
   * Now the final isEntitled response is not part of it, but includes the data needed to calculate it.
   *
   * `authorized user`
   */
}

export default new EntitlementsApi('default');