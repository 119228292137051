import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Area } from '../../../services/content-api/content-api.service';
import { Subject, Subscription } from 'rxjs';
import { FronteggAuthService } from '@frontegg/angular';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-web-catpure-batch-creation',
  templateUrl: './web-capture-batch-creation.component.html',
  styleUrls: ['./web-capture-batch-creation.component.scss']
})
export class WebCatpureBatchCreationComponent implements OnInit {
  private subscription = new Subscription();

  hasUploadedFilesAtCreation: boolean = false;
  user?: any;
  tenantId: string = '';
  batchIdToCreate: string = '';
  areas: Area[] = [];
  isCreationFinished: boolean = false;
  deleteDocumentsEventSubject: Subject<void> = new Subject<void>();

  constructor(
    private fronteggAuthService: FronteggAuthService,
    private router: Router,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.tenantId = localStorage.getItem('tenantId') || '';
    const organization = localStorage.getItem('vanityName');

    console.log('Tenant ID:', this.tenantId);

    if (this.tenantId && organization) {
      this.subscription.add(
        this.fronteggAuthService.authState$.subscribe(authState => {
          if (authState.user?.accessToken) {
            this.user = authState.user;
            console.log('User Batching:', this.user);
          }
        })
      );
    }
  }

  openBatch(batchId: string): void {
    this.router.navigate(['webcapture/batches/' + batchId]);
  }

  cancelBatchCreation() {
    this.router.navigate(['webcapture/batches']);
  }

  finishBatchCreation() {
    this.isCreationFinished = true;
    this.router.navigate(['webcapture/batches']);
  }

  canDeactivate(): Promise<boolean> {
    if (!this.hasUploadedFilesAtCreation || this.isCreationFinished) {
      return Promise.resolve(true);
    } else {
      return new Promise(resolve => {
        this.confirmationService.confirm({
          message: 'unsavedDataWarning',
          key: 'tabChangeDialog',
          accept: () => {
            this.deleteDocumentsEventSubject.next();
            resolve(true);
          },
          reject: () => {
            resolve(false);
          }
        });
      });
    }
  }
}
