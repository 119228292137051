import _extends from "@babel/runtime/helpers/esm/extends";
import { deepResetState, errorHandler } from '../../helpers';
import { initialState } from './state';
import { FronteggApiError, ISubscriptionStatus } from '@frontegg/rest-api';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setCheckoutState = state => {
    Object.assign(store.subscriptions.checkout, state);
  };
  const resetCheckoutState = () => {
    deepResetState(store, ['subscriptions', 'checkout'], initialState);
  };
  const loadCheckout = () => {
    setCheckoutState({
      fetching: false,
      loading: false,
      error: null,
      confirmed: false
    });
  };
  const resetCheckout = () => {
    setCheckoutState({
      loading: false,
      error: null,
      confirmed: false
    });
  };
  const submitCheckout = () => {
    setCheckoutState({
      loading: true,
      error: null
    });
  };
  const confirmCheckout = async payload => {
    const {
      paymentMethodId,
      planId
    } = payload;
    const subscription = store.subscriptions.billing.subscription.subscription;
    const summary = store.subscriptions.billing.information.summary;
    const isTrialing = (subscription == null ? void 0 : subscription.status) === ISubscriptionStatus.TRIALING;
    const hasPaymentMethod = !!(summary != null && summary.paymentMethodId);
    if (!subscription) {
      setCheckoutState({
        loading: false,
        error: new FronteggApiError('Subscription not found', 500, null)
      });
      return;
    }
    setCheckoutState({
      loading: true,
      error: null
    });
    if (isTrialing && hasPaymentMethod) {
      setCheckoutState({
        loading: false,
        error: null,
        confirmed: true
      });
      actions.setBillingSubscriptionState({
        subscription: _extends({}, subscription, {
          status: ISubscriptionStatus.ACTIVE
        })
      });
      return;
    }
    try {
      await api.subscriptions.updateManagedSubscription(subscription.id, {
        paymentMethodId,
        planId
      });
      await Promise.all([actions.loadBillingInformation(), actions.loadPaymentMethod(), actions.loadInvoices()]);
      setCheckoutState({
        loading: false,
        error: null,
        confirmed: true
      });
    } catch (e) {
      setCheckoutState({
        loading: false,
        error: errorHandler(e)
      });
    }
  };
  const errorCheckout = payload => {
    setCheckoutState({
      loading: false,
      error: payload
    });
  };
  return {
    setCheckoutState,
    resetCheckoutState,
    loadCheckout,
    resetCheckout,
    submitCheckout,
    confirmCheckout,
    errorCheckout
  };
});