import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';

/**
 * UsersApi class provides methods for handling user and impersonation-related API requests.
 */
export class ImpersonateApi extends BaseApiClient {
  constructor(appName) {
    super(appName);
    this.impersonate = async body => {
      return this.post(`${urls.identity.impersonate.v1}`, body);
    };
  }

  /**
   * Impersonating user should be called after creating actor token that contains information about impersonation.
   * `impersonate` should contain impersonated user info (userId, tenantId, and vendorId) and the actor token that was created for him to perform impersonation.
   *
   * @throws exception if impersonation failed
   */
}

export default new ImpersonateApi('default');