import _extends from "@babel/runtime/helpers/esm/extends";
import getValue from 'get-value';
import setValue from 'set-value';
import { DEFAULT_RETRY_CONFIG } from '../constants';
export const delay = (ms = 500) => new Promise(resolve => {
  setTimeout(resolve, ms);
});
export const retry = async (asyncFunc, retryCount, delay, shouldRetryWhenNull) => {
  let attempts = 0;
  while (attempts < retryCount - 1) {
    try {
      const response = await asyncFunc();
      if (shouldRetryWhenNull && response === null) {
        // throw to retry
        attempts++;
        if (attempts >= retryCount) {
          return response;
        }
        await new Promise(resolve => setTimeout(resolve, delay));
      } else {
        return response;
      }
    } catch (error) {
      attempts++;
      if (attempts >= retryCount) {
        throw error;
      }
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }
  return await asyncFunc();
};
export const retryIfNeeded = async (asyncFunc, config) => {
  if (!config) {
    return asyncFunc();
  }
  return retry(asyncFunc, config.retryCount, config.delay || 500, config.shouldRetryWhenNull);
};
export const withRetryConfig = payload => _extends({}, payload, {
  retryConfig: DEFAULT_RETRY_CONFIG
});
export const errorTraceId = e => (e == null ? void 0 : e.traceId) || undefined;
export function omitProps(props, keys) {
  const newProps = _extends({}, props);
  keys.forEach(key => {
    delete newProps[key];
  });
  return newProps;
}
function shouldBeCloneable(o) {
  const type = typeof o;
  return (o == null ? void 0 : o.constructor) === {}.constructor || type === 'undefined' || o === null || type === 'boolean' || type === 'number' || type === 'string' || o instanceof Date || o instanceof RegExp || o instanceof Blob || o instanceof File || o instanceof FileList || o instanceof ArrayBuffer || o instanceof ImageData || o instanceof ImageBitmap || o instanceof Array || o instanceof Map || o instanceof Set;
}

/**
 * Check if the object is a proxy object to avoid cloning it
 */
export function isProxy(obj) {
  if (obj === null || obj === undefined) {
    return;
  }
  const _shouldBeCloneable = shouldBeCloneable(obj);
  return _shouldBeCloneable && obj.__isProxy !== undefined;
}
export const deepClone = objectToClone => {
  return JSON.parse(JSON.stringify(objectToClone));
};

/**
 * Used to reset the state of the store to the initial state
 * @param store - the store object
 * @param statePath - the path to the state that needs to be reset
 * @param initialState - the initial state object
 */
export const deepResetState = (store, statePath, initialState) => {
  const initialLoginState = deepClone(initialState);
  const currentState = getValue(store, statePath);
  for (const key in currentState) {
    if (key === '__isProxy') {
      continue;
    }
    if (key in initialLoginState) {
      setValue(store, [...statePath, key], initialLoginState[key]);
    } else {
      setValue(store, [...statePath, key], undefined);
    }
  }
};