export class FronteggApiError extends Error {
  constructor(message, statusCode, traceId, errorCode, errorContext) {
    super(message);
    this._statusCode = void 0;
    this._traceId = void 0;
    this._errorCode = void 0;
    this._errorContext = void 0;
    this._statusCode = statusCode;
    this._traceId = traceId;
    this._errorCode = errorCode;
    this._errorContext = errorContext;
  }
  get statusCode() {
    return this._statusCode;
  }
  get traceId() {
    return this._traceId;
  }
  get errorCode() {
    return this._errorCode;
  }
  get errorContext() {
    return this._errorContext;
  }
}