import _extends from "@babel/runtime/helpers/esm/extends";
import { initialState } from './state';
import { MachineToMachineAuthStrategy } from '@frontegg/rest-api';
import { errorHandler, delay, deepResetState, retryIfNeeded } from '../../helpers';
import { ApiStateKeys } from './interfaces';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setApiTokensLoader = payload => {
    Object.assign(store.auth.apiTokensState, {
      loaders: _extends({}, store.auth.apiTokensState.loaders, {
        [payload.key]: payload.value
      })
    });
  };
  const setApiTokensError = payload => {
    Object.assign(store.auth.apiTokensState, {
      errors: _extends({}, store.auth.apiTokensState.errors, {
        [payload.key]: payload.value
      }),
      loaders: _extends({}, store.auth.apiTokensState.loaders, {
        [payload.key]: false
      })
    });
  };
  const setApiTokensState = state => {
    Object.assign(store.auth.apiTokensState, state);
  };
  const resetApiTokensState = () => {
    deepResetState(store, ['auth', 'apiTokensState'], initialState);
  };
  const loadApiTokens = async payload => {
    if (!(payload != null && payload.silentLoading)) {
      setApiTokensLoader({
        key: ApiStateKeys.LOAD_API_TOKENS,
        value: true
      });
    }
    try {
      var _payload$callback;
      const [apiTokensDataUser = [], apiTokensDataTenant = []] = await Promise.all([api.auth.getUserApiTokensData(), api.auth.getTenantApiTokensData()]);
      // @ts-ignore TODO: fix types in rest-api
      setApiTokensState({
        apiTokensDataUser,
        apiTokensDataTenant
      });
      setApiTokensLoader({
        key: ApiStateKeys.LOAD_API_TOKENS,
        value: false
      });
      payload == null ? void 0 : (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, true);
    } catch (e) {
      var _payload$callback2;
      setApiTokensError({
        key: ApiStateKeys.LOAD_API_TOKENS,
        value: errorHandler(e)
      });
      setApiTokensLoader({
        key: ApiStateKeys.LOAD_API_TOKENS,
        value: false
      });
      payload == null ? void 0 : (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, null, e);
    }
  };
  const loadUserApiTokens = async payload => {
    if (!(payload != null && payload.silentLoading)) {
      setApiTokensLoader({
        key: ApiStateKeys.LOAD_API_TOKENS,
        value: true
      });
    }
    try {
      var _accessTokensData$acc, _payload$callback3;
      const [apiTokensData = [], accessTokensData] = await Promise.all([retryIfNeeded(() => api.auth.getUserApiTokensData(), payload == null ? void 0 : payload.retryConfig), retryIfNeeded(() => api.auth.getUserAccessTokensData(), payload == null ? void 0 : payload.retryConfig)]);

      // @ts-ignore TODO: fix createAt type to Date
      const accessTokens = (_accessTokensData$acc = accessTokensData.accessTokens) != null ? _accessTokensData$acc : [];
      const allTokens = accessTokens.map(item => _extends({}, item, {
        type: MachineToMachineAuthStrategy.AccessToken
      })).concat(apiTokensData.map(item => _extends({}, item, {
        type: MachineToMachineAuthStrategy.ClientCredentials
      })));
      const sortedTokensByDate = allTokens.sort((date1, date2) => new Date(date2.createdAt) - new Date(date1.createdAt));
      setApiTokensState({
        apiTokensDataUser: sortedTokensByDate != null ? sortedTokensByDate : []
      });
      setApiTokensLoader({
        key: ApiStateKeys.LOAD_API_TOKENS,
        value: false
      });
      payload == null ? void 0 : (_payload$callback3 = payload.callback) == null ? void 0 : _payload$callback3.call(payload, true);
    } catch (e) {
      var _payload$callback4;
      setApiTokensError({
        key: ApiStateKeys.LOAD_API_TOKENS,
        value: errorHandler(e)
      });
      setApiTokensLoader({
        key: ApiStateKeys.LOAD_API_TOKENS,
        value: false
      });
      payload == null ? void 0 : (_payload$callback4 = payload.callback) == null ? void 0 : _payload$callback4.call(payload, null, e);
    }
  };
  const loadTenantApiTokens = async payload => {
    if (!(payload != null && payload.silentLoading)) {
      setApiTokensLoader({
        key: ApiStateKeys.LOAD_API_TOKENS,
        value: true
      });
    }
    try {
      var _accessTokensData$acc2, _payload$callback5;
      const [apiTokensData = [], accessTokensData] = await Promise.all([retryIfNeeded(() => api.auth.getTenantApiTokensData(), payload == null ? void 0 : payload.retryConfig), retryIfNeeded(() => api.auth.getTenantAccessTokensData(), payload == null ? void 0 : payload.retryConfig)]);

      // @ts-ignore TODO: fix type in rest-api
      const accessTokens = (_accessTokensData$acc2 = accessTokensData == null ? void 0 : accessTokensData.accessTokens) != null ? _accessTokensData$acc2 : [];
      const allTokens = accessTokens.map(item => _extends({}, item, {
        type: MachineToMachineAuthStrategy.AccessToken
      })).concat(apiTokensData.map(item => _extends({}, item, {
        type: MachineToMachineAuthStrategy.ClientCredentials
      })));
      const sortedTokensByDate = allTokens.sort((date1, date2) => new Date(date2.createdAt) - new Date(date1.createdAt));
      setApiTokensState({
        apiTokensDataTenant: sortedTokensByDate != null ? sortedTokensByDate : []
      });
      setApiTokensLoader({
        key: ApiStateKeys.LOAD_API_TOKENS,
        value: false
      });
      payload == null ? void 0 : (_payload$callback5 = payload.callback) == null ? void 0 : _payload$callback5.call(payload, true);
    } catch (e) {
      var _payload$callback6;
      setApiTokensError({
        key: ApiStateKeys.LOAD_API_TOKENS,
        value: errorHandler(e)
      });
      setApiTokensLoader({
        key: ApiStateKeys.LOAD_API_TOKENS,
        value: false
      });
      payload == null ? void 0 : (_payload$callback6 = payload.callback) == null ? void 0 : _payload$callback6.call(payload, null, e);
    }
  };
  const addTenantApiToken = async payload => {
    const {
      description,
      roleIds,
      expires,
      callback
    } = payload;
    const actionKey = ApiStateKeys.ADD_API_TOKEN;
    setApiTokensLoader({
      key: actionKey,
      value: true
    });
    try {
      const {
        policy
      } = store.auth.securityPolicyState.publicPolicy;
      const isAccessToken = (policy == null ? void 0 : policy.machineToMachineAuthStrategy) === MachineToMachineAuthStrategy.AccessToken;

      // TODO: fix type in rest api , let data: ITenantApiTokensData;
      let data;
      let expiresInMinutes = null;
      if (expires) {
        expiresInMinutes = expires * 1440;
      }
      if (isAccessToken) {
        data = await api.auth.createTenantAccessToken({
          description,
          expiresInMinutes,
          roleIds
        });
      } else {
        data = await api.auth.updateTenantApiTokensData({
          description,
          roleIds,
          expiresInMinutes
        });
      }
      setApiTokensState({
        showAddTokenDialog: false
      });
      await delay(200);
      const type = isAccessToken ? MachineToMachineAuthStrategy.AccessToken : MachineToMachineAuthStrategy.ClientCredentials;
      store.auth.apiTokensState.apiTokensDataTenant.unshift(_extends({}, data, {
        type
      }));
      store.auth.apiTokensState.successDialog = {
        open: true,
        secret: data.secret,
        clientId: !isAccessToken ? data.clientId : undefined
      };
      callback == null ? void 0 : callback(null);
    } catch (e) {
      setApiTokensError({
        key: actionKey,
        value: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    } finally {
      setApiTokensLoader({
        key: actionKey,
        value: false
      });
    }
  };
  const addUserApiToken = async payload => {
    const {
      description,
      expires,
      callback
    } = payload;
    const actionKey = ApiStateKeys.ADD_API_TOKEN;
    setApiTokensLoader({
      key: actionKey,
      value: true
    });
    try {
      const {
        policy
      } = store.auth.securityPolicyState.publicPolicy;
      const isAccessToken = (policy == null ? void 0 : policy.machineToMachineAuthStrategy) === MachineToMachineAuthStrategy.AccessToken;

      // TODO: fix type in rest api , let data: IUserApiTokensData;
      let data;
      let expiresInMinutes = null;
      if (expires) {
        expiresInMinutes = expires * 1440;
      }
      if (isAccessToken) {
        data = await api.auth.createUserAccessToken({
          description,
          expiresInMinutes
        });
      } else {
        data = await api.auth.updateUserApiTokensData({
          description,
          expiresInMinutes
        });
      }
      setApiTokensState({
        showAddTokenDialog: false
      });
      await delay(200);
      const type = isAccessToken ? MachineToMachineAuthStrategy.AccessToken : MachineToMachineAuthStrategy.ClientCredentials;
      store.auth.apiTokensState.apiTokensDataUser.unshift(_extends({}, data, {
        type
      }));
      store.auth.apiTokensState.successDialog = {
        open: true,
        secret: data.secret,
        clientId: !isAccessToken ? data.clientId : undefined
      };
      callback == null ? void 0 : callback(null);
    } catch (e) {
      setApiTokensError({
        key: actionKey,
        value: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    } finally {
      setApiTokensLoader({
        key: actionKey,
        value: false
      });
    }
  };
  const deleteUserApiToken = async payload => {
    const actionKey = ApiStateKeys.DELETE_API_TOKEN;
    setApiTokensLoader({
      key: actionKey,
      value: true
    });
    try {
      const apiTokensDataUser = store.auth.apiTokensState.apiTokensDataUser;
      const isAccessToken = apiTokensDataUser.some(token => token.id === payload);
      if (isAccessToken) {
        await api.auth.deleteUserAccessToken({
          id: payload
        });
        setApiTokensState({
          apiTokensDataUser: apiTokensDataUser.filter(token => token.id !== payload),
          deleteTokenDialog: {
            open: false,
            id: payload
          }
        });
      } else {
        await api.auth.deleteUserApiToken({
          tokenId: payload
        });
        setApiTokensState({
          apiTokensDataUser: apiTokensDataUser.filter(token => token.clientId !== payload),
          deleteTokenDialog: {
            open: false,
            clientId: payload
          }
        });
      }
    } catch (e) {
      setApiTokensError({
        key: actionKey,
        value: errorHandler(e)
      });
    } finally {
      setApiTokensLoader({
        key: actionKey,
        value: false
      });
    }
  };
  const deleteTenantApiToken = async payload => {
    const actionKey = ApiStateKeys.DELETE_API_TOKEN;
    setApiTokensLoader({
      key: actionKey,
      value: true
    });
    try {
      const apiTokensDataTenant = store.auth.apiTokensState.apiTokensDataTenant;
      const isAccessToken = apiTokensDataTenant.some(token => token.id === payload);
      if (isAccessToken) {
        await api.auth.deleteTenantAccessToken({
          id: payload
        });
        setApiTokensState({
          apiTokensDataTenant: apiTokensDataTenant.filter(token => token.id !== payload),
          deleteTokenDialog: {
            open: false,
            id: payload
          }
        });
      } else {
        await api.auth.deleteTenantApiToken({
          tokenId: payload
        });
        setApiTokensState({
          apiTokensDataTenant: apiTokensDataTenant.filter(token => token.clientId !== payload),
          deleteTokenDialog: {
            open: false,
            id: payload
          }
        });
      }
    } catch (e) {
      setApiTokensError({
        key: actionKey,
        value: errorHandler(e)
      });
    } finally {
      setApiTokensLoader({
        key: actionKey,
        value: false
      });
    }
  };
  return {
    setApiTokensLoader,
    setApiTokensError,
    setApiTokensState,
    resetApiTokensState,
    loadApiTokens,
    loadUserApiTokens,
    loadTenantApiTokens,
    // initApiTokensData, not used
    addTenantApiToken,
    addUserApiToken,
    deleteUserApiToken,
    deleteTenantApiToken
  };
});