import _extends from "@babel/runtime/helpers/esm/extends";
import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
const defaultFeatureFlags = {
  flags: {}
};
export class FeatureFlags {
  constructor() {
    var _defaultFeatureFlags$;
    this._flags = {};
    this._flags = (_defaultFeatureFlags$ = defaultFeatureFlags.flags) != null ? _defaultFeatureFlags$ : {};
  }
  static getInstance(name = 'default') {
    return this._instances[name];
  }
  static set(featureFlags, name = 'default') {
    const featureFlagsInstance = new FeatureFlags();
    featureFlagsInstance.set(featureFlags);
    FeatureFlags._instances[name] = featureFlagsInstance;
    return featureFlagsInstance;
  }
  static getFeatureFlags(flags, name) {
    const featureFlagsInstance = this.getInstance(name);
    return flags.map(flag => (featureFlagsInstance == null ? void 0 : featureFlagsInstance._flags[flag]) === 'on');
  }
  get flags() {
    var _this$flags;
    return (_this$flags = this.flags) != null ? _this$flags : {};
  }
  set(featureFlags) {
    var _defaultFeatureFlags$2;
    this._flags = _extends({}, (_defaultFeatureFlags$2 = defaultFeatureFlags == null ? void 0 : defaultFeatureFlags.flags) != null ? _defaultFeatureFlags$2 : {}, featureFlags != null ? featureFlags : {});
  }
}
FeatureFlags._instances = {};
export class FeatureFlagsApi extends BaseApiClient {
  constructor(appName) {
    super(appName);
    this.loadFeatureFlags = async () => {
      return this.get(urls.featureFlags.v1);
    };
  }
}
export default new FeatureFlagsApi('default');