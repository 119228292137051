export let REQUEST_NAME;
(function (REQUEST_NAME) {
  REQUEST_NAME["LOAD_FEATURE_FLAGS"] = "LOAD_FEATURE_FLAGS,";
  REQUEST_NAME["LOAD_ADMIN_BOX_METADATA"] = "LOAD_ADMIN_BOX_METADATA,";
  REQUEST_NAME["LOAD_SSO_PUBLIC_CONFIGURATION"] = "LOAD_SSO_PUBLIC_CONFIGURATION,";
  REQUEST_NAME["LOAD_PUBLIC_AUTH_STRATEGIES_POLICY"] = "LOAD_PUBLIC_AUTH_STRATEGIES_POLICY,";
  REQUEST_NAME["LOAD_ALLOW_SIGNUPS"] = "LOAD_ALLOW_SIGNUPS,";
  REQUEST_NAME["LOAD_CUSTOM_LOGIN_ROUTES"] = "LOAD_CUSTOM_LOGIN_ROUTES";
  REQUEST_NAME["LOAD_PASSWORD_CONFIGURATION"] = "LOAD_PASSWORD_CONFIGURATION";
})(REQUEST_NAME || (REQUEST_NAME = {}));
export let UserVerifiedOriginTypes;
(function (UserVerifiedOriginTypes) {
  UserVerifiedOriginTypes["SOCIAL_LOGIN"] = "SOCIAL_LOGIN";
  UserVerifiedOriginTypes["PASSWORDLESS"] = "PASSWORDLESS";
  UserVerifiedOriginTypes["ACTIVATION_LINK"] = "ACTIVATION_LINK";
})(UserVerifiedOriginTypes || (UserVerifiedOriginTypes = {}));
export let AuthenticationTypes;
(function (AuthenticationTypes) {
  AuthenticationTypes["SOCIAL_LOGIN"] = "SOCIAL_LOGIN";
  AuthenticationTypes["PASSWORD"] = "PASSWORD";
})(AuthenticationTypes || (AuthenticationTypes = {}));
export let AdminPortalPagesForEvents;

// noinspection JSUnusedGlobalSymbols
(function (AdminPortalPagesForEvents) {
  AdminPortalPagesForEvents["profile"] = "profile";
  AdminPortalPagesForEvents["privacy"] = "privacy";
  AdminPortalPagesForEvents["personalApiTokens"] = "personalApiTokens";
  AdminPortalPagesForEvents["account"] = "account";
  AdminPortalPagesForEvents["users"] = "users";
  AdminPortalPagesForEvents["groups"] = "groups";
  AdminPortalPagesForEvents["security"] = "security";
  AdminPortalPagesForEvents["sso"] = "sso";
  AdminPortalPagesForEvents["audits"] = "audits";
  AdminPortalPagesForEvents["webhooks"] = "webhooks";
  AdminPortalPagesForEvents["apiTokens"] = "apiTokens";
  AdminPortalPagesForEvents["roles"] = "roles";
  AdminPortalPagesForEvents["subscriptions"] = "subscription";
  AdminPortalPagesForEvents["allAccounts"] = "allAccounts";
})(AdminPortalPagesForEvents || (AdminPortalPagesForEvents = {}));
export const AdminPortalPages = {
  profile: AdminPortalPagesForEvents.profile,
  privacy: AdminPortalPagesForEvents.privacy,
  personalApiTokens: AdminPortalPagesForEvents.personalApiTokens,
  account: AdminPortalPagesForEvents.account,
  users: AdminPortalPagesForEvents.users,
  groups: AdminPortalPagesForEvents.groups,
  security: AdminPortalPagesForEvents.security,
  sso: AdminPortalPagesForEvents.sso,
  audits: AdminPortalPagesForEvents.audits,
  webhooks: AdminPortalPagesForEvents.webhooks,
  apiTokens: AdminPortalPagesForEvents.apiTokens,
  roles: AdminPortalPagesForEvents.roles,
  subscriptions: AdminPortalPagesForEvents.subscriptions,
  allAccounts: AdminPortalPagesForEvents.allAccounts
};