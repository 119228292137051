import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["id"],
  _excluded2 = ["id", "enabled"];
import { channels, channels2Platform } from './consts';
import { deepResetState, errorHandler, retryIfNeeded } from '../helpers';
import { initialState } from './state';
import { DEFAULT_RETRY_CONFIG } from '../constants';
export default ((store, api, sharedActions) => {
  const type2ApiGet = {
    slack: api.connectivity.getSlackConfiguration,
    email: api.connectivity.getEmailConfiguration,
    sms: api.connectivity.getSMSConfiguration,
    webhook: api.connectivity.getWebhooksConfigurations,
    categories: api.connectivity.getCategories,
    channelMap: api.connectivity.getChannelMaps
  };
  const addApi = ['categories', 'channelMap'];
  const type2ApiPost = {
    slack: api.connectivity.postSlackConfiguration,
    email: api.connectivity.postEmailConfiguration,
    sms: api.connectivity.postSMSConfiguration,
    webhook: api.connectivity.postWebhooksConfiguration
  };
  const setConnectivityState = state => {
    Object.assign(store.connectivity, state);
  };
  const postWebhookRetryResult = result => {
    var _store$connectivity$r;
    const retryResult = (_store$connectivity$r = store.connectivity.retryResult) != null ? _store$connectivity$r : {};
    store.connectivity.retryResult = _extends({}, retryResult, result);
  };
  const resetConnectivityState = () => {
    deepResetState(store, ['connectivity'], initialState);
  };
  const loadConfigurationFor = async (api, params, retryConfig) => {
    try {
      return await retryIfNeeded(() => type2ApiGet[api](params), retryConfig);
    } catch (e) {
      return undefined;
    }
  };
  const loadSlackChannels = async () => {
    try {
      setConnectivityState({
        slackChannels: {
          isLoading: true
        }
      });
      // @ts-ignore // TODO: [Typescript 4.8] fix @frontegg/rest-api return value
      const data = await api.connectivity.getSlackChannels();
      setConnectivityState({
        error: undefined,
        slackChannels: {
          isLoading: false,
          data
        }
      });
    } catch (e) {
      setConnectivityState({
        error: undefined,
        slackChannels: {
          isLoading: false
        }
      });
    }
  };
  const loadConnectivityChannels = async _payload => {
    const payload = _payload != null ? _payload : channels;
    setConnectivityState({
      isLoading: true
    });
    try {
      const values = await Promise.all([
      // @ts-ignore
      ...payload.map(a => loadConfigurationFor(a, undefined, DEFAULT_RETRY_CONFIG)),
      // @ts-ignore
      loadConfigurationFor('categories', undefined, DEFAULT_RETRY_CONFIG),
      // @ts-ignore
      (async () => {
        const res = await Promise.all(payload.map(ch => loadConfigurationFor('channelMap', ch, DEFAULT_RETRY_CONFIG)));
        return res.reduce((acc, curr, idx) => _extends({}, acc, {
          [`${payload[idx]}`]: curr
        }), {});
      })()]);
      const webhooks = await loadConfigurationFor('webhook', undefined, DEFAULT_RETRY_CONFIG);
      const data = values.reduce((acc, curr, idx) => {
        if (payload[idx]) {
          if (values[values.length - 1][payload[idx]].length) {
            return _extends({}, acc, {
              [`${payload[idx]}`]: curr,
              list: [...acc.list, {
                id: idx,
                key: payload[idx],
                events: channels2Platform[payload[idx]].events(curr),
                active: channels2Platform[payload[idx]].isActive(curr),
                platform: channels2Platform[payload[idx]].title,
                image: channels2Platform[payload[idx]].image
              }]
            });
          } else {
            return acc;
          }
        } else {
          return _extends({}, acc, {
            [`${addApi[idx - payload.length]}`]: curr
          });
        }
      }, {
        list: [],
        webhook: webhooks
      });
      setConnectivityState(_extends({}, data, {
        error: undefined,
        isSaving: false,
        isLoading: false
      }));
    } catch (e) {
      setConnectivityState({
        isLoading: false,
        error: errorHandler(e)
      });
    }
  };
  const postSlackData = async payload => {
    var _store$connectivity$s;
    const stateSlackSubscriptions = (_store$connectivity$s = store.connectivity.slack) == null ? void 0 : _store$connectivity$s.slackSubscriptions;
    if (!stateSlackSubscriptions) {
      return;
    }
    const {
      slackSubscriptions
    } = payload;
    await Promise.all([...slackSubscriptions.reduce((acc, curr) => {
      var _curr$slackEvents$0$c, _curr$slackEvents$0$c2;
      if (!curr.id && curr.slackEvents && (_curr$slackEvents$0$c = curr.slackEvents[0].channelIds) != null && _curr$slackEvents$0$c.length) {
        return [...acc, curr];
      }
      const el = stateSlackSubscriptions == null ? void 0 : stateSlackSubscriptions.find(_ref => {
        let {
            id
          } = _ref,
          props = _objectWithoutPropertiesLoose(_ref, _excluded);
        return id === curr.id && JSON.stringify(_extends({
          id
        }, props)) !== JSON.stringify(curr);
      });
      if (el && curr.slackEvents && (_curr$slackEvents$0$c2 = curr.slackEvents[0].channelIds) != null && _curr$slackEvents$0$c2.length) {
        return [...acc, curr];
      }
      return acc;
    }, []).map(el => type2ApiPost.slack(el)), ...slackSubscriptions.reduce((acc, curr) => {
      if (curr.id && !curr.slackEvents[0].channelIds.length) {
        return [...acc, curr];
      }
      return acc;
    }, []).map(el => api.connectivity.deleteSlackConfiguration(el))]);
  };
  const postEmailSMSData = async (payload, type) => {
    const processIds = store.connectivity.processIds;
    const stateData = store.connectivity[type];
    if (!stateData) return;
    let actionsResult = [];
    try {
      actionsResult = await Promise.all([
      // create new
      ...payload.reduce((acc, curr) => {
        const state = stateData.find(({
          eventKey
        }) => eventKey === curr.eventKey);
        if (!state && curr.subscriptions[0].recipients.filter(el => el).length) {
          return [...acc, curr];
        }
        return acc;
      }, []).map(data => {
        if (type === 'email') {
          return api.connectivity.postEmailConfiguration(data);
        } else {
          return api.connectivity.postSMSConfiguration(data);
        }
      }), ...payload.reduce((acc, curr) => {
        const state = stateData.find(({
          eventKey
        }) => eventKey === curr.eventKey);
        if (state && JSON.stringify(state) !== JSON.stringify(curr)) {
          return [...acc, curr];
        }
        return acc;
      }, []).map(data => {
        const {
          subscriptions,
          eventKey
        } = data;
        const _subscriptions$ = subscriptions[0],
          {
            id = '',
            enabled
          } = _subscriptions$,
          body = _objectWithoutPropertiesLoose(_subscriptions$, _excluded2);
        return Promise.all([type === 'email' ? api.connectivity.patchEmailConfiguration({
          eventKey,
          enabled
        }) : api.connectivity.patchSMSConfiguration({
          eventKey,
          enabled
        }), type === 'email' ? api.connectivity.putEmailSubscriptions(id, eventKey, _extends({}, body, {
          enabled
        })) : api.connectivity.putSMSSubscriptions(id, eventKey, _extends({}, body, {
          enabled
        }))]);
      }),
      // delete record with empty recipients
      ...payload.reduce((acc, curr) => {
        const state = stateData.find(({
          eventKey
        }) => eventKey === curr.eventKey);
        if (state && state.subscriptions[0].recipients.length && !curr.subscriptions[0].recipients.filter(el => !!el).length) {
          return [...acc, curr];
        }
        return acc;
      }, []).map(({
        eventKey,
        subscriptions
      }) => {
        if (type === 'email') {
          return api.connectivity.deleteEmailSubscriptions(eventKey, subscriptions[0].id || '');
        } else {
          return api.connectivity.deleteSMSSubscriptions(eventKey, subscriptions[0].id || '');
        }
      })]);
    } catch {
      /* empty */
    }
    if (actionsResult.length) {
      // @ts-ignore
      const newData = await loadConfigurationFor(type);
      setConnectivityState({
        error: undefined,
        isSaving: false,
        [`${type}`]: newData,
        processIds: newData.id ? processIds.filter(el => el !== newData.id) : processIds
      });
      await checkNewStatus(type, newData);
    } else {
      setConnectivityState({
        error: undefined,
        isSaving: false,
        [`${type}`]: stateData,
        processIds: processIds
      });
    }
  };
  const checkNewStatus = async (platform, data) => {
    const {
      list
    } = store.connectivity;
    const currPlatform = list.find(({
      key
    }) => key === platform);
    if (!currPlatform) return;
    const newActive = channels2Platform[platform].isActive(data);
    if (newActive === currPlatform.active) return;
    setConnectivityState({
      list: list.map(elm => elm.key === platform ? _extends({}, elm, {
        active: newActive
      }) : elm)
    });
  };
  const postChannelData = async payload => {
    const {
      platform,
      data,
      callback
    } = payload;
    const processIds = store.connectivity.processIds;
    try {
      setConnectivityState({
        isSaving: true,
        //@ts-ignore
        processIds: platform === 'webhook' ? [data._id, ...processIds] : processIds
      });
      if (platform === 'slack') {
        await postSlackData(data);
      } else if (['sms', 'email'].includes(platform)) {
        await postEmailSMSData(data, platform);
      } else {
        await type2ApiPost[platform](data);
      }
      if (!['sms', 'email'].includes(platform)) {
        // @ts-ignore
        const newData = await loadConfigurationFor(platform);
        if (platform === 'webhook') {
          newData.id = data._id;
        }
        setConnectivityState({
          error: undefined,
          isSaving: false,
          [`${platform}`]: newData,
          processIds: newData.id ? processIds.filter(el => el !== newData.id) : processIds
        });
        await checkNewStatus(platform, newData);
        callback == null ? void 0 : callback(true);
      }
    } catch (e) {
      setConnectivityState({
        error: errorHandler(e),
        isSaving: false,
        isLoading: false
      });
    }
  };
  const postSlackCode = async payload => {
    try {
      await api.connectivity.postSlackCode(payload);
    } catch {
      /* empty */
    }
    setConnectivityState({
      error: undefined,
      isSaving: false
    });
  };
  const loadSlackPermissions = async () => {
    const slackChannels = store.connectivity.slackChannels;
    try {
      setConnectivityState({
        slackChannels: _extends({}, slackChannels, {
          isLoadingScope: true
        })
      });
      // TODO: fix rest-api types
      const {
        clientId
      } = await api.connectivity.getSlackScope();
      setConnectivityState({
        slackChannels: {
          error: undefined,
          clientId,
          isLoadingScope: false,
          isLoading: false
        }
      });
    } catch {
      setConnectivityState({
        slackChannels: {
          error: undefined,
          isLoadingScope: false,
          isLoading: false
        }
      });
    }
  };
  const deleteWebhookConfig = async payload => {
    const {
      callback,
      webhookId
    } = payload;
    const processIds = store.connectivity.processIds;
    try {
      setConnectivityState({
        isSaving: true
      });
      await api.connectivity.deleteWebhooksConfiguration(webhookId);
    } catch (e) {
      callback == null ? void 0 : callback(null, errorHandler(e));
    }
    const newData = await loadConfigurationFor('webhook');
    if (newData) {
      setConnectivityState({
        error: undefined,
        isSaving: false,
        webhook: newData,
        processIds: newData.id ? processIds.filter(el => el !== newData.id) : processIds
      });
    }
    callback == null ? void 0 : callback(true);
  };
  const postWebhookTest = async payload => {
    try {
      setConnectivityState({
        isTesting: true
      });
      // TODO: fix rest-api types
      const {
        statusCode,
        body
      } = await api.connectivity.postWebhookTest(payload);
      if ([201, 200].includes(statusCode)) {
        const message = JSON.stringify(body, null, 2);
        setConnectivityState({
          isTesting: false,
          testResult: {
            status: 'success',
            message
          }
        });
      } else {
        setConnectivityState({
          isTesting: false,
          testResult: {
            status: 'failed'
          }
        });
      }
    } catch (e) {
      setConnectivityState({
        isTesting: false,
        testResult: {
          status: 'failed',
          message: errorHandler(e)
        }
      });
    }
  };
  const postWebhookRetry = async payload => {
    try {
      // TODO: fix rest-api types
      const {
        statusCode
      } = await api.connectivity.postWebhookRetry(payload);
      postWebhookRetryResult({
        [payload]: {
          isProcess: false,
          success: statusCode === 202
        }
      });
    } catch (e) {
      postWebhookRetryResult({
        [payload]: {
          isProcess: false,
          success: false
        }
      });
    }
  };
  const loadWebhookLogs = async payload => {
    const {
      id,
      limit,
      offset
    } = payload;
    const webhookLogs = store.connectivity.webhookLogs;
    try {
      setConnectivityState({
        webhookLogs: _extends({}, webhookLogs, {
          isLoading: true
        })
      });
      //TODO: Fix types
      const data = await api.connectivity.getWebhookLog(id, offset, limit);
      setConnectivityState({
        error: undefined,
        webhookLogs: _extends({
          isLoading: false
        }, data)
      });
    } catch (e) {
      setConnectivityState({
        error: undefined,
        webhookLogs: {
          isLoading: false
        }
      });
    }
  };
  const cleanWebhookTestData = () => {
    setConnectivityState({
      testResult: undefined
    });
  };
  const cleanWebhookLogsData = async () => {
    setConnectivityState({
      webhookLogs: undefined
    });
  };
  const cleanWebhookTestMessage = async state => {
    var _state$testResult;
    setConnectivityState({
      testResult: {
        status: (_state$testResult = state.testResult) == null ? void 0 : _state$testResult.status,
        message: undefined
      }
    });
  };
  const cleanError = async () => {
    setConnectivityState({
      error: undefined
    });
  };
  const cleanSlackData = async () => {
    setConnectivityState({
      slackChannels: {
        isLoading: false
      }
    });
  };
  return {
    setConnectivityState,
    // @deprecated Use  resetConnectivityState
    initData: resetConnectivityState,
    resetConnectivityState,
    // @deprecated Use loadSlackChannels
    loadSlackActions: loadSlackChannels,
    loadSlackChannels,
    // @deprecated Use loadConnectivityChannels
    loadDataAction: loadConnectivityChannels,
    loadConnectivityChannels,
    // @deprecated Use postChannelData
    postDataAction: postChannelData,
    postChannelData,
    // @deprecated Use postSlackCode
    postCodeAction: postSlackCode,
    postSlackCode,
    // @deprecated Use loadSlackPermissions
    loadScope: loadSlackPermissions,
    loadSlackPermissions,
    // @deprecated Use deleteWebhookConfig
    deleteWebhookConfigAction: deleteWebhookConfig,
    deleteWebhookConfig,
    // @deprecated Use postWebhookTest
    postWebhookTestAction: postWebhookTest,
    postWebhookTest,
    // @deprecated Use postWebhookRetry
    postWebhookRetryAction: postWebhookRetry,
    postWebhookRetry,
    // @deprecated Use loadWebhookLogs
    loadWebhookLogsAction: loadWebhookLogs,
    loadWebhookLogs,
    cleanWebhookTestData,
    cleanWebhookLogsData,
    cleanWebhookTestMessage,
    cleanError,
    cleanSlackData
  };
});