import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Area } from '../../../services/content-api/content-api.service';
import { Observable, Subscription } from 'rxjs';
import { FronteggAuthService } from '@frontegg/angular';
import { ConfirmationService } from 'primeng/api';
import { UserPrivilegesService } from '../../../services/auth-privileges/auth-privileges.service';

@Component({
  selector: 'app-web-capture-batching',
  templateUrl: './web-capture-batching.component.html',
  styleUrls: ['./web-capture-batching.component.scss']
})
export class WebCaptureBatchingComponent implements OnInit {
  private subscription = new Subscription();

  hasUploadedFilesAtCreation: boolean = false;
  user?: any;
  tenantId: string = '';
  batchIdToCreate: string = '';
  areas: Area[] = [];

  constructor(
    private fronteggAuthService: FronteggAuthService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private userPrivilegesService: UserPrivilegesService
  ) {}

  ngOnInit(): void {
    this.tenantId = localStorage.getItem('tenantId') || '';
    const organization = localStorage.getItem('vanityName');

    if (this.tenantId && organization) {
      this.subscription.add(
        this.fronteggAuthService.authState$.subscribe(authState => {
          if (authState.user?.accessToken) {
            this.user = authState.user;
            console.log('User Batching:', this.user);
          }
        })
      );
    }
  }

  startBatchCreation() {
    this.router.navigate(['webcapture/batches/new']);
  }

  openBatch(batchId: string): void {
    this.router.navigate(['webcapture/batches/' + batchId]);
  }

  canPerformAction(actionCode: string): Observable<boolean> {
    return this.userPrivilegesService.canPerformAction(actionCode);
  }
}
