import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["callback", "appIds"],
  _excluded2 = ["roles"],
  _excluded3 = ["callback", "profileImage"],
  _excluded4 = ["callback"],
  _excluded5 = ["callback"],
  _excluded6 = ["callback"];
import { GroupRelations } from '@frontegg/rest-api';
import { TeamStateKeys } from '../interfaces';
import { errorHandler, retryIfNeeded } from '../../../helpers';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;

  /**
   * @private
   */
  const __getGroupsForUsers = async retryConfig => {
    try {
      // @ts-ignore // TODO: fix type
      const {
        groups
      } = await retryIfNeeded(() => api.groups.getGroups({
        _groupsRelations: GroupRelations.roles
      }), retryConfig);
      return groups;
    } catch (e) {
      return [];
    }
  };
  const loadRoles = async payload => {
    actions.setTeamLoader({
      key: TeamStateKeys.ROLES_AND_PERMISSIONS,
      value: true
    });
    try {
      var _payload$callback;
      const retryConfig = payload == null ? void 0 : payload.retryConfig;
      // @ts-ignore // TODO: fix type
      const [{
        items: roles
      }, {
        items: permissions
      }] = await Promise.all([retryIfNeeded(() => api.teams.loadAvailableRoles(), retryConfig), retryIfNeeded(() => api.teams.loadAvailablePermissions(), retryConfig)]);
      actions.setTeamState({
        roles,
        permissions
      });
      payload == null ? void 0 : (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, {
        roles,
        permissions
      });
    } catch (e) {
      var _payload$callback2;
      payload == null ? void 0 : (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, null, e);
      actions.setTeamError({
        key: TeamStateKeys.ROLES_AND_PERMISSIONS,
        value: errorHandler(e)
      });
    } finally {
      actions.setTeamLoader({
        key: TeamStateKeys.ROLES_AND_PERMISSIONS,
        value: false
      });
    }
  };
  /*** @deprecated loadUsers is deprecated, use loadUsersV2 instead */
  const loadUsers = async payload => {
    var _payload$pageSize, _payload$pageOffset, _payload$filter, _payload$sort;
    const {
      silentLoading,
      callback
    } = payload;
    const teamState = store.auth.teamState;
    const pageSize = (_payload$pageSize = payload.pageSize) != null ? _payload$pageSize : teamState.pageSize;
    const pageOffset = (_payload$pageOffset = payload.pageOffset) != null ? _payload$pageOffset : teamState.pageOffset;
    const filter = (_payload$filter = payload.filter) != null ? _payload$filter : teamState.filter;
    const sort = (_payload$sort = payload.sort) != null ? _payload$sort : teamState.sort;
    actions.setTeamLoader({
      key: TeamStateKeys.USERS,
      value: !silentLoading
    });
    actions.setTeamState({
      pageSize,
      pageOffset,
      filter: filter,
      sort: sort
    });
    try {
      // @ts-ignore // TODO: fix type in rest-api
      const [{
        items: users,
        totalPages,
        totalItems
      }] = await Promise.all([api.teams.loadUsers({
        pageSize,
        pageOffset,
        filter,
        sort
      }), loadRoles()]);
      actions.setTeamState({
        users,
        totalPages,
        totalItems
      });
      callback == null ? void 0 : callback(users);
    } catch (e) {
      actions.setTeamError({
        key: TeamStateKeys.USERS,
        value: errorHandler(e)
      });
      actions.setTeamState({
        totalPages: 0,
        users: []
      });
      callback == null ? void 0 : callback(null, e);
    }
    actions.setTeamLoader({
      key: TeamStateKeys.USERS,
      value: false
    });
  };
  const loadUsersV2 = async payload => {
    var _payload$pageSize2, _payload$pageOffset2, _payload$filter2, _payload$sort2, _payload$shouldShowSu;
    const {
      silentLoading,
      callback,
      shouldLoadRoles = true,
      shouldLoadApps,
      retryConfig
    } = payload;
    const teamState = store.auth.teamState;
    const pageSize = (_payload$pageSize2 = payload.pageSize) != null ? _payload$pageSize2 : teamState.pageSize;
    const pageOffset = (_payload$pageOffset2 = payload.pageOffset) != null ? _payload$pageOffset2 : teamState.pageOffset;
    const filter = (_payload$filter2 = payload.filter) != null ? _payload$filter2 : teamState.filterV2;
    const sort = (_payload$sort2 = payload.sort) != null ? _payload$sort2 : teamState.sortV2;
    const shouldIncludeSubTenants = (_payload$shouldShowSu = payload == null ? void 0 : payload.shouldShowSubTenantUsersIfReseller) != null ? _payload$shouldShowSu : teamState == null ? void 0 : teamState.shouldShowSubTenantUsersIfReseller;
    actions.setTeamLoader({
      key: TeamStateKeys.USERS,
      value: !silentLoading
    });
    actions.setTeamState({
      pageSize,
      pageOffset,
      filterV2: filter,
      sortV2: sort
    });
    try {
      const [{
        items: users,
        _metadata: {
          totalPages,
          totalItems
        }
      }, {
        items: roles
      }, {
        items: permissions
      }, groups] = await Promise.all([retryIfNeeded(() => api.users.getUsersV2(_extends({}, (filter == null ? void 0 : filter.length) && {
        _filter: filter
      }, sort && {
        _sortBy: sort
      }, payload.order && {
        _order: payload.order
      }, {
        _offset: pageOffset,
        _limit: pageSize,
        _includeSubTenants: shouldIncludeSubTenants
      })), retryConfig), retryIfNeeded(() => api.teams.loadAvailableRoles(), retryConfig),
      // TODO: check rest-api types
      retryIfNeeded(() => api.teams.loadAvailablePermissions(), retryConfig),
      // TODO: check rest-api types
      __getGroupsForUsers(retryConfig), shouldLoadRoles ? loadRoles({
        retryConfig
      }) : undefined]);

      //TODO: extend users type with groups in rest-api
      const usersWithGroups = users.map(user => {
        var _user$groups;
        const userGroupsFullData = user == null ? void 0 : (_user$groups = user.groups) == null ? void 0 : _user$groups.map(group => groups.filter(g => g.id === group.id)).flat();
        return _extends({}, user, {
          groups: userGroupsFullData
        });
      });
      const userWithRoleIds = usersWithGroups.map(user => _extends({}, user, {
        roleIds: user.roles.map(role => role.id)
      }));
      const mappedUsers = await sharedActions.mapUsersWithApplicationData({
        shouldLoadApps,
        users: userWithRoleIds,
        retryConfig
      });
      sharedActions.setTeamState({
        users: mappedUsers,
        totalPages,
        totalItems,
        roles,
        permissions
      });
      callback == null ? void 0 : callback(users);
    } catch (e) {
      console.error('Failed to load users', e);
      actions.setTeamError({
        key: TeamStateKeys.USERS,
        value: errorHandler(e)
      });
      actions.setTeamState({
        totalPages: 0,
        users: []
      });
      callback == null ? void 0 : callback(null, e);
    }
    actions.setTeamLoader({
      key: TeamStateKeys.USERS,
      value: false
    });
  };
  const addUser = async payload => {
    const {
        callback,
        appIds
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded);
    const teamState = store.auth.teamState;
    actions.setTeamState({
      addUserDialogState: _extends({}, teamState.addUserDialogState, {
        loading: true
      })
    });
    try {
      var _roles$map;
      const res = await api.teams.addUser(body);
      const {
          roles
        } = res,
        userWithoutRoleIds = _objectWithoutPropertiesLoose(res, _excluded2);
      const roleIds = (_roles$map = roles == null ? void 0 : roles.map(role => role.id)) != null ? _roles$map : [];
      const newUser = _extends({}, userWithoutRoleIds, {
        roleIds
      });
      const apps = await sharedActions.assignNewUserToApps({
        appIds,
        user: newUser
      });
      if (apps) {
        newUser.applications = apps;
      }
      callback == null ? void 0 : callback(newUser);
      actions.setTeamState({
        users: [newUser, ...teamState.users],
        addUserDialogState: {
          open: false,
          loading: false
        }
      });
    } catch (e) {
      console.error('Failed to add user', e);
      actions.setTeamState({
        addUserDialogState: _extends({}, teamState.addUserDialogState, {
          loading: false,
          error: errorHandler(e)
        })
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const addUsersBulk = async payload => {
    const {
      callback
    } = payload;
    const teamState = store.auth.teamState;
    actions.setTeamState({
      addUserDialogState: _extends({}, teamState.addUserDialogState, {
        loading: true
      })
    });
    const allowedEmails = [];
    const unallowedEmails = [];
    const bodies = payload.emails.map(email => _extends({
      email,
      roleIds: payload.roleIds
    }, payload.expirationInSeconds && {
      expirationInSeconds: payload.expirationInSeconds
    }));
    actions.setTeamState({
      addUserDialogState: {
        open: true,
        loading: true
      }
    });
    for (let i = 0; i < bodies.length; i++) {
      const body = bodies.at(i);
      try {
        await api.teams.addUser(body);
        allowedEmails.push(body.email);
      } catch (e) {
        unallowedEmails.push(body.email);
      }
    }
    const queryObject = {
      pageOffset: 0,
      pageSize: 10,
      filter: '',
      silentLoading: payload.emails.length > 0
    };
    await actions.loadUsersV2(queryObject);
    actions.setTeamState({
      addUserDialogState: {
        loading: false
      }
    });
    callback == null ? void 0 : callback({
      unallowedEmails,
      allowedEmails
    });
  };
  const updateUser = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded3);
    const {
      id: userId
    } = body;
    const teamState = store.auth.teamState;
    const oldUserData = teamState.users.find(user => user.id === body.id);
    if (oldUserData === undefined) {
      callback == null ? void 0 : callback(null, 'User not in state');
      return;
    }
    actions.setTeamLoader({
      key: TeamStateKeys.UPDATE_USER,
      value: userId || ''
    });
    actions.setTeamState({
      addUserDialogState: _extends({}, teamState.addUserDialogState, {
        loading: true
      })
    });
    actions.setTeamState({
      addUserDialogState: _extends({}, teamState.addUserDialogState, {
        loading: true
      }),
      users: teamState.users.map(user => {
        if (user.id === body.id) {
          return _extends({}, user, body);
        }
        return user;
      })
    });
    try {
      var _body$roleIds;
      if (oldUserData.roleIds.length > 0 && ((_body$roleIds = body.roleIds) == null ? void 0 : _body$roleIds.length) === 0) {
        body.roleIds = [''];
      }
      // @ts-ignore // TODO: fix type in rest-api
      const {
        item: newUser
      } = await api.teams.updateUser(body);
      callback == null ? void 0 : callback(newUser);
      actions.setTeamState({
        users: teamState.users.map(user => {
          return user.id === newUser.id ? _extends({}, user, newUser, {
            groups: user.groups,
            createdAt: user.createdAt,
            customData: user.customData,
            lastLogin: user.lastLogin
          }) : user;
        })
      });
      actions.setTeamLoader({
        key: TeamStateKeys.UPDATE_USER,
        value: false
      });
    } catch (e) {
      actions.setTeamState({
        addUserDialogState: _extends({}, teamState.addUserDialogState, {
          loading: false,
          error: errorHandler(e)
        }),
        users: teamState.users.map(user => user.id === body.id ? _extends({}, user, oldUserData) : user)
      });
      actions.setTeamLoader({
        key: TeamStateKeys.UPDATE_USER,
        value: false
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const deleteUser = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded4);
    const teamState = store.auth.teamState;
    actions.setTeamState({
      deleteUserDialogState: _extends({}, teamState.deleteUserDialogState, {
        loading: true
      })
    });
    try {
      await api.teams.deleteUser(body);
      callback == null ? void 0 : callback(true);
      actions.setTeamState({
        users: teamState.users.filter(user => user.id !== body.userId),
        deleteUserDialogState: {
          open: false,
          loading: false
        }
      });
    } catch (e) {
      actions.setTeamState({
        deleteUserDialogState: _extends({}, teamState.deleteUserDialogState, {
          loading: false,
          error: errorHandler(e)
        })
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const enableUser = async payload => {
    const {
      callback,
      userId
    } = payload;
    const teamState = store.auth.teamState;
    actions.setTeamState({
      enableUserDialogState: _extends({}, teamState.enableUserDialogState, {
        loading: true
      })
    });
    try {
      await api.teams.enableUser(userId);
      callback == null ? void 0 : callback(true);
      actions.setTeamState({
        users: teamState.users.map(user => user.id === userId ? _extends({}, user, {
          isDisabled: false
        }) : user),
        enableUserDialogState: {
          open: false,
          loading: false
        }
      });
    } catch (e) {
      actions.setTeamState({
        enableUserDialogState: _extends({}, teamState.enableUserDialogState, {
          loading: false,
          error: errorHandler(e)
        })
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const disableUser = async payload => {
    const {
      callback,
      userId
    } = payload;
    const teamState = store.auth.teamState;
    actions.setTeamState({
      disableUserDialogState: _extends({}, teamState.disableUserDialogState, {
        loading: true
      })
    });
    try {
      await api.teams.disableUser(userId);
      callback == null ? void 0 : callback(true);
      actions.setTeamState({
        users: teamState.users.map(user => user.id === userId ? _extends({}, user, {
          isDisabled: true
        }) : user),
        disableUserDialogState: {
          open: false,
          loading: false
        }
      });
    } catch (e) {
      actions.setTeamState({
        disableUserDialogState: _extends({}, teamState.disableUserDialogState, {
          loading: false,
          error: errorHandler(e)
        })
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const getTemporaryUsersConfig = async payload => {
    actions.setTeamError({
      key: TeamStateKeys.CONFIG_TEMPORARY_USERS,
      value: false
    });
    try {
      const temporaryUsersConfig = await retryIfNeeded(() => api.teams.getTemporaryUserConfiguration(), payload == null ? void 0 : payload.retryConfig);
      actions.setTeamState({
        temporaryUsersConfig
      });
    } catch (e) {
      actions.setTeamError({
        key: TeamStateKeys.CONFIG_TEMPORARY_USERS,
        value: errorHandler(e)
      });
    }
  };
  const updateUserExpirationTime = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded5);
    const teamState = store.auth.teamState;
    actions.setTeamLoader({
      key: TeamStateKeys.UPDATE_USER_EXPIRATION_TIME,
      value: true
    });
    try {
      // @ts-ignore // TODO: fix type in rest-api
      const res = await api.teams.updateUserExpirationTime(body);
      actions.setTeamState({
        users: teamState.users.map(user => {
          return user.id === body.userId ? _extends({}, user, {
            temporaryExpirationDate: new Date(res.temporaryExpirationDate)
          }) : user;
        })
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      actions.setTeamError({
        key: TeamStateKeys.UPDATE_USER_EXPIRATION_TIME,
        value: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    } finally {
      actions.setTeamLoader({
        key: TeamStateKeys.UPDATE_USER_EXPIRATION_TIME,
        value: false
      });
    }
  };
  const setUserAsPermanent = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded6);
    const teamState = store.auth.teamState;
    actions.setTeamLoader({
      key: TeamStateKeys.UPDATE_USER_EXPIRATION_TIME,
      value: true
    });
    try {
      await api.teams.setPermanentUser(body.userId);
      actions.setTeamState({
        users: teamState.users.map(user => {
          return user.id === body.userId ? _extends({}, user, {
            temporaryExpirationDate: undefined
          }) : user;
        })
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      actions.setTeamError({
        key: TeamStateKeys.UPDATE_USER_EXPIRATION_TIME,
        value: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    } finally {
      actions.setTeamLoader({
        key: TeamStateKeys.UPDATE_USER_EXPIRATION_TIME,
        value: false
      });
    }
  };
  return {
    loadRoles,
    loadUsers,
    loadUsersV2,
    addUser,
    addUsersBulk,
    updateUser,
    deleteUser,
    enableUser,
    disableUser,
    getTemporaryUsersConfig,
    updateUserExpirationTime,
    setUserAsPermanent
  };
});