import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["callback"],
  _excluded2 = ["callback"],
  _excluded3 = ["callback"],
  _excluded4 = ["callback"],
  _excluded5 = ["callback"],
  _excluded6 = ["callback"];
import { RolesStateKeys } from './interfaces';
import { initialState } from './state';
import { deepResetState, errorHandler, retryIfNeeded } from '../../helpers';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setRolesState = state => {
    Object.assign(store.auth.rolesState, state);
  };
  const resetRolesState = () => {
    deepResetState(store, ['auth', 'rolesState'], initialState);
  };
  const setRolesStateLoader = payload => {
    Object.assign(store.auth.rolesState, {
      loaders: _extends({}, store.auth.rolesState.loaders, {
        [payload.key]: payload.value
      })
    });
  };
  const setRolesStateError = payload => {
    Object.assign(store.auth.rolesState, {
      errors: _extends({}, store.auth.rolesState.errors, {
        [payload.key]: payload.value
      }),
      loaders: _extends({}, store.auth.rolesState.loaders, {
        [payload.key]: false
      })
    });
  };
  const loadRolesAndPermissions = async payload => {
    var _payload$silentLoadin;
    const key = RolesStateKeys.LOAD_ROLES;
    setRolesStateLoader({
      key,
      value: !((_payload$silentLoadin = payload == null ? void 0 : payload.silentLoading) != null ? _payload$silentLoadin : false)
    });
    try {
      const retryConfig = payload == null ? void 0 : payload.retryConfig;
      const [roles, permissions, permissionCategories] = await Promise.all([retryIfNeeded(() => api.roles.getRoles(), retryConfig), retryIfNeeded(() => api.roles.getPermissions(), retryConfig), retryIfNeeded(() => api.roles.getPermissionCategories(), retryConfig)]);
      const {
        selectedRole
      } = store.auth.rolesState;
      const updatedSelectedRole = roles == null ? void 0 : roles.find(({
        id: roleId
      }) => (selectedRole == null ? void 0 : selectedRole.id) === roleId);
      setRolesState({
        roles,
        permissions,
        permissionCategories,
        selectedRole: updatedSelectedRole
      });
    } catch (e) {
      setRolesStateError({
        key,
        value: errorHandler(e)
      });
    } finally {
      setRolesStateLoader({
        key,
        value: false
      });
    }
  };
  const addRole = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded);
    const key = RolesStateKeys.ADD_ROLE;
    setRolesStateLoader({
      key,
      value: true
    });
    try {
      const role = await api.roles.addRole(body);
      await loadRolesAndPermissions({
        silentLoading: true
      });
      callback == null ? void 0 : callback(role);
    } catch (e) {
      setRolesStateError({
        key,
        value: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    } finally {
      setRolesStateLoader({
        key,
        value: false
      });
    }
  };
  const addRoleV2 = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded2);
    const key = RolesStateKeys.ADD_ROLE;
    setRolesStateLoader({
      key,
      value: true
    });
    try {
      const role = await api.roles.addRoleV2(body);
      await loadRolesAndPermissions({
        silentLoading: true
      });
      callback == null ? void 0 : callback(role);
    } catch (e) {
      setRolesStateError({
        key,
        value: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    } finally {
      setRolesStateLoader({
        key,
        value: false
      });
    }
  };
  const updateRole = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded3);
    const key = RolesStateKeys.EDIT_ROLE_DIALOG;
    setRolesStateLoader({
      key,
      value: true
    });
    try {
      const role = await api.roles.updateRole(body);
      await loadRolesAndPermissions({
        silentLoading: true
      });
      setRolesState({
        saving: false
      });
      callback == null ? void 0 : callback(role);
    } catch (e) {
      setRolesStateError({
        key,
        value: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    } finally {
      setRolesStateLoader({
        key,
        value: false
      });
    }
  };
  const deleteRole = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded4);
    const key = RolesStateKeys.DELETE_ROLE_DIALOG;
    setRolesStateLoader({
      key,
      value: true
    });
    try {
      await api.roles.deleteRole(body);
      await loadRolesAndPermissions({
        silentLoading: true
      });
      setRolesState({
        saving: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setRolesStateError({
        key,
        value: errorHandler(e)
      });
      callback == null ? void 0 : callback(false, e);
    } finally {
      setRolesStateLoader({
        key,
        value: false
      });
    }
  };
  const attachPermissionsToRole = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded5);
    const key = RolesStateKeys.MANAGE_PERMISSIONS;
    setRolesStateLoader({
      key,
      value: true
    });
    try {
      const role = await api.roles.attachPermissionsToRole(body);
      await loadRolesAndPermissions({
        silentLoading: true
      });
      setRolesState({
        saving: false
      });
      callback == null ? void 0 : callback(role);
    } catch (e) {
      setRolesStateError({
        key,
        value: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    } finally {
      setRolesStateLoader({
        key,
        value: false
      });
    }
  };
  const attachPermissionToRoles = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded6);
    const key = RolesStateKeys.MANAGE_PERMISSIONS;
    setRolesStateLoader({
      key,
      value: true
    });
    try {
      await Promise.all(body.roleIds.map(roleId => attachPermissionsToRole({
        roleId,
        permissionIds: [body.permissionId]
      })));
      await loadRolesAndPermissions({
        silentLoading: true
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setRolesStateError({
        key,
        value: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    } finally {
      setRolesStateLoader({
        key,
        value: false
      });
    }
  };
  return {
    setRolesState,
    setRolesStateLoader,
    setRolesStateError,
    resetRolesState,
    loadRolesAndPermissions,
    addRole,
    addRoleV2,
    updateRole,
    deleteRole,
    attachPermissionsToRole,
    /**
     * @deprecated removed in v6.176.x
     *
     * https://github.com/frontegg/admin-box/commit/2f9d3b515b560e6197f61a36327be31ad71d79a2#diff-9aad7a2338a48d5d9d0eb9e90e438b36c496e3628ff106b3a11aa1f02bc483b1
     */
    attachPermissionToRoles
  };
});