import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"],
  _excluded2 = ["callback"],
  _excluded3 = ["user"],
  _excluded4 = ["deviceId"],
  _excluded5 = ["deviceId"],
  _excluded6 = ["callback"],
  _excluded7 = ["callback"],
  _excluded8 = ["deviceId", "callback"];
import { MFAStep } from './interfaces';
import { errorHandler, base64urlDecode, publicKeyCredentialToJSON, deepResetState, retryIfNeeded } from '../../helpers';
import { initialState } from './state';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;

  /**
   * @protected
   */
  async function __getMfaStateAfterEnroll(res) {
    const step = store.auth.mfaState.step;
    const mfaState = {
      step: res.recoveryCode ? MFAStep.recoveryCode : step,
      loading: false,
      saving: false,
      error: undefined,
      recoveryCode: res == null ? void 0 : res.recoveryCode
    };
    return mfaState;
  }
  const setMfaState = state => {
    Object.assign(store.auth.mfaState, state);
  };
  const resetMfaState = () => {
    deepResetState(store, ['auth', 'mfaState'], initialState);
  };
  const getMFADevices = async () => {
    setMfaState({
      loading: true
    });
    try {
      const mfaDevices = await api.auth.getMFADevices();
      setMfaState({
        loading: false,
        error: undefined,
        mfaDevices
      });
    } catch (e) {
      setMfaState({
        loading: false,
        error: errorHandler(e)
      });
    }
  };
  const getMFAStrategies = async payload => {
    setMfaState({
      loading: true
    });
    try {
      const res = await retryIfNeeded(() => api.auth.getMFAStrategiesConfig(), payload == null ? void 0 : payload.retryConfig);
      const mfaStrategies = res.strategies.filter(({
        isActive
      }) => isActive).map(({
        strategy
      }) => strategy);
      setMfaState({
        loading: false,
        error: undefined,
        mfaStrategies
      });
    } catch (e) {
      setMfaState({
        loading: false,
        error: errorHandler(e)
      });
    }
  };
  const enrollMfa = async () => {
    setMfaState({
      saving: true
    });
    try {
      const {
        qrCode
      } = await api.auth.enrollMfa();
      setMfaState({
        saving: false,
        error: undefined,
        qrCode
      });
    } catch (e) {
      setMfaState({
        saving: false,
        error: errorHandler(e)
      });
    }
  };
  const verifyMfa = async _payload => {
    const {
        callback
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded);
    setMfaState({
      saving: true
    });
    try {
      // TODO: fix type
      const user = store.auth.user;
      const data = await api.auth.verifyMfa(payload);
      const mfaDevices = await api.auth.getMFADevices();
      const mfaState = await __getMfaStateAfterEnroll(data);
      setMfaState(_extends({}, mfaState, {
        mfaDevices
      }));
      actions.setUser(_extends({}, user, {
        mfaEnrolled: true
      }));
      callback == null ? void 0 : callback(mfaState.recoveryCode);
    } catch (e) {
      setMfaState({
        saving: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const verifyMfaAfterForce = async _payload => {
    const {
        callback
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded2);
    setMfaState({
      loading: true
    });
    try {
      const _await$api$auth$login = await api.auth.loginWithMfaV2(payload),
        {
          user
        } = _await$api$auth$login,
        rest = _objectWithoutPropertiesLoose(_await$api$auth$login, _excluded3);
      const mfaState = {
        step: MFAStep.recoveryCode,
        loading: false,
        saving: false,
        error: undefined
      };
      if (user != null && user.recoveryCode) {
        mfaState.recoveryCode = user.recoveryCode;
      }
      setMfaState(mfaState);

      // @ts-ignore
      await actions.afterAuthenticationStateUpdate(_extends({
        user
      }, rest));
      callback == null ? void 0 : callback(mfaState.recoveryCode);
    } catch (e) {
      setMfaState({
        loading: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const disableMfa = async payload => {
    setMfaState({
      saving: true
    });
    try {
      var _payload$callback;
      await api.auth.disableMfa(payload);
      const mfaDevices = await api.auth.getMFADevices();
      setMfaState({
        saving: false,
        error: undefined,
        mfaDevices
      });
      (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, true);
    } catch (e) {
      var _payload$callback2;
      setMfaState({
        saving: false,
        error: errorHandler(e)
      });
      (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, false, e);
    }
  };
  const preDisableMfaSms = async _payload => {
    const {
        deviceId
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded4);
    setMfaState({
      saving: true
    });
    try {
      var _payload$callback3;
      const res = await api.auth.preDisableMFASMS(deviceId);
      setMfaState({
        saving: false,
        error: undefined,
        otcToken: res.otcToken,
        phoneNumber: res.phoneNumber
      });
      (_payload$callback3 = payload.callback) == null ? void 0 : _payload$callback3.call(payload, true);
    } catch (e) {
      var _payload$callback4;
      setMfaState({
        saving: false,
        error: errorHandler(e)
      });
      (_payload$callback4 = payload.callback) == null ? void 0 : _payload$callback4.call(payload, false, e);
    }
  };
  const disableMfaSms = async _payload => {
    const {
        deviceId
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded5);
    setMfaState({
      saving: true
    });
    try {
      var _payload$callback5;
      await api.auth.disableMFASMS(deviceId, payload);
      const mfaDevices = await api.auth.getMFADevices();
      setMfaState({
        saving: false,
        error: undefined,
        mfaDevices
      });
      (_payload$callback5 = payload.callback) == null ? void 0 : _payload$callback5.call(payload, true);
    } catch (e) {
      var _payload$callback6;
      setMfaState({
        saving: false,
        error: errorHandler(e)
      });
      (_payload$callback6 = payload.callback) == null ? void 0 : _payload$callback6.call(payload, false, e);
    }
  };
  const preEnrollMfaSms = async payload => {
    setMfaState({
      saving: true
    });
    try {
      var _payload$callback7;
      const res = await api.auth.preEnrollMFASMS(payload);
      setMfaState({
        saving: false,
        error: undefined,
        otcToken: res.otcToken,
        phoneNumber: res.phoneNumber
      });
      (_payload$callback7 = payload.callback) == null ? void 0 : _payload$callback7.call(payload, true);
    } catch (e) {
      var _payload$callback8;
      setMfaState({
        saving: false,
        error: errorHandler(e)
      });
      (_payload$callback8 = payload.callback) == null ? void 0 : _payload$callback8.call(payload, false, e);
    }
  };
  const enrollMfaSms = async _payload => {
    const {
        callback
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded6);
    setMfaState({
      saving: true
    });
    try {
      // @ts-ignore // TODO: fix enrollMFASMS in rest-api
      const data = await api.auth.enrollMFASMS(payload);
      const mfaDevices = await api.auth.getMFADevices();
      const mfaState = await __getMfaStateAfterEnroll(data);
      setMfaState(_extends({}, mfaState, {
        mfaDevices
      }));
      callback == null ? void 0 : callback(mfaState.recoveryCode);
    } catch (e) {
      setMfaState({
        saving: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const preEnrollMfaWebAuthn = async payload => {
    setMfaState({
      saving: true
    });
    try {
      var _data$options$exclude, _payload$callback9;
      const data = await api.auth.preEnrollMFAWebAuthn();
      const options = _extends({}, data.options, {
        challenge: base64urlDecode(data.options.challenge),
        user: _extends({}, data.options.user, {
          id: base64urlDecode(data.options.user.id)
        }),
        excludeCredentials: (_data$options$exclude = data.options.excludeCredentials) == null ? void 0 : _data$options$exclude.map(credentials => _extends({}, credentials, {
          id: base64urlDecode(credentials.id)
        }))
      });
      setMfaState({
        saving: false
      });
      (_payload$callback9 = payload.callback) == null ? void 0 : _payload$callback9.call(payload, {
        options,
        webauthnToken: data.webauthnToken
      });
    } catch (e) {
      var _payload$callback10;
      setMfaState({
        saving: false,
        error: errorHandler(e)
      });
      (_payload$callback10 = payload.callback) == null ? void 0 : _payload$callback10.call(payload, null, e);
    }
  };
  const enrollMfaWebAuthn = async _payload => {
    const {
        callback
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded7);
    setMfaState({
      saving: true
    });
    try {
      const publicKey = publicKeyCredentialToJSON(payload.publicKey);

      // @ts-ignore // TODO: fix enrollMFAWebAuthn in rest-api
      const data = await api.auth.enrollMFAWebAuthn(_extends({}, payload, {
        options: publicKey
      }));
      const mfaDevices = await api.auth.getMFADevices();
      const mfaState = await __getMfaStateAfterEnroll(data);
      setMfaState(_extends({}, mfaState, {
        mfaDevices
      }));
      callback == null ? void 0 : callback(mfaState.recoveryCode);
    } catch (e) {
      setMfaState({
        saving: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const preDisableMfaWebAuthn = async _payload => {
    const {
      deviceId,
      callback
    } = _payload;
    setMfaState({
      saving: true
    });
    try {
      var _data$options$allowCr;
      const data = await api.auth.preDisableMFAWebAuthn(deviceId);
      const options = _extends({}, data.options, {
        challenge: base64urlDecode(data.options.challenge),
        allowCredentials: (_data$options$allowCr = data.options.allowCredentials) == null ? void 0 : _data$options$allowCr.map(credentials => _extends({}, credentials, {
          id: base64urlDecode(credentials.id)
        }))
      });
      setMfaState({
        saving: false
      });
      callback == null ? void 0 : callback({
        options,
        webauthnToken: data.webauthnToken
      });
    } catch (e) {
      setMfaState({
        saving: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const disableMfaWebAuthn = async _payload => {
    const {
        deviceId,
        callback
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded8);
    setMfaState({
      saving: true
    });
    try {
      const publicKey = publicKeyCredentialToJSON(payload.publicKey);
      await api.auth.disableMFAWebAuthn(deviceId, _extends({}, payload, {
        options: publicKey
      }));
      const mfaDevices = await api.auth.getMFADevices();
      setMfaState({
        saving: false,
        error: undefined,
        mfaDevices
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setMfaState({
        saving: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(false, e);
    }
  };
  return {
    setMfaState,
    resetMfaState,
    getMFADevices,
    getMFAStrategies,
    enrollMfa,
    verifyMfa,
    verifyMfaAfterForce,
    disableMfa,
    preDisableMfaSms,
    disableMfaSms,
    preEnrollMfaSms,
    enrollMfaSms,
    preEnrollMfaWebAuthn,
    enrollMfaWebAuthn,
    preDisableMfaWebAuthn,
    disableMfaWebAuthn,
    __getMfaStateAfterEnroll
  };
});