import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"],
  _excluded2 = ["callback", "ssoConfigId"],
  _excluded3 = ["callback", "ssoConfigId"],
  _excluded4 = ["callback", "ssoConfigId"];
import { SSOStateKeys } from '../interfaces';
import { errorHandler, readFileAsText, retryIfNeeded } from '../../../helpers';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;

  /**
   * @private
   */
  const __loadOidc = async retryConfig => {
    try {
      return await retryIfNeeded(() => api.auth.getOidcConfiguration(), retryConfig);
    } catch (e) {
      return null;
    }
  };

  /**
   * @private
   */
  const __loadSaml = async retryConfig => {
    try {
      return await retryIfNeeded(() => api.metadata.getSamlMetadata(), retryConfig);
    } catch (e) {
      return null;
    }
  };

  /**
   * Helper function to perform loading and error handling with try catch
   * @param key loading / error key
   * @param callback withCallback function
   * @param block function to execute
   * @private
   */ // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const __ssoTryCatch = async (key, callback, block) => {
    actions.setSSOLoader({
      key,
      value: true
    });
    try {
      await block();
    } catch (error) {
      actions.setSSOError({
        key,
        value: errorHandler(error)
      });
      callback == null ? void 0 : callback(null, error);
    } finally {
      actions.setSSOLoader({
        key,
        value: false
      });
    }
  };
  const loadSSOConfigurationsV2 = async payload => {
    await __ssoTryCatch(SSOStateKeys.LOAD_SSO_CONFIGURATIONS, undefined, async () => {
      const [oidcConfiguration, samlMetadata] = await Promise.all([__loadOidc(payload == null ? void 0 : payload.retryConfig), __loadSaml(payload == null ? void 0 : payload.retryConfig)]);
      const ssoConfigurations = await retryIfNeeded(() => api.auth.getSSOConfigurations(), payload == null ? void 0 : payload.retryConfig);
      actions.setSSOState({
        ssoConfigurations,
        oidcConfiguration,
        samlMetadata
      });
    });
  };
  const saveSSOConfigurationV2 = async payload => {
    const {
        callback
      } = payload,
      ssoConfiguration = _objectWithoutPropertiesLoose(payload, _excluded);
    const ssoConfigurations = store.auth.ssoState.ssoConfigurations;
    await __ssoTryCatch(SSOStateKeys.SAVE_SSO_CONFIGURATION, callback, async () => {
      var _ssoConfiguration$ena;
      const newSsoConfiguration = await api.auth.createSSOConfiguration(_extends({}, ssoConfiguration, {
        enabled: (_ssoConfiguration$ena = ssoConfiguration.enabled) != null ? _ssoConfiguration$ena : false
      }));
      actions.setSSOState({
        ssoConfigurations: [...ssoConfigurations, newSsoConfiguration]
      });
      callback == null ? void 0 : callback(newSsoConfiguration);
    });
  };
  const updateSSOConfiguration = async payload => {
    const {
        callback,
        ssoConfigId
      } = payload,
      ssoConfiguration = _objectWithoutPropertiesLoose(payload, _excluded2);
    const ssoConfigurations = store.auth.ssoState.ssoConfigurations;
    await __ssoTryCatch(SSOStateKeys.UPDATE_SSO_CONFIGURATION, callback, async () => {
      const newSsoConfiguration = await api.auth.updateSSOConfiguration(ssoConfigId, ssoConfiguration);
      const newSsoConfigurations = ssoConfigurations.map(ssoConfig => ssoConfig.id === ssoConfigId ? newSsoConfiguration : ssoConfig);
      actions.setSSOState({
        ssoConfigurations: newSsoConfigurations
      });
      callback == null ? void 0 : callback(newSsoConfiguration);
    });
  };
  const deleteSSOConfiguration = async payload => {
    const {
      ssoConfigId,
      callback
    } = payload;
    const ssoConfigurations = store.auth.ssoState.ssoConfigurations;
    await __ssoTryCatch(SSOStateKeys.DELETE_SSO_CONFIGURATION, callback, async () => {
      await api.auth.deleteSSOConfiguration(ssoConfigId);
      actions.setSSOState({
        ssoConfigurations: ssoConfigurations.filter(({
          id
        }) => id !== ssoConfigId)
      });
      callback == null ? void 0 : callback(true);
    });
  };
  const saveSSODomain = async payload => {
    const {
        callback,
        ssoConfigId
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded3);
    const ssoConfigurations = store.auth.ssoState.ssoConfigurations;
    await __ssoTryCatch(SSOStateKeys.SAVE_SSO_DOMAIN, callback, async () => {
      const domain = await api.auth.createSSODomain(ssoConfigId, body);
      const newSsoConfigurations = ssoConfigurations.map(ssoConfig => {
        if (ssoConfig.id === ssoConfigId) {
          return _extends({}, ssoConfig, {
            domains: [...ssoConfig.domains, domain]
          });
        }
        return ssoConfig;
      });
      actions.setSSOState({
        ssoConfigurations: newSsoConfigurations
      });
      callback == null ? void 0 : callback(domain);
    });
  };
  const deleteSSODomain = async payload => {
    const {
      ssoConfigId,
      domainId,
      callback
    } = payload;
    const ssoConfigurations = store.auth.ssoState.ssoConfigurations;
    await __ssoTryCatch(SSOStateKeys.DELETE_SSO_DOMAIN, callback, async () => {
      await api.auth.deleteSSODomain(ssoConfigId, domainId);
      const newSsoConfigurations = ssoConfigurations.map(ssoConfig => {
        if (ssoConfig.id === ssoConfigId) {
          return _extends({}, ssoConfig, {
            domains: ssoConfig.domains.filter(({
              id
            }) => id !== domainId)
          });
        }
        return ssoConfig;
      });
      actions.setSSOState({
        ssoConfigurations: newSsoConfigurations
      });
      callback == null ? void 0 : callback(true);
    });
  };
  const validateSSODomainV2 = async payload => {
    const {
      callback,
      domainId,
      ssoConfigId
    } = payload;
    await __ssoTryCatch(SSOStateKeys.VALIDATE_SSO_DOMAIN, callback, async () => {
      const ssoConfigurations = store.auth.ssoState.ssoConfigurations;
      await api.auth.validateSSODomainV2(ssoConfigId, domainId);
      const newSsoConfigurations = ssoConfigurations.map(ssoConfig => {
        if (ssoConfig.id === ssoConfigId) {
          return _extends({}, ssoConfig, {
            domains: ssoConfig.domains.map(ssoDomain => ssoDomain.id === domainId ? _extends({}, ssoDomain, {
              validated: true
            }) : ssoDomain)
          });
        }
        return ssoConfig;
      });
      actions.setSSOState({
        ssoConfigurations: newSsoConfigurations
      });
      callback == null ? void 0 : callback(true);
    });
  };
  const setSSODefaultRoles = async payload => {
    const {
      callback,
      ssoConfigId,
      roleIds
    } = payload;
    await __ssoTryCatch(SSOStateKeys.UPDATE_SSO_DEFAULT_ROLES, callback, async () => {
      const ssoConfigurations = store.auth.ssoState.ssoConfigurations;
      await api.auth.setSSODefaultRoles(ssoConfigId, {
        roleIds
      });
      const newSsoConfigurations = ssoConfigurations.map(ssoConfig => {
        if (ssoConfig.id === ssoConfigId) {
          return _extends({}, ssoConfig, {
            roleIds
          });
        }
        return ssoConfig;
      });
      actions.setSSOState({
        ssoConfigurations: newSsoConfigurations
      });
      callback == null ? void 0 : callback(true);
    });
  };
  const setSSOGroups = async payload => {
    const {
      groupsToAdd,
      groupsToDelete,
      ssoConfigId,
      callback
    } = payload;
    await __ssoTryCatch(SSOStateKeys.SAVE_SSO_GROUPS, callback, async () => {
      const ssoConfigurations = store.auth.ssoState.ssoConfigurations;
      await Promise.all(groupsToDelete.map(groupId => api.auth.deleteSSOGroup(ssoConfigId, groupId)));
      await Promise.all(groupsToAdd.map(group => api.auth.createSSOGroup(ssoConfigId, group)));
      const groups = await api.auth.getSSOGroups(ssoConfigId);
      const newSsoConfigurations = ssoConfigurations.map(ssoConfig => {
        if (ssoConfig.id === ssoConfigId) {
          return _extends({}, ssoConfig, {
            groups
          });
        }
        return ssoConfig;
      });
      actions.setSSOState({
        ssoConfigurations: newSsoConfigurations
      });
      callback == null ? void 0 : callback(true);
    });
  };
  const updateSSOGroups = async payload => {
    const {
      ssoConfigId,
      changedGroups,
      callback
    } = payload;
    await __ssoTryCatch(SSOStateKeys.SAVE_SSO_GROUPS, callback, async () => {
      await Promise.all(changedGroups.map(group => api.auth.updateSSOGroup(ssoConfigId, group)));
      callback == null ? void 0 : callback(true);
    });
  };
  const saveSSOConfigurationByMetadata = async payload => {
    const {
      configFile,
      callback
    } = payload;
    await __ssoTryCatch(SSOStateKeys.SAVE_SSO_CONFIGURATION_BY_METADATA, callback, async () => {
      const ssoConfigurations = store.auth.ssoState.ssoConfigurations;
      const metadata = await readFileAsText(configFile);
      const newSsoConfiguration = await api.auth.createSSOConfigurationByMetadata({
        metadata
      });
      actions.setSSOState({
        ssoConfigurations: [...ssoConfigurations, newSsoConfiguration]
      });
      callback == null ? void 0 : callback(newSsoConfiguration);
    });
  };
  const updateSSOConfigurationByMetadata = async payload => {
    const {
      callback,
      ssoConfigId,
      configFile
    } = payload;
    await __ssoTryCatch(SSOStateKeys.UPDATE_SSO_CONFIGURATION_BY_METADATA, callback, async () => {
      const ssoConfigurations = store.auth.ssoState.ssoConfigurations;
      const metadata = await readFileAsText(configFile);
      const newSsoConfiguration = await api.auth.updateSSOConfigurationByMetadata(ssoConfigId, {
        metadata
      });
      const newSsoConfigurations = ssoConfigurations.map(ssoConfig => ssoConfig.id === ssoConfigId ? newSsoConfiguration : ssoConfig);
      actions.setSSOState({
        ssoConfigurations: newSsoConfigurations
      });
      callback == null ? void 0 : callback(newSsoConfiguration);
    });
  };
  const saveSSOConfigurationByData = async payload => {
    const {
        callback,
        ssoConfigId
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded4);
    const ssoConfigurations = store.auth.ssoState.ssoConfigurations;
    await __ssoTryCatch(SSOStateKeys.SAVE_SSO_CONFIGURATION, callback, async () => {
      let newSsoConfiguration;
      let newSsoConfigurations;
      if (ssoConfigId) {
        newSsoConfiguration = await api.auth.updateSSOConfiguration(ssoConfigId, body);
        newSsoConfigurations = ssoConfigurations.map(ssoConfig => ssoConfig.id === ssoConfigId ? newSsoConfiguration : ssoConfig);
      } else {
        newSsoConfiguration = await api.auth.createSSOConfiguration(body);
        newSsoConfigurations = [newSsoConfiguration, ...ssoConfigurations];
      }
      actions.setSSOState({
        ssoConfigurations: newSsoConfigurations
      });
      callback == null ? void 0 : callback(newSsoConfiguration);
    });
  };
  const saveSSOConfigurationByUrl = async payload => {
    const {
      metadataUrl,
      configMetadata,
      ssoConfigId,
      callback
    } = payload;
    await __ssoTryCatch(SSOStateKeys.SAVE_SSO_CONFIGURATION_BY_METADATA, callback, async () => {
      const ssoConfigurations = store.auth.ssoState.ssoConfigurations;
      const body = {
        url: metadataUrl,
        configMetadata
      };
      let newSsoConfiguration;
      let newSsoConfigurations;
      if (ssoConfigId) {
        newSsoConfiguration = await api.auth.updateSSOConfigurationByMetadataUrl(ssoConfigId, body);
        newSsoConfigurations = ssoConfigurations.map(ssoConfig => ssoConfig.id === ssoConfigId ? newSsoConfiguration : ssoConfig);
      } else {
        newSsoConfiguration = await api.auth.createSSOConfigurationByMetadataUrl(body);
        newSsoConfigurations = [newSsoConfiguration, ...ssoConfigurations];
      }
      actions.setSSOState({
        ssoConfigurations: newSsoConfigurations
      });
      callback == null ? void 0 : callback(newSsoConfiguration);
    });
  };
  return {
    loadSSOConfigurationsV2,
    saveSSOConfigurationV2,
    updateSSOConfiguration,
    deleteSSOConfiguration,
    saveSSODomain,
    deleteSSODomain,
    validateSSODomainV2,
    setSSOGroups,
    updateSSOGroups,
    setSSODefaultRoles,
    saveSSOConfigurationByMetadata,
    updateSSOConfigurationByMetadata,
    saveSSOConfigurationByUrl,
    saveSSOConfigurationByData
  };
});