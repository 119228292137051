import { createProxy } from '../../../toolkit/proxy';
export const initialState = {
  loaders: {},
  errors: {},
  recommendations: undefined,
  insights: undefined,
  hasSecurityChanges: false,
  score: 0,
  breachedPasswordUsersTable: {
    users: [],
    usersPageOffset: 0,
    totalUsersItems: 0,
    totalUsersPages: 0,
    queryParams: {
      _offset: 0,
      _limit: 10
    }
  },
  unenrolledMfaUsersTable: {
    users: [],
    usersPageOffset: 0,
    totalUsersItems: 0,
    totalUsersPages: 0,
    queryParams: {
      _offset: 0,
      _limit: 10
    }
  },
  inactiveUsersTable: {
    users: [],
    usersPageOffset: 0,
    totalUsersItems: 0,
    totalUsersPages: 0,
    queryParams: {
      _offset: 0,
      _limit: 10,
      _maxInactiveSeconds: 7776000
    }
  }
};
export default (overrideState => createProxy(initialState, overrideState));