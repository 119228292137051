import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class SubTenantsApi extends BaseApiClient {
  constructor(appName) {
    super(appName);
    this.loadAllUsers = async params => {
      return this.get(urls.identity.subTenants.v1, params);
    };
    this.addUserToTenantAndSubTenants = async body => {
      return this.post(urls.identity.subTenants.v1, body);
    };
    this.removeUserFromTenantAndSubTenants = async (body, options) => {
      return this.delete(urls.identity.subTenants.v1, body, {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.addUserRolesForSubTenants = async (userId, body, options) => {
      return this.post(`${urls.identity.subTenants.v1}/${userId}/roles`, body, {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.removeUserRolesFromSubTenants = async (userId, body, options) => {
      return this.delete(`${urls.identity.subTenants.v1}/${userId}/roles`, body, {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.setUserRolesForSubTenants = async (userId, body, options) => {
      return this.patch(`${urls.identity.subTenants.v1}/${userId}/roles`, body, {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.createSubTenant = async (body, options) => {
      return this.post(urls.tenants.subTenants.v1, body, {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.deleteSubTenant = async (tenantId, options) => {
      return this.delete(`${urls.tenants.subTenants.v1}/${tenantId}`, undefined, {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.updateSubTenant = async ({
      tenantId
    }, body, options) => {
      return this.patch(`${urls.tenants.subTenants.v1}/${tenantId}`, body, {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.updateSubTenantManagement = async ({
      tenantId
    }, body, options) => {
      return this.put(`${urls.tenants.subTenants.v1}/${tenantId}/management`, body, {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.updateSubAccountAccess = async (userId, body, options) => {
      return this.put(`${urls.identity.subTenants.v1}/${userId}/access`, body, {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.addUsersToSubTenant = async (body, options) => {
      return this.post(`${urls.identity.subTenants.v2}/`, body, {
        headers: this.extractHeadersFromOptions(options)
      });
    };
  }

  /**
   * Loads all users based on given parameters.
   */

  updateSubTenantHierarchySettings({
    tenantId
  }, body, options) {
    return this.put(`${urls.tenants.subTenants.v1}/${tenantId}/hierarchy-settings`, body, {
      headers: this.extractHeadersFromOptions(options)
    });
  }

  /**
   * Updates sub-account access.
   */
}

export default new SubTenantsApi('default');