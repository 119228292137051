import _extends from "@babel/runtime/helpers/esm/extends";
import { urls } from '../constants';
import { setTabTenantInSessionStorage } from '../auth/utils';
import { ContextHolder } from '../ContextHolder';
import { BaseApiClient } from '../BaseApiClient';
export class TenantsApi extends BaseApiClient {
  constructor(appName) {
    super(appName);
    this.switchTenant = async body => {
      if (ContextHolder.for(this.appName).isSessionPerTenantEnabled()) {
        setTabTenantInSessionStorage(body.tenantId);
      }
      return this.put(`${urls.identity.users.v1}/tenant`, body);
    };
    this.getTenants = async () => {
      return this.get(`${urls.identity.users.v2}/me/tenants`);
    };
    this.getSubTenants = async options => {
      return this.get(urls.tenants.hierarchy.v1, undefined, {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.getSubTenantsAsTree = async options => {
      return this.get(urls.tenants.hierarchy.tree.v1, undefined, {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.getParentTenants = async options => {
      return this.get(urls.tenants.hierarchy.parents.v1, undefined, {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.getParentTenantsById = async (tenantId, options) => {
      return this.get(`${urls.tenants.hierarchy.parents.v1}/${tenantId}`, undefined, {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.getTenantsUsersCount = async (body, options) => {
      return this.post(`${urls.identity.tenants.users.v1}/count`, body, {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.searchSubTenants = async (params, options) => {
      return this.get(urls.tenants.hierarchy.v2, params, {
        headers: this.extractHeadersFromOptions(options)
      });
    };
    this.getHierarchyMetadata = async (params, options) => {
      return this.get(urls.tenants.hierarchy.metadata.v2, _extends({}, params, {
        _tenantIds: params._tenantIds.join(',')
      }), {
        headers: this.extractHeadersFromOptions(options)
      });
    };
  }

  /**
   * Switch logged in user to specific tenant by providing tenantId.
   * @throws exception if invalid tenantId.
   */
}

export default new TenantsApi('default');