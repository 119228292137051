import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class VendorApi extends BaseApiClient {
  constructor(appName) {
    super(appName);
    this.getVendorPublicInfo = async () => {
      return this.get(`${urls.vendor}/public`);
    };
  }

  /**
   * Provide vendor public info by providing the vendor context.
   * @returns {Promise<IVendorPublicInfoResponse>}
   */
}

export default new VendorApi('default');