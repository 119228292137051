import { RestrictionType } from '@frontegg/rest-api';
import { createProxy } from '../../../toolkit/proxy';
export const initialState = {
  ipRestrictions: {
    loading: false,
    pagination: {
      _offset: 0,
      _limit: 4,
      _filter: RestrictionType.ALLOW
    },
    totalPages: 1,
    userIpValid: false
  },
  emailDomainRestrictions: {
    loading: false
  }
};
export default (overrideState => createProxy(initialState, overrideState));