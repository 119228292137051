import _extends from "@babel/runtime/helpers/esm/extends";
import { initialState } from './state';
import { deepResetState, errorHandler, retryIfNeeded } from '../../helpers';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setAuditLogsState = state => {
    Object.assign(store.auditLogs.auditLogsState, state);
  };
  const resetAuditLogsState = () => {
    deepResetState(store, ['auditLogs', 'auditLogsState'], initialState);
  };
  const exportAuditsCsv = async () => {
    const state = store.auditLogs.auditLogsState;
    const {
      columns
    } = store.auditLogs.auditsMetadataState;
    try {
      const filter = state.filter;
      const sort = state.sort;
      const sortParams = sort.reduce((p, n) => _extends({}, p, {
        sortBy: n.id,
        sortDirection: n.desc ? 'desc' : 'asc'
      }), {});
      const filterParams = filter.reduce((p, n) => _extends({}, p, {
        [n.id]: n.value
      }), {});
      setAuditLogsState({
        isDownloadingCsv: true
      });
      const outputFileName = `audits.csv`;
      await api.audits.exportAudits(_extends({
        endpoint: 'csv/v2',
        headerProps: columns,
        offset: 0,
        outputFileName
      }, sortParams, filterParams));
    } catch (e) {
      console.error('failed to export audits - ', e);
    }
    setAuditLogsState({
      isDownloadingCsv: false
    });
  };
  const loadAuditLogs = async payload => {
    setAuditLogsState({
      loading: !(payload != null && payload.silentLoading),
      error: null
    });
    const state = store.auditLogs.auditLogsState;
    const retryConfig = payload == null ? void 0 : payload.retryConfig;
    const {
      columns
    } = store.auditLogs.auditsMetadataState;
    try {
      var _payload$pageSize, _payload$pageOffset, _payload$filter, _payload$sort, _payload$callback;
      const pageSize = (_payload$pageSize = payload.pageSize) != null ? _payload$pageSize : state.pageSize;
      const pageOffset = (_payload$pageOffset = payload.pageOffset) != null ? _payload$pageOffset : state.pageOffset;
      const filter = (_payload$filter = payload.filter) != null ? _payload$filter : state.filter;
      const sort = (_payload$sort = payload.sort) != null ? _payload$sort : state.sort;
      setAuditLogsState({
        pageSize,
        pageOffset,
        filter,
        sort
      });
      const sortParams = sort.reduce((p, n) => _extends({}, p, {
        sortBy: n.id,
        sortDirection: n.desc ? 'desc' : 'asc'
      }), {});
      const filterParams = filter.reduce((p, n) => _extends({}, p, {
        [n.id]: n.value
      }), {});
      if (!columns) {
        await actions.loadAuditsMetadata({
          retryConfig
        });
      }
      const {
        data,
        total
      } = await retryIfNeeded(() => api.audits.getAudits(_extends({
        offset: pageOffset,
        count: pageSize
      }, sortParams, filterParams)), retryConfig);
      setAuditLogsState({
        loading: false,
        logs: data != null ? data : [],
        totalPages: +Math.ceil(total / pageSize)
      });
      payload == null ? void 0 : (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, true);
    } catch (e) {
      var _payload$callback2;
      setAuditLogsState({
        loading: false,
        error: errorHandler(e)
      });
      payload == null ? void 0 : (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, null, e);
    }
  };
  return {
    setAuditLogsState,
    resetAuditLogsState,
    exportAuditsCsv,
    loadAuditLogs
  };
});