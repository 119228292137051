import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class PhoneNumbersApi extends BaseApiClient {
  constructor(appName) {
    super(appName);
    this.getUserPhoneNumbers = async queryParams => {
      return this.get(`${urls.identity.phoneNumbers.v1}`, queryParams);
    };
    this.createPhoneNumber = async body => {
      return this.post(`${urls.identity.phoneNumbers.v1}`, body);
    };
    this.deletePhoneNumber = async phoneId => {
      return this.delete(`${urls.identity.phoneNumbers.v1}/${phoneId}`);
    };
    this.preVerifyPhoneNumber = async body => {
      return this.post(`${urls.identity.phoneNumbers.v1}/preverify`, body);
    };
    this.verifyPhoneNumber = async body => {
      return this.post(`${urls.identity.phoneNumbers.v1}/verify`, body);
    };
    this.verifyDeletePhoneNumber = async (phoneId, body) => {
      return this.post(`${urls.identity.phoneNumbers.v1}/${phoneId}/delete/verify`, body);
    };
  }

  /**
   * Get phone numbers of user
   */
}

export default new PhoneNumbersApi('default');