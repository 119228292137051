import { buildLoginActions } from '../../auth/LoginState';
import { dummyIps, userDemo } from '../dummy';
import { mockActionsExpect } from '../helpers';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const originalActions = buildLoginActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setLoginState', 'resetLoginState', 'afterAuthNavigation']);
  mockedActions.requestAuthorize = async firstTime => {
    if (firstTime) {
      actions.setAuthState({
        isLoading: true
      });
    }
    const user = userDemo;
    await actions.loadTenants();
    actions.setAuthState({
      user,
      isAuthenticated: true,
      isLoading: false
    });
  };
  mockedActions.getUserIP = async payload => {
    const {
      callback
    } = payload;
    try {
      const {
        ip
      } = dummyIps[0];
      await actions.setAuthState({
        userIpData: {
          ip
        },
        userIp: ip
      });
      callback == null ? void 0 : callback(true, ip);
    } catch (e) {
      callback == null ? void 0 : callback(false, e);
    }
  };
  return mockedActions;
});