import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ContextHolder } from '@frontegg/angular';

@Injectable({
  providedIn: 'root'
})
export class SignOutService {
  private signOutSubject = new BehaviorSubject<boolean>(false);
  signOut$ = this.signOutSubject.asObservable();
  public isFilingContent: boolean = false;

  setSignOutState(state: boolean): void {
    this.signOutSubject.next(state);
  }

  getSignOutState(): boolean {
    return this.signOutSubject.value;
  }

  signOut(): void {
    const baseUrl = ContextHolder.getContext().baseUrl;
    window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
  }
}
