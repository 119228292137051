import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener
} from '@angular/core';
import { BatchResponse, Department, ListBatchesParams } from '../../models/sdk-interfaces';
import { BatchService } from '../../services/batch/batch.service';
import { BatchStatus } from '../../constants';
import { UserPrivilegesService } from '../../services/auth-privileges/auth-privileges.service';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { FronteggAuthService } from '@frontegg/angular';

interface BatchColumn {
  field: string;
  header: string;
}

@Component({
  selector: 'app-batch-list',
  templateUrl: './batch-list.component.html',
  styleUrls: ['./batch-list.component.scss']
})
export class BatchListComponent implements OnInit {
  @Input() tenantId: string = '';
  @Output() openBatchEvent = new EventEmitter<string>();

  @ViewChild('tableContainer', { static: true }) tableContainer!: ElementRef;

  private subscription = new Subscription();

  user?: any;
  batches: BatchResponse[] = [];
  cols: BatchColumn[] = [];
  isLoading: boolean = false;
  offset: number = 0;
  count: number = 25;
  hasMoreData: boolean = true;
  canListBatches: boolean = false;
  canEditBatches: boolean = false;
  canManageCompletedBatches: boolean = false;
  faEllipsis = faEllipsis;
  isOpeningSettings: boolean = false;
  userDepartments: Department[] = [];

  filters: { [key: string]: string } = {};
  globalFilterValue: string = '';

  currentTab: string = 'available';

  baseCols: BatchColumn[] = [
    { field: 'batchId', header: 'id' },
    { field: 'name', header: 'batchName' },
    { field: 'createdBy', header: 'batchCreator' },
    { field: 'createdAt', header: 'creationDate' },
    { field: 'status', header: 'status' },
    { field: 'filingProgress', header: 'filingProgress' }
  ];

  constructor(
    private fronteggAuthService: FronteggAuthService,
    private batchService: BatchService,
    private userPrivilegesService: UserPrivilegesService
  ) {}

  ngOnInit(): void {
    this.tenantId = localStorage.getItem('tenantId') || '';
    const organization = localStorage.getItem('vanityName');

    if (this.tenantId && organization) {
      this.subscription.add(
        this.fronteggAuthService.authState$.subscribe(authState => {
          if (authState.user?.accessToken) {
            this.user = authState.user;
          }
        })
      );
    }

    this.userPrivilegesService.getDepartments().subscribe(departments => {
      this.userDepartments = departments;
    });

    this.cols = [...this.baseCols];
    this.userPrivilegesService.canPerformAction('LIST_BATCHES').subscribe(canList => {
      this.canListBatches = canList;
      if (canList) {
        this.currentTab = 'available';
      }
    });
    this.userPrivilegesService.canPerformAction('EDIT_BATCH').subscribe(canEdit => {
      this.canEditBatches = canEdit;
      if (this.currentTab === '' && canEdit) {
        this.currentTab = 'available';
      }
    });
    this.userPrivilegesService.canPerformAction('MODIFY_COMPLETED_BATCH').subscribe(canManage => {
      this.canManageCompletedBatches = canManage;
      if (this.currentTab === '' && canManage) {
        this.currentTab = 'completed';
      }
    });
    this.loadData();
  }

  @HostListener('document:click')
  public onClick(): void {
    this.closeAllSettings();
  }

  filterByTab(tab: string): void {
    this.currentTab = tab;
    this.updateCols();

    let status: string | undefined;

    switch (tab) {
      case 'available':
        status = `${BatchStatus.Available}`;
        break;
      case 'completed':
        status = `${BatchStatus.Completed}`;
        break;
      case 'checkedOut':
        status = `${BatchStatus.CheckedOut}`;
        break;
      default:
        status =
          BatchStatus[(tab.charAt(0).toUpperCase() + tab.slice(1)) as keyof typeof BatchStatus];
        break;
    }

    this.resetData(status);
  }

  /**
   * Updates the columns to display based on the current tab.
   */
  updateCols(): void {
    this.cols = [...this.baseCols];

    if (this.currentTab === 'checkedOut') {
      this.cols.push(
        { field: 'checkedOutBy', header: 'checkedOutBy' },
        { field: 'settings', header: 'settings' }
      );
    }
  }

  resetData(status?: string): void {
    this.batches = [];
    this.offset = 0;
    this.hasMoreData = true;
    this.loadData(status);
  }

  loadData(status?: string): void {
    if (this.isLoading || !this.hasMoreData) {
      return;
    }

    this.isLoading = true;

    const params: ListBatchesParams = {
      offset: this.offset,
      count: this.count,
      status: status || this.getCurrentStatusFilter()
    };

    this.batchService
      .listBatches(this.tenantId, params)
      .then(response => {
        const newBatches = response.items;

        if (newBatches.length < this.count) {
          this.hasMoreData = false; // No more data to load
        }

        this.batches = this.filterBatchesByDepartment(
          [...this.batches, ...newBatches],
          this.userDepartments
        );
        this.offset += this.count;
        this.isLoading = false;
      })
      .catch(error => {
        console.error('Error fetching batches:', error);
        this.isLoading = false;
      });
  }

  filterBatchesByDepartment(batches: BatchResponse[], departments: Department[]): BatchResponse[] {
    return (this.batches = batches.filter(batch => {
      return departments.some(department => department.code === batch.department);
    }));
  }

  getFieldValue(batch: BatchResponse, field: string): any {
    if (field in batch) {
      return (batch as any)[field];
    }
    return '';
  }

  getCurrentStatusFilter(): string | undefined {
    switch (this.currentTab) {
      case 'active':
        return `${BatchStatus.Available},${BatchStatus.CheckedOut}`;
      case 'all':
        return undefined;
      default:
        return BatchStatus[
          (this.currentTab.charAt(0).toUpperCase() +
            this.currentTab.slice(1)) as keyof typeof BatchStatus
        ];
    }
  }

  getFilingProgress(batch: BatchResponse): string {
    const documentCount = batch.statistics?.documentCount || 0;
    const unprocessedDocumentCount = batch.statistics?.unprocessedDocumentCount || 0;
    const processedDocCount = documentCount - unprocessedDocumentCount;
    return `${processedDocCount}/${documentCount}`;
  }

  onScroll(): void {
    const element = this.tableContainer.nativeElement;
    const scrollPos = element.scrollTop + element.clientHeight;
    const threshold = 5;
    if (element.scrollHeight - scrollPos <= threshold) {
      this.loadData();
    }
  }

  openBatch(batchId: string | undefined): void {
    if (this.canOpenBatch()) {
      this.openBatchEvent.emit(batchId);
    }
  }

  getStatusBadgeClass(status: string | undefined): string {
    if (!status) {
      return 'status-badge';
    }

    switch (status.toLowerCase()) {
      case 'available':
      case 'checked_out':
        return 'status-badge available';
      case 'draft':
        return 'status-badge draft';
      case 'deleted':
        return 'status-badge deleted';
      case 'completed':
        return 'status-badge completed';
      default:
        return 'status-badge';
    }
  }

  showAvailableList(): boolean {
    return this.canListBatches || this.canEditBatches;
  }

  showCheckedOutList(): boolean {
    return this.canEditBatches;
  }

  showCompletedList(): boolean {
    return this.canManageCompletedBatches;
  }

  canOpenBatch(): boolean {
    if (this.currentTab === 'completed' || this.currentTab === 'checkedOut') {
      return false;
    } else {
      return this.canEditBatches;
    }
  }

  /**
   * Shows the settings menu for a batch.
   * @param batchRowIndex The index of the batch in the list.
   */
  showBatchSettings(batchRowIndex: number): void {
    this.isOpeningSettings = true;
    document.getElementById(`batch-settings-${batchRowIndex}`)?.classList.toggle('show');
  }

  /**
   * Closes all settings menus.
   */
  closeAllSettings(): void {
    if (!this.isOpeningSettings) {
      const settingsMenus = document.getElementsByClassName('batch-settings-menu');
      for (let i = 0; i < settingsMenus.length; i++) {
        settingsMenus[i].classList.remove('show');
      }
    } else {
      this.isOpeningSettings = false;
    }
  }

  /**
   * Checks in a batch.
   * @param batchId The ID of the batch to check in.
   */
  checkInBatch(batchId: string): void {
    if (this.canEditBatches) {
      this.batchService
        .checkinBatch(this.tenantId, this.user.customClaims.principal_id, batchId)
        .then(() => {
          this.resetData();
        });
    }
  }
}
