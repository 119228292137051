/**
 * Entitlements config from frontegg provider
 */

export class ContextHolder {
  constructor() {
    this.context = null;
    this.accessToken = null;
    this.user = null;
    this.requestSource = null;
    this.onRedirectTo = path => window.location.href = path;
    this.logout = () => window.location.href = '/account/logout';
    this.entitlementsOptions = null;
    this.appName = null;
    this.sessionContext = void 0;
    this.basename = null;
    this.cdnUrl = null;
    this.assetsUrl = null;
    this.setContext = context => {
      this.context = context;
    };
    this.setAccessToken = accessToken => {
      this.accessToken = accessToken;
    };
    this.setUser = user => {
      this.user = user;
    };
    this.setRequestSource = requestSource => {
      this.requestSource = requestSource;
    };
    this.setOnRedirectTo = onRedirectTo => {
      this.onRedirectTo = onRedirectTo;
    };
    this.setLogout = logout => {
      this.logout = logout;
    };
    this.setEntitlementsOptions = entitlementsOptions => {
      this.entitlementsOptions = entitlementsOptions;
    };
    this.setSessionContext = sessionContext => {
      this.sessionContext = sessionContext;
    };
    this.setAppName = appName => {
      this.appName = appName;
    };
    this.getContext = () => {
      var _this$context;
      return (_this$context = this.context) != null ? _this$context : {
        baseUrl: window.location.href,
        tokenResolver: () => 'my-authentication-token',
        logLevel: 'error'
      };
    };
    this.getAccessToken = () => {
      return this.accessToken;
    };
    this.getRequestSource = () => {
      return this.requestSource;
    };
    this.getUser = () => {
      return this.user;
    };
    this.shouldLoadEntitlements = () => {
      var _this$entitlementsOpt;
      return ((_this$entitlementsOpt = this.entitlementsOptions) == null ? void 0 : _this$entitlementsOpt.enabled) || false;
    };
    this.isSessionPerTenantEnabled = () => {
      var _this$sessionContext;
      return ((_this$sessionContext = this.sessionContext) == null ? void 0 : _this$sessionContext.enableSessionPerTenant) || false;
    };
    this.getAppName = () => {
      return this.appName;
    };
    this.setCdnUrl = cdnUrl => {
      this.cdnUrl = cdnUrl;
    };
    this.getCdnUrl = () => {
      return this.cdnUrl;
    };
    this.setAssetsUrl = assets => {
      this.assetsUrl = assets;
    };
    this.getAssetsUrl = () => {
      return this.assetsUrl;
    };
  }
  static default() {
    return ContextHolder.getInstance(DEFAULT_APP_NAME);
  }
  static for(appName) {
    return ContextHolder.getInstance(appName);
  }

  /**
   * @deprecated - use ContextHolder.for(appName) / ContextHolder.default()  instead
   * @param appName
   */
  static getInstance(appName = 'default') {
    if (!ContextHolder._apps) {
      ContextHolder._apps = {};
    }
    if (ContextHolder._apps[appName]) {
      return ContextHolder._apps[appName];
    }
    ContextHolder._apps[appName] = new ContextHolder();
    return ContextHolder._apps[appName];
  }
  static createInstance(appName = 'default') {
    ContextHolder._apps[appName] = new ContextHolder();
    ContextHolder._apps[appName].appName = appName;
    return ContextHolder._apps[appName];
  }

  /**
   * @deprecated - use ContextHolder.for(appName).setContext instead
   */
  static setContext(context, appName = 'default') {
    ContextHolder.for(appName).context = context;
  }
  /**
   * @deprecated - use ContextHolder.for(appName).setAccessToken instead
   */
  static setAccessToken(accessToken, appName = DEFAULT_APP_NAME) {
    ContextHolder.for(appName).accessToken = accessToken;
  }
  /**
   * @deprecated - use ContextHolder.for(appName).setUser instead
   */
  static setUser(user, appName = 'default') {
    ContextHolder.for(appName).user = user;
  }
  /**
   * @deprecated - use ContextHolder.for(appName).setRequestSource instead
   */
  static setRequestSource(requestSource, appName = 'default') {
    ContextHolder.for(appName).requestSource = requestSource;
  }
  /**
   * @deprecated - use ContextHolder.for(appName).setOnRedirectTo instead
   */
  static setOnRedirectTo(onRedirectTo, appName = 'default') {
    ContextHolder.for(appName).onRedirectTo = onRedirectTo;
  }
  /**
   * @deprecated - use ContextHolder.for(appName).setLogout instead
   */
  static setLogout(logout, logoutUrl, appName = 'default') {
    const context = ContextHolder.for(appName);
    context.logout = callback => {
      if (!callback) {
        context.onRedirectTo(logoutUrl, undefined);
      } else {
        logout(callback);
      }
    };
  }
  /**
   * App name should also be set for entitlements
   * @deprecated - use ContextHolder.for(appName).setEntitlementsOptions instead
   * @param entitlementsOptions frontegg options entitlements config
   * @param appName - app name
   */
  static setEntitlementsOptions(entitlementsOptions, appName = 'default') {
    ContextHolder.for(appName).entitlementsOptions = entitlementsOptions;
  }
  /**
   * @deprecated - use ContextHolder.for(appName).setSessionContext instead
   * @param sessionContext frontegg options SessionContext config
   * @param appName - app name
   */
  static setSessionContext(sessionContext, appName = 'default') {
    ContextHolder.for(appName).sessionContext = sessionContext;
  }
  /**
   * @deprecated - use ContextHolder.for(appName).setAppName instead
   */
  static setAppName(appName) {
    ContextHolder.for(appName).appName = appName;
  }
  /**
   * @deprecated - use ContextHolder.for(appName).getContext instead
   */
  static getContext(appName = 'default') {
    var _ContextHolder$for$co;
    return (_ContextHolder$for$co = ContextHolder.for(appName).context) != null ? _ContextHolder$for$co : {
      baseUrl: window.location.href,
      tokenResolver: () => 'my-authentication-token',
      logLevel: 'error'
    };
  }
  /**
   * @deprecated - use ContextHolder.for(appName).getAccessToken instead
   */
  static getAccessToken(appName = 'default') {
    return ContextHolder.for(appName).accessToken;
  }
  /**
   * @deprecated - use ContextHolder.for(appName).requestSource instead
   */
  static getRequestSource(appName = 'default') {
    return ContextHolder.for(appName).requestSource;
  }
  /**
   * @deprecated - use ContextHolder.for(appName).getBasename instead
   */
  static getBasename(appName = 'default') {
    return ContextHolder.for(appName).basename;
  }
  getBasename() {
    return this.basename;
  }

  /**
   * @deprecated - use ContextHolder.for(appName).setBasename instead
   */
  static setBasename(basename, appName = DEFAULT_APP_NAME) {
    ContextHolder.for(appName).basename = basename;
  }
  setBasename(basename) {
    this.basename = basename;
  }

  /**
   * @deprecated - use ContextHolder.for(appName).getUser instead
   */
  static getUser(appName = 'default') {
    return ContextHolder.for(appName).user;
  }
  /**
   * @deprecated - use ContextHolder.for(appName).onRedirectTo instead
   */
  static onRedirectTo(path, opts, appName = 'default') {
    return ContextHolder.for(appName).onRedirectTo(path, opts);
  }

  /**
   * @deprecated - use ContextHolder.for(appName).logout instead
   */
  static logout(callback, appName = 'default') {
    return ContextHolder.for(appName).logout(callback);
  }

  /**
   * @deprecated - use ContextHolder.for(appName).shouldLoadEntitlements instead
   * @returns shouldLoadEntitlements. Default to false.
   */
  static shouldLoadEntitlements(appName = 'default') {
    var _ContextHolder$for$en;
    return ((_ContextHolder$for$en = ContextHolder.for(appName).entitlementsOptions) == null ? void 0 : _ContextHolder$for$en.enabled) || false;
  }
  /**
   * @deprecated - use ContextHolder.for(appName).isSessionPerTenantEnabled instead
   * @returns isSessionPerTenantEnabled. Default to false.
   */
  static isSessionPerTenantEnabled(appName = 'default') {
    var _ContextHolder$for$se;
    return ((_ContextHolder$for$se = ContextHolder.for(appName).sessionContext) == null ? void 0 : _ContextHolder$for$se.enableSessionPerTenant) || false;
  }
  /**
   * @deprecated - use ContextHolder.for(appName).getAppName instead
   * @returns app name value
   */
  static getAppName(appName = 'default') {
    return ContextHolder.for(appName).appName;
  }
}
ContextHolder._apps = void 0;
const DEFAULT_APP_NAME = 'default';
/**
 * Frontegg context to be used in the application
 *
 * @deprecated use ContextHolder.for instead
 */
export const FronteggContext = {
  getContext: () => ContextHolder.getContext(DEFAULT_APP_NAME),
  getAccessToken: () => ContextHolder.getAccessToken(DEFAULT_APP_NAME),
  setAccessToken: accessToken => ContextHolder.setAccessToken(accessToken, DEFAULT_APP_NAME),
  getUser: () => ContextHolder.getUser(DEFAULT_APP_NAME),
  setUser: user => ContextHolder.setUser(user, DEFAULT_APP_NAME),
  onRedirectTo: (path, opts) => ContextHolder.onRedirectTo(path, opts, DEFAULT_APP_NAME),
  setOnRedirectTo: onRedirectTo => ContextHolder.setOnRedirectTo(onRedirectTo, DEFAULT_APP_NAME),
  logout: callback => ContextHolder.logout(callback, DEFAULT_APP_NAME),
  getRequestSource: () => ContextHolder.getRequestSource(DEFAULT_APP_NAME),
  setRequestSource: requestSource => ContextHolder.setRequestSource(requestSource, DEFAULT_APP_NAME),
  isSessionPerTenantEnabled: () => ContextHolder.isSessionPerTenantEnabled(DEFAULT_APP_NAME),
  shouldLoadEntitlements: () => ContextHolder.shouldLoadEntitlements(DEFAULT_APP_NAME),
  getAppName: () => ContextHolder.getAppName(DEFAULT_APP_NAME),
  setEntitlementsOptions: entitlementsOptions => ContextHolder.setEntitlementsOptions(entitlementsOptions, DEFAULT_APP_NAME),
  setSessionContext: sessionContext => ContextHolder.setSessionContext(sessionContext, DEFAULT_APP_NAME),
  getBasename: () => ContextHolder.getBasename(DEFAULT_APP_NAME),
  setBasename: basename => ContextHolder.setBasename(basename, DEFAULT_APP_NAME)
};