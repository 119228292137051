import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["roleIds", "userIds"];
import { GroupRelations, GroupsStateKeys } from './interfaces';
import { errorHandler, deepResetState, retryIfNeeded } from '../../helpers';
import { initialState } from './state';
export default ((store, api, sharedActions) => {
  const setUsersGroupsLoader = payload => {
    Object.assign(store.auth.groupsState, {
      loaders: _extends({}, store.auth.groupsState.loaders, {
        [payload.key]: payload.value
      })
    });
  };
  const setUsersGroupsError = payload => {
    Object.assign(store.auth.groupsState, {
      errors: _extends({}, store.auth.groupsState.errors, {
        [payload.key]: payload.value
      }),
      loaders: _extends({}, store.auth.groupsState.loaders, {
        [payload.key]: false
      })
    });
  };
  const setGroupsState = payload => {
    Object.assign(store.auth.groupsState, payload);
  };
  const resetUsersGroupsState = () => {
    deepResetState(store, ['auth', 'groupsState'], initialState);
  };
  const loadGroups = async payload => {
    const key = GroupsStateKeys.GET_GROUPS;
    const state = store.auth.groupsState;
    setUsersGroupsLoader({
      key,
      value: true
    });
    try {
      // @ts-ignore TODO: fix return type in rest-api
      const {
        groups
      } = await retryIfNeeded(() => api.groups.getGroups({
        _groupsRelations: GroupRelations.rolesAndUsers
      }), payload == null ? void 0 : payload.retryConfig);
      setGroupsState(_extends({
        groups
      }, state.selectedGroup && {
        selectedGroup: groups.find(g => {
          var _state$selectedGroup;
          return g.id === ((_state$selectedGroup = state.selectedGroup) == null ? void 0 : _state$selectedGroup.id);
        })
      }));
    } catch (e) {
      setUsersGroupsError({
        key,
        value: errorHandler(e)
      });
    } finally {
      setUsersGroupsLoader({
        key,
        value: false
      });
    }
  };
  const getGroupById = async payload => {
    const key = GroupsStateKeys.GET_GROUP;
    setUsersGroupsLoader({
      key,
      value: true
    });
    try {
      const selectedGroup = await api.groups.getGroupById({
        groupId: payload.groupId
      }, {
        _groupsRelations: GroupRelations.rolesAndUsers
      });
      setGroupsState({
        selectedGroup
      });
    } catch (e) {
      setUsersGroupsError({
        key,
        value: errorHandler(e)
      });
    } finally {
      setUsersGroupsLoader({
        key,
        value: false
      });
    }
  };
  const createGroup = async payload => {
    const key = GroupsStateKeys.CREATE_GROUP;
    const {
        roleIds,
        userIds
      } = payload,
      createGroupPayload = _objectWithoutPropertiesLoose(payload, _excluded);
    setUsersGroupsLoader({
      key,
      value: true
    });
    try {
      var _payload$callback;
      const response = await api.groups.createGroup(_extends({
        name: createGroupPayload.name
      }, !!createGroupPayload.description && {
        description: createGroupPayload.description
      }, {
        color: createGroupPayload.color
      }));
      if (userIds != null && userIds.length) {
        addUsersToGroup({
          groupId: response.id,
          userIds
        });
      }
      if (roleIds != null && roleIds.length) {
        addRolesToGroup({
          groupId: response.id,
          roleIds
        });
      }
      await loadGroups();
      payload == null ? void 0 : (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, true);
    } catch (e) {
      var _payload$callback2;
      setUsersGroupsError({
        key,
        value: errorHandler(e)
      });
      payload == null ? void 0 : (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, false);
    } finally {
      setUsersGroupsLoader({
        key,
        value: false
      });
    }
  };
  const updateGroup = async payload => {
    const key = GroupsStateKeys.UPDATE_GROUP;
    setUsersGroupsLoader({
      key,
      value: true
    });
    try {
      var _payload$callback3;
      await api.groups.updateGroup(payload);

      // TODO: check if should await
      await loadGroups();
      payload == null ? void 0 : (_payload$callback3 = payload.callback) == null ? void 0 : _payload$callback3.call(payload, true);
    } catch (e) {
      var _payload$callback4;
      setUsersGroupsError({
        key,
        value: errorHandler(e)
      });
      payload == null ? void 0 : (_payload$callback4 = payload.callback) == null ? void 0 : _payload$callback4.call(payload, false);
    } finally {
      setUsersGroupsLoader({
        key,
        value: false
      });
    }
  };
  const deleteGroup = async payload => {
    const key = GroupsStateKeys.DELETE_GROUP;
    setUsersGroupsLoader({
      key,
      value: true
    });
    try {
      var _payload$callback5;
      await api.groups.deleteGroup(payload.groupId);

      // TODO: check if should await
      await loadGroups();
      payload == null ? void 0 : (_payload$callback5 = payload.callback) == null ? void 0 : _payload$callback5.call(payload, true);
    } catch (e) {
      var _payload$callback6;
      setUsersGroupsError({
        key,
        value: errorHandler(e)
      });
      payload == null ? void 0 : (_payload$callback6 = payload.callback) == null ? void 0 : _payload$callback6.call(payload, false, e);
    } finally {
      setUsersGroupsLoader({
        key,
        value: false
      });
    }
  };
  const addRolesToGroup = async payload => {
    const key = GroupsStateKeys.ADD_ROLES_TO_GROUP;
    setUsersGroupsLoader({
      key,
      value: true
    });
    try {
      var _payload$callback7;
      await api.groups.addRolesToGroup(payload.groupId, {
        roleIds: payload.roleIds
      });
      // TODO: check if should await
      await loadGroups();
      payload == null ? void 0 : (_payload$callback7 = payload.callback) == null ? void 0 : _payload$callback7.call(payload, true);
    } catch (e) {
      var _payload$callback8;
      setUsersGroupsError({
        key,
        value: errorHandler(e)
      });
      payload == null ? void 0 : (_payload$callback8 = payload.callback) == null ? void 0 : _payload$callback8.call(payload, false);
    } finally {
      setUsersGroupsLoader({
        key,
        value: false
      });
    }
  };
  const deleteRolesFromGroup = async payload => {
    const key = GroupsStateKeys.DELETE_ROLES_FROM_GROUP;
    setUsersGroupsLoader({
      key,
      value: true
    });
    try {
      var _payload$callback9;
      await api.groups.deleteRolesFromGroup(payload.groupId, {
        roleIds: payload.roleIds
      });
      await loadGroups();
      payload == null ? void 0 : (_payload$callback9 = payload.callback) == null ? void 0 : _payload$callback9.call(payload, true);
    } catch (e) {
      var _payload$callback10;
      setUsersGroupsError({
        key,
        value: errorHandler(e)
      });
      payload == null ? void 0 : (_payload$callback10 = payload.callback) == null ? void 0 : _payload$callback10.call(payload, false);
    } finally {
      setUsersGroupsLoader({
        key,
        value: false
      });
    }
  };
  const addUsersToGroup = async payload => {
    const key = GroupsStateKeys.ADD_USERS_TO_GROUP;
    setUsersGroupsLoader({
      key,
      value: true
    });
    try {
      var _payload$callback11;
      await api.groups.addUsersToGroup(payload.groupId, {
        userIds: payload.userIds
      });

      // TODO: check if should await
      await loadGroups();
      payload == null ? void 0 : (_payload$callback11 = payload.callback) == null ? void 0 : _payload$callback11.call(payload, true);
    } catch (e) {
      var _payload$callback12;
      payload == null ? void 0 : (_payload$callback12 = payload.callback) == null ? void 0 : _payload$callback12.call(payload, false);
      setUsersGroupsError({
        key,
        value: errorHandler(e)
      });
    } finally {
      setUsersGroupsLoader({
        key,
        value: false
      });
    }
  };
  const deleteUsersFromGroup = async payload => {
    const key = GroupsStateKeys.DELETE_USERS_FROM_GROUP;
    setUsersGroupsLoader({
      key,
      value: true
    });
    try {
      var _payload$callback13;
      await api.groups.deleteUsersFromGroup(payload.groupId, {
        userIds: payload.userIds
      });

      // TODO: check if should await
      await loadGroups();
      payload == null ? void 0 : (_payload$callback13 = payload.callback) == null ? void 0 : _payload$callback13.call(payload, true);
    } catch (e) {
      var _payload$callback14;
      setUsersGroupsError({
        key,
        value: errorHandler(e)
      });
      payload == null ? void 0 : (_payload$callback14 = payload.callback) == null ? void 0 : _payload$callback14.call(payload, false);
    } finally {
      setUsersGroupsLoader({
        key,
        value: false
      });
    }
  };
  const getGroupConfiguration = async payload => {
    const key = GroupsStateKeys.GET_GROUP_CONFIGURATION;
    setUsersGroupsLoader({
      key,
      value: true
    });
    try {
      const groupsConfiguration = await retryIfNeeded(() => api.groups.getGroupConfiguration(), payload == null ? void 0 : payload.retryConfig);
      setGroupsState({
        groupsConfiguration
      });
    } catch (e) {
      setUsersGroupsError({
        key,
        value: errorHandler(e)
      });
    } finally {
      setUsersGroupsLoader({
        key,
        value: false
      });
    }
  };
  const updateGroupConfiguration = async payload => {
    const key = GroupsStateKeys.UPDATE_GROUP_CONFIGURATION;
    setUsersGroupsLoader({
      key,
      value: true
    });
    try {
      await api.groups.updateGroupConfiguration(payload);

      // TODO: check if should await
      await getGroupConfiguration();
    } catch (e) {
      setUsersGroupsError({
        key,
        value: errorHandler(e)
      });
    } finally {
      setUsersGroupsLoader({
        key,
        value: false
      });
    }
  };
  const getTeamUsers = async payload => {
    const key = GroupsStateKeys.GET_TEAM_USERS;
    setUsersGroupsLoader({
      key,
      value: true
    });
    try {
      const {
        items: teamUsers
      } = await api.users.getUsersV2({
        _filter: payload.filter,
        _offset: payload.pageOffset,
        _limit: payload.pageSize,
        _includeSubTenants: false
      });

      // @ts-ignore // TODO: fix type in rest-api / group state or add mapper
      setGroupsState({
        teamUsers
      });
    } catch (e) {
      setUsersGroupsError({
        key,
        value: errorHandler(e)
      });
    } finally {
      setUsersGroupsLoader({
        key,
        value: false
      });
    }
  };
  return {
    setUsersGroupsLoader,
    setUsersGroupsError,
    setGroupsState,
    resetUsersGroupsState,
    loadGroups,
    getGroupById,
    createGroup,
    updateGroup,
    deleteGroup,
    addRolesToGroup,
    deleteRolesFromGroup,
    addUsersToGroup,
    deleteUsersFromGroup,
    getGroupConfiguration,
    updateGroupConfiguration,
    getTeamUsers
  };
});