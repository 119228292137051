import { BaseApiClient } from '../BaseApiClient';
export class MetadataApi extends BaseApiClient {
  constructor(appName) {
    super(appName);
    this.getMetadata = async body => {
      var _data$rows, _data$rows2;
      const data = await this.get('/metadata', body);
      if (data != null && (_data$rows = data.rows) != null && _data$rows[0]) return data == null ? void 0 : (_data$rows2 = data.rows) == null ? void 0 : _data$rows2[0];
      throw new Error(`metadata not found: ${body.entityName}`);
    };
    this.getAdminBoxMetadata = async () => {
      return this.get('/metadata/admin-box');
    };
    this.updateAdminBoxMetadata = async body => {
      return this.post('/metadata/admin-box', body);
    };
    this.getIpMetadata = async ip => {
      const data = await this.get(`/metadata/ip/${ip}`);
      if (data) return data;
      throw new Error(`ip metadata not found`);
    };
    this.getCurrentUserIpMetadata = async () => {
      const data = await this.get(`/metadata/ipme`);
      if (data) return data;
      throw new Error(`ip metadata not found`);
    };
    this.getNotificationsMetadata = async () => {
      return this.getMetadata({
        entityName: 'notifications'
      });
    };
    this.getSamlMetadata = async () => {
      return this.getMetadata({
        entityName: 'saml'
      });
    };
    this.getAuditsMetadata = async () => {
      return this.getMetadata({
        entityName: 'audits'
      });
    };
    this.getIpAdressMetadata = async ip => {
      return this.getIpMetadata(ip);
    };
  }

  /**
   * Retrieves metadata based on the given criteria.
   *
   * @param body - Metadata request parameters.
   * @returns Metadata matching the criteria.
   * @throws Will throw an error if metadata is not found.
   */
}

export default new MetadataApi('default');