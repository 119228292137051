<url-verification
  *ngIf="!isAccessFlagLoading && !isUrlVerified; else webCaptureContainer"
  [hasAccess]="isAccessEnabled"
  [noAccessMessage]="featureFlagErrorText | translate"
  (successfulVerificationEvent)="onSuccessfulVerification()"
  (mustSignOutEvent)="signOut()"
></url-verification>

<ng-template #webCaptureContainer>
  <div
    class="container"
    *ngIf="!isLoading && user"
  >
    <div class="header-container">
      <img
        src="../../../assets/etrieve.svg"
        class="etrieve-logo"
        alt="Etrieve Logo"
      />
      <span class="header-title">
        {{ 'webCapture' | translate }}
      </span>
      <div class="nav-buttons">
        <button
          class="header-button"
          [attr.aria-label]="'about' | translate"
          title="{{ 'resourceCenter' | translate }}"
        >
          <fa-icon
            class="header-icon pendo-icon"
            [icon]="faQuestionCircle"
          />
        </button>
        <button
          class="header-button"
          (click)="toggleShowSettings()"
          [attr.aria-label]="'settings' | translate"
          title="{{ 'settings' | translate }}"
          [ngClass]="{ active: showSettings }"
        >
          <fa-icon
            class="header-icon"
            [icon]="faGear"
          />
        </button>
        <div
          class="settings-menu"
          *ngIf="showSettings"
          (focusout)="checkNavButtonsFocus($event)"
        >
          <ul class="settings-list">
            <li>
              <button
                (click)="changeContentUrl()"
                [attr.aria-label]="'contentUrl' | translate"
                class="settings-option"
              >
                <span>
                  <img
                    src="../../../assets/browser-solid.svg"
                    class="img-icon"
                    alt="Content URL Icon"
                  />
                  {{ 'contentUrl' | translate }}
                </span>
              </button>
            </li>
            <li>
              <button
                (click)="showAboutModal = true; showSettings = false"
                [attr.aria-label]="'about' | translate"
                class="settings-option"
              >
                <span>
                  <fa-icon [icon]="faInfoCircle" />
                  {{ 'about' | translate }}
                </span>
              </button>
            </li>
            <div class="separator-line"></div>
            <span class="only-text settings-option">
              {{ user.name }}
            </span>
            <div class="separator-line"></div>
            <li>
              <button
                (click)="signOut()"
                [attr.aria-label]="'signOut' | translate"
                class="settings-option"
              >
                <span>
                  <fa-icon [icon]="faSignOut" />
                  {{ 'signOut' | translate }}
                </span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Tab buttons for Ad hoc and Batch Upload -->
    <div class="tab-buttons">
      <button
        (click)="goToTab('')"
        [class.active]="!currentTab"
        [attr.aria-label]="'documentUpload' | translate"
      >
        <fa-icon [icon]="faFile"></fa-icon>
        {{ 'documentUpload' | translate }}
      </button>
      <button
        (click)="goToTab('batches')"
        [class.active]="currentTab === 'batches'"
        [attr.aria-label]="'documentBatching' | translate"
      >
        <img
          src="../../../assets/rectangle-history-circle-plus-solid.svg"
          alt="Upload Batch Icon"
        />
        {{ 'documentBatching' | translate }}
      </button>
    </div>
    <div class="router-container">
      <router-outlet></router-outlet>
    </div>
  </div>
  <p-dialog
    [header]="'about' | translate"
    [modal]="true"
    [(visible)]="showAboutModal"
    [style]="{ width: '680px' }"
    class="about-dialog"
    [draggable]="false"
    [resizable]="false"
    [closeAriaLabel]="'close' | translate"
  >
    <p>
      {{ 'aboutHeader' | translate }}
    </p>
    <span class="title">
      {{ 'conformanceStatus' | translate }}
    </span>
    <p>
      <a
        href="https://www.w3.org/WAI/standards-guidelines/wcag/"
        target="_blank"
        >{{ 'wcagLink' | translate }}</a
      >
      {{ 'conformanceStatusText' | translate }}
      <a
        href="https://www.softdocs.com/trust/"
        target="_blank"
        >{{ 'vpatLink' | translate }}</a
      >
    </p>
    <hr class="separator-line" />
    <span class="title">
      {{ 'feedback' | translate }}
    </span>
    <p>
      {{ 'feedbackText' | translate }}
    </p>
    <ul>
      <li>
        {{ 'feedbackPhone' | translate }}
      </li>
      <li>
        {{ 'feedbackEmail' | translate }}
      </li>
      <li>
        {{ 'feedbackPostal' | translate }}
      </li>
    </ul>
    <p>
      {{ 'feedbackEnd' | translate }}
    </p>
    <hr class="separator-line" />
    <div class="about-buttons">
      <button
        class="close-button"
        (click)="showAboutModal = false"
        [attr.aria-label]="'close' | translate"
      >
        {{ 'close' | translate }}
      </button>
    </div>
  </p-dialog>
  <p-confirmDialog
    #tabChangeDialog
    key="tabChangeDialog"
  >
    <ng-template
      pTemplate="headless"
      let-message
    >
      <div class="confirm-container">
        <div class="">
          <p>{{ message.message | translate }}</p>
        </div>
        <div class="buttons-container">
          <button
            class="cancel-button"
            (click)="tabChangeDialog.reject()"
            [attr.aria-label]="'cancel' | translate"
          >
            {{ 'cancel' | translate }}
          </button>
          <button
            class="confirm-button"
            (click)="tabChangeDialog.accept()"
            [attr.aria-label]="'confirm' | translate"
          >
            {{ 'confirm' | translate }}
          </button>
        </div>
      </div>
    </ng-template>
  </p-confirmDialog>
  <p-confirmDialog
    #closeBatchDialog
    key="closeBatchDialog"
  >
    <ng-template
      pTemplate="headless"
      let-message
    >
      <div class="confirm-container">
        <div class="">
          <p>{{ message.message | translate }}</p>
        </div>
        <div class="buttons-container">
          <button
            class="cancel-button"
            (click)="closeBatchDialog.reject()"
            [attr.aria-label]="'cancel' | translate"
          >
            {{ 'cancel' | translate }}
          </button>
          <button
            class="confirm-button"
            (click)="closeBatchDialog.accept()"
            [attr.aria-label]="'confirm' | translate"
          >
            {{ 'confirm' | translate }}
          </button>
        </div>
      </div>
    </ng-template>
  </p-confirmDialog>
  <p-confirmDialog
    #deleteBatchConfirmDialog
    key="deleteBatchConfirmDialog"
    [header]="'warning' | translate"
  >
    <ng-template
      pTemplate="message"
      let-message
    >
      <div class="delete-confirm-container">
        <div class="">
          <p>{{ 'confirmDeleteBatchMessage' | translate }}</p>
          <p>{{ 'confirmDeleteBatchMessage2' | translate }}</p>
        </div>
      </div>
    </ng-template>
    <ng-template
      pTemplate="footer"
      let-footer
    >
      <div class="delete-confirm-buttons-container">
        <button
          class="cancel-button"
          (click)="deleteBatchConfirmDialog.reject()"
        >
          {{ 'cancel' | translate }}
        </button>
        <button
          class="confirm-button"
          (click)="deleteBatchConfirmDialog.accept()"
        >
          {{ 'deleteBatch' | translate }}
        </button>
      </div>
    </ng-template>
  </p-confirmDialog>
  <p-confirmDialog
    #deleteBatchFileConfirmDialog
    key="deleteBatchFileConfirmDialog"
    [header]="'warning' | translate"
  >
    <ng-template
      pTemplate="message"
      let-message
    >
      <div class="delete-confirm-container">
        <div class="">
          <p>{{ 'confirmDeleteBatchFileMessage' | translate }}</p>
          <p>{{ 'confirmDeleteBatchFileMessage2' | translate }}</p>
        </div>
      </div>
    </ng-template>
    <ng-template
      pTemplate="footer"
      let-footer
    >
      <div class="delete-confirm-buttons-container">
        <button
          class="cancel-button"
          (click)="deleteBatchFileConfirmDialog.reject()"
        >
          {{ 'cancel' | translate }}
        </button>
        <button
          class="confirm-button"
          (click)="deleteBatchFileConfirmDialog.accept()"
        >
          {{ 'deleteDocument' | translate }}
        </button>
      </div>
    </ng-template> </p-confirmDialog
  ><p-confirmDialog
    #deleteAdHocFileConfirmDialog
    key="deleteAdHocFileConfirmDialog"
    [header]="'warning' | translate"
  >
    <ng-template
      pTemplate="message"
      let-message
    >
      <div class="delete-confirm-container">
        <div class="">
          <p>{{ 'confirmDeleteAdHocFileMessage' | translate }}</p>
          <p>{{ 'confirmDeleteAdHocFileMessage2' | translate }}</p>
        </div>
      </div>
    </ng-template>
    <ng-template
      pTemplate="footer"
      let-footer
    >
      <div class="delete-confirm-buttons-container">
        <button
          class="cancel-button"
          (click)="deleteAdHocFileConfirmDialog.reject()"
        >
          {{ 'cancel' | translate }}
        </button>
        <button
          class="confirm-button"
          (click)="deleteAdHocFileConfirmDialog.accept()"
        >
          {{ 'deleteDocument' | translate }}
        </button>
      </div>
    </ng-template>
  </p-confirmDialog>
</ng-template>
