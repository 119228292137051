import _extends from "@babel/runtime/helpers/esm/extends";
import { AuthStrategyEnum, RestrictionType, GroupManagedByEnum, InsightCode, RecommendationSeverity, RecommendationCode, SecurityCategory, PermissionAssignmentTypeEnum, MachineToMachineAuthStrategy } from '@frontegg/rest-api';
export const apiTokensDataDemo = {
  clientId: 'CLIENT_ID_16806d3d-8fc3-4450-be97-abdaf66b723e',
  secret: 'SECRET_16806d3d-8fc3-4450-be97-abdaf66b723e',
  createdAt: 'createdAt',
  description: 'Demo API Token (preview only)'
};
export const apiTokensDataTenantDemo = _extends({}, apiTokensDataDemo, {
  roleIds: [],
  tenantId: 'my-tenant-id',
  createdByUserId: 'createdByUserId'
});
export const rolesAdminViewerDemo = [{
  id: 'a040dcf6-b907-4e83-9970-6bd23c88fd40',
  key: 'ReadOnly',
  name: 'Read Only',
  description: null,
  isDefault: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  permissions: ['8d71b676-754c-4600-afe7-ca5add1e6809', '04dbcc11-61db-40a9-9577-9b73e50d1d03'],
  vendorId: 'vendorId'
}, {
  id: 'b43b2c4b-e056-4eec-8c55-d200a475bbc0',
  key: 'Admin',
  name: 'Admin',
  description: null,
  isDefault: true,
  createdAt: new Date(),
  updatedAt: new Date(),
  permissions: ['8d71b676-754c-4600-afe7-ca5add1e6809', '04dbcc11-61db-40a9-9577-9b73e50d1d03', '16647407-ed6f-4ca6-83a0-86225d921959', 'f403c6b9-1abe-4ec7-9971-aa032255fc25', 'a43e059d-d731-44b1-94bb-fb6b16c62210'],
  vendorId: 'vendorId'
}, {
  id: '1234',
  key: 'Support',
  name: 'Support',
  description: null,
  isDefault: true,
  createdAt: new Date(),
  updatedAt: new Date(),
  permissions: ['8d71b676-754c-4600-afe7-ca5add1e6809', '04dbcc11-61db-40a9-9577-9b73e50d1d03', 'f26b00f8-ffe0-4739-b15c-7db15c27ea26', 'a9c803b0-ebfa-49b9-9607-f0d3a390ee61'],
  tenantId: 'tenantId',
  vendorId: 'vendorId'
}];
export const roleDemo = [{
  id: 'roleId',
  key: 'admin',
  isDefault: false,
  name: 'Admin',
  description: null,
  permissions: ['8d71b676-754c-4600-afe7-ca5add1e6809', '04dbcc11-61db-40a9-9577-9b73e50d1d03'],
  tenantId: 'tenantId',
  vendorId: 'vendorId',
  createdAt: new Date(),
  updatedAt: new Date()
}];
export const rolePermissionDemo = [{
  id: '8d71b676-754c-4600-afe7-ca5add1e6809',
  key: 'fe.*',
  name: 'General Admin',
  description: 'description',
  categoryId: 'category',
  fePermission: true,
  createdAt: new Date(),
  updatedAt: new Date(),
  roleIds: []
}];
export const userProfileDemo = {
  sub: '',
  tenants: [],
  id: 'testId',
  name: 'Demo User Name',
  email: 'demo-user@frontegg.com',
  mfaEnrolled: true,
  profilePictureUrl: 'https://www.gravatar.com/avatar/42b2ad2bad6fc9b9db5086dfcf8072ac?d=https://ui-avatars.com/api/fe/128/random?t=1617261890875?t=1617261917434',
  roles: roleDemo,
  permissions: rolePermissionDemo,
  tenantId: 'my-tenant-id',
  tenantIds: ['my-tenant-id'],
  activatedForTenant: true,
  metadata: JSON.stringify({
    phoneNumber: '+972-54123456',
    jobTitle: 'Developer',
    address: {
      city: 'Tel Aviv',
      country: 'Israel'
    }
  }),
  verified: false
};
export const userDemo = _extends({}, userProfileDemo, {
  accessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1MTY5YmY0Zi02YmI5LTQ5NGMtOGNkZS05MDc4NDQ0NWY4MDciLCJuYW1lIjoiRHVtbXkgVXNlciIsImVtYWlsIjoiZHVtbXlAZnJvbnRlZ2cuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsIm1ldGFkYXRhIjp7fSwicm9sZXMiOlsiYWRtaW4iXSwicGVybWlzc2lvbnMiOlsiZmUuKiJdLCJ0ZW5hbnRJZCI6Im15LXRlbmFudC1pZCIsInRlbmFudElkcyI6WyJteS10ZW5hbnQtaWQiXSwicHJvZmlsZVBpY3R1cmVVcmwiOiJodHRwczovL3d3dy5ncmF2YXRhci5jb20vYXZhdGFyLzQyYjJhZDJiYWQ2ZmM5YjlkYjUwODZkZmNmODA3MmFjP2Q9aHR0cHM6Ly91aS1hdmF0YXJzLmNvbS9hcGkvZmUvMTI4L3JhbmRvbT90PTE2MTcyNjE4OTA4NzU_dD0xNjE3MjYxOTE3NDM0IiwidHlwZSI6InVzZXJUb2tlbiIsImlhdCI6MTYxNzkwNjMyNCwiZXhwIjoxNjE3OTkyNzI0LCJpc3MiOiJmcm9udGVnZyJ9.paaXLkpWEWbQmUtoK2P8IwXCxK4WJp7XhXonvzF8g1I',
  expiresIn: 86400,
  mfaRequired: false,
  refreshToken: 'refresh-token-dummy-de39dc9c-9d22-4852-b7f5-c3c0aa613b58',
  type: 'userToken',
  iat: 1617906324,
  exp: 1617992724,
  iss: 'frontegg',
  email_verified: true
});
export const profileStateDemo = {
  loading: false,
  error: undefined,
  saving: true,
  profile: userProfileDemo
};
export const policyDemo = {
  id: 'id',
  enforceMFAType: 'DontForce',
  createdAt: new Date(),
  updatedAt: new Date(),
  allowOverrideEnforcePasswordHistory: true,
  allowOverridePasswordComplexity: false,
  allowOverridePasswordExpiration: false,
  allowSignups: true,
  apiTokensEnabled: true,
  cookieSameSite: 'cookieSameSite',
  defaultRefreshTokenExpiration: 0,
  defaultTokenExpiration: 1,
  publicKey: 'publicKey'
};
export const publicSecurityPolicy = {
  allowSignups: true,
  allowNotVerifiedUsersLogin: false,
  apiTokensEnabled: true,
  forcePermissions: false,
  authStrategy: AuthStrategyEnum.EmailAndPassword,
  machineToMachineAuthStrategy: MachineToMachineAuthStrategy.ClientCredentials
};
export const policyMfaDemo = {
  id: 'id',
  enforceMFAType: 'DontForce',
  createdAt: new Date(),
  updatedAt: new Date()
};
export const policyLockoutDemo = {
  id: 'id',
  enabled: true,
  maxAttempts: 1,
  createdAt: new Date(),
  updatedAt: new Date()
};
export const policyPasswordHistoryDemo = {
  id: 'id',
  enabled: true,
  historySize: 1,
  createdAt: new Date(),
  updatedAt: new Date()
};
export const samlConfigurationDemo = {
  enabled: true,
  domain: 'domain',
  validated: true,
  generatedVerification: 'generatedVerification',
  ssoEndpoint: 'ssoEndpoint',
  publicCertificate: 'publicCertificate',
  signRequest: true,
  createdAt: new Date(),
  updatedAt: new Date(),
  acsUrl: undefined,
  spEntityId: undefined,
  oidcClientId: undefined,
  oidcSecret: undefined,
  type: undefined
};
export const samlMetadataDemo = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  properties: [],
  vendorId: 'vendorId',
  entityName: 'saml',
  isActive: true,
  configuration: {
    acsUrl: 'acsUrl',
    spEntityId: 'spEntityId'
  }
};
export const rolesDemo = [{
  id: 'id',
  description: 'description',
  key: 'key',
  name: 'name',
  permissions: [],
  permissionLevel: 1
}];
export const ssoStateDemo = {
  firstLoad: false,
  loading: false,
  error: undefined,
  saving: false,
  samlConfiguration: samlConfigurationDemo,
  roles: rolesDemo,
  authorizationRoles: [],
  errors: {},
  loaders: {},
  ssoConfigurations: []
};
export const permissionsDemo = [{
  description: 'string',
  fePermission: true,
  id: 'id',
  key: 'key',
  name: 'name',
  roleIds: [],
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: 'categoryId'
}];
export const userTeamDemo2 = {
  id: 'id2',
  email: 'demo-user2@frontegg.com',
  name: 'Demo User Name2',
  phone: undefined,
  profileImage: undefined,
  profileImageUrl: undefined,
  tenantId: 'tenantId',
  vendorId: 'vendorId',
  roleIds: [],
  activatedForTenant: true,
  verified: false,
  createdAt: new Date().toISOString(),
  customData: undefined,
  lastLogin: undefined,
  mfaEnabled: undefined,
  roles: [rolesAdminViewerDemo[0]],
  groups: [{
    id: 'id2',
    name: 'Developers',
    color: '#03A9F4',
    roles: [rolesAdminViewerDemo[0]],
    managedBy: GroupManagedByEnum.FRONTEGG
  }]
}; // TODO add verified to ITeamUser

export const userTeamDemo2V3 = {
  id: userTeamDemo2.id,
  email: userTeamDemo2.email,
  name: userTeamDemo2.name,
  phoneNumber: undefined,
  profileImage: undefined,
  tenantId: 'tenantId',
  activatedForTenant: true,
  createdAt: new Date(2022, 4, 1),
  verified: true,
  lastLogin: new Date(2022, 5, 25),
  mfaEnrolled: false,
  roles: [rolesAdminViewerDemo[1]],
  tenantIds: [],
  metadata: '',
  sub: '',
  tenants: []
};
export const userTeamDemo = {
  id: 'id',
  email: 'demo-user@frontegg.com',
  name: 'Demo User Name',
  phone: undefined,
  profileImage: undefined,
  profileImageUrl: undefined,
  tenantId: 'tenantId',
  vendorId: 'vendorId',
  roleIds: [],
  activatedForTenant: true,
  createdAt: new Date(2022, 4, 1).toISOString(),
  verified: true,
  customData: undefined,
  lastLogin: new Date(2022, 5, 25).toISOString(),
  mfaEnabled: undefined,
  roles: [rolesAdminViewerDemo[1]],
  groups: [{
    id: 'id1',
    name: 'Designers',
    color: '#E1F5E2',
    roles: [rolesAdminViewerDemo[0]],
    managedBy: GroupManagedByEnum.FRONTEGG
  }]
};
export const userTeamDemoV3 = {
  id: userTeamDemo.id,
  email: userTeamDemo.email,
  name: userTeamDemo.name,
  phoneNumber: undefined,
  profileImage: undefined,
  tenantId: 'tenantId',
  activatedForTenant: true,
  createdAt: new Date(2022, 4, 1),
  verified: true,
  lastLogin: new Date(2022, 5, 25),
  mfaEnrolled: false,
  roles: [rolesAdminViewerDemo[1]],
  tenantIds: [],
  metadata: '',
  sub: '',
  tenants: []
};
export const userSubTenantDemo = {
  metadata: '',
  mfaEnrolled: false,
  provider: '',
  sub: '',
  tenantIds: [],
  tenants: [],
  verified: false,
  id: 'id',
  email: 'email',
  name: 'name',
  tenantId: 'tenantId',
  createdAt: new Date()
};
export const usersDemo = [userTeamDemo, userTeamDemo2];
export const usersDemoV3 = [userTeamDemoV3, userTeamDemo2V3];
export const allUsersDemo = [userSubTenantDemo];
export const tenantsDemo = [{
  id: 'my-tenant-id',
  isReseller: false,
  name: 'Frontegg demo',
  deletedAt: null,
  metadata: undefined,
  tenantId: 'my-tenant-id',
  vendorId: 'vendorId',
  createdAt: new Date(),
  updatedAt: new Date(),
  address: undefined,
  timezone: undefined,
  dateFormat: undefined,
  timeFormat: undefined,
  currency: undefined,
  logo: undefined
}];
export const sessionsMock = [{
  id: `laptop`,
  userAgent: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.0 Safari/605.1.15',
  createdAt: new Date(),
  ipAddress: '190.194.88.251',
  current: true
}, {
  id: `iphone`,
  userAgent: 'Mozilla/5.0 (iPhone; CPU iPhone OS 15_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.2 Mobile/15E148 Safari/604.1',
  createdAt: new Date(Date.now() - 10000),
  ipAddress: '191.49.203.21'
}, {
  id: `tablet`,
  userAgent: 'Mozilla/5.0 (Linux; Android 6.0.1; SGP771 Build/32.2.A.0.253; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/52.0.2743.98 Safari/537.36',
  createdAt: new Date(Date.now() - 30000),
  ipAddress: '130.19.196.136'
}, {
  id: `anotherlaptop`,
  userAgent: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.0 Safari/605.1.15',
  createdAt: new Date(Date.now() - 1002232300),
  ipAddress: '2.228.163.92'
}];
export const sessionsConfigDummies = {
  sessionConcurrentConfiguration: {
    isActive: true,
    maxSessions: 10
  },
  sessionIdleTimeoutConfiguration: {
    isActive: true,
    timeout: 3600
  },
  sessionTimeoutConfiguration: {
    isActive: true,
    timeout: 7776000
  }
};
export const dummyIpConfig = {
  isActive: true,
  strategy: RestrictionType.ALLOW
};
export const dummyIps = [{
  updatedAt: new Date(),
  createdAt: new Date(),
  id: '1',
  ip: '255.255.255.255',
  isActive: true,
  strategy: RestrictionType.ALLOW,
  description: 'My IP'
}, {
  updatedAt: new Date(),
  createdAt: new Date(),
  id: '2',
  ip: '255.255.255.254',
  isActive: true,
  strategy: RestrictionType.ALLOW,
  description: 'IP 2'
}, {
  updatedAt: new Date(),
  createdAt: new Date(),
  id: '3',
  ip: '255.255.255.253',
  isActive: true,
  strategy: RestrictionType.BLOCK,
  description: 'IP 3'
}, {
  updatedAt: new Date(),
  createdAt: new Date(),
  id: '4',
  ip: '255.255.255.253',
  isActive: true,
  strategy: RestrictionType.BLOCK,
  description: 'IP 4'
}];
const generateGroupUsers = count => {
  const users = [];
  Array.from({
    length: count
  }).forEach((_, i) => {
    users.push(_extends({}, userTeamDemo, {
      name: 'Demo User',
      id: `id${i}`,
      profilePictureUrl: null,
      createdAt: new Date()
    }));
  });
  return users;
};
const generateRoleByName = roleName => {
  return {
    id: 'b43b2c4b-e056-4eec-8c55-d200a475bbc0' + roleName,
    key: roleName,
    name: roleName,
    description: null,
    isDefault: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    permissions: [],
    tenantId: 'tenantId',
    vendorId: 'vendorId'
  };
};
export const allGroupsDummy = [{
  id: 'id1',
  name: 'Designers',
  color: '#E1F5E2',
  description: 'The whole design team',
  users: generateGroupUsers(6),
  roles: [generateRoleByName('Editor')],
  metadata: '{"lastTermsCheck":"2022-08-16T10:31:11.270Z"}',
  managedBy: GroupManagedByEnum.FRONTEGG
}, {
  id: 'id2',
  name: 'Developers',
  color: '#03A9F4',
  description: 'All frontend and backend developers',
  users: generateGroupUsers(44),
  roles: [generateRoleByName('Editor')],
  managedBy: GroupManagedByEnum.FRONTEGG
}, {
  id: 'id3',
  name: 'Product',
  color: '#E1583E',
  description: 'PMs and team leads',
  users: generateGroupUsers(8),
  roles: [generateRoleByName('Viewer')],
  managedBy: GroupManagedByEnum.FRONTEGG
}, {
  id: 'id4',
  name: 'Management',
  color: '#9AE0FF',
  description: 'Executives',
  users: generateGroupUsers(5),
  roles: [generateRoleByName('Admin')],
  managedBy: GroupManagedByEnum.FRONTEGG
}, {
  id: 'id5',
  name: 'Sales',
  color: '#ED8E7C',
  users: generateGroupUsers(21),
  description: 'BDR, AE, and inside sales',
  roles: [generateRoleByName('Viewer')],
  managedBy: GroupManagedByEnum.FRONTEGG
}, {
  id: 'id6',
  name: 'Support',
  color: '#A79D7B',
  users: generateGroupUsers(23),
  description: 'Dev success and customer success',
  roles: [generateRoleByName('Admin')],
  managedBy: GroupManagedByEnum.FRONTEGG
}, {
  id: 'id7',
  name: 'Marketing',
  color: '#2CA744',
  users: generateGroupUsers(10),
  description: 'The marketing department',
  roles: [generateRoleByName('Viewer')],
  managedBy: GroupManagedByEnum.FRONTEGG
}, {
  id: 'id8',
  name: 'HR',
  color: '#EAE1C2',
  users: generateGroupUsers(4),
  description: 'Human resources',
  roles: [generateRoleByName('Viewer')],
  managedBy: GroupManagedByEnum.FRONTEGG
}, {
  id: 'id9',
  name: 'Finance',
  color: '#5587C0',
  users: generateGroupUsers(3),
  roles: [generateRoleByName('Viewer')],
  managedBy: GroupManagedByEnum.FRONTEGG
}, {
  id: 'id10',
  name: 'Operations',
  color: '#B1CAE7',
  users: generateGroupUsers(8),
  roles: [generateRoleByName('Editor')],
  managedBy: GroupManagedByEnum.FRONTEGG
}, {
  id: 'id11',
  name: 'Legal',
  color: '#2CA744',
  users: generateGroupUsers(4),
  roles: [generateRoleByName('Viewer')],
  managedBy: GroupManagedByEnum.FRONTEGG
}];
export const securityCenterRecommendationsMock = {
  scoring: {
    score: 79
  },
  recommendations: {
    items: [{
      code: RecommendationCode.FORCE_MFA,
      severity: RecommendationSeverity.WARNING,
      category: SecurityCategory.MFA
    }, {
      code: RecommendationCode.ENABLE_FORCE_RELOGIN,
      severity: RecommendationSeverity.WARNING,
      category: SecurityCategory.SESSIONS
    }]
  }
};
export const securityCenterInsightsMock = {
  insights: {
    items: [{
      category: SecurityCategory.MFA,
      code: InsightCode.PARTIALLY_FORCED_MFA,
      metadata: {
        totalUsers: 2,
        mfa: {
          enrolledUsersCount: 1
        }
      }
    }, {
      category: SecurityCategory.SESSIONS,
      code: InsightCode.PARTIAL_SESSION_SETTINGS,
      metadata: {
        totalUsers: 2,
        sessions: {
          idleTimeoutRecommendation: 1800
        }
      }
    }, {
      category: SecurityCategory.PASSWORD,
      code: InsightCode.IMPROVE_PASSWORD_HISTORY_POLICY,
      metadata: {
        password: {
          userLockoutRecommendation: 5,
          historyRecommendations: 5
        }
      }
    }, {
      category: SecurityCategory.DOMAIN,
      code: InsightCode.NO_IP_RESTRICTIONS,
      metadata: {
        restrictions: {
          ipsCount: 1,
          domainsCount: 1
        }
      }
    }]
  }
};
export const securityCenterBreachedPasswordUsersMock = {
  users: usersDemoV3,
  totalUsersItems: 2,
  totalUsersPages: 1,
  usersPageOffset: 0
};
export const securityCenterInactivityPasswordUsersMock = {
  users: [usersDemoV3[0]],
  totalUsersItems: 1,
  totalUsersPages: 1,
  usersPageOffset: 0,
  queryParams: {
    _maxInactiveSeconds: 7776000
  }
};
export const securityCenterUnenrolledMfaUsersMock = {
  users: [usersDemoV3[1]],
  totalUsersItems: 1,
  totalUsersPages: 1,
  usersPageOffset: 0
};
export const permissionsMock = [{
  id: '8d71b676-754c-4600-afe7-ca5add1e6809',
  key: 'fe.secure.*',
  name: 'Secure write',
  description: 'all secure access permissions',
  categoryId: '77154759-0a04-4669-b6b4-0858d3c1190e',
  fePermission: true,
  assignmentType: PermissionAssignmentTypeEnum.ASSIGNABLE,
  createdAt: new Date(),
  updatedAt: new Date()
}, {
  id: '85e1fd7b-2bb7-498f-8521-5f8e5774c01e',
  key: 'fe.account-hierarchy.write.subAccountAccess',
  name: 'Give access to sub accounts',
  description: 'Give a user access to sub account',
  assignmentType: PermissionAssignmentTypeEnum.ASSIGNABLE,
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: 'd24db1e3-002b-421d-951d-09a967dd7cd5',
  fePermission: true
}, {
  id: 'a1807695-54a2-4f95-95de-a9b246488aae',
  key: 'fe.account-hierarchy.read.subAccount',
  name: 'Read sub accounts',
  description: 'Read sub accounts',
  assignmentType: PermissionAssignmentTypeEnum.ASSIGNABLE,
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: 'd24db1e3-002b-421d-951d-09a967dd7cd5',
  fePermission: true
}, {
  id: '04dbcc11-61db-40a9-9577-9b73e50d1d03',
  key: 'fe.secure.read.*',
  name: 'Secure read',
  description: 'all secure access read permissions',
  assignmentType: PermissionAssignmentTypeEnum.ASSIGNABLE,
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: '77154759-0a04-4669-b6b4-0858d3c1190e',
  fePermission: true
}, {
  id: 'a1807695-54a2-4f95-95de-a9b246488aae',
  key: 'fe.account-hierarchy.read.subAccount',
  name: 'Read sub accounts',
  description: 'Read sub accounts',
  assignmentType: PermissionAssignmentTypeEnum.ASSIGNABLE,
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: 'd24db1e3-002b-421d-951d-09a967dd7cd5',
  fePermission: true
}, {
  id: '85e1fd7b-2bb7-498f-8521-5f8e5774c01e',
  key: 'fe.account-hierarchy.write.subAccountAccess',
  name: 'Give access to sub accounts',
  description: 'Give a user access to sub account',
  assignmentType: PermissionAssignmentTypeEnum.ASSIGNABLE,
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: 'd24db1e3-002b-421d-951d-09a967dd7cd5',
  fePermission: true
}, {
  id: 'a9c803b0-ebfa-49b9-9607-f0d3a390ee61',
  key: 'fe.account-settings.delete.account',
  name: 'Delete Account',
  description: 'Delete my account',
  assignmentType: PermissionAssignmentTypeEnum.ASSIGNABLE,
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: '42aaebe1-893d-4da5-99d7-375a4537b7a4',
  fePermission: true
}, {
  id: 'f26b00f8-ffe0-4739-b15c-7db15c27ea26',
  key: 'fe.account-settings.write.custom-login-box',
  name: 'Edit custom login settings',
  description: "Write account's custom login box styling",
  assignmentType: PermissionAssignmentTypeEnum.ASSIGNABLE,
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: '42aaebe1-893d-4da5-99d7-375a4537b7a4',
  fePermission: true
}, {
  id: 'a43e059d-d731-44b1-94bb-fb6b16c62210',
  key: 'fe.connectivity.delete.*',
  name: 'Connectivity delete',
  description: 'all connectivity delete permissions',
  assignmentType: PermissionAssignmentTypeEnum.ASSIGNABLE,
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: 'd18fccaa-e09a-4e6e-b902-cc9c615ff12d',
  fePermission: true
}, {
  id: 'f403c6b9-1abe-4ec7-9971-aa032255fc25',
  key: 'fe.connectivity.delete.slackAppRegistration',
  name: 'Delete slack application registration',
  description: 'allow user to delete slack app registration',
  assignmentType: PermissionAssignmentTypeEnum.ASSIGNABLE,
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: '62fc3fe0-f06b-4a0f-854c-cdf51353b616',
  fePermission: true
}, {
  id: 'e8c0e727-f562-449a-82c5-f7691defde02',
  key: 'fe.connectivity.read.*',
  name: 'Connectivity read',
  description: 'all connectivity read permissions',
  assignmentType: PermissionAssignmentTypeEnum.ASSIGNABLE,
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: 'd18fccaa-e09a-4e6e-b902-cc9c615ff12d',
  fePermission: true
}, {
  id: '2a5dcd6d-57a3-45c4-815f-c265609cc1f7',
  key: 'fe.connectivity.read.emailConfig',
  name: 'Read email configuration',
  description: 'read email integration configuration',
  assignmentType: PermissionAssignmentTypeEnum.ASSIGNABLE,
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: '2d7b4be7-8269-4ff4-81d6-0c92e95790d5',
  fePermission: true
}, {
  id: '4fee0eb0-5b17-450b-bea4-b9b908230bda',
  key: 'fe.connectivity.read.eventCategories',
  name: 'Read events categories',
  description: 'view connectivity events categories',
  assignmentType: PermissionAssignmentTypeEnum.ASSIGNABLE,
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: '31c7a260-55d6-43d5-a872-4f9e22982ff5',
  fePermission: true
}, {
  id: '750a96a5-8abf-4340-b91a-723c7bb8bcfd',
  key: 'fe.connectivity.read.events',
  name: 'Read events',
  description: 'view connectivity events',
  assignmentType: PermissionAssignmentTypeEnum.ASSIGNABLE,
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: '31c7a260-55d6-43d5-a872-4f9e22982ff5',
  fePermission: true
}, {
  id: 'ef2a5f1a-74c5-431f-b1a6-d4fb5026e374',
  key: 'fe.connectivity.read.slackApp',
  name: 'Read slack application',
  description: 'get slack application configuration in order to register',
  assignmentType: PermissionAssignmentTypeEnum.ASSIGNABLE,
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: '62fc3fe0-f06b-4a0f-854c-cdf51353b616',
  fePermission: true
}, {
  id: '9fc1fa37-a8b9-4931-b2b5-5de55df25289',
  key: 'fe.connectivity.read.slackChannels',
  name: 'Read slack channels',
  description: 'view registered slack workspace channels',
  assignmentType: PermissionAssignmentTypeEnum.ASSIGNABLE,
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: '62fc3fe0-f06b-4a0f-854c-cdf51353b616',
  fePermission: true
}, {
  id: '87c3df9d-0ab2-4e97-bfc8-777a68ba2543',
  key: 'fe.connectivity.read.slackSubscriptions',
  name: 'Read slack subscriptions',
  description: 'view slack events subscriptions',
  assignmentType: PermissionAssignmentTypeEnum.ASSIGNABLE,
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: '62fc3fe0-f06b-4a0f-854c-cdf51353b616',
  fePermission: true
}, {
  id: '7826470b-151f-4bab-b2c0-785e09878616',
  key: 'fe.connectivity.read.slackUsers',
  name: 'Read slack users',
  description: 'view registered slack workspace users',
  assignmentType: PermissionAssignmentTypeEnum.ASSIGNABLE,
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: '62fc3fe0-f06b-4a0f-854c-cdf51353b616',
  fePermission: true
}, {
  id: 'ee779734-a7e3-47b3-ace9-944f9bee8ad8',
  key: 'fe.connectivity.read.smsConfig',
  name: 'Read SMS configuration',
  description: 'read SMS integration configuration',
  assignmentType: PermissionAssignmentTypeEnum.ASSIGNABLE,
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: '5743fccc-b4ea-4907-bdca-7446ea088974',
  fePermission: true
}, {
  id: '54583a96-c731-4a84-9abc-1b8b827f06ae',
  key: 'fe.connectivity.read.userBellNotifications',
  name: 'Read bell notifications',
  description: 'view user bell notifications',
  assignmentType: PermissionAssignmentTypeEnum.ASSIGNABLE,
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: '18d2584b-7f03-4038-ab93-bc2b0f4511be',
  fePermission: true
}, {
  id: '2dfde8ef-7df2-4426-bb8d-33615e399a4d',
  key: 'fe.connectivity.read.webhookLogs',
  name: 'Read webhooks logs',
  description: 'view webhooks logs',
  assignmentType: PermissionAssignmentTypeEnum.ASSIGNABLE,
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: 'e74e5390-396b-429c-9544-62417912d018',
  fePermission: true
}, {
  id: '16647407-ed6f-4ca6-83a0-86225d921959',
  key: 'fe.connectivity.read.webhooks',
  name: 'Read webhooks',
  description: 'view webhooks configuration',
  assignmentType: PermissionAssignmentTypeEnum.ASSIGNABLE,
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: 'e74e5390-396b-429c-9544-62417912d018',
  fePermission: true
}];
export const permissionCategoriesMock = [{
  id: 'd9b4dbd0-476c-4708-b079-d9e6e4758971',
  name: 'test',
  description: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  permissionIds: [],
  feCategory: false
}, {
  id: '2b3f1746-1135-4b8d-aff3-5697cffc4482',
  name: 'SAML',
  description: 'Secure access SAML',
  createdAt: new Date(),
  updatedAt: new Date(),
  permissionIds: ['8ea45a49-c403-4551-9da9-77f5da44f130', '8d94c261-1f7d-46bd-88a8-511e9f39273b', '7391ac41-b076-4386-8a14-7c2d798d3335', '6d8f5a1f-b3cd-421a-ba85-7784bdd97dc2', '3c989065-35e1-4198-8c9c-c94084222794'],
  feCategory: true
}, {
  id: '2c2281da-cf81-4943-9605-42969677e9fe',
  name: 'Security Policies',
  description: 'Secure access security policies',
  createdAt: new Date(),
  updatedAt: new Date(),
  permissionIds: ['e5642338-b265-4ce5-94e4-8c21490033ad', 'daddb9e9-a836-4901-b175-2d1182dab6f5', 'bd4242ce-e84f-4387-9c41-c90e7d1fc6fa', 'ba33b4c1-47f0-4aa2-9e3a-fcf96229d355', 'ade4558f-5438-4383-b42b-728e8a745cc8', 'ad064258-2178-48f8-845c-ac1ce0217be8', 'acefb7fd-11c7-4a43-9544-b4fb461db94c', '909b6a0a-4efc-4464-90ac-b8b9a8f22fc2', '8a03c1e7-3991-4cca-8726-88c339766144', '860eb891-b9eb-44ba-94c1-51cfc2be9dd9', '719e09ae-5513-4cc3-bce1-97503a0a7658', '6fcd99e7-2206-4c45-86aa-411a87b7fcac', '235592cc-47c7-49cc-82e4-129f5333cc45', '2214404d-3140-44bf-9c9d-438fa56b5a50'],
  feCategory: true
}, {
  id: '2d7b4be7-8269-4ff4-81d6-0c92e95790d5',
  name: 'Email integration',
  description: 'Connectivity email integration',
  createdAt: new Date(),
  updatedAt: new Date(),
  permissionIds: ['9a0bb3db-8bdb-4278-8f18-9c484bcd2f8c', '440f5616-ad85-43d1-b25d-1fd7930c6d26', '2a5dcd6d-57a3-45c4-815f-c265609cc1f7'],
  feCategory: true
}, {
  id: '31c7a260-55d6-43d5-a872-4f9e22982ff5',
  name: 'Events',
  description: 'Connectivity Events',
  createdAt: new Date(),
  updatedAt: new Date(),
  permissionIds: ['85c90d0f-3bb0-40c0-914a-640c7ca9eea5', '750a96a5-8abf-4340-b91a-723c7bb8bcfd', '4fee0eb0-5b17-450b-bea4-b9b908230bda'],
  feCategory: true
}, {
  id: '42aaebe1-893d-4da5-99d7-375a4537b7a4',
  name: 'Account settings',
  description: 'Account profile settings',
  createdAt: new Date(),
  updatedAt: new Date(),
  permissionIds: ['f26b00f8-ffe0-4739-b15c-7db15c27ea26', 'eb11313d-c7bb-43c0-a85d-c79bedab46b1', 'a9c803b0-ebfa-49b9-9607-f0d3a390ee61', '634d3fab-b470-4a92-a25b-245459ddd6db'],
  feCategory: true
}, {
  id: '62fc3fe0-f06b-4a0f-854c-cdf51353b616',
  name: 'Slack integration',
  description: 'Connectivity Slack integration',
  createdAt: new Date(),
  updatedAt: new Date(),
  permissionIds: ['f403c6b9-1abe-4ec7-9971-aa032255fc25', 'ef2a5f1a-74c5-431f-b1a6-d4fb5026e374', 'e3231a61-e425-4ac1-aa87-654ab995af5c', '9fc1fa37-a8b9-4931-b2b5-5de55df25289', '87c3df9d-0ab2-4e97-bfc8-777a68ba2543', '7993a558-0af5-4d77-b9fc-8e8114f0ce0b', '7826470b-151f-4bab-b2c0-785e09878616', '5b0323d1-a0a0-4d4f-9bb8-b1634d9afa94', '4245736e-13b0-48fd-9a08-bc93809dcdaf', '2fccccb1-36f5-4c5c-8d93-73ab15d0b2d9'],
  feCategory: true
}, {
  id: '6b925df5-8b09-470a-9fcc-dae0beae1af4',
  name: 'Audits',
  description: 'Audit Logs',
  createdAt: new Date(),
  updatedAt: new Date(),
  permissionIds: ['97085bb5-bec7-49eb-9744-4d35bbb83ad3'],
  feCategory: true
}, {
  id: '77154759-0a04-4669-b6b4-0858d3c1190e',
  name: 'Secure access',
  description: 'Secure access general',
  createdAt: new Date(),
  updatedAt: new Date(),
  permissionIds: ['925e0c16-aead-41e6-b62f-8ddb2ea4ed06', '8d71b676-754c-4600-afe7-ca5add1e6809', '1c81d4ca-47a2-4564-b9d9-9d346b8cdbe7', '04dbcc11-61db-40a9-9577-9b73e50d1d03'],
  feCategory: true
}, {
  id: '916d6db5-489e-4109-95cf-57dee7802c57',
  name: 'Webpush integration',
  description: 'Webpush notifications integration',
  createdAt: new Date(),
  updatedAt: new Date(),
  permissionIds: ['e5a7bbd3-f76b-4559-979d-885c5c75f72f', 'cb47ea6f-0a59-4694-97d0-9c32f6732e85'],
  feCategory: true
}, {
  id: '9707ef7f-afd3-4d1f-962e-68c0d876db53',
  name: 'Subscriptions',
  description: 'Subscriptions access general',
  createdAt: new Date(),
  updatedAt: new Date(),
  permissionIds: ['ce03de78-a7f4-4498-acce-df4aae30ed0d', 'ae81dd56-2f94-4da8-9c16-b6c781b0dcb7', '72e55675-ea98-4f1a-85f0-09319cdcbcbf'],
  feCategory: true
}, {
  id: 'a71e2e14-0cdb-42df-b297-d00af2d4e62e',
  name: 'Users management',
  description: 'Secure access users management',
  createdAt: new Date(),
  updatedAt: new Date(),
  permissionIds: ['e7263c99-874f-4e17-8d63-25ae3d9f5739', 'db957d5f-4048-4011-bcb4-5d21af0560e4', 'c52b0a96-dcff-4fba-aa8e-ce3a7336bde2', 'bc754ce1-ebbe-446a-bb84-4f287be0651c', '998756c3-773a-4771-b7a5-510e795fb5f4', '88565631-dc77-472c-b1d9-18674483c24b', '6b84ddeb-a242-48c5-bf2a-62105315518a', '61874f64-2204-4eec-8f02-0d38bd5a66b8', '4c47caae-d384-48bb-99bc-554aafa2888f', '43695880-d40e-412d-b342-434d58c5c8ca', '4003ac5b-326c-4dc2-afb1-8900e16ee443', '007c612d-5184-4f04-846e-d49c6591de0c'],
  feCategory: true
}, {
  id: 'c058e114-917c-4ad1-940e-11e9cc40b638',
  name: 'Roles and permissions',
  description: 'Secure access roles and permissions',
  createdAt: new Date(),
  updatedAt: new Date(),
  permissionIds: ['c89960c8-6d67-44fa-a3aa-1a6826f45b0d', '93be3a9a-98a2-48b8-a146-a223e457c904', '80e49f9a-7628-4efc-913b-5f80ca92f106', '7f2576d6-cbbb-4c7b-bb70-d75a88d9ce47', '6a337814-34e5-4c24-a56f-d462c1db218b'],
  feCategory: true
}, {
  id: 'c38e9f59-ff65-4f9f-a31d-0bbfc44df347',
  name: 'Groups',
  description: 'Secure access groups',
  createdAt: new Date(),
  updatedAt: new Date(),
  permissionIds: ['e1907e68-877d-4f2d-a266-d54e143c3c0c', '7a17405a-6c4b-4053-9843-f9d5e3af9e00', '5ee7bb2d-aad4-497f-94d2-9e255c470882', '4c406972-c815-46a9-8f47-9708adc334b8', '399bff45-aa24-49ae-9357-5f05a5eef3a5', '0037b3ad-5f77-4157-aa33-33c92b5fe057'],
  feCategory: true
}, {
  id: 'd18fccaa-e09a-4e6e-b902-cc9c615ff12d',
  name: 'Connectivity',
  description: 'Connectivity general',
  createdAt: new Date(),
  updatedAt: new Date(),
  permissionIds: ['e8c0e727-f562-449a-82c5-f7691defde02', 'c88bf74a-66a4-48c2-bc1b-2e2aa057858d', 'a43e059d-d731-44b1-94bb-fb6b16c62210', '3f118e4c-badd-45d1-8056-3f3428be92ca'],
  feCategory: true
}, {
  id: 'd24db1e3-002b-421d-951d-09a967dd7cd5',
  name: 'Account Hierarchy',
  description: 'Account hierarchy',
  createdAt: new Date(),
  updatedAt: new Date(),
  permissionIds: ['b6e66e0f-c6d2-4113-865a-57d08b1dec45', 'a1807695-54a2-4f95-95de-a9b246488aae', '85e1fd7b-2bb7-498f-8521-5f8e5774c01e', '60cfedb4-64ca-40d1-8b3a-4288bcde1209'],
  feCategory: true
}, {
  id: 'e74e5390-396b-429c-9544-62417912d018',
  name: 'Webhooks integration',
  description: 'Connectivity webhooks integration',
  createdAt: new Date(),
  updatedAt: new Date(),
  permissionIds: ['996e42ed-b1d8-4e36-aa76-58091c56bdf7', '8e7014d4-13fa-439a-a313-a2b9141f8b47', '2dfde8ef-7df2-4426-bb8d-33615e399a4d', '16647407-ed6f-4ca6-83a0-86225d921959'],
  feCategory: true
}, {
  id: 'e8af2833-1bbf-4ccf-8658-f6e0c60621b9',
  name: 'API tokens',
  description: 'Secure access API tokens',
  createdAt: new Date(),
  updatedAt: new Date(),
  permissionIds: ['f03dc571-0769-4bcf-9c51-776b81582a14', 'c395932b-c732-41ad-bec6-ced7602bf79e', '94351961-e9c8-4c63-8818-ae8481ceab22', '5a744287-d01e-4a7a-8a37-2ce212ce5ff5', '0cf97f9f-84dc-4dfc-bf41-2ad4b6c072e9', '0c8cdac0-b364-4ab6-9737-7aa43f944013'],
  feCategory: true
}];