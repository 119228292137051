<div class="batch-settings">
  <span class="batch-settings-title">
    {{ 'batchSettings' | translate }}
  </span>
  <form
    class="batch-form"
    [formGroup]="batchFormGroup"
  >
    <label>
      {{ 'batchId' | translate }}
      <input
        type="text"
        [placeholder]="'batchId' | translate"
        formControlName="batchId"
      />
    </label>
    <label>
      {{ 'batchName' | translate }}
      <input
        type="text"
        [placeholder]="'batchName' | translate"
        formControlName="name"
      />
    </label>
    <label>
      {{ 'department' | translate }}
      <div class="select-container">
        <select formControlName="department">
          <option
            *ngFor="let department of departments"
            [value]="department.code"
          >
            {{ department.name }}
          </option>
        </select>
      </div>
    </label>
    <label htmlFor="defaultArea">
      {{ 'defaultArea' | translate }}
      <div class="select-container">
        <select formControlName="defaultArea">
          <option
            *ngFor="let area of areas"
            [value]="area.code"
          >
            {{ area.name }}
          </option>
        </select>
      </div>
    </label>
    <label>
      {{ 'defaultDocumentType' | translate }}
      <div class="select-container">
        <select formControlName="defaultDocumentType">
          <option
            *ngFor="let documentType of filteredDocumentTypes"
            [value]="documentType.code"
          >
            {{ documentType.name }}
          </option>
        </select>
      </div>
    </label>
  </form>
</div>
