import { createProxy } from '../../../toolkit/proxy';
export const initialState = {
  globalPolicy: {
    loading: true
  },
  publicPolicy: {
    loading: false
  },
  vendorMfaPolicy: {
    loading: true
  },
  mfaPolicy: {
    loading: true
  },
  vendorLockoutPolicy: {
    loading: true
  },
  lockoutPolicy: {
    loading: true
  },
  captchaPolicy: {
    loading: true
  },
  passwordHistoryPolicy: {
    loading: true
  },
  passwordPolicy: {
    loading: true
  },
  vendorPasswordHistoryPolicy: {
    loading: true
  },
  publicAuthStrategyPolicy: {
    loading: true
  },
  passwordRotationPolicy: {
    loading: true
  }
};
export default (overrideState => createProxy(initialState, overrideState));