import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddInComponent } from './pages/add-in/add-in.component';
import { PrinterComponent } from './pages/printer/printer.component';
import { WebCaptureComponent } from './pages/web-capture/web-capture.component';
import { OpenBatchComponent } from './pages/web-capture/open-batch/open-batch.component';
import { WebCaptureUploadComponent } from './pages/web-capture/web-capture-upload/web-capture-upload.component';
import { WebCaptureBatchingComponent } from './pages/web-capture/web-capture-batching/web-capture-batching.component';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { BatchCreationComponent } from './components/batch-creation/batch-creation.component';
import { WebCatpureBatchCreationComponent } from './pages/web-capture/web-capture-batch-creation/web-capture-batch-creation.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/webcapture',
    pathMatch: 'full'
  },
  {
    path: 'outlookaddin',
    component: AddInComponent,
    data: { title: 'Softdocs for Outlook' }
  },
  {
    path: 'printer',
    component: PrinterComponent,
    data: { title: 'Softdocs Virtual Printer' }
  },
  {
    path: 'webcapture',
    component: WebCaptureComponent,
    data: { title: 'Softdocs Web Capture' },
    canDeactivate: [CanDeactivateGuard],
    children: [
      {
        path: '',
        component: WebCaptureUploadComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Softdocs Web Capture' }
      },
      {
        path: 'batches/new',
        component: WebCatpureBatchCreationComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Softdocs Web Capture' }
      },
      {
        path: 'batches',
        component: WebCaptureBatchingComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Softdocs Web Capture' }
      },
      {
        path: 'batches/:batchId',
        component: OpenBatchComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Softdocs Web Capture' }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
