// External Libraries
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, catchError, combineLatest, of, Subscription, switchMap } from 'rxjs';
import { MessageService } from 'primeng/api';

// Frontegg Services
import { ContextHolder, FronteggAppService, FronteggAuthService } from '@frontegg/angular';

// Project Services
import { AuthService } from '../../services/auth/auth.service';
import {
  ContentApiService,
  Area,
  DocumentType,
  KeyField as DocumentTypeKeyField
} from '../../services/content-api/content-api.service';
import { TenantApiService } from '../../services/tenant-api/tenant-api.service';
import { FeatureFlagService } from '../../services/feature-flag/feature-flag.service';

// Project Models
import { Attachment } from '../../models/attachment';
import { MappingKeyField, MapResponse } from '../../models/docTypeMapping';
import { ErrorResponse } from '../../models/errorResponse';
import { ExtractionResponse } from '../../models/extractionResponse';
import { KeyField } from '../../models/statusResponse';
import { FileSelectorAttachment } from '../../components/file-selector/file-selector.component';

// Project Components
import { KeyFieldsComponent } from '../../components/key-fields/key-fields.component';

// Utilities
import { base64ToFile, buildKeyFields } from '../../utils/documentUtils';

// Icons
import {
  faFileCircleCheck,
  faCircleCheck,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';

// Environment Variables
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

declare const Softdocs: any;
declare const pendo: any;

@Component({
  selector: 'app-add-in',
  templateUrl: './add-in.component.html',
  styleUrl: './add-in.component.scss'
})
export class AddInComponent implements OnInit, OnDestroy {
  @ViewChild(KeyFieldsComponent) keyFieldsComponent!: KeyFieldsComponent;

  private activeKeys: Set<string> = new Set();
  private subscription: Subscription = new Subscription();

  autoclassifierSdk = new Softdocs.DocumentAnalysisSdk(environment.urls.autoclassifierApi);
  docTypeMappingSdk = new Softdocs.DocTypeMappingSdk(environment.urls.autoclassifierApi);

  attachments$: BehaviorSubject<Attachment[]> = new BehaviorSubject<Attachment[]>([]);
  autoclassificationFlag$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  faFileCircleCheck = faFileCircleCheck;
  faCircleCheck = faCircleCheck;
  faExclamationCircle = faExclamationCircle;

  isLoading = true;
  loadingSubscription: Subscription;
  user?: any;
  documentForm!: FormGroup;
  attachments: Attachment[] = [];
  tenantId: string = '';
  documentTypes: DocumentType[] = [];
  areas: Area[] = [];
  filteredDocumentTypes: DocumentType[] = [];
  currentDocumentType: DocumentType | undefined;
  formStates: Map<number, any> = new Map();
  currentAttachmentIndex: number = 0;
  isUrlVerified: boolean = false;
  isUnsupportedFile: boolean = false;
  isAutoClassificationEnabled: boolean = false;
  outlookAddInAccessEnabled: boolean | null = null;
  currentMostConfidentDocument: any;
  showClassificationResults: boolean = false;
  isAccessFlagLoading: boolean = true;
  isAutoclassificationFlagLoading: boolean = true;
  featureFlagErrorText: string = '';

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private contentService: ContentApiService,
    private tenantService: TenantApiService,
    private messageService: MessageService,
    private featureFlagService: FeatureFlagService,
    private fronteggAuthService: FronteggAuthService,
    private fronteggAppService: FronteggAppService,
    private spinner: NgxSpinnerService
  ) {
    this.loadingSubscription = fronteggAppService.isLoading$.subscribe(
      isLoading => (this.isLoading = isLoading)
    );
  }

  ngOnInit(): void {
    this.checkFeatureFlag();

    this.subscription.add(
      combineLatest([this.attachments$, this.autoclassificationFlag$]).subscribe(
        ([attachments, autoclassificationEnabled]) => {
          if (autoclassificationEnabled && attachments.length > 0) {
            this.analyzeAttachments();
          }
        }
      )
    );
  }

  /**
   * Run the feature flag check for the provided tenantId
   * @param tenantId the provided tenantId
   */
  runFeatureFlagCheck(tenantId: string): void {
    this.isAccessFlagLoading = true;
    this.featureFlagService
      .initialize({ tenantId })
      .then(() =>
        this.subscription.add(
          this.featureFlagService
            .getFlag('outlook-add-in-access', false)
            .pipe(
              switchMap(flagValue => {
                this.isAccessFlagLoading = false;
                if (flagValue) {
                  this.outlookAddInAccessEnabled = true;
                  pendo.initialize({ account: { id: tenantId } });
                  this.startAddIn();
                } else {
                  this.isUrlVerified = false;
                  this.outlookAddInAccessEnabled = false;
                  this.featureFlagErrorText = 'addInFeatureFlagError';
                }
                return of(null);
              })
            )
            .subscribe()
        )
      )
      .catch(() => {
        this.isAccessFlagLoading = false;
        this.isUrlVerified = false;
        this.outlookAddInAccessEnabled = false;
      });
  }

  /**
   * Re-verifies the tenant URL and triggers feature flag check based on the tenant ID
   */
  onSuccessfulVerification(): void {
    const tenantId = localStorage.getItem('tenantId');
    if (tenantId) {
      this.isAccessFlagLoading = true;
      this.runFeatureFlagCheck(tenantId);
    }
  }

  /**
   * Check the feature flag based on tenantId from localStorage.
   */
  checkFeatureFlag(): void {
    const tenantId = localStorage.getItem('tenantId');

    if (tenantId) {
      this.runFeatureFlagCheck(tenantId);
    } else {
      this.isAccessFlagLoading = false;
      this.isUrlVerified = false;
      this.outlookAddInAccessEnabled = null;
    }
  }

  /**
   * Starts the add-in by initializing the document form, loading data, and setting up communication with Outlook
   */
  startAddIn() {
    this.isUrlVerified = true;

    const organization = localStorage.getItem('vanityName');
    this.tenantId = localStorage.getItem('tenantId') || '';

    if (this.tenantId && organization) {
      this.subscription.add(
        this.fronteggAuthService.authState$.subscribe(authState => {
          if (!authState.isAuthenticated && !this.isLoading) {
            this.loginWithRedirect(this.tenantId, organization);
          } else {
            if (authState.user?.accessToken) {
              this.user = authState.user;
              this.authService.setAuthToken(this.user?.accessToken);

              this.initializeCommunication();
              this.loadData();
            }
          }
        })
      );

      this.initializeDocumentForm();
    } else {
      this.isUrlVerified = false;
    }
  }

  /**
   * Redirects the user to the login page with the specified tenant id and organization
   * @param tenantId - the id of the tenant to log in to
   * @param organization - the organization name associated with the tenant
   */
  loginWithRedirect(tenantId: string, organization: string): void {
    window.localStorage.setItem('FRONTEGG_AFTER_AUTH_REDIRECT_URL', window.location.href);
    this.fronteggAuthService.loginWithRedirect({
      tenantId: tenantId,
      organization: organization
    });
  }

  /**
   * Initializes communication between the add-in and Outlook
   */
  initializeCommunication(): void {
    // Signal to Outlook that dialog is ready to receive messages
    Office.context.ui.messageParent(JSON.stringify({ status: 'ready' }));

    // Add event handler to process messages received from Outlook
    Office.context.ui.addHandlerAsync(
      Office.EventType.DialogParentMessageReceived,
      this.processMessageFromParent.bind(this)
    );
  }

  /**
   * Initializes data to be used in the dialog, including tenant information, document types, and areas
   */
  loadData(): void {
    this.featureFlagService.identify({
      key: this.user.customClaims.principal_id,
      name: this.user.name,
      tenantId: this.tenantId
    });

    this.subscription.add(
      this.featureFlagService
        .getFlag('autoclassification-for-outlook-add-in', false)
        .pipe(
          switchMap(flagValue => {
            this.isAutoclassificationFlagLoading = false;
            this.isAutoClassificationEnabled = flagValue as boolean;

            console.log(
              'autoclassification-for-outlook-add-in flag value:',
              this.isAutoClassificationEnabled
            );

            this.autoclassificationFlag$.next(this.isAutoClassificationEnabled);

            return this.tenantService.getTenantUrls(this.tenantId);
          }),
          switchMap(tenantUrls => {
            this.contentService.setBaseUrl(tenantUrls.contentApiUrl);
            return this.contentService.getDocumentTypes();
          }),
          switchMap(docTypes => {
            this.documentTypes = docTypes;
            return this.contentService.getAreas();
          }),
          catchError(error => {
            console.error('Error in chained requests', error);
            this.showErrorToast('dataError');
            return of([]); // Return an observable to keep the stream alive
          })
        )
        .subscribe(areas => {
          this.areas = areas;
        })
    );
  }

  /**
   * Initializes the document form
   */
  initializeDocumentForm() {
    this.documentForm = this.fb.group({
      area: [''],
      documentTypeCode: ['']
    });
  }

  /**
   * Event listener to handle changes to an attachment's classification request
   * @param event - The documentStatusUpdate event
   */
  @HostListener('window:documentStatusUpdate', ['$event'])
  handleDocumentStatusUpdate(event: CustomEvent) {
    const index = this.attachments.findIndex(
      attachment => attachment.requestId === event.detail.requestId
    );
    if (index !== -1 && !this.attachments[index].status) {
      this.attachments[index].status = event.detail.status;
      if (
        index === this.currentAttachmentIndex &&
        this.attachments[this.currentAttachmentIndex].classificationStarted
      ) {
        this.buildFormFields();
      }
    }
  }

  /**
   * Event listener to handle errors in a document's classification request
   * @param {CustomEvent} event - The documentStatusError event
   */
  @HostListener('window:documentStatusError', ['$event'])
  handleDocumentStatusError(event: CustomEvent) {
    const { requestId, errorCode } = event.detail;
    const index = this.attachments.findIndex(doc => doc.requestId === requestId);

    if (index !== -1) {
      const doc = this.attachments[index];
      doc.classificationStarted = true;

      let errorMessage;
      switch (errorCode) {
        case 'documentInvalid':
          errorMessage = 'attachmentInvalidError';
          break;
        default:
          errorMessage = 'analyzeError';
          break;
      }

      this.showErrorToast(errorMessage, doc.name);
      this.cancelFileAutoClassification(index);

      if (
        index === this.currentAttachmentIndex &&
        this.attachments[this.currentAttachmentIndex].classificationStarted
      ) {
        this.buildFormFields();
      }
    }
  }

  /**
   * Handles the keydown event for both macOS and Windows platforms
   * @param {KeyboardEvent} event - The keydown event
   */
  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.activeKeys.add(event.key.toLowerCase());

    const isMac = this.isMacPlatform();

    // CMD + SHIFT + A + I
    const isMacShortcutPressed =
      isMac &&
      event.metaKey &&
      event.shiftKey &&
      this.activeKeys.has('a') &&
      this.activeKeys.has('i');

    // CTRL + SHIFT + A + I
    const isWindowsShortcutPressed =
      !isMac &&
      event.ctrlKey &&
      event.shiftKey &&
      this.activeKeys.has('a') &&
      this.activeKeys.has('i');

    if (isMacShortcutPressed || isWindowsShortcutPressed) {
      event.preventDefault();
      this.openDialog();
      this.activeKeys.clear();
    }
  }

  /**
   * Handles the keyup event by removing the key from the activeKeys set
   * @param {KeyboardEvent} event - The keyup event
   */
  @HostListener('window:keyup', ['$event'])
  onKeyUp(e: KeyboardEvent) {
    this.activeKeys.delete(e.key.toLowerCase());
  }

  /**
   * Checks if the platform is macOS
   * @returns {boolean} - Returns true if the platform is macOS, otherwise false
   */
  isMacPlatform(): boolean {
    // Use navigator.userAgentData if available (future-proof)
    if ((navigator as any).userAgentData) {
      return (navigator as any).userAgentData.platform === 'macOS';
    }

    // Fallback to user agent string check if userAgentData is not available
    return /Mac|iPhone|iPod|iPad/.test(navigator.userAgent);
  }

  /**
   * Opens the classification results dialog.
   */
  openDialog() {
    if (!this.showClassificationResults) {
      this.currentMostConfidentDocument = this.getMostConfidentDocument(
        this.attachments[this.currentAttachmentIndex]
      );
    } else {
      this.currentMostConfidentDocument = null;
    }
    this.showClassificationResults = !this.showClassificationResults;
  }

  /**
   * Closes the classification results dialog.
   */
  closeClassificationResults(): void {
    this.showClassificationResults = false;
  }

  /**
   * Handles changes to the area and document type fields
   * @param event - The change event
   */
  formOnChange(event: any): void {
    const target = event.target;

    if (target && target.id === 'areaSelector') {
      const selectedArea = target.value;
      this.documentForm.controls['area'].setValue(selectedArea);

      this.filterDocumentTypes(selectedArea);

      const foundType = this.filteredDocumentTypes.find(
        type => type.code === this.currentDocumentType?.code
      );

      if (!foundType) {
        this.initializeFormControls(selectedArea, '');
        this.currentDocumentType = undefined;
      }
    }

    if (target && target.id === 'documentTypeSelector') {
      this.formStates.set(this.currentAttachmentIndex, this.documentForm.value);

      const selectedTypeCode = target.value;
      this.currentDocumentType = this.filteredDocumentTypes.find(
        type => type.code === selectedTypeCode
      );

      if (this.currentDocumentType) {
        const currentAttachment = this.attachments[this.currentAttachmentIndex];
        const savedMapItems = currentAttachment.mappedItems;

        if (savedMapItems) {
          const mapItem = savedMapItems.find(item => item.docTypeCode === selectedTypeCode);

          if (mapItem) {
            const controlsAsFormControl = this.documentForm.controls as {
              [key: string]: FormControl;
            };

            this.populateFormControls(controlsAsFormControl, selectedTypeCode, mapItem.keyFields);

            this.handleLookupFields(mapItem.keyFields);
          } else {
            this.buildFormFields();
          }
        } else {
          this.buildFormFields();
        }
      } else {
        this.currentDocumentType = undefined;
        this.documentForm.controls['documentTypeCode'].reset();
      }
    }
  }

  /**
   * Processes a message received from the parent (Outlook)
   * @param arg - The message received from the parent
   */
  processMessageFromParent(arg: any): void {
    const data = JSON.parse(arg.message);
    const attachments = JSON.parse(data.message) as Attachment[];

    attachments.forEach(attachment => {
      attachment.classificationStarted = true;
      this.spinner.show('fileClassificationSpinner-' + attachment.name);
    });

    this.attachments = attachments;

    this.attachments$.next(attachments);
  }

  /**
   * Filters the document types list by the currently selected area
   * @param areaCode - The area that the document types should be filtered by
   */
  filterDocumentTypes(areaCode: string): void {
    this.filteredDocumentTypes = this.documentTypes.filter(type => type.areaCode === areaCode);
  }

  /**
   * Builds and prepopulates form fields for the currently selected attachment
   */
  buildFormFields(): void {
    this.currentMostConfidentDocument = this.getMostConfidentDocument(
      this.attachments[this.currentAttachmentIndex]
    );

    const savedState = this.formStates.get(this.currentAttachmentIndex);

    if (savedState && savedState.area) {
      this.initializeFormControls(savedState.area, savedState.documentTypeCode);
      this.documentForm.patchValue(savedState);
    } else {
      this.buildFormFieldsForNewAttachment();
    }
  }

  /**
   * Initializes the form controls for the document form
   * @param area - The selected area
   * @param documentTypeCode - The selected document type code
   */
  private initializeFormControls(area: string, documentTypeCode: string): void {
    const formControls: { [key: string]: FormControl } = {
      area: new FormControl(area),
      documentTypeCode: new FormControl(documentTypeCode)
    };
    this.filterDocumentTypes(area);
    this.currentDocumentType = this.filteredDocumentTypes.find(
      type => type.code === documentTypeCode
    );

    if (this.currentDocumentType) {
      this.addKeyFieldsToFormControls(formControls);
    }

    this.documentForm = this.fb.group(formControls);
  }

  /**
   * Builds form fields for a new attachment
   */
  private buildFormFieldsForNewAttachment(): void {
    const formControls: { [key: string]: FormControl } = {
      area: new FormControl(''),
      documentTypeCode: new FormControl('')
    };

    this.currentDocumentType = undefined;
    const attachment = this.attachments[this.currentAttachmentIndex];

    if (
      attachment.status &&
      Array.isArray(attachment.status.documents) &&
      attachment.status.documents.length > 0
    ) {
      this.setMappedKeyFields(attachment, formControls);
    } else {
      // Create empty form controls if no status is available
      this.initializeFormControls('', '');
    }
  }

  setMappedKeyFields(attachment: Attachment, formControls: { [key: string]: FormControl }): void {
    const document = this.getMostConfidentDocument(attachment);

    if (document) {
      const keyFields = this.extractKeyFields(document);
      this.mapKeyFields(document.classifyInfo?.documentType as string, keyFields).then(response => {
        if (response && response.mapItems.length > 0) {
          const mapItem = response.mapItems[0];

          attachment.mappedItems = response.mapItems;

          const docTypeCode = mapItem.docTypeCode;
          this.currentDocumentType = this.getDocumentTypeByCode(docTypeCode);
          this.populateFormControls(formControls, docTypeCode, mapItem.keyFields);

          this.documentForm = this.fb.group(formControls);

          this.handleLookupFields(mapItem.keyFields);
        }
      });
    }
  }

  /**
   * Extracts key fields from a document
   * @param document - The document from which to extract key fields
   * @returns {KeyField[]} - An array of extracted key fields
   */
  private extractKeyFields(document: any): KeyField[] {
    return document.extractInfo?.keyFields.map((field: KeyField) => ({
      ...field,
      value: field.value === null ? '' : field.value
    })) as KeyField[];
  }

  /**
   * Retrieves the most confident document from an attachment
   * @param attachment - The attachment containing documents
   * @returns {any} - The document with the highest confidence level
   */
  private getMostConfidentDocument(attachment: Attachment): any {
    if ((attachment.status?.documents.length ?? 0) < 1) {
      return null;
    }
    return attachment.status?.documents.reduce((prev, current) => {
      if (prev.classifyInfo && current.classifyInfo) {
        return prev.classifyInfo.confidence > current.classifyInfo.confidence ? prev : current;
      }
      return prev;
    });
  }

  /**
   * Retrieves the document type by its code
   * @param docTypeCode - The code of the document type
   * @returns {DocumentType | undefined} - The document type, if found
   */
  private getDocumentTypeByCode(docTypeCode: string | undefined): DocumentType | undefined {
    if (this.currentDocumentType?.areaCode) {
      return this.documentTypes.find(
        type => type.code === docTypeCode && type.areaCode === this.currentDocumentType?.areaCode
      );
    } else {
      return this.documentTypes.find(type => type.code === docTypeCode);
    }
  }

  /**
   * Populates the form controls with the selected area and document type code
   * @param formControls - The form controls to populate
   * @param docTypeCode - The selected document type code
   * @param mappedKeyFields - The key fields to populate
   */
  private populateFormControls(
    formControls: { [key: string]: FormControl },
    docTypeCode: string | undefined,
    mappedKeyFields: MappingKeyField[]
  ): void {
    if (this.currentDocumentType) {
      const areaCode = this.currentDocumentType.areaCode;
      this.filterDocumentTypes(areaCode);
      (formControls['area'] as FormControl).setValue(areaCode);
      (formControls['documentTypeCode'] as FormControl).setValue(docTypeCode);

      this.addKeyFieldsToFormControls(formControls);

      mappedKeyFields.forEach(mappedField => {
        const formControl = formControls[mappedField.key];
        if (formControl) {
          if (
            this.currentDocumentType?.keyfields.find(kf => kf.code === mappedField.key)
              ?.dataType === 'Date'
          ) {
            const date = new Date(mappedField.value);
            if (!isNaN(date.getDate())) {
              formControl.setValue(new Date(mappedField.value));
            }
          } else {
            formControl.setValue(mappedField.value);
          }
        }
      });

      this.documentForm.patchValue(this.documentForm.getRawValue(), { emitEvent: true });
    }
  }

  /**
   * Adds key fields to the form controls
   * @param formControls - The form controls to which key fields should be added
   */
  private addKeyFieldsToFormControls(formControls: { [key: string]: FormControl }): void {
    this.currentDocumentType?.keyfields.forEach(field => {
      formControls[field.code] = new FormControl('');
      this.applyFieldRules(field, formControls);
    });
  }

  /**
   * Handles lookup fields in the form, performing lookups as necessary
   * @param {MappingKeyField[]} mappedKeyFields - The key fields for lookup
   */
  private handleLookupFields(mappedKeyFields: MappingKeyField[]): void {
    if (this.currentDocumentType?.keyfields.some(keyfield => keyfield.dataType === 'Lookup')) {
      this.setLookup(mappedKeyFields);
    }
  }

  /**
   * Applies validation and default rules to a form control
   * @param field - The key field metadata
   * @param formControls - The form controls to which rules should be applied
   */
  applyFieldRules(field: DocumentTypeKeyField, formControls: { [key: string]: FormControl }) {
    if (field.default) {
      if (field.dataType === 'Date' && field.default === 'CurrentDate') {
        formControls[field.code].setValue(new Date());
      } else {
        formControls[field.code].setValue(field.default);
      }
    }

    if (field.required) {
      formControls[field.code].setValidators([Validators.required]);
    }

    if (field.maxLength) {
      formControls[field.code].setValidators([Validators.maxLength(field.maxLength)]);
    }

    if (field.textPattern) {
      formControls[field.code].setValidators([Validators.pattern(field.textPattern)]);
    }

    if (field.minValue !== undefined) {
      formControls[field.code].setValidators([Validators.min(field.minValue)]);
    }
  }

  /**
   * Shows a message indicating an unsupported file format
   * @param isUnsupportedFile - The unsupported file status
   */
  showUnsupportedFileMessage(isUnsupportedFile: any): void {
    if (isUnsupportedFile.detail) {
      this.isUnsupportedFile = true;
    }
  }

  /**
   * Maps key fields based on the classified document type and provided key fields
   * @param classifiedDocType - The classified document type
   * @param keyFields - The key fields to map
   * @param documentType - The document type to map to (optional)
   * @returns {Promise<MapResponse>} - A promise resolving to the map response
   */
  mapKeyFields(
    classifiedDocType: string,
    keyFields: KeyField[],
    documentType?: string
  ): Promise<MapResponse> {
    const mappingKeyFields: MappingKeyField[] = keyFields
      .filter(kf => kf.value !== undefined && kf.value !== null && kf.value !== '')
      .map(kf => ({ key: kf.key, value: kf.value }));

    return this.docTypeMappingSdk
      .mapKeyFields(this.tenantId, {
        modelDocType: classifiedDocType,
        keyFields: mappingKeyFields,
        docTypeCode: documentType
      })
      .then((response: MapResponse) => {
        return response;
      })
      .catch((error: ErrorResponse) => {
        console.error('Error mapping key fields: ', error);
        return undefined;
      });
  }

  /**
   * Kicks off the document analysis process for all attachments
   * @returns {Promise<void>} - A promise that resolves when all attachments have been analyzed
   */
  analyzeAttachments(): Promise<void> {
    return Promise.all(this.attachments.map(att => this.analyzeDocument(att)))
      .then(() => {})
      .catch(error => {
        console.error('Error processing attachments', error);
      });
  }

  /**
   * Selects an attachment and builds form fields for that attachment
   * @param index - The index of the selected attachment
   */
  selectAttachment(index: number): void {
    if (index >= 0 && index < this.attachments.length) {
      this.formStates.set(this.currentAttachmentIndex, this.documentForm.value);

      this.isUnsupportedFile = false;
      this.currentAttachmentIndex = index;

      if (
        this.attachments[this.currentAttachmentIndex].classificationStarted ||
        !this.isAutoClassificationEnabled
      ) {
        this.buildFormFields();
      } else {
        this.initializeDocumentForm(); // Initialize the form if no classification has started
      }
    }
  }

  /**
   * Performs a lookup on key fields that are of type 'Lookup'
   * @param {MappingKeyField[]} mappedKeyFields - The mapped key field data to use for the lookup
   */
  setLookup(mappedKeyFields: MappingKeyField[] | undefined): void {
    this.currentDocumentType?.keyfields
      .filter(keyfield => keyfield.dataType === 'Lookup')
      .forEach(keyfield => {
        if (keyfield.lookupType?.namingFields.some(namingField => namingField.required)) {
          let lookupSearchTerm = '';
          keyfield.lookupType.namingFields
            .filter(namingField => namingField.required)
            .forEach(namingField => {
              const matchingField = mappedKeyFields?.find(
                mappedField => mappedField.key === `${keyfield.code}.${namingField.fieldCode}`
              );
              if (matchingField) {
                lookupSearchTerm += `${matchingField.value} `;
              }
            });

          lookupSearchTerm = lookupSearchTerm.trim();
          if (
            lookupSearchTerm &&
            this.attachments[this.currentAttachmentIndex].classificationStarted
          ) {
            this.keyFieldsComponent.lookupUsers(
              keyfield.code,
              keyfield.lookupType.code,
              lookupSearchTerm,
              true
            );
          }
        }
      });
  }

  /**
   * Analyzes an attachment using the autoclassifier SDK
   * @param attachment - The attachment to analyze
   * @returns {Promise<void>} - A promise that resolves when the analysis is complete
   */
  async analyzeDocument(attachment: Attachment): Promise<void> {
    if (attachment.content.format === Office.MailboxEnums.AttachmentContentFormat.Base64) {
      const file = base64ToFile(
        attachment.content.content,
        attachment.name,
        attachment.contentType
      );
      this.autoclassifierSdk
        .extractDocumentFromUpload(file, true)
        .then((response: ExtractionResponse) => {
          const index = this.attachments.findIndex(att => att.id === attachment.id);
          this.attachments[index].requestId = response.requestId;
        })
        .catch((error: ErrorResponse) => {
          console.error('Error analyzing document:', error);
          this.showErrorToast('analyzeError', attachment.name);
        });
    } else {
      console.warn('Cannot handle an unsupported attachment format.');
    }
  }

  /**
   * Starts the classification process for the currently selected attachment
   */
  startAttachmentClassification(): void {
    this.attachments[this.currentAttachmentIndex].classificationStarted = true;
    this.spinner.show(
      'fileClassificationSpinner-' + this.attachments[this.currentAttachmentIndex].name
    );

    if (this.attachments[this.currentAttachmentIndex].status) {
      this.buildFormFields();
    }
  }

  /**
   * Uploads the attachment with type, area, and key field information to Softdocs Etrieve
   */
  uploadDocument(): void {
    if (this.documentForm?.valid) {
      const formData = buildKeyFields(
        this.documentForm.value,
        this.currentDocumentType as DocumentType
      );

      const multiValueFields = this.currentDocumentType?.keyfields.filter(kf => kf.multivalue);

      if (multiValueFields) {
        multiValueFields.forEach(multiValueField => {
          const multValueFieldCode = multiValueField.code + '-' + this.currentDocumentType?.code;
          if (this.keyFieldsComponent.multiValueFields[multValueFieldCode]) {
            this.keyFieldsComponent.multiValueFields[multValueFieldCode].forEach(value => {
              formData.push({
                fieldCode: multiValueField.code,
                value: value
              });
            });
          }
        });
      }

      this.contentService
        .uploadDocument(
          this.attachments[this.currentAttachmentIndex],
          this.documentForm.controls['area'].value as string,
          this.documentForm.controls['documentTypeCode'].value as string,
          formData
        )
        .subscribe({
          error: error => {
            console.error('Failed to upload document', error);
            this.showErrorToast('uploadError');
            this.keyFieldsComponent.onUploadComplete();
          },
          complete: () => {
            this.showUploadDoneToast();
            this.keyFieldsComponent.onUploadComplete();
            this.attachments[this.currentAttachmentIndex].completed = true;
            if (this.attachments.findIndex(att => !att.completed) !== -1) {
              this.selectAttachment(this.attachments.findIndex(att => !att.completed));
            } else {
              this.currentAttachmentIndex = -1;
            }
          }
        });
    } else {
      console.error('Form is not valid, cannot upload document.');
      this.keyFieldsComponent.onUploadComplete();
      this.showErrorToast('validationError');
    }
  }

  /**
   * Retrieves the file selector attachments
   * @returns {FileSelectorAttachment[]} - An array of file selector attachments
   */
  getFileSelectorAttachments(): FileSelectorAttachment[] {
    return this.attachments as FileSelectorAttachment[];
  }

  /**
   * Shows a toast message indicating that the file upload is complete
   */
  showUploadDoneToast(): void {
    this.messageService.add({
      severity: 'success',
      summary: 'uploadToastTitleAddIn',
      detail: 'uploadToastMessageAddIn',
      key: 'uploadDoneToast',
      life: 10000
    });
  }

  /**
   * Shows an error toast message
   * @param toastMessage - The message to display in the toast
   * @param toastData - Additional data to display (optional)
   */
  showErrorToast(toastMessage: string, toastData?: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: toastMessage,
      key: 'errorToast',
      data: toastData ? toastData : '',
      life: 10000
    });
  }

  /**
   * Opens a new browser window with the content URL
   */
  goToContent(): void {
    if (localStorage.getItem('contentURL')) {
      window.open(localStorage.getItem('contentURL')?.toString(), '_blank', 'location=yes');
    }
  }

  /**
   * Closes the dialog window and signals to Outlook that the dialog should be closed
   */
  closeDialog(): void {
    // Signal to Outlook to close the dialog window
    Office.context.ui.messageParent(JSON.stringify({ status: 'closeDialog' }));
    window.close();
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
    this.subscription.unsubscribe();
  }

  /**
   * Remove URL verificaetion items from local storage and reload the page to trigger the URL verification process
   */
  changeContentUrl(): void {
    localStorage.removeItem('tenantId');
    localStorage.removeItem('vanityName');
    location.reload();
  }

  /**
   * Signs out the user of the application and redirects them back to the log in page
   */
  signOut(): void {
    const baseUrl = ContextHolder.getContext().baseUrl;
    window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
  }

  /**
   * Sets the current attachment status to an empty object, cancelling the auto-classification process
   */
  cancelFileAutoClassification(index: number): void {
    this.attachments[index].status = {
      documents: []
    };
  }
}
