import _extends from "@babel/runtime/helpers/esm/extends";
import { ContextHolder } from './ContextHolder';
import { FronteggApiError } from './error';
import { FRONTEGG_TRACE_ID, GENERIC_ERROR_CODE, GENERIC_ERROR_MESSAGE } from './constants';
import { fronteggHeaders } from './interfaces';

/**
 * FetchClient is a class that wrap around the fetch API to make requests.
 * It is used by the BaseApiClient to make requests to the server
 */
export class FetchClient {
  constructor(appName) {
    this.appName = void 0;
    this.getFronteggContext = () => {
      return ContextHolder.for(this.appName);
    };
    this.prepareUrl = async (url, params) => {
      const context = this.getFronteggContext().getContext();
      const baseUrl = this.getContextBaseUrl(url);
      const paramsToSend = await this.buildQueryParams(context, params);
      let finalUrl = url.startsWith('http') ? url : `${baseUrl}${url}`;
      const hasKeys = Object.keys(paramsToSend).length > 0;
      if (paramsToSend && hasKeys) {
        const urlParams = new URLSearchParams(paramsToSend);
        finalUrl += `?${urlParams}`;
      }
      return finalUrl;
    };
    this.buildQueryParams = async (context, params) => {
      if (!params) {
        params = {};
      }
      const additionalQueryParams = await this.getAdditionalQueryParams(context);
      for (const queryParam of additionalQueryParams) {
        params[queryParam.key] = queryParam.value;
      }
      const keys = Object.keys(params);
      for (const key of keys) {
        const value = params[key];
        params[key] = typeof value === 'object' ? JSON.stringify(value) : value;
      }
      return params;
    };
    this.getAdditionalQueryParams = async context => {
      let output = [];
      if (context.additionalQueryParamsResolver) {
        output = await context.additionalQueryParamsResolver();
      }
      return output;
    };
    this.getAdditionalHeaders = async context => {
      let output = [];
      if (context.additionalHeadersResolver) {
        output = await context.additionalHeadersResolver();
      }
      if (context.tenantResolver) {
        const resolvedTenantResult = await context.tenantResolver();
        if (!!(resolvedTenantResult != null && resolvedTenantResult.tenant)) {
          output.push({
            key: 'frontegg-login-alias',
            value: resolvedTenantResult.tenant
          });
        }
      }
      if (context.appId) {
        output.push({
          key: 'frontegg-requested-application-id',
          value: context.appId
        });
      }
      return output;
    };
    this.buildRequestHeaders = async contentType => {
      var _context$tokenResolve;
      const fronteggContext = this.getFronteggContext();
      const context = fronteggContext.getContext();
      const headers = FetchClient.getMetadataHeaders(context);
      const authToken = await ((_context$tokenResolve = context == null ? void 0 : context.tokenResolver) != null ? _context$tokenResolve : fronteggContext.getAccessToken)();
      const requestSource = fronteggContext.getRequestSource();
      const scopedTenant = FetchClient.getScopedTenant();
      if (contentType) {
        headers[fronteggHeaders.contentType] = contentType;
      }
      if (requestSource) {
        headers[fronteggHeaders.source] = requestSource;
      }
      if (authToken) {
        headers.Authorization = `Bearer ${authToken}`;
      }
      if (scopedTenant) {
        headers[fronteggHeaders.scopedTenant] = scopedTenant;
      }
      for (const additionalHeader of await this.getAdditionalHeaders(context)) {
        headers[`${additionalHeader.key}`] = `${additionalHeader.value}`;
      }
      return headers;
    };
    this.getResponseTraceId = response => {
      var _response$headers, _response$headers$get;
      return response == null ? void 0 : (_response$headers = response.headers) == null ? void 0 : (_response$headers$get = _response$headers.get) == null ? void 0 : _response$headers$get.call(_response$headers, FRONTEGG_TRACE_ID);
    };
    this.sendRequest = async opts => {
      var _opts$method, _ref, _opts$credentials;
      const context = this.getFronteggContext().getContext();
      const headers = await this.buildRequestHeaders(opts.contentType);
      const url = await this.prepareUrl(opts.url, opts.params);
      const interceptor = context.beforeRequestInterceptor;
      const initialRequestOptions = {
        body: opts.body ? opts.contentType === 'application/json' ? JSON.stringify(opts.body) : opts.body : null,
        method: (_opts$method = opts.method) != null ? _opts$method : 'GET',
        headers: _extends({}, headers, opts.headers),
        credentials: (_ref = (_opts$credentials = opts.credentials) != null ? _opts$credentials : context.requestCredentials) != null ? _ref : 'same-origin'
      };
      const requestOptions = interceptor ? interceptor(initialRequestOptions, url) : initialRequestOptions;
      const response = await fetch(url, requestOptions);
      if (response.status === 302) {
        window.location.href = await response.text();
        return new Promise(() => {});
      }
      if (!response.ok) {
        var _errorMessage, _errorMessage2, _context$logLevel, _context$logLevel2, _errorCode;
        if (response.status === 413) {
          throw new FronteggApiError('Error request is too large', response.status, this.getResponseTraceId(response), undefined);
        }
        let errorMessage;
        let errorCode;
        let errorContext;
        let isJsonResponse = true;
        try {
          errorMessage = await response.text();
          errorMessage = JSON.parse(errorMessage);
        } catch (e) {
          isJsonResponse = false;
        }
        if ((_errorMessage = errorMessage) != null && _errorMessage.errorCode) {
          errorCode = errorMessage.errorCode;
        }
        if ((_errorMessage2 = errorMessage) != null && _errorMessage2.context && typeof errorMessage.context === 'object') {
          errorContext = errorMessage.context;
        }
        if (errorMessage.errors) {
          errorMessage = errorMessage.errors.join(', ');
        } else if (typeof errorMessage !== 'string') {
          errorMessage = `Error ${response.status} - ${response.statusText}`;
        }
        if (response.status >= 400 && response.status < 500 && ['warn'].includes((_context$logLevel = context.logLevel) != null ? _context$logLevel : '')) console.warn(errorMessage);else if (response.status === 500 && ['warn', 'error'].includes((_context$logLevel2 = context.logLevel) != null ? _context$logLevel2 : '')) console.error(errorMessage);
        throw new FronteggApiError(isJsonResponse ? errorMessage : GENERIC_ERROR_MESSAGE, response.status, this.getResponseTraceId(response), (_errorCode = errorCode) != null ? _errorCode : GENERIC_ERROR_CODE, errorContext);
      }
      if (opts.responseType === 'stream') {
        return response.body;
      }
      if (!opts.responseType || opts.responseType === 'json') {
        try {
          return await response.json();
        } catch (e) {
          return {};
        }
      } else if (opts.responseType === 'blob') {
        const {
          outputFileName
        } = opts.params;
        return await response.blob().then(blob => URL.createObjectURL(blob)).then(url => {
          const tempLink = document.createElement('a');
          tempLink.href = url;
          tempLink.setAttribute('download', outputFileName || 'output');
          tempLink.click();
        });
      } else {
        return await response.text();
      }
    };
    this.get = async (url, params, opts) => this.sendRequest(_extends({
      url,
      method: 'GET',
      contentType: 'application/json',
      params
    }, opts));
    this.post = async (url, body, opts) => this.sendRequest(_extends({
      url,
      method: 'POST',
      contentType: 'application/json',
      body
    }, opts));
    this.patch = async (url, body, opts) => this.sendRequest(_extends({
      url,
      method: 'PATCH',
      contentType: 'application/json',
      body
    }, opts));
    this.put = async (url, body, opts) => this.sendRequest(_extends({
      url,
      method: 'PUT',
      contentType: 'application/json',
      body
    }, opts));
    this.delete = async (url, body, opts) => this.sendRequest(_extends({
      url,
      method: 'DELETE',
      contentType: 'application/json',
      body
    }, opts));
    this.postDownload = async (url, body, params, opts) => this.sendRequest(_extends({
      url,
      method: 'POST',
      contentType: 'application/json',
      responseType: 'blob',
      body,
      params
    }, opts));
    this.extractHeadersFromOptions = (options = {}) => _extends({}, options.jwt ? {
      Authorization: options.jwt
    } : {});
    this.getBaseUrl = (context, url, withFronteggPrefix = true) => {
      return FetchClient.getBaseUrl(context, url, withFronteggPrefix);
    };
    this.getContextBaseUrl = (url, withFronteggPrefix = true) => {
      const context = this.getFronteggContext().getContext();
      return this.getBaseUrl(context, url, withFronteggPrefix);
    };
    this.getMetadataHeaders = context => {
      return FetchClient.getMetadataHeaders(context);
    };
    this.getContextMetadataHeaders = () => {
      const context = this.getFronteggContext().getContext();
      return FetchClient.getMetadataHeaders(context);
    };
    this.appName = appName;
  }
}

/**
 * import FetchClient and use it to make requests to the server for specific application
 * @param appName - the application name to make requests for
 * @deprecated - use FetchClient directly
 */
FetchClient.getBaseUrl = (context, url, withFronteggPrefix = true) => {
  let baseUrl;
  if (typeof context.baseUrl === 'function') {
    baseUrl = context.baseUrl(url);
  } else {
    baseUrl = context.baseUrl;
  }
  const prefix = context.urlPrefix || 'frontegg';
  const removePrefix = prefix === 'frontegg' && !withFronteggPrefix;
  if (!baseUrl.endsWith('/')) {
    baseUrl += '/';
  }
  if (!baseUrl.endsWith(prefix) && !removePrefix) {
    baseUrl += prefix;
  }
  return baseUrl;
};
FetchClient.getMetadataHeaders = context => {
  var _context$metadataHead, _context$metadataHead2;
  const headers = {};
  if (context != null && (_context$metadataHead = context.metadataHeaders) != null && _context$metadataHead.fronteggSdkVersion) {
    headers[fronteggHeaders.fronteggSdkVersion] = context.metadataHeaders.fronteggSdkVersion;
  }
  if (context != null && (_context$metadataHead2 = context.metadataHeaders) != null && _context$metadataHead2.framework) {
    headers[fronteggHeaders.frameWork] = context.metadataHeaders.framework;
  }
  return headers;
};
FetchClient.getScopedTenant = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const scopedTenant = urlParams.get('tenantId');
  if (scopedTenant) {
    return scopedTenant;
  }
  return null;
};
export default new FetchClient('default');